import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { withRouter } from "react-router";
import LeftBar from '../Site/LeftBar';
import UserProfile from './UserProfile';

import './style.css';

class User extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);
    
    const { location } = this.props
    //console.log('path: '+ location.pathname )
    //console.log('clientname: '+ location.clientname)


    var pathArray = location.pathname.split('/');
    
    //pathArray[1] = order
    //pathArray[2] = client id
    //pathArray[3] = order id
    
    //console.log('arr: '+ pathArray)
    
    this.state = {
        userId: pathArray[2]
    };
  }

  render() {
    return (
      <div>
        <LeftBar />
        <UserProfile userId={this.state.userId} />
      </div>

    );
  }
}

export default withRouter(User);