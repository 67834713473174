// Header.js
import React from 'react';
import 'react-table/react-table.css';
import 'react-dropdown/style.css';
import Barcode from 'react-barcode';
import './print.css';

class RackPrintLabel extends React.Component {
   constructor(props) {
        super(props);
            this.state = {
                        
        };
             
    }     

    range = (start, end) => {
        var ans = [];
        for (let i = start; i <= end; i++) {
            ans.push(i);
        }
        return ans;
    }

    pad = (number, length) => {
   
      var str = '' + number;
      while (str.length < length) {
          str = '0' + str;
      }
     
      return str;
  
  }

    createLabels = () => {

      //1A-01-1 thru 1A-27-1 to 1A-01-5 thru 1A-27-5. Same range for the other side which is 1B. Then 12 isles with this range so from 1A to 12B.
      //We also have C's and D's but these will have a slightly different numbering concept as we will be using 100's for these like so... 1C-01-100 thru 103. 
                 
      let table = []

      var _printLocFromA = this.props.printLocationFromA
      var _printLocFromB = this.props.printLocationFromB //letter is the same
      var _printLocFromC = this.props.printLocationFromC
      var _printLocFromD = this.props.printLocationFromD
        
      var _printLocToA = this.props.printLocationToA
      var _printLocToB = this.props.printLocationToB //letter is the same
      var _printLocToC = this.props.printLocationToC
      var _printLocToD = this.props.printLocationToD
    
      var hasRange = false;

      var aRange = [];
      var cRange = [];
      var dRange = [];

      if (_printLocToA > _printLocFromA) {
        aRange = this.range(_printLocFromA,_printLocToA);
        console.log(JSON.stringify(aRange))
        hasRange=true;
      } else {
          aRange.push(_printLocFromA);
      }

      if (_printLocToC > _printLocFromC) {
        cRange = this.range(_printLocFromC,_printLocToC);
        console.log(JSON.stringify(cRange))
        hasRange=true;
      } else {
        cRange.push(_printLocFromC);
      }

      if (_printLocToD > _printLocFromD) {
        dRange = this.range(_printLocFromD,_printLocToD);
        console.log(JSON.stringify(dRange))
        hasRange=true;
      } else {
        dRange.push(_printLocFromD);
      }

      if (hasRange) {
        var _key=1;        
        aRange.forEach((objA) => {
            cRange.forEach((objC) => {
                dRange.forEach((objD) => {  

                    var _printLocation = objA+''+_printLocFromB+'-'+this.pad(objC,2)+'-'+objD
                    console.log(_printLocation)

                    let tabId = `tab-${_key}`;
                   
                    table.push(<div key={tabId} className="label">{_printLocation}</div>);
                    table.push(<div key={tabId} className="label"><Barcode value={_printLocation} displayValue={false} /></div>);
                    _key++;
                })
            })
        })
      } else {
        var _printLocation = _printLocFromA+''+_printLocFromB+'-'+this.pad(_printLocFromC,2)+'-'+_printLocFromD

        for (let i = 0; i < this.props.printQty; i++) {
        
            let tabId = `tab-${i}`;
 
            table.push(<div key={tabId} className="label">{_printLocation}</div>);
            table.push(<div key={tabId} className="label"><Barcode value={_printLocation} displayValue={false} /></div>);
           
        }        
      }
      
      return table;     

    } 
    
    render() {
        
    return (
      <div className="print">
             
        {this.createLabels()}   
     
      </div>
    );
  }
}
export default RackPrintLabel;