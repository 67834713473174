import React, { Component } from 'react';
import { withRouter } from "react-router";
import CustomerBar from '../Site/CustomerBar';
import ProductKitList from './ProductKitList';

import './style.css';

class ProductKits extends Component {

  render() {
    return (
      <div>
        <CustomerBar />
        <ProductKitList />
      </div>    


    );
  }
}

export default withRouter(ProductKits);