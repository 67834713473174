import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import LeftBar from '../Site/LeftBar';
import ReceiverContent from './ReceiverContent';

import './style.css';

class ReceiverDetail extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }


  constructor(props) {
    super(props);
    
    const { location } = this.props
    console.log('path: '+ location.pathname )

    var pathArray = location.pathname.split('/');
    //pathArray[1] = order
    //pathArray[2] = client id
    //pathArray[3] = order id
    
    console.log('arr: '+ pathArray)
    this.state = {
        clientId: pathArray[2],
        receiverId : pathArray[3]
    };
  }

  render() {
    return (
      <div>       
        <LeftBar />
        <ReceiverContent clientId={this.state.clientId} receiverId={this.state.receiverId} />
      </div>    


    );
  }
}

export default withRouter(ReceiverDetail);