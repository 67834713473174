import React, { Component } from 'react';
import LeftBar from '../Site/LeftBar';
import Content from '../Site/Content';
import PropTypes from 'prop-types'

//import { state } from 'aws-cognito-redux-saga'

export class ProtectedComponent extends Component {
  static propTypes = {
    isSignedIn: PropTypes.string,
    auth: PropTypes.object
  }

  render() {
    //const { auth } = this.props

    return (
      <div>
        <LeftBar />
        <Content />
      </div>   
       
    )
  }
}
