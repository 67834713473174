import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Select from 'react-select';
import Modal from 'react-modal';
import 'react-dropdown/style.css';
import ReactToPrint from "react-to-print";
import ProductPrintLabel from '../Products/ProductPrintLabel';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import 'react-dropdown/style.css';
import matchSorter from 'match-sorter';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '400px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};

const printModalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '500px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};
class ProductCaseDetail extends Component {

    constructor(props) {
        super(props);          

        this.state = {
            upc_search: '',              
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'), 
            cs_id: typeof(this.props.cs_id) === 'undefined' ? '' : this.props.cs_id, 
            pr_id: '',           
            cases: [],
            products: [],
            selectedproduct:'',
            productcases: [],
            cs_name:'',
            cs_sku:'',
            cs_upc:'',
            cs_qty:'',
            cs_active:'',
            editModalMessage: '',
            editModalIsOpen: false,
            disableAddButton: true,
            edit_cs_id: '',
            edit_cs_name: '',
            edit_cs_sku: '',
            edit_cs_qty: '',
            edit_cs_upc: '',   
            edit_cs_active:'',
            labelModalIsOpen: false,
            printQty: 1,
            casePrefix: 'Case - ',
            userToken: localStorage.getItem('userToken')
        };               
        
        //TODO: finish all event handlers Add clock in and clock out to the login/logout actions. Do we auto log out people? 
        
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.handleQtyInputChange = this.handleQtyInputChange.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.getProductCases = this.getProductCases.bind(this);
        this.updateCases = this.updateCases.bind(this);
        this.handleStatusInputChange = this.handleStatusInputChange.bind(this);
        this.handleNewStatusInputChange = this.handleNewStatusInputChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleLabelPrint = this.handleLabelPrint.bind(this);
        this.getSku = this.getSku.bind(this);
        this.goSku = this.goSku.bind(this);

        this.openLabelModal = this.openLabelModal.bind(this);
        this.afterOpenLabelModal = this.afterOpenLabelModal.bind(this);
        this.closeLabelModal = this.closeLabelModal.bind(this);

        this.afterOpenEditModal = this.afterOpenEditModal.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
               
    }

    componentDidMount() {
      Modal.setAppElement('#main');
      this.handleClientChange(this.props.cl_id);      
    }
   
    componentDidUpdate(prevProps, prevState) {
     
    }

    handleLabelPrint = (e) => {
      //console.log('print qty: ' + this.state.printQty);
      e.preventDefault();      
      //alert('print label ');
      return false;
    }

    //LABEL
    openLabelModal = (e) => {
     
        this.setState({labelModalIsOpen: true});
        this.setState({cs_sku: e.cs_sku});
        this.setState({printUPC: e.cs_upc});  

    }

    afterOpenLabelModal = (e) => {

    }

    closeLabelModal() {
      this.setState({labelModalIsOpen: false});
      this.setState({printQty:1});
    } 
    
    afterOpenEditModal() {
    }
    
    //EDIT
    //
    openEditModal = (e) => {    

      this.setState({editModalIsOpen: true});    

      console.log(JSON.stringify(e.original));
      
      this.setState({edit_cs_id: e.original.cs_id});
      this.setState({edit_cs_name: e.original.cs_name});
      this.setState({edit_cs_sku: e.original.cs_sku});
      this.setState({edit_cs_qty: e.original.cs_qty});
      this.setState({edit_cs_upc: e.original.cs_upc});
      this.setState({edit_cs_active: e.original.cs_active});

    }
    
    
    closeEditModal() {
      this.setState({editModalIsOpen: false});      
    }

    handleUpdateCase = (event) => {
      event.preventDefault();
      this.setState({editModalIsOpen: false}); 

      var caseJSON = JSON.stringify({
        cs_id: this.state.edit_cs_id,
        cs_name: this.state.edit_cs_name,
        cs_sku: this.state.edit_cs_sku,
        cs_upc: this.state.edit_cs_upc,
        cs_qty: this.state.edit_cs_qty,        
        cs_active: this.state.edit_cs_active
      });

     //console.log(caseJSON)
      
     
     fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/cases/u/'+this.state.edit_cs_id, {
            method: 'post',
            body: caseJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            //console.log(response);
        }).then(function(data) { 
            //console.log(data);      
            alert('Case updated');  
            window.location.reload();     
        }).catch(function(error) {
            alert('Case could not be updated'); 
            //console.log('submit err: ' + error);       
        });          
        
    }



    // EVENT HANDLERS

    getSku = () => {

      var otherSku = this.state.cs_sku;

      var otherJSON = JSON.stringify({
          cl_id: this.state.cl_id,
          sku: otherSku            
      });

      if (otherSku !== '')
      {        
          fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/sku/'+this.state.cl_id, {
              method: 'post',
              body: otherJSON,      
              headers: {            
              'Content-Type': 'application/json',
              'bwToken': this.state.userToken          
              }
              //TODO:
              //credentials: 'same-origin', // send cookies
              //credentials: 'include'   // send cookies, even in CORS
          
          }).then(res => res.json())
          .then(product => this.setState({ product }))
          .then(this.goSku).catch(function(error) {
              alert('Other SKU could not be updated. SKU should be unique or contact support.'); 
              //console.log('submit err: ' + error);       
          });  
      }
    }
  
    goSku = () => {
    
      const product = this.state.product; 
      console.log('issku: ' + JSON.stringify(product))

      let somevar = product[0];

      let ind = somevar[0];

      if (typeof ind==='undefined') {
        ind = somevar[1];          
      }          
      
      if (ind === 1) {
        alert('SKU is in use. Check your value and try again.');
        this.setState({cs_sku:''});
        return false;
      } else {
        return true;
      }    
      
    }


    goBack() {
      window.history.back();
    }

    getProducts = (url) => {

        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(products => this.setState({ products })); 

    }

    getProductCases = (url) => {
      fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(productcases => this.setState({ productcases }));
    }
    
    getCases(prId) {
      //console.log('get cases');
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/cases/'+prId,
      {
          method: 'GET',      
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }

          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(res => res.json())
      .then(cases => this.setState({ cases }));
    }

    handleClientChange = (cl_id) => {
               
        if (cl_id === 0) {
            this.getProducts(process.env.REACT_APP_NODE_ROOT_URL+'/products');
            this.getProductCases(process.env.REACT_APP_NODE_ROOT_URL+'/products/cases');
            this.setState({disableAddButton: true});

        } else {
            this.getProducts(process.env.REACT_APP_NODE_ROOT_URL+'/products/client/'+cl_id);       
            this.getProductCases(process.env.REACT_APP_NODE_ROOT_URL+'/products/cases/client/'+cl_id);     
            this.setState({disableAddButton: false});
        }    
    }    
    
    handleProductChange = (e) => {
      console.log(e);      
      this.setState({selectedproduct: e});
      this.getCases(e.value);
      this.setState({cs_sku: this.state.casePrefix + e.pr_sku + ' '});
      this.setState({cs_name: 'Case ' + ' ' + e.pr_desc});
      
    }

    handleStatusInputChange(event,i) {

      const target = event.target;
      const value = target.value;     

      this.setState({cs_active: value});         
    
    }

    handleUpdateStatusInputChange(event,i) {

      const target = event.target;
      const value = target.value;     

      this.setState({edit_cs_active: value});         
    
    }

    handleNewStatusInputChange(event,i) {

      const target = event.target;
      const value = target.value;     

      let {cases} = this.state;  

      cases[i].cs_active = value;

      this.setState({cases});         
    
    }

    handleSubmit = (e) => {

      e.preventDefault();

      var caseJSON = JSON.stringify({
        cs_name: this.state.cs_name,
        cs_sku: this.state.cs_sku,
        cs_upc: this.state.cs_upc,
        cs_qty: this.state.cs_qty,
        cs_active: this.state.cs_active,
        cl_id: this.state.cl_id,
        pr_id: this.state.selectedproduct.value
      });

      //console.log(caseJSON);
      
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/cases/'+this.state.cl_id, {
            method: 'post',        
            body: caseJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            return response.json();
        }).then(function(data) {            
            alert('Case added successfully'); 
            window.location.reload();   
        }).catch(function(error) {
            alert('Case could not be added. UPC should be unique or contact support.'); 
            //console.log('submit err: ' + error);       
        }); 
        
    }

    handleUpdate = (event) => {

      event.preventDefault();

      if (this.state.cases.length > 0) {
        //console.log('update cases')
     
        const updaterequests = this.state.cases.map((caseitem) =>         
          this.updateCases(caseitem.cs_id,caseitem.cs_name,caseitem.cs_sku,caseitem.cs_upc,caseitem.cs_qty,caseitem.cs_active)          
        );
      
        Promise.all(updaterequests).then(() => {
          //console.log('Case updates done')
          alert('Cases updated successfully')
          window.location.reload();
        });      
        
      } else { console.log('no case items to update') }

      
    }
    
    updateCases = (csId,csName,csSku,csUpc,csQty,csActive) => {

      var caseJSON = JSON.stringify({  
        cs_id: csId,
        cs_name: csName,
        cs_sku: csSku,
        cs_upc: csUpc,         
        cs_qty: csQty,
        cs_active: csActive
      });

     //console.log(caseJSON)     
    
     fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/cases/u/'+csId, {
            method: 'post',
            body: caseJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            //console.log(response);
        }).then(function(data) { 
            //console.log(data);            
        }).catch(function(error) {
            alert('Case could not be updated. UPC should be unique or contact support.'); 
            //console.log('submit err: ' + error);       
        });
        
    }
            
    deleteCase = (csID) => {

      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/cases/del/'+csID, {
            method: 'post',             
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            //console.log(response);
        }).then(function(data) { 
            //console.log(data);
          window.location.reload();           
        }).catch(function(error) {
            alert('Case could not be deleted. Contact support.'); 
            //console.log('submit err: ' + error);       
        });                
    }      

    handleQtyInputChange(event,i) {

      const target = event.target;
      const value = target.value;

      let {cases, selectedproduct} = this.state;  
      /*
      The SKU would be *Case-(item sku)-(number of items in case)*
      Then the description would be *Case (number of items in case) -
      (description of item)*
      */
      
      cases[i].cs_qty = Number(value);
      cases[i].cs_sku = this.state.casePrefix + selectedproduct.pr_sku + ' - ' + value;
      cases[i].cs_name = 'Case ' + value + ' ' + selectedproduct.pr_desc;

      this.setState({cases});
    
    }  


    handleInputChange(event) {      
   
      const target = event.target;
      const value = target.value;
      const name = target.name;
      
      this.setState({
       [name]: value
      });

      if (name=='cs_qty') {
        this.setState({cs_sku: this.state.casePrefix + this.state.selectedproduct.pr_sku + ' - ' + value});
        this.setState({cs_name: 'Case ' + value + ' ' + this.state.selectedproduct.pr_desc});
      }
    }

    handleKeyPress = (event) => {
      if(event.key === 'Enter'){
          //set upc from search box
          var find_upc = this.state.upc_search;
          
          this.findUPC(find_upc);       
          
          //used to track total scans if needed
          //this.setState((state) => ({ scans: state.scans + 1}));
          
          //TODO: check case value 
          
      }
    } 

    onRemoveItem = (i,cId) => {      
            
      this.setState(state => {
      const cases = state.cases.filter((item, j) => i !== j);
        return {
          cases,
        };
      });     
      
      this.deleteCase(cId);
      
    }    

    render(){     

      //case items
      //const mappingFunction = p => ({ id: p.pr_id, label: p.pr_desc, sku: p.pr_sku, price: p.pr_price, qty: 0 });

      const mappingFunction = p => ({ label: p.pr_sku + ' - ' + p.pr_desc + ' (' + p.pr_upc + ')', value: p.pr_id, qty:1, qoh: p.pr_qty, qty_res: p.pr_qty_reserved, pr_id: p.pr_id, cl_id: p.cl_id, pr_desc: p.pr_desc, pr_sku: p.pr_sku });


      //product drop down
      const productsearch = this.state.products.map(mappingFunction);
           
      const { upc_search, productcases } = this.state;

      const notifyOptions = [
        'Yes','No'
      ]
  
        return (
          <div id="main">
            <div className="page-wrapper">
               <div className="row page-titles">
               <div className="col-md-12">
                       <h3 className="text-primary">Product Cases</h3> 
               </div>
                       
            <div className="container-fluid">   
                   
            <div className="row">  
                  <div className="col-md-12">
                  <div id="accordion">

                  <div className="card">
                    <div className="card-header" id="headingThree" style={{padding:"0px"}}>
                      <h4 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                         Instructions
                        </button>
                      </h4>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                      
                      <div className="card-body" style={{marginLeft:"10px", marginTop:"10px"}}>
                        <p>Case SKU CANNOT be the same as any other SKU. Naming is automatic.</p>
                        <p>Cases are used to define a one to one mapping between an incoming SKU and and existing SKU with a quantity of more than 1. </p>
                        <p>Example: Case-SKU-111-12 contains quantity 12 of SKU-111</p>                        
                        <p>Cases can be used as-is for Receiving and Orders. Ideally, match the Case UPC to the scannable Case bar code. </p>
                        <p>Case UPC can match Product UPC for easy scanning, but must be unique as a Case.</p>
                        <p>Marking Cases as Inactive will preserve historical data. Deleting Cases should only be used on new cases without orders.</p>                    
                        <p>Use the grid below to find and edit an existing case.</p>
                      </div>
                      
                    </div>
                  </div>
                </div>                  
                
                    <ReactTable                
                      data={productcases}
                      filterable                      
                      defaultFilterMethod={(filter, row) =>
                      String(row[filter.id]) === filter.value}          
                      columns={[
                        {                                          
                          columns: [                                            
                            {
                              Header: "Delete",
                              accessor: "cs_id",
                              width: 50,
                              filterable: false,
                              Cell: props => (
                                <div><button type="button" onClick={() => { if (window.confirm('Delete this case?')) this.deleteCase(props.original.cs_id) }} className="btn btn-xs">del</button></div> 
                              )
                            },
                            {
                              Header: "Edit",
                              accessor: "cs_id",
                              width: 50,
                              filterable: false,
                              Cell: props => (
                                <div><button onClick={(e) => this.openEditModal(props)} className="btn btn-xs">edit</button></div>                                                
                              )
                            },
                            {
                              Header: "Case ID",
                              accessor: "cs_id",
                              width: 25,
                              show: false,
                              filterMethod: (filter, row) =>
                                row[filter.id].startsWith(filter.value)          
                            },
                            {
                              Header: "Client ID",
                              accessor: "cl_id",
                              width: 25,
                              show: false,
                              filterMethod: (filter, row) =>
                                row[filter.id].startsWith(filter.value),
                              Cell: row => (
                                <div style={{ textAlign: "center"}}>{row.value}</div>
                              )
                            },
                            {
                              Header: "Product SKU",
                              accessor: "pr_sku",
                              width: 100,
                              filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, { keys: ["pr_sku"] }),
                              filterAll: true,
                              Cell: row => (
                                <div style={{ textAlign: "left"}}>{row.value}</div>
                              )
                            },
                            {
                              Header: "Case Name",
                              accessor: "cs_name",
                              width: 300,
                              filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, { keys: ["cs_name"] }),
                              filterAll: true,
                              Cell: row => (
                                <div style={{ textAlign: "left"}}>{row.value}</div>
                              )
                            },
                            {
                              Header: "Case Qty",
                              accessor: "cs_qty",
                              width: 50,
                              filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, { keys: ["cs_qty"] }),
                              filterAll: true,
                              Cell: row => (
                                <div style={{ textAlign: "left"}}>{row.value}</div>
                              )
                            },
                            {
                              Header: "Case SKU",
                              accessor: "cs_sku",
                              width: 100,
                              filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, { keys: ["cs_sku"] }),
                              filterAll: true,
                              Cell: row => (
                                <div style={{ textAlign: "left"}}>{row.value}</div>
                              )
                            },                            
                            {
                              Header: "Case UPC",
                              accessor: "cs_upc",
                              width: 150,
                              filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, { keys: ["cs_upc"] }),
                              filterAll: true,
                              Cell: row => (
                                <div style={{ textAlign: "left"}}>{row.value}</div>
                              )          
                            },
                            
                            {
                              Header: "Active?",
                              accessor: "cs_active",
                              width: 50,
                              filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, { keys: ["cs_active"] }),
                              filterAll: true,
                              Cell: row => (
                                <div style={{ textAlign: "left"}}>{row.value}</div>
                              )
                            }
                          ]
                        }
                      ]}
                      defaultPageSize={5}
                      className="-striped -highlight"
                      
                    />
                  </div>
              </div>
            <div className="card">
              <div className="card-body" name="card-order-list">
              <div className="row">  
                  <div className="col-md-6">
                    <h4>New Case Selection</h4>
                    <p>Search a product by name, sku, or upc to add a case.</p>  
                    <Select options={productsearch} className="product-drop" name="upc_search" onChange={this.handleProductChange} value={upc_search} placeholder="Search by SKU, Name, UPC" />
                  </div>
              </div>
                <div className="row">  
                    <div className="col-md-12">  
                    <strong>Product:</strong> <label>{this.state.selectedproduct.label}</label><br/>
                      <strong>Quantity On Hand:</strong> <label>{this.state.selectedproduct.qoh}</label><br/>
                      <strong>Quantity Reserved:</strong> <label>{this.state.selectedproduct.qty_res}</label><br/>
                      <br/> <br/>

                      
                        {this.state.cases.length > 0 ? ( 
                          <div id="div-order-items" className="form-group">

                        <form onSubmit={this.handleUpdate}>   

                          <h3>Current Cases</h3>
                            <table className="table-order-items">
                              <thead> 
                                <tr>                          
                                <td><u>Print Label</u></td>
                                  <td className="td-oi-name"><u>Case Name</u></td>                                  
                                  <td className="td-oi-sku"><u>Case SKU</u></td>
                                  <td className="td-oi-label"><u>Case UPC</u></td>                            
                                  <td className="td-oi-qty"><u>Item Qty in Case</u></td>                            
                                  <td className="td-oi-active"><u>Active</u></td> 
                                  <td className="td-oi-rem"><u>Delete</u></td>                             
                                </tr>
                              </thead>
                              {this.state.cases.map((newitem, idx) => {                        
                                let tblId = `tbl-${idx}`;
                                let nameId = `item-${idx}`;

                                return (
                                    <tbody key={tblId}>
                                    <tr>
                                      <td className="td-oi-sku"><a href="#main" onClick={(e) => this.openLabelModal(this.state.cases[idx])} title="print labels">print</a></td>
                                      <td className="td-oi-sku">{this.state.cases[idx].cs_name}</td>    
                                      <td className="td-oi-sku">{this.state.cases[idx].cs_sku}</td>
                                      <td className="td-oi-label">{this.state.cases[idx].cs_upc}</td>                                
                                      <td className="td-oi-qty"><input name={nameId} 
                                        type="text" 
                                        value={this.state.cases[idx].cs_qty || 0} 
                                        onChange={(e) => {this.handleQtyInputChange(e, idx)}}                                      
                                        className="qty_field" />
                                        
                                      </td>  
                                      <td>
                                      <select value={this.state.cases[idx].cs_active} 
                                              onChange={(e) => {this.handleNewStatusInputChange(e, idx)}}>
                                        {notifyOptions.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                                      </select>
                                      </td>                              
                                      <td><button
                                              type="button"
                                              onClick={() => { if (window.confirm('Delete this case?')) this.onRemoveItem(idx,this.state.cases[idx].cs_id) }}
                                            >
                                              X
                                            </button>
                                      </td>
                                    </tr>
                                    </tbody>                                 
                                  )
                                })                
                              }                          

                                              
                          </table>                         
                          <input type="submit" value="Update Cases" className="btn btn-primary btn-sm"/>
                        </form>
                        </div>
                    
                        ) : (<div></div>) }
                    
                    </div> 
                   </div>
                </div>   
                <hr/>
                <div className="row">  
                  <div className="col-md-9">  
                  <form onSubmit={this.handleSubmit}>     
                      
                      <div className="form-group">
                         <h3>Add a New Case </h3>
                         <div key="divId"> 
                          <label>
                            <strong>Item Qty in Case</strong><br/>
                            <input
                              name="cs_qty"
                              type="text"
                              value={this.state.cs_qty}
                              onChange={(e) => {this.handleInputChange(e)}}
                              onBlur={this.getSku} />
                          </label>
                          <br />            
                          <label>
                            <strong>Case SKU</strong><br/>
                            <input
                              name="cs_sku"
                              type="text"
                              value={this.state.cs_sku}
                              onChange={(e) => {this.handleInputChange(e)}}
                              />                            
                          </label>
                          <br />
                          <label>
                            <strong>Case UPC</strong><br/>
                            <input
                              name="cs_upc"
                              type="text"
                              value={this.state.cs_upc}
                              onChange={(e) => {this.handleInputChange(e)}}
                              />
                          </label>
                          <br/>
                          <label>
                          <strong>Active?</strong><br/>
                          <select value={this.state.cs_active} 
                                  onChange={(e) => {this.handleStatusInputChange(e)}}>
                            {notifyOptions.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                          </select>
                          </label>
                          </div>  
                      </div>
                       <input type="submit" value="Add Case" className="btn btn-primary btn-sm"/>
                        </form>   
                  
                </div> 
              </div>
              </div>
              </div>
           
            </div>                      
  
         </div>
    
         <Modal
            isOpen={this.state.labelModalIsOpen}
            onAfterOpen={this.afterOpenLabelModal}                            
            style={printModalStyle}
            contentLabel="Print Case Label"
          >                   
          <div className="frame">
              <div className="scroll"> 
            <div className="container-fluid">               
              <form onSubmit={this.handleLabelPrint}>
                  <div className="row">
                      <div className="col-md-6">            
                        <h3>Print Case Label</h3>
                        <div className="form-group" >
                          <label htmlFor="printQty" className="form-label">Label Qty: </label>&nbsp; 
                          <input
                            className="form-input"
                            id="printQty"
                            name="printQty"
                            type="text"
                            value={this.state.printQty}
                            onChange={(e) => {this.handleInputChange(e)}}
                            placeholder="" 
                          />
                          
                        </div>
                        <div className="modalMessage">{this.state.modalMessage}</div>
                        <div>
                          <ReactToPrint
                            trigger={() => <Link to="#"><button type="button" className="btn btn-info btn-sm">Print</button></Link>}
                            content={() => this.componentRef}
                          /> &nbsp;&nbsp;
                          <button className="btn btn-info btn-sm" onClick={this.closeLabelModal}>Cancel</button>
                        </div> 
                        <ProductPrintLabel ref={el => (this.componentRef = el)} printQty={this.state.printQty} pr_sku={this.state.cs_sku} printUPC={this.state.printUPC} {...this.props} />
                      
                      </div>
                      
                  </div>
                  
              </form> 

              </div>                          
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.editModalIsOpen}     
            onAfterOpen={this.afterOpenEditModal}                       
            style={customStyles}
            contentLabel="Update Case Info"
          >                   
          
            <div className="container-fluid">               
              <form onSubmit={this.handleUpdateCase}>
                  <div className="row">
                      <div className="col-md-6">            

                        <div className="form-group" >
                          <label htmlFor="edit_cs_name"><strong>Case Name</strong> </label><br/>
                          {this.state.edit_cs_name}                                  
                          <br/><br/>
                          <label htmlFor="edit_cs_sku"><strong>Case SKU</strong> </label><br/>
                          {this.state.edit_cs_sku}
                          <br/><br/>
                          <label htmlFor="edit_cs_upc" ><strong>Case UPC</strong> </label><br/>
                          <input
                            className="form-input"
                            id="edit_cs_upc"
                            name="edit_cs_upc"
                            type="text"
                            value={this.state.edit_cs_upc}
                            onChange={(e) => {this.handleInputChange(e)}}
                            placeholder="" 
                          /><br/><br/>
                          <label htmlFor="edit_cs_qty" ><strong>Case QTY</strong> </label>
                          <input
                            className="form-input"
                            id="edit_cs_qty"
                            name="edit_cs_qty"
                            type="text"
                            value={this.state.edit_cs_qty}
                            onChange={(e) => {this.handleInputChange(e)}}
                            placeholder="" 
                          />
                         
                          <br/><br/>
                          <label>
                          <strong>Active?</strong><br/>
                          <select value={this.state.edit_cs_active} 
                                  onChange={(e) => {this.handleUpdateStatusInputChange(e)}}>
                            {notifyOptions.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                          </select>
                          </label>
                          
                        </div>
                        <div className="modalMessage">{this.state.modalMessage}</div>
                        <div>
                          <button type="submit" className="btn btn-info btn-sm">Update</button>&nbsp;<button onClick={this.closeEditModal} className="btn btn-info btn-sm">Cancel</button>
                        </div>               
                        
                      </div>

                      
                  </div>
                  
              </form> 

              </div>                          
            
          </Modal>


         </div>

                )
    }
}
export default ProductCaseDetail;