// Header.js
import React from 'react';
import 'react-table/react-table.css';
import 'react-dropdown/style.css';
import Barcode from 'react-barcode';

class LocationPrintLabel extends React.Component {
   constructor(props) {
        super(props);
            this.state = {
                        
        };
             
    } 
    
    componentDidMount() {     
      
    }

    createLabels = () => {
                 
      let table = []
      
      for (let i = 0; i < this.props.printQty; i++) {    

        let lblId = `lbl-${i}`;
        
        table.push(<div key={lblId} className="label-product"><span style={{fontSize:this.props.printSize + 'pt'}}>   
        <Barcode width={Number(this.props.labelWidth)} height={Number(this.props.labelHeight)} fontSize={Number(this.props.printSize)} value={this.props.printLocation} displayValue={true} />
        </span>
        </div>);
        
      }        
      
      return table;     

    }  

    
    render() {
        
    return (
      <div>
       
      {this.createLabels()}   
   
    </div>
    );
  }
}
export default LocationPrintLabel;