// Header.js
import React, {Component} from 'react';
import ReactToPrint from "react-to-print";
import 'react-dropdown/style.css';
import OrderPrintContent from './OrderPrintContent';

class OrderPrint extends Component {
    
    constructor(props) {
        super(props);
            this.state = {          
            cl_id: typeof(this.props.cl_id) === 'undefined' ? 0 : this.props.cl_id,
            or_id: typeof(this.props.or_id) === 'undefined' ? 0 : this.props.or_id,
            selectedIds: typeof(this.props.selectedIds) === 'undefined' ? 0 : this.props.selectedIds,
            checked: '',
            userToken: localStorage.getItem('userToken')
        };      
      
        this.handleCheck = this.handleCheck.bind(this);
        this.updateOrders = this.updateOrders.bind(this);
    } 

    handleCheck = (event) => {     
      
        var curval = this.state.checked;

        if (!curval) {        
            this.setState({checked:true})
        } else {
            this.setState({checked:false})
        }

    }

    updateOrders = () => {
     
        if (this.state.checked===true) {
          if (this.state.selectedIds.length) {
  
            //console.log('update selected orders to in process');
            //console.log(JSON.stringify(this.state.orders));           
  
              this.state.selectedIds.forEach(element => {
  
                var obj = { or_id: element, or_status: 'In Process', cl_id: this.state.cl_id };
                var myJSON = JSON.stringify(obj);
      
                //console.log(myJSON);
                
                fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/status/'+element, {
                    method: 'post',        
                    body: myJSON,      
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'bwToken': this.state.userToken       
                    }
                  
                    //credentials: 'same-origin', // send cookies
                    //credentials: 'include'   // send cookies, even in CORS
                
                }).then(function(response) {  
                    return response.json();
                }).then(function(data) { 
                    console.log(data);                      
                }).catch(function(error) {
                    console.log(error);        
                });                
    
              });                
  
          } else {
            console.log('no orders to print');
          }
        } else {
          //console.log('the checkbox not checked');
        }
         
      }

    render(){
        
        const { cl_id, or_id, selectedIds } = this.state;
        
        return (
        <div className="page-wrapper">
            <div className="row page-titles">  
             <div className="col-md-5 align-self-center">
                    <h3 className="text-primary">Orders</h3>  
                    <hr/>

                    <ReactToPrint
                      trigger={() => <button type="button" className="btn btn-info btn-sm">Print Orders</button>}
                      content={() => this.componentRef}
                      onAfterPrint={() => this.updateOrders()}
                    /><br/><br/>
                   <input type="checkbox" id="checked" onChange={this.handleCheck} defaultChecked={this.state.checked}/>&nbsp;<label htmlFor="checked">Update status to "In Process"</label>  <br/><br/>
		    
                </div>
                
            </div>

            <div className="container-fluid">
        
        
            <OrderPrintContent ref={el => (this.componentRef = el)} cl_id={cl_id} or_id={or_id} selectedIds={selectedIds} {...this.props} />
            </div>
            
        </div>

                )
    }
}
export default OrderPrint;