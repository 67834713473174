// Header.js
import React from 'react';
import 'react-table/react-table.css';
import 'react-dropdown/style.css';
import Barcode from 'react-barcode';
import './print.css';

class TrojanPrintAllUpload extends React.Component {
    constructor(props) {
        super(props);
            this.state = {
                        
        };
             
    }

    // Connect this to loop through printRows

    createLabels = () => {
        
        let table = [];
        let printRows = this.props.printRows

        //console.log('p rows')
        //console.log(printRows)

        let i = 1;
        for (const p of printRows.slice(1)) {
        
          console.log('p1: ' + p.pr_sku)         
      
          var _printSizeValue = p[0]
          var _printSerialValue = p[1]
    
          //180/72/39-20091726st+jd0729-4
          if (_printSizeValue !== '') {
            var _printLocation = _printSizeValue+'-'+_printSerialValue

            //for (let i = 0; i < this.props.printQty; i++) {
            
            let lblId = `lbl-${i}`;

            table.push(<div key={lblId} className="label-product">
            <span style={{fontSize:this.props.printSize + 'pt'}}>{_printLocation}<br/>     
            <Barcode width={Number(this.props.labelWidth)} height={Number(this.props.labelHeight)} fontSize={Number(this.props.printSize)} value={_printLocation} displayValue={false} />
            </span>
            </div>);

          }          

          i++;
        
        }
        
      
      return table;     

    }
    
    render() {
        
    return (
      <div className="print">
             
        {this.createLabels()}   
     
      </div>
    );
  }
}
export default TrojanPrintAllUpload;