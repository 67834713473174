// Header.js
import React, {Component} from 'react';
import Modal from 'react-modal';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import matchSorter from 'match-sorter'
import moment from 'moment';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '400px',
    color                 : 'blue'
  }
};

const cancelButton = {
  content : {
    float:'right'  
  }
};

class AlertList extends Component {

    constructor(props) {
        super(props);
        
            this.state = {
            alerts: [],
            selectedAlertId:'',
            selectedClientId:'',
            customerClientId: typeof(localStorage.getItem('customerClientId')) === 'undefined' ? 0 : localStorage.getItem('customerClientId'), 
            modalIsOpen: false,
            userToken: localStorage.getItem('userToken')
        };
       
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDeleteAlert = this.handleDeleteAlert.bind(this);
    }
	
	componentDidMount() {
        this.getAlerts(process.env.REACT_APP_NODE_ROOT_URL+'/alerts/client/'+this.state.customerClientId);	  	   
	}

    openModal = (e) => {
      //this.setState({modalIsOpen: true});
      //console.log(JSON.stringify(e.original));
      //console.log('client id: ' + e.original.cl_id);
      //console.log('alert id: ' + e.original.lg_id);
      this.setState({selectedAlertId: e.original.lg_id});
      this.setState({selectedClientId: e.original.cl_id});
    }

    afterOpenModal() {
      // references are now sync'd and can be accessed.
      this.subtitle.style.color = '#f00';
    }

    closeModal() {
      this.setState({modalIsOpen: false});
    }

    handleSubmit(event) {
      //alert('Client name: ' + this.state.cl_name);
      //event.preventDefault();

      //TODO: ADD ALERT COMMENT WHEN CLEARING

      //lg_message=?,lg_is_read=?,lg_us_id=? where lg_id=?

      var obj = { lg_id: this.state.selectedAlertId, lg_is_read: 1, lg_us_id: this.state.lg_us_id, lg_message: this.state.lg_message };
      var myJSON = JSON.stringify(obj);

      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/alerts/'+this.state.selectedAlertId, {
          method: 'POST',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken
          }

          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {  
          return response.json();
      }).then(function(data) { 
          console.log(data);            
      }).catch(function(error) {
          alert(error);        
      });

    }

    handleChange(event) {
      this.setState({cl_name: event.target.value});
    }

    handleDeleteAlert = (e) => {
      
      console.log(e)
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/alerts/del/'+e.original.lg_id, {
          method: 'post',   
          headers: {
          'Accept': 'application/json',          
          'bwToken': this.state.userToken       
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {
          return response.json();          
      }).then(function(data) { 
        //console.log(data);
        //alert("Alert deleted successfully");
        window.location.reload();         
      }).catch(function(error) {
          alert(error);        
      });
      
  
    }

    getAlerts = (url) => {
        fetch(url, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(alerts => this.setState({ alerts }));        
    }

    render(){

       const { alerts } = this.state;

        return (
        <div id="main">
        <div className="page-wrapper">
            <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                    <h3 className="text-primary">Alerts</h3> 
                             
                </div>                
                <div className="col-md-7 align-self-center">
                    
                </div>
                
            </div>  
             
            
            <div className="container-fluid">               

                <div className="row">					
                    <div className="col-lg-12">
                        <div className="card">                                                
                           <div className="card-body" name="card-order-list">
                                <div className="table-responsive">
                                    <ReactTable

                                      data={alerts}
                                      filterable
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}
                                      columns={[
                                        {                                          
                                          columns: [
                                            {
                                              Header: "cl_id",
                                              accessor: "cl_id",
                                              show: false
                                            },
                                            {
                                              Header: "Client",
                                              accessor: "cl_name",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            },
                                            {
                                              Header: "Activity",
                                              accessor: "log_activity",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            },
                                            {
                                              Header: "Message",
                                              accessor: "lg_message",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            }, 
                                            {
                                              Header: "SKU",
                                              accessor: "lg_ss_sku",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            }, 
                                            {
                                              Header: "Last Received",
                                              id: "lg_date_time",                                              
                                              accessor: d => {
                                              return moment(d.lg_date_time)
                                                .format("MM-DD-YYYY hh:mm:ss a")
                                              },
                                              width: 150,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["lg_date_time"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center"}}>{row.value}</div>
                                              )
                                            },                                              
                                            {
                                              Header: "Type",
                                              accessor: "lg_type",
                                              show: false,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            }                                            
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={10}
                                      className="-striped -highlight"
                                      getTdProps={(state, rowInfo, column, instance) => {
                                        return {
                                          onClick: (e, handleOriginal) => {                                           
                                            console.log('row clicked'); 
                                            //window.location.href = "/alert/"+rowInfo.row.cl_id;

                                            // IMPORTANT! React-Table uses onClick internally to trigger
                                            // events like expanding SubComponents and pivots.
                                            // By default a custom 'onClick' handler will override this functionality.
                                            // If you want to fire the original onClick handler, call the
                                            // 'handleOriginal' function.
                                            if (handleOriginal) {
                                              handleOriginal();
                                            }
                                          }
                                        };
                                      }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>               
                <div className="row">   
				          
                </div>


            </div>
       
        </div>

        <div id="modalMain" className="col-lg-4 align-self-left">
                       
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.handleSubmit}
            style={customStyles}
            contentLabel="Clear Alert"
          >                   
         
            <div className="container-fluid">               
              <form onSubmit={this.handleSubmit}>
                  <div className="row">
                      <div className="col-md-6">            

                        <div className="form-group" >
                          <label htmlFor="cl_name" className="form-label">By clearing this alert, you have indicated that you have read this alert and taken action if needed. </label>&nbsp; 
                                                   
                        </div>
                        
                        <div>
                          <button type="submit">Clear</button>&nbsp;<button onClick={this.closeModal} style={cancelButton}>Cancel</button>
                        </div>                
              
                      </div>

                      
                  </div>
                 
              </form> 

             </div>                          
            
          </Modal>
        </div>






        </div>
                )
    }
}
export default AlertList;