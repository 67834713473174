import React, { Component } from 'react';
import MobileBar from '../Site/MobileBar';
import ProductBinDetail from './ProductBinDetail';

import './style.css';

class Products extends Component {

  render() {
    return (
      <div>
        <MobileBar />
        <ProductBinDetail />
      </div>    


    );
  }
}

export default Products;