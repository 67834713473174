// Header.js
import React, {Component} from 'react';
import 'react-table/react-table.css'
import 'react-dropdown/style.css';

class ProductContent extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            upc_search: '',            
            cl_id: '',
            cl_name: '',
            pr_id: this.props.productId,
            pr_sku: '',
            pr_desc: '',
            pr_upc: '',
            pr_ean: '',
            pr_qty: 0,
            pr_price: 0,
            pr_other1: 0,
            pr_weight: 0,
            pr_qty_floor: 0,
            productbins: [],
            productcases: [],
            productkits: [],
            userToken: localStorage.getItem('userToken')
        };
       
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.openCaseModal = this.openCaseModal.bind(this);
        this.afterOpenCaseModal = this.afterOpenCaseModal.bind(this);
        this.closeCaseModal = this.closeCaseModal.bind(this);
        this.getProductBins = this.getProductBins.bind(this);
        this.getProductKits = this.getProductKits.bind(this);
        this.getProductCases = this.getProductCases.bind(this);

    }

      getProduct() {
      
        fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/'+this.props.productId,
        {
            method: 'GET',      
            headers: {
            'Accept': 'application/json',
            'bwToken': this.state.userToken
            }

            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(res => res.json())
        .then(product => this.setState({
          cl_id:product[0].cl_id || '',
          cl_name:product[0].cl_name || '',
          pr_id:product[0].pr_id || '',
          pr_sku:product[0].pr_sku || '',
          pr_desc:product[0].pr_desc || '',
          pr_upc:product[0].pr_upc || '',
          pr_ean:product[0].pr_ean || '',
          pr_qty:product[0].pr_qty || 0,
          pr_price:product[0].pr_price || 0,
          pr_other1:product[0].pr_other1 || 0,
          pr_weight:product[0].pr_weight || 0,
          pr_qty_floor:product[0].pr_qty_floor || 0,
        }));
        
      }

    getProductBins = (url) => {
        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(productbins => this.setState({ productbins }));
    }
    
    getProductKits = (url) => {
        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(productkits => this.setState({ productkits }));
    }

    getProductCases = (url) => {
      fetch(url, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(productcases => this.setState({ productcases }));
    }
 
    componentDidMount() {
      this.getProduct();
      this.getProductBins(process.env.REACT_APP_NODE_ROOT_URL+'/products/bins/'+this.props.productId);  
      this.getProductCases(process.env.REACT_APP_NODE_ROOT_URL+'/products/cases/'+this.props.productId);
      this.getProductKits(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits/single/'+this.props.productId);
    }

    handleInputChange(event) {     
      
      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.setState({
       [name]: value
      });

    }

         //IMPORT
    openCaseModal() {
      this.setState({importModalIsOpen: true});
    }

    afterOpenCaseModal() {
    }

    closeCaseModal() {
      this.setState({importModalIsOpen: false});
    } 

    handleSubmit(event) {  
      event.preventDefault();

      let _productSku = this.state.pr_sku;
      let _productUpc = this.state.pr_upc;

      if (_productSku == '' || _productUpc == '') {

        alert('Every product needs a SKU and UPC');  
        return false;

      } else {
     
        var productJSON = JSON.stringify({
          pr_sku: this.state.pr_sku,
          pr_desc: this.state.pr_desc,
          pr_upc: this.state.pr_upc,
          pr_ean: this.state.pr_ean,
          pr_qty: this.state.pr_qty,
          pr_price: this.state.pr_price,
          pr_other1: this.state.pr_other1,
          pr_weight: this.state.pr_weight,
          pr_qty_floor: this.state.pr_qty_floor});

        console.log(productJSON);
        
        fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/'+this.props.productId, {
              method: 'post',        
              body: productJSON,      
              headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'bwToken': this.state.userToken
              }
              //TODO:
              //credentials: 'same-origin', // send cookies
              //credentials: 'include'   // send cookies, even in CORS
          
          }).then(function(response) {            
              return response.json();
          }).then(function(data) {
              alert('Product updated successfully'); 
            
          }).catch(function(error) {
              alert('Product could not be updated'); 
              console.log('submit err: ' + error);       
          });
      }

    }
 

render(){
        
        return (

        <div className="page-wrapper" id="main">
            <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                    <h3 className="text-primary">Product Information</h3> </div>
                <div className="col-md-7 align-self-center">
                    
                </div>
            </div>

            <div className="container-fluid">               
            <form onSubmit={this.handleSubmit}>
            <div className="card">                                                
              <div className="card-body" name="card-order-list">

                <div className="row">  
                    <div className="col-md-4">            

                      <div className="form-group">                         
                          <label>
                            Client: <strong> {this.state.cl_name} </strong>
                          </label>
                          <br/>
                          <label>
                            SKU: <br/>
                            <input
                              name="pr_sku"
                              type="text"
                              value={this.state.pr_sku}
                              className="prod-sku"
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Description:<br/>
                            <input
                              name="pr_desc"
                              type="text"
                              value={this.state.pr_desc}
                              className="prod-desc"
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            UPC:<br/>
                            <input
                              name="pr_upc"
                              type="text"
                              value={this.state.pr_upc}
                              onChange={this.handleInputChange} 
                              disabled />
                          </label> 
                          <br />
                          <label>
                            EAN:<br/>
                            <input
                              name="pr_ean"
                              type="text"
                              value={this.state.pr_ean}
                              onChange={this.handleInputChange} 
                               />
                          </label> 
                          <br/>
                                                    
                        </div>
                    </div>               
                    <div className="col-md-2">            

                      <div className="form-group">                         
                         
                          <label>
                            Quantity:<br/>
                            <input
                              name="pr_qty"
                              type="text"
                              value={this.state.pr_qty}
                              className="num-desc"
                              disabled
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Low QOH Alert Quantity:<br/>
                            <input
                              name="pr_qty_floor"
                              type="text"
                              value={this.state.pr_qty_floor}
                              className="num-desc"
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Price:<br/>
                            <input
                              name="pr_price"
                              type="text"
                              value={this.state.pr_price}
                              className="num-desc"
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Weight:<br/>
                            <input
                              name="pr_weight"
                              type="text"
                              value={this.state.pr_weight}
                              className="num-desc"
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Other 1:<br/>
                            <input
                              name="pr_other1"
                              type="text"
                              value={this.state.pr_other1}                             
                              onChange={this.handleInputChange} />
                          </label>
                        
                        </div>
                    </div>
                    <div className="col-md-6">            

                      <div className="form-group">
                          <label>
                            <u>Locations</u> <span className="tool-link"></span><br/>                            
                            <ul>
                            {this.state.productbins.map((bin, idx) => {                              
                              let divId = `div-${idx}`;
                              return (
                                  <li key={divId}>{this.state.productbins[idx].bn_bin}</li>
                                )
                              })                
                            }
                            </ul>
                          </label>
                          <br/>
                          <label>
                            <u>Cases</u> <span className="tool-link"></span><br/>
                            <ul>
                            {this.state.productcases.map((cs, idx) => {                              
                              
                              return (
                                  <li>{cs.cs_sku}</li>
                                )
                              })                
                            }
                            </ul>
                          </label>
                          <br />
                          <label>
                          <u>Kits</u> <span className="tool-link"></span><br/>
                            <ul>
                            {this.state.productkits.map((kt, idx) => {                              
                              let divKtId = `div-${idx}`;
                              return (                                  
                                  <li key={divKtId}>{kt.kt_sku}</li>
                                )
                              })                
                            }
                            </ul>
                          </label>
                          
                        </div>
                    </div>               


              </div>
             <div className="col-md-12">
                        <input type="submit" value="Update" className="btn btn-primary btn-sm"/>
                    </div>   
            </div>     
            </div>
            </form>           

            </div>                      
        </div>

                )
    }
}
export default ProductContent;