// Header.js
import React, {Component} from 'react';
import Modal from 'react-modal';
import ReactTable from "react-table";
import 'react-table/react-table.css'

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '400px',
    color                 : 'blue'
  }
};


const cancelButton = {
  content : {
    float:'right'  
  }
};


class ClientList extends Component {

    constructor(props) {
        super(props);
        
            this.state = {
            clients: [], 
            orderitems: [],
            cl_id:'',
            modalIsOpen: false,
            cl_name:'',
            userToken: localStorage.getItem('userToken')
        };
       
        //this.handleChange = this.handleChange.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
	
    openModal() {
      this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
      // references are now sync'd and can be accessed.
      this.subtitle.style.color = '#f00';
    }

    closeModal() {
      this.setState({modalIsOpen: false});
    }

    handleSubmit(event) {
      //alert('Client name: ' + this.state.cl_name);
      //event.preventDefault();

      var obj = { cl_name: this.state.cl_name };
      var myJSON = JSON.stringify(obj);

      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/clients', {
          method: 'POST',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken
          }

          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {
          //console.log(response);
      }).then(function(data) { 
          //console.log(data);
          //window.location.reload();
          this.getClients(process.env.REACT_APP_NODE_ROOT_URL+'/clients');           
      }).catch(function(error) {
          //alert('Client could not be added. Contact support.'); 
          //console.log('submit err: ' + error);       
      });                


    }

    handleChange(event) {
      this.setState({cl_name: event.target.value});
    }

    componentDidMount() {
      Modal.setAppElement('#main');
      this.getClients(process.env.REACT_APP_NODE_ROOT_URL+'/clients');
    }   

    getClients = (url) => {        
        
        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(clients => this.setState({ clients }));   
        
    }

    render(){      

        const { clients } = this.state;

        return (
        <div id="main">
        <div className="page-wrapper">
            <div className="row page-titles">
                <div className="col-md-12 align-self-center">
                    <h3 className="text-primary">Clients</h3>                                 
                </div>    
            </div>             
           
            <div className="container-fluid">               

                <div className="row">				
                    <div className="col-lg-12">                    
                        <div className="card">
                        <button type="button" style={{width:"100px"}} className="btn btn-info btn-sm" onClick={this.openModal} disabled={this.state.disableAddButton}>Add Client</button>&nbsp;                                                
                           <div className="card-body" name="card-order-list">                           
                                <div className="table-responsive">
                                  <ReactTable
                                      data={clients}
                                      filterable
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}
                                      columns={[
                                        {                                          
                                          columns: [                                            
                                            {
                                              Header: "ID",
                                              accessor: "cl_id",
                                              maxWidth: 100
                                            },
                                            {
                                              Header: "Name",
                                              accessor: "cl_name",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            }                                         
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={10}
                                      className="-striped -highlight"
                                      getTdProps={(state, rowInfo, column, instance) => {
                                        return {
                                          onClick: (e, handleOriginal) => {                                           
                                             window.location.href = "/client/"+rowInfo.row.cl_id;

                                            // IMPORTANT! React-Table uses onClick internally to trigger
                                            // events like expanding SubComponents and pivots.
                                            // By default a custom 'onClick' handler will override this functionality.
                                            // If you want to fire the original onClick handler, call the
                                            // 'handleOriginal' function.
                                            if (handleOriginal) {
                                              handleOriginal();
                                            }
                                          }
                                        };
                                      }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>               
             

            </div>
       
        </div>

        <div id="modalMain" className="col-lg-4 align-self-left">
                       
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}            
            style={customStyles}
            contentLabel="New Client"
          >                   
         
            <div className="container-fluid">               
              <form onSubmit={this.handleSubmit}>
                  <div className="row">
                      <div className="col-md-6">            

                        <div className="form-group" >
                          <label htmlFor="cl_name" className="form-label" ref={subtitle => this.subtitle = subtitle}>Client Name: </label>&nbsp; 
                          <input
                            className="form-input"
                            id="cl_name"
                            name="cl_name"
                            type="text"
                            value={this.state.cl_name}
                            onChange={this.handleChange}
                            placeholder="" 
                          />
                          
                        </div>
                        <div className="modalMessage">{this.state.modalMessage}</div>
                        <div>
                          <button type="submit">Add</button>&nbsp;<button onClick={this.closeModal} style={cancelButton}>Cancel</button>
                        </div>                
              
                      </div>

                      
                  </div>
                 
              </form> 

             </div>                          
            
          </Modal>
        </div>



        </div>
                )
    }
}
export default ClientList;