import React, { Component } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

class ClientSelector extends Component {	
	constructor(props) {	
		super(props);
		this.state = {
			clients: [],
			selectedClient:'',
			userToken: localStorage.getItem('userToken')
		};
		
    	this.handleChange = this.handleChange.bind(this);
  	}
	
	handleChange(e) {
	
		this.setState({ selectedClient: e.value });
		this.props.onClientChange(e.value);
	}	

	componentDidMount() {	
	this.getClients(process.env.REACT_APP_NODE_ROOT_URL+'/clients');
	}   

	getClients = (url) => {        
		
		fetch(url, {
		method: 'GET',   
		headers: {
		'Accept': 'application/json',
		'bwToken': this.state.userToken
		}      
	}).then(res => res.json())
		.then(clients => this.setState({ clients }));   
		
	}	

  	render() {

		const clientMappingFunction = c => ({ value: '' + c.cl_id + '', label: ''+ c.cl_name +'' });
     
		const clients = this.state.clients.map(clientMappingFunction);

		let dropdown;
		dropdown = <Dropdown options={clients} onChange={this.handleChange} value={this.props.cl_id} placeholder="Select a client" className="dropClients"/>;
	
	    return (
	      <div>
			 {dropdown}	         
	      </div> 
	    );
	  }
}

export default ClientSelector;