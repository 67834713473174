import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import LeftBar from '../Site/LeftBar';
import ProductKitDetail from './ProductKitDetail';

import './style.css';

class ProductKitMain extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }


  constructor(props) {
    super(props);
    
    const { location } = this.props
    console.log('path: '+ location.pathname )

    var pathArray = location.pathname.split('/');

    //pathArray[1] = product
    //pathArray[2] = client id
    //pathArray[3] = product id
    
    console.log('cl_id: '+ pathArray[2])
    
    this.state = {        
        cl_id : pathArray[2],
        kt_id : pathArray[3]
    };
  }


  render() {
    return (
      <div>
        <LeftBar />
        <ProductKitDetail cl_id={this.state.cl_id} kt_id={this.state.kt_id} />
      </div>    


    );
  }
}

export default withRouter(ProductKitMain);