import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { withRouter } from "react-router";
import LeftBar from '../Site/LeftBar';
import ClientSettings from './ClientSettings';

import './style.css';

class Client extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);
    
    const { location } = this.props
    //console.log('path: '+ location.pathname )
    //console.log('clientname: '+ location.clientname)


    var pathArray = location.pathname.split('/');
    
    //pathArray[1] = order
    //pathArray[2] = client id
    //pathArray[3] = order id
    
    //console.log('arr: '+ pathArray)
    
    this.state = {
        clientId: pathArray[2],
        clientName: location.clientname
    };
  }

  render() {
    return (
      <div>
        <LeftBar />
        <ClientSettings clientId={this.state.clientId} clientName={this.state.clientName} />
      </div>

    );
  }
}

export default withRouter(Client);