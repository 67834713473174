// Header.js
import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import ReactTable from "react-table";
import Modal from 'react-modal';
import 'react-table/react-table.css'
import 'react-dropdown/style.css';
class ProductKitList extends Component {

    constructor(props) {
        super(props);        
            this.state = {
            productkits: [],            
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),
            //cl_id: typeof(this.props.cl_id) === 'undefined' ? 0 : this.props.cl_id,
            upc_search: '',
            kt_sku: '',
            modalMessage: '',
            importModalMessage: '',
            importModalIsOpen: false,
            disableAddButton:true,
            userToken: localStorage.getItem('userToken')
        };
       
        
        this.handleChange = this.handleChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);



    }

       
    getProductKits = (url) => {
      fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(productkits => this.setState({ productkits }));
    }

    handleChange (event) {
     this.setState({pr_sku: event.target.value});
    }

    //TODO: add get kit by client id route and model 
    //
    handleClientChange = (cl_id) => {
        this.setState({cl_id: cl_id});        
        if (cl_id === 0) {
            this.getProductKits(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits');
            this.setState({disableAddButton:true});
        } else {
            this.getProductKits(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits/client/'+cl_id);
            this.setState({disableAddButton:false});
        }         
    }

    handleSubmit(event) {
      //alert('Client name: ' + this.state.cl_name);
      //event.preventDefault();

      var obj = { cl_id: this.state.cl_id, pr_sku: this.state.pr_sku };
      var myJSON = JSON.stringify(obj);
   
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits/', {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {  
          return response.json();
      }).then(function(data) { 
          console.log(data);            
      }).catch(function(error) {
          alert(error);        
      });      

    }

    componentDidMount() {
        Modal.setAppElement('#main');
        this.handleClientChange(this.state.cl_id); 
    }
 
    render(){

        const { cl_id, productkits } = this.state;
        
        return (
        <div id="main">
        <div className="page-wrapper">
                <div className="row page-titles">
      
                  <div className="col-md-2 align-self-center">
                       <h3 className="text-primary">Product Kits</h3>
                    
                  </div>
                  <div className="col-md-10 align-self-center"> 
                  <Link to={`/customer-product-kit/${cl_id}`}><button type="button" className="btn btn-info btn-sm" disabled={this.state.disableAddButton} >Add a Kit</button></Link>                 
                  
                  </div>
                 
                </div>

                <div className="container-fluid">             

                <div className="row">
                    <div className="col-lg-12">
                       
                            <div className="card-body" name="card-order-list">
                                <div className="table-responsive">
                                  <ReactTable

                                      data={productkits}
                                      filterable
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}          
                                      columns={[
                                        {                                          
                                          columns: [                                            
                                            {
                                              Header: "Kit ID",
                                              accessor: "kt_id",
                                              width: 50,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)          
                                            },
                                            {
                                              Header: "Cl ID",
                                              accessor: "cl_id",
                                              width: 50,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Kit Name",
                                              accessor: "kt_name",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)          
                                            },
                                            {
                                              Header: "Kit SKU",
                                              accessor: "kt_sku",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)          
                                            },
                                            {
                                              Header: "Kit UPC",
                                              accessor: "kt_upc",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)          
                                            },                                            
                                            {
                                              Header: "Active?",
                                              accessor: "kt_active",
                                              width: 50,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)          
                                            }
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={10}
                                      className="-striped -highlight"
                                      getTdProps={(state, rowInfo, column, instance) => {
                                        return {                                          
                                          onClick: (e, handleOriginal) => {
                                            if (rowInfo) {
                                              console.log('r: '+rowInfo.row);
                                              //window.location.href = "/product-kit/"+rowInfo.row.cl_id+"/"+rowInfo.row.kt_sku+"/"+rowInfo.row.kt_upc;
                                              window.location.href = "/customer-product-kit/"+rowInfo.row.cl_id+"/"+rowInfo.row.kt_id;
                                            }

                                            // IMPORTANT! React-Table uses onClick internally to trigger
                                            // events like expanding SubComponents and pivots.
                                            // By default a custom 'onClick' handler will override this functionality.
                                            // If you want to fire the original onClick handler, call the
                                            // 'handleOriginal' function.
                                            if (handleOriginal) {
                                              handleOriginal();
                                            }
                                          }
                                        };
                                      }}
                                    />



                               
                                </div>
                            </div>                         

                       
                    </div>
                </div>               
                <div className="row">   
                    <div className="col-lg-4">
                       
                    </div>
                </div>


            </div>
        </div>
        </div>

                )
    }
}
export default ProductKitList;