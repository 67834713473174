// Header.js
import React, {Component} from 'react';
class TimeclockList extends Component {

    constructor(props) {
        super(props);
        
            this.state = {
            users: [], 
            timeclockentries: [],
            userToken: localStorage.getItem('userToken')
        };
       
    }
	   
    
	  componentDidMount() {

      //Modal.setAppElement('#main');
      this.getTimeClockEntries(process.env.REACT_APP_NODE_ROOT_URL+'/timeclock');
    
    }
    
    getTimeClockEntries = (url) => {
      fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(timeclockentries => this.setState({ timeclockentries }));
    }

    render(){

        return (
        <div id="main">
          <div className="page-wrapper">
            <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                    <h3 className="text-primary">Time Clock</h3> 
                    <hr/>
                    <button type="button" className="btn btn-info btn-sm" onClick={this.openModal}>Add Manual Entry</button>                   
                </div>                
                <div className="col-md-7 align-self-center">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active">Clients</li>
                    </ol>
                </div>
            </div>
            <div className="container-fluid">
              <div className="row">         
                <div className="col-lg-12">
                  <div className="card">                                                
                    <div className="card-body" name="card-order-list">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>User ID</th>                                                
                              <th>In Time</th>
                              <th>Out Time</th>
                              <th>IP Address</th>
                              <th>Notes</th>
                            </tr>
                          </thead>
                            <tbody>
                            {this.state.timeclockentries.map(entry =>
                              <tr key={entry.tc_id} >
                                <td>{entry.us_id}</td>                                              
                                <td>{entry.tc_in_time}</td>
                                <td>{entry.tc_out_time}</td>
                                <td>{entry.tc_ip_address}</td>
                                <td>{entry.tc_notes}</td>
                              </tr>          
                            )}                                         
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>               
              </div>


          </div>
        </div>
                )
    }
}
export default TimeclockList;