// Header.js
import React, {Component} from 'react';
import StateSelector from '../Form/StateSelector';
import CountrySelector from '../Form/CountrySelector';
import BillingReportEmbed from '../Customer/BillingReportEmbed';

class ClientSettings extends Component {

    constructor(props) {
        super(props);
        
          this.state = {          
            cl_id:this.props.clientId,
            cl_name:'',
            cl_phone:'',
            cl_email:'',
            cl_contact:'',
            cl_address1:'',
            cl_address2:'',
            cl_city:'',
            cl_state:'',
            cl_zip:'',
            cl_country:'',
            cl_mail_from:'',
            cl_mail_from_name:'',
            cl_mail_to_copy:'',
            cl_email_template:'',
            cl_notifications:'',
            cl_tax_rate:0,
            cl_shipstation_api_key:'',
            cl_shipstation_api_secret:'',
            cl_item_rate:0,
            cl_item_rate_1:0,
            cl_item_rate_2:0,
            cl_item_rate_3:0,
            cl_item_rate_4:0,
            cl_hour_rate:0,
            cl_box_rate:0,
            cl_pallet_rate:0,
            cl_order_rate:0,
            cl_oe_rate_freight:0,
            cl_oe_rate_ups:0,
            cl_oe_rate_usps:0,
            cl_oe_rate_fedex:0,
            cl_case_rate:0,
            cl_shipstation_manual_id:'',
            shipmethods: [{name:''}],
            stores:[{name:''}],
            cl_logo: '',     
            userToken: localStorage.getItem('userToken')
          };
       
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleMethodChange = this.handleMethodChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleStoreChange = this.handleStoreChange.bind(this);
        this.addStore = this.addStore.bind(this);
        this.onRemoveStore = this.onRemoveStore.bind(this);
        this.handleAttach = this.handleAttach.bind(this);


    }
	
	  componentDidMount() {
      //TODO: need to handle loading existing ship methods
      //need to pass auth to route
      //
      this.getClient();
	      
	  }	
    
    getClient() {
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/clients/'+this.props.clientId,
      {
          method: 'GET',      
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }

          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(res => res.json())      
      .then(client => this.setState({           
          cl_name:client[0].cl_name || '',
          cl_phone:client[0].cl_phone || '',
          cl_email:client[0].cl_email || '',
          cl_contact:client[0].cl_contact || '',
          cl_address1:client[0].cl_address1 || '',
          cl_address2:client[0].cl_address2 || '',
          cl_city:client[0].cl_city || '',
          cl_state:client[0].cl_state || '',
          cl_zip:client[0].cl_zip || '',
          cl_country:client[0].cl_country || '',
          cl_mail_from:client[0].cl_mail_from || '',
          cl_mail_from_name:client[0].cl_mail_from_name || '',
          cl_mail_to_copy:client[0].cl_mail_to_copy || '',
          cl_email_template:client[0].cl_email_template || '',
          cl_notifications:client[0].cl_notifications || 0,
          cl_shipstation_api_key:client[0].cl_shipstation_api_key || '',
          cl_shipstation_api_secret:client[0].cl_shipstation_api_secret || '',
          cl_tax_rate:client[0].cl_tax_rate || 0,
          cl_item_rate:client[0].cl_item_rate || 0,
          cl_hour_rate:client[0].cl_hour_rate || 0,
          cl_box_rate:client[0].cl_box_rate || 0,
          cl_pallet_rate:client[0].cl_pallet_rate || 0,
          cl_order_rate:client[0].cl_order_rate || 0,
          cl_oe_rate_freight:client[0].cl_oe_rate_freight || 0,
          cl_oe_rate_ups:client[0].cl_oe_rate_ups || 0,
          cl_oe_rate_usps:client[0].cl_oe_rate_usps || 0,
          cl_oe_rate_fedex:client[0].cl_oe_rate_fedex || 0,
          cl_item_rate_1:client[0].cl_item_rate_1 || 0,
          cl_item_rate_2:client[0].cl_item_rate_2 || 0,
          cl_item_rate_3:client[0].cl_item_rate_3 || 0,
          cl_item_rate_4:client[0].cl_item_rate_4 || 0,
          cl_case_rate:client[0].cl_case_rate || 0,
          cl_shipstation_manual_id:client[0].cl_shipstation_manual_id || '',
          shipmethods:this.tryParse(client[0].cl_ship_methods) || [{name:''}],
          stores:this.tryParse(client[0].cl_stores) || [{name:''}]
      }));      
    }

    tryParse(input) {
      try {        
          return JSON.parse(input);
      } catch (e) {          
          return undefined;
      }
    }

    handleStateChange = (cl_state) => {
      this.setState({cl_state});        
    }    

    handleCountryChange = (cl_country) => {
      this.setState({cl_country});        
    }

    handleStoreChange = (e) => {
      e.preventDefault();
      if (["name"].includes(e.target.className) ) {
        let stores = [...this.state.stores]
        stores[e.target.dataset.id][e.target.className] = e.target.value.toUpperCase()
        this.setState({ stores }, () => console.log(this.state.stores))
      } else {
        this.setState({ [e.target.name]: e.target.value.toUpperCase() })
      }
    }

    addStore = (e) => {
      this.setState((prevState) => ({
        stores: [...prevState.stores, {name:""}],
      }));
    }

    onRemoveStore = i => {
      this.setState(state => {
      const stores = state.stores.filter((item, j) => i !== j);
        return {
          stores,
        };
      });
    };
   

    handleMethodChange = (e) => {
      e.preventDefault();
      if (["name"].includes(e.target.className) ) {
        let shipmethods = [...this.state.shipmethods]
        shipmethods[e.target.dataset.id][e.target.className] = e.target.value.toUpperCase()
        this.setState({ shipmethods }, () => console.log(this.state.shipmethods))
      } else {
        this.setState({ [e.target.name]: e.target.value.toUpperCase() })
      }
    }

    addShipMethod = (e) => {
      this.setState((prevState) => ({
        shipmethods: [...prevState.shipmethods, {name:""}],
      }));
    }

    onRemoveItem = i => {
      this.setState(state => {
      const shipmethods = state.shipmethods.filter((item, j) => i !== j);
        return {
          shipmethods,
        };
      });
    };
   
    handleInputChange(event) {     
      
      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.setState({
       [name]: value
      });

    }

    handleSubmit(event) {  
      event.preventDefault();
      var myJSON = JSON.stringify(this.state);
      console.log(myJSON);

      let sClient = {
          cl_name:this.state.cl_name || '',
          cl_phone:this.state.cl_phone || '',
          cl_email:this.state.cl_email || '',
          cl_contact:this.state.cl_contact || '',
          cl_address1:this.state.cl_address1 || '',
          cl_address2:this.state.cl_address2 || '',
          cl_city:this.state.cl_city || '',
          cl_state:this.state.cl_state || '',
          cl_zip:this.state.cl_zip || '',
          cl_country:this.state.cl_country || '',
          cl_mail_from:this.state.cl_mail_from || '',
          cl_mail_from_name:this.state.cl_mail_from_name || '',
          cl_mail_to_copy:this.state.cl_mail_to_copy || '',
          cl_email_template:this.state.cl_email_template || '',
          cl_notifications:this.state.cl_notifications || 0,
          cl_shipstation_api_key:this.state.cl_shipstation_api_key || '',
          cl_shipstation_api_secret:this.state.cl_shipstation_api_secret || '',
          cl_tax_rate:this.state.cl_tax_rate || 0,
          cl_item_rate:this.state.cl_item_rate || 0,
          cl_hour_rate:this.state.cl_hour_rate || 0,
          cl_box_rate:this.state.cl_box_rate || 0,
          cl_pallet_rate:this.state.cl_pallet_rate || 0,
          cl_order_rate:this.state.cl_order_rate || 0,
          cl_oe_rate_freight:this.state.cl_oe_rate_freight || 0,
          cl_oe_rate_ups:this.state.cl_oe_rate_ups || 0,
          cl_oe_rate_usps:this.state.cl_oe_rate_usps || 0,
          cl_oe_rate_fedex:this.state.cl_oe_rate_fedex || 0,
          cl_item_rate_1:this.state.cl_item_rate_1 || 0,
          cl_item_rate_2:this.state.cl_item_rate_2 || 0,
          cl_item_rate_3:this.state.cl_item_rate_3 || 0,
          cl_item_rate_4:this.state.cl_item_rate_4 || 0,
          cl_case_rate:this.state.cl_case_rate || 0,
          cl_shipstation_manual_id:this.state.cl_shipstation_manual_id || '',
          shipmethods:this.state.shipmethods,
          stores:this.state.stores
      };
      
      var clientJSON = JSON.stringify(sClient);

      console.log(clientJSON)
      
      var strUrl = process.env.REACT_APP_NODE_ROOT_URL+'/clients/'+this.props.clientId;

      fetch(strUrl, {
            method: 'POST',        
            body: myJSON,      
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            alert('Client updated successfully'); 
            //return response.json();
            window.location.reload()
        }).then(function(data) { 
           console.log(data);         
           //window.location.replace(retUrl);  
        }).catch(function(error) {
            alert('Client details could not be updated'); 
            console.log('submit err: ' + JSON.stringify(error));       
        });
      

    }

    handleAttach = (e) => {
      e.preventDefault();    
      
      const myFile = document.getElementById('myFile');
      const data = new FormData();
      data.append('myFile', myFile.files[0]);

      //var fetchURL = "http://54.244.206.141:3001/attach/"+this.props.clientId+"/"+this.props.receiverId;
      
      var fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/files/logo/'+this.props.clientId;

      fetch(fetchURL, {
      method: 'POST',
      body: data,
      headers: {            
        'bwToken': this.state.userToken          
      }
       }).then(function(response) { 
              
              //console.log(response);
              //console.log(fetchURL);

              if (response.statusText === 'OK'){
                 alert('Logo updated successfully.')
                 //this.setState({filesModalMessage:'File uploaded successfully'});
              } else {
                 alert('Logo update failed. Try again.')
                 //this.setState({filesModalMessage:'Upload failed. Try again.'});
              }                 

              //return response;
          }).then(function(data) { 
              //console.log(data);
          }).catch(function(error) {            
              alert('Logo could not be updated: ' + error);        
          });
      
      e.target.value = null; 
    }

    render(){
        
        const notifyOptions = [
          'No', 'Yes'
        ]
       
        const { shipmethods, stores } = this.state; 

        return (

        <div className="page-wrapper" id="main">
            <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                    <h3 className="text-primary">{this.state.cl_name}</h3> </div>
                <div className="col-md-7 align-self-center">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active"><a href="/clients">Clients</a></li>
                    </ol>
                </div>
            </div>

            <div className="container-fluid">

            
            <div className="card">
              <div className="card-body" name="card-order-list"> 
                
                <form onSubmit={this.handleSubmit}>
                <div className="row threecol">  
                
                    <div className="col-md-4">           

                      <div className="form-group">
                         <h3 className="text-primary">Client Information</h3>
                          <label>
                            Name:<br/>
                            <input
                              name="cl_name"
                              type="text"
                              value={this.state.cl_name}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Phone:<br/>
                            <input
                              name="cl_phone"
                              type="text"
                              value={this.state.cl_phone}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Email:<br/>
                            <input
                              name="cl_email"
                              type="text"
                              value={this.state.cl_email}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Contact Name:<br/>
                            <input
                              name="cl_contact"
                              type="text"
                              value={this.state.cl_contact}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Address:<br/>
                            <input
                              name="cl_address1"
                              type="text"
                              value={this.state.cl_address1}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Address Two:<br/>
                            <input
                              name="cl_address2"
                              type="text"
                              value={this.state.cl_address2}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            City:<br/>
                            <input
                              name="cl_city"
                              type="text"
                              value={this.state.cl_city}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            State:<br/>
                            <StateSelector state={this.state.cl_state} onStateChange={this.handleStateChange}/>
                          </label>
                          <br />
                          <label>
                            Zip:<br/>
                            <input
                              name="cl_zip"
                              type="text"
                              value={this.state.cl_zip}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Country:<br/>
                            <CountrySelector country={this.state.cl_country} onCountryChange={this.handleCountryChange}/>
                          </label>
                          <h3 className="text-primary">Client Logo</h3>
                           <label>
                            Upload a logo in .png format. Max 200px wide. :<br/>
                           
                               <input type="file" id="myFile" name="myFile" />
                               <input type="submit" onClick={this.handleAttach} value="Upload a logo"/>
                                                     
                          </label>
                          <br/>
                          <img alt="logo" src={`https://bw-admin-files.s3.amazonaws.com/files/logos/${this.state.cl_id}/logo.png`} width="100px"></img>
                        
                          
                        </div>
                      </div> 
                      <div className="col-md-4">      
                        <div className="form-group">
                        <h3 className="text-primary">Billing</h3>
                        Below rates are used in billing reports.<br/>
                        All rates in decimal format. (ex. 0.000)<br/>
                        <label>
                            Per Order Rate:<br/>
                            <input
                              name="cl_order_rate"
                              type="text"
                              value={this.state.cl_order_rate}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Default Charge - Per Item:<br/>
                            <input
                              name="cl_item_rate"
                              type="text"
                              value={this.state.cl_item_rate}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Level Charge 1 - Per Item:<br/>
                            <input
                              name="cl_item_rate_1"
                              type="text"
                              value={this.state.cl_item_rate_1}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                          Level Charge 2 - Per Item:<br/>
                            <input
                              name="cl_item_rate_2"
                              type="text"
                              value={this.state.cl_item_rate_2}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Level Charge 3 - Per Item:<br/>
                            <input
                              name="cl_item_rate_3"
                              type="text"
                              value={this.state.cl_item_rate_3}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Level Charge 4 - Per Item:<br/>
                            <input
                              name="cl_item_rate_4"
                              type="text"
                              value={this.state.cl_item_rate_4}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Charge Per Case:<br/>
                            <input
                              name="cl_case_rate"
                              type="text"
                              value={this.state.cl_case_rate}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Charge Per Hour:<br/>
                            <input
                              name="cl_hour_rate"
                              type="text"
                              value={this.state.cl_hour_rate}
                              onChange={this.handleInputChange} />
                          </label>
                           <br />
                          <label>
                            Charge Per Box:<br/>
                            <input
                              name="cl_box_rate"
                              type="text"
                              value={this.state.cl_box_rate}
                              onChange={this.handleInputChange} />
                          </label>
                           <br />
                          <label>
                            Charge Per Pallet:<br/>
                            <input
                              name="cl_pallet_rate"
                              type="text"
                              value={this.state.cl_pallet_rate}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <h3 className="text-primary">Mail Settings</h3>
                          <label>
                          Turn On Client Email Notifications?<br/>
                          <select value={this.state.cl_notifications} 
                                  onChange={(e) => this.setState({cl_notifications: e.target.value})}>
                            {notifyOptions.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                          </select>
                          </label>
                          <br/>
                          These fields will be used to send emails for client entered orders that do not contain an email address and for any system generated email notifications. 
                          <br/>
                          <label>
                            Mail From/To Address:<br/>
                            <input
                              name="cl_mail_from"
                              type="text"
                              value={this.state.cl_mail_from}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Mail From Name:<br/>
                            <input
                              name="cl_mail_from_name"
                              type="text"
                              value={this.state.cl_mail_from_name}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                         
                                            
                        </div>     

                      </div>
                       <div className="col-md-4">      
                        <div className="form-group">
                         <h3 className="text-primary">Orders</h3>
                         Below "BW" rates are used to calculate the "Actual Ship Cost" the client will see.<br/>
                          All rates in decimal format. (ex. 0.000)<br/>
                          <label>
                            BW Freight Rate:<br/>
                            <input
                              name="cl_oe_rate_freight"
                              type="text"
                              value={this.state.cl_oe_rate_freight}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            BW UPS Rate:<br/>
                            <input
                              name="cl_oe_rate_ups"
                              type="text"
                              value={this.state.cl_oe_rate_ups}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            BW USPS Rate:<br/>
                            <input
                              name="cl_oe_rate_usps"
                              type="text"
                              value={this.state.cl_oe_rate_usps}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            BW FedEx Rate:<br/>
                            <input
                              name="cl_oe_rate_fedex"
                              type="text"
                              value={this.state.cl_oe_rate_fedex}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                         <h3 className="text-primary">Integrations</h3>
                          <label>
                            Shipstation API Key:<br/>
                            <input
                              name="cl_shipstation_api_key"
                              type="text"
                              value={this.state.cl_shipstation_api_key}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Shipstation API Secret:<br/>
                            <input
                              name="cl_shipstation_api_secret"
                              type="text"
                              value={this.state.cl_shipstation_api_secret}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Shipstation Manual Store ID for Order Entry:<br/>
                            <input
                              name="cl_shipstation_manual_id"
                              type="text"
                              value={this.state.cl_shipstation_manual_id}
                              onChange={this.handleInputChange} />
                          </label> 
                          <br />     
                        <label>Shipstation Store IDs for Order Import: </label> 
                          {
                            stores.map((val, idx)=> {
                              let smId = `store-${idx}`;
                             
                              return (
                                  <div key={idx}>
                                    <input
                                      type="text"
                                      name={smId}
                                      data-id={idx}
                                      id={smId}
                                      value={stores[idx].name} 
                                      className="name"
                                      onChange={this.handleStoreChange}
                                    /> <button
                                            type="button"
                                            onClick={() => this.onRemoveStore(idx)}
                                          >
                                            X
                                          </button><br/><br/>                            
                                  </div>
                              )
                            })
                          }                          
                           <button type="button" onClick={this.addStore}>Add </button>
                           <br/><br/>                
                         <h3 className="text-primary">Shipping Accounts</h3> 
                         Used for the "Small Package" Order Type on Order Entry. Should be one or more of the following: <br/>
                         'BW ShipStation Account'<br/>'Client Shipstation Account'<br/>'Client Provided Account' <br/><br/>
                          {
                            shipmethods.map((val, idx)=> {
                              let smId = `method-${idx}`;
                              
                              return (
                                  <div key={idx}>
                                    <input
                                      type="text"
                                      name={smId}
                                      data-id={idx}
                                      id={smId}
                                      value={shipmethods[idx].name} 
                                      className="name"
                                      onChange={this.handleMethodChange}
                                    /> <button
                                            type="button"
                                            onClick={() => this.onRemoveItem(idx)}
                                          >
                                            X
                                          </button><br/><br/>                            
                                  </div>
                              )
                            })
                          }                          
                          <button type="button" onClick={this.addShipMethod}>Add </button>
                    
                        </div>     
                        
                </div>
                <div className="col-md-12">
                  <input type="submit" value="Update" className="btn btn-primary btn-sm"/>
                </div>             
              </div>
               </form>
               <hr className="threecol"/>
               
              </div>  
              <br/>
              <h3 className="text-primary">Shipment Billing Report</h3>  

              <BillingReportEmbed cl_id={this.props.clientId} />      
              </div>
              
            </div>                      
            
        </div>

                )
    }
}
export default ClientSettings;