// Header.js
import React, {Component} from 'react';
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import Modal from 'react-modal';
import 'react-table/react-table.css'
import 'react-dropdown/style.css';
import DateTimePicker from 'react-datetime-picker';

var moment = require('moment');

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '400px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};

const printModalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '500px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};

const cancelButton = {
  content : {
    float                 : 'right'  
  }
};

var date = new Date();

class OtherStatusReport extends Component {

    constructor(props) {
        super(props);        
            this.state = {
            statusitems: [],
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),
            us_id: localStorage.getItem('usId'),
            oi_status: 'Shipped',
            sortOptions: [{ id: 'pr_id', desc: true }],
            startDate: new Date(date.getFullYear(), date.getMonth(), 1),
            endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            userToken: localStorage.getItem('userToken')
        };
       
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.filterReport = this.filterReport.bind(this);

    }

    openModal() {
      this.setState({modalIsOpen: true});
      if (this.state.cl_id =='') {
        this.setState({modalMessage: 'Please select a client first. '});
      } 

    }

    afterOpenModal() {     
    }

    closeModal() {
      this.setState({modalIsOpen: false});
    }

    filterReport = (url) => {
      this.getStatus(process.env.REACT_APP_NODE_ROOT_URL+'/reports/other/status/'+this.state.cl_id);     
    }

    getStatus = (url) => {
      
     //Left of here with creating billing report
      
      let _startdate = moment(this.state.startDate).format("YYYY-MM-DD");
      let _enddate = moment(this.state.endDate).format("YYYY-MM-DD");
      let _status = this.state.oi_status;
      
      var productJSON = JSON.stringify({
        cl_id: this.state.cl_id,      
        oi_status: _status,  
        start_date: _startdate,
        end_date: _enddate
      });

      console.log('logging status json...');
      console.log(productJSON);      
      
      fetch(url, {
            method: 'post',        
            body: productJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
        }             
      }).then(res => res.json())
        .then(statusitems => this.setState({ statusitems }));      
    
    }  
       
    componentDidMount() {
      Modal.setAppElement('#main');
      this.getStatus(process.env.REACT_APP_NODE_ROOT_URL+'/reports/other/status/'+this.state.cl_id);          
    }
 
    render(){
        
        const { statusitems } = this.state;
        
        return (
        <div id="main">
        <div className="page-wrapper">
                <div className="row page-titles">
      
                  <div className="col-md-11 align-self-center">
                       <h3 className="text-primary">Additional SKU Shipment Report</h3>                     
                  </div>
                  <div className="col-md-1 align-self-center"> 
                  
                  </div>
                </div>

                <div className="container-fluid">

                <div className="row">

                    <div className="col-lg-12">
                    <p>Select a Start and End Date for the report to see results. </p>
                            <table>                              
                              <tbody>
                              <tr>
                                <td>
                                Start Date:<br/>
                                <DateTimePicker
                                disableClock={true}
                                onChange={startDate => this.setState({ startDate })}
                                value={this.state.startDate}
                                format={"MM-dd-y"}
                              />&nbsp;&nbsp;</td>
                                <td>
                                End Date:<br/>
                                <DateTimePicker
                                disableClock={true}
                                onChange={endDate => this.setState({ endDate })}
                                onBlur={this.filterReport}
                                value={this.state.endDate}
                                format={"MM-dd-y"}
                              /></td>
                              </tr>
                              </tbody>
                            </table>
                           <br/>
                            <div className="card-body" name="card-order-list">
                            <CSVLink data={statusitems}>Download CSV of Additional SKU Shipments</CSVLink>
                                <div className="table-responsive">
                                  <ReactTable
                                      data={statusitems}
                                      sorted={this.state.sortOptions}
                                      onSortedChange={val => {
                                      this.setState({ sortOptions: val }) }}
                                      filterable
                                      noDataText='Loading status report...'
                                      loading={this.state.loading}
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}          
                                      columns={[
                                        { 
                                          columns: [
                                            {
                                              Header: "Order Detail",
                                              accessor: "or_id",
                                              width: 50,
                                              show: true,
                                              Cell: props => (
                                                <div style={{ textAlign: "center" }}><button onClick={() => {  window.open("/customer-order/"+this.state.cl_id+"/"+props.original.or_id, "_new"); } } className="btn btn-info btn-sm" title="view order detail">view</button></div>
                                              )
                                            },
                                            {
                                              Header: "Order ID",
                                              accessor: "or_actual_order_id",
                                              width: 100,
                                              filterable: false,
                                              show: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                           },    
                                                               
                                            {
                                              Header: "Product SKU",
                                              accessor: "pr_sku",
                                              width: 175,
                                              show: true,                                              
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },
                                            {
                                                Header: "Other SKU",
                                                accessor: "ot_sku",
                                                width: 175,
                                                show: true,                                              
                                                filterMethod: (filter, row) =>
                                                  row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                              },
                                            {
                                              Header: "Ship Date",
                                              accessor: "sh_date",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            }
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={50}
                                      className="-striped -highlight"
                                      
                                    />
                               
                                </div>
                            </div>
                       
                    </div>
                </div>               
                <div className="row">   
                    <div className="col-lg-4">
                       
                    </div>
                </div>


            </div>
        </div>
        </div>

                )
    }
}
export default OtherStatusReport;