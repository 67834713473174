import React, {Component} from 'react';
import { CSVLink } from "react-csv";
import ReactTable from "react-table";
import Modal from 'react-modal';
import 'react-table/react-table.css'
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '600px'
  }
};


const cancelButton = {
  content : {
    float                 : 'right'  
  }
};

class ReceiverList extends Component {
 constructor(props) {
        super(props);              

            this.state = {
            receivers: [],            
            receiveritems: [],
            recentitems: [],
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),            
            rc_number: '',
            rc_company: '',
            rc_contact_email: '',
            rc_status: '',      
            rc_type: '',
            modalMessage: '',
            expectedDate: new Date(),            
            disableAddButton:true,
            modalIsOpen:false,
            sortOptions: [{ id: 'rc_id', desc: true }],
            sortRecentOptions: [{ id: 'lg_date_updated', desc: true }],
            userToken: localStorage.getItem('userToken')
        };
       
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }    

    componentDidMount() {       
       
      this.handleClientChange(this.state.cl_id);
      this.getRecentItems(process.env.REACT_APP_NODE_ROOT_URL+'/reports/receiveritems/'+localStorage.getItem('headerClientId')); 
      Modal.setAppElement('#main');
    }   

    openModal() {
      this.setState({modalIsOpen: true});
      if (this.state.cl_id =='') {
        this.setState({modalMessage: 'Please select a client first. '});
      } 

    }

    afterOpenModal() {
      // references are now sync'd and can be accessed.
      // this.subtitle.style.color = '#f00';
    }

    closeModal() {
      this.setState({modalIsOpen: false});
    }

    getRecentItems = (url) => {
      
      var productJSON = JSON.stringify({
        cl_id: this.state.cl_id,
        pr_id: 0
      });

      console.log('logging inventory json...');
      console.log(productJSON);      
      
      fetch(url, {
            method: 'post',        
            body: productJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
        }             
      }).then(res => res.json())
        .then(recentitems => this.setState({ recentitems }));       
      
    }  

    getReceivers = (url) => {
        fetch(url, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(receivers => this.setState({ receivers }));        
    }

    handleChange (event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.setState({
       [name]: value
      });
    }

    handleClientChange = (cl_id) => {     
        this.setState({cl_id: cl_id});  
        if (cl_id === 0) {
            this.getReceivers(process.env.REACT_APP_NODE_ROOT_URL+'/receivers');
            this.setState({disableAddButton:true});
        } else {
            this.getReceivers(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/client/'+cl_id); 
            this.setState({disableAddButton:false});
        }    
    }

    handleDateChange = (date) => {
      console.log('exp-date: ' + date);
    }

    handleSubmit(event) {
      //alert('Client name: ' + this.state.cl_name);
      event.preventDefault();
      /*
      var _adddate = moment().tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm:ss");

      var _expdate = moment(this.state.expectedDate).utc().format("YYYY-MM-DD HH:mm:ss");
      var _finaldate = moment(this.state.maxDate).utc().format("YYYY-MM-DD HH:mm:ss");

      */
      var _rec_type = this.state.rc_type;
      if (_rec_type == '') {
        alert('Please select a receiver type');
        return false;
      } else { 

        var _adddate = moment().format("YYYY-MM-DD HH:mm:ss");

        var _expdate = moment(this.state.expectedDate).format("YYYY-MM-DD HH:mm:ss");
        var _finaldate = moment().format("YYYY-MM-DD HH:mm:ss");

        console.log('expdate: ' + _expdate);
        console.log('finaldate: ' + _finaldate);
        
        var obj = { cl_id: this.state.cl_id, rc_number: this.state.rc_number, rc_company: this.state.rc_company, rc_status: 'Open', rc_contact_email: this.state.rc_contact_email, rc_date_added: _adddate, rc_date_modified: _adddate, rc_date_expected: _expdate, rc_date_final: _finaldate, rc_type: this.state.rc_type };
        var myJSON = JSON.stringify(obj);

        console.log(myJSON);

        fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/', {
            method: 'post',        
            body: myJSON,      
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {  
            return response.json();
        }).then(function(data) { 
            window.location.reload();            
        }).catch(function(error) {
            alert(error);        
        });
      


      }
    }
 
    render(){
        
        const { receivers, recentitems } = this.state;

        const receiverTypes = [
          'Container', 'Return', 'Pallet', 'Parcel' 
        ]

        const headersrecent = [
          { label: "Client ID", key: "lg_cl_id" },
          { label: "Product ID", key: "pr_id" },
          { label: "Receiver Number", key: "rc_number" },
          { label: "SKU", key: "pr_sku" },
          { label: "Desc", key: "pr_desc" },
          { label: "UPC", key: "pr_upc" },
          { label: "Scan Count", key: "lg_scan_count" },
          { label: "Date Last Received", key: "lg_date_updated" }  
        ];
        
        return (
        <div id="main">
        <div className="page-wrapper">
               <div className="row page-titles">
                <div className="col-md-1">
                    <h3 className="text-primary">Receiving</h3>                    
                </div>
                <div className="col-md-11 align-self-center">
                   <button type="button" className="btn btn-info btn-sm" onClick={this.openModal} disabled={this.state.disableAddButton} >Add Receiver</button>
                      
                </div>
            </div>

            <div id="modalMain" className="col-lg-4 align-self-left">
                       
                          <Modal
                            isOpen={this.state.modalIsOpen}
                            onAfterOpen={this.afterOpenModal}
                            onRequestClose={this.handleSubmit}
                            style={customStyles}
                            contentLabel="New Receiver"
                          >                   
                         
                            <div className="container-fluid">               
                              <form onSubmit={this.handleSubmit}>
                              <h2>Add New Receiver</h2>
                                  <div className="row">
                                    <div className="col-sm-4 right">                                      
                                      Name/Number:
                                    </div>
                                    <div className="col-sm-8">
                                     
                                      <input
                                            className="form-input"
                                            id="rc_number"
                                            name="rc_number"
                                            type="text"
                                            value={this.state.rc_number}
                                            onChange={this.handleChange}
                                            placeholder="" 
                                          />
                                    </div>                                    
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-4 right">                                      
                                      Company:
                                    </div>
                                    <div className="col-sm-8">                                    
                                      <input
                                            className="form-input"
                                            id="rc_company"
                                            name="rc_company"
                                            type="text"
                                            value={this.state.rc_company}
                                            onChange={this.handleChange}
                                            placeholder="" 
                                          />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-4 right">                                      
                                      Email to:
                                    </div>
                                    <div className="col-sm-8">                                    
                                      <input
                                            className="form-input"
                                            id="rc_contact_email"
                                            name="rc_contact_email"
                                            type="text"
                                            value={this.state.rc_contact_email}
                                            onChange={this.handleChange}
                                            placeholder="" 
                                          />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-4 right">                                      
                                      Expected Date:
                                    </div>
                                    <div className="col-sm-8">                                    
                                       <DateTimePicker
                                          disableClock={true}
                                          onChange={expectedDate => this.setState({ expectedDate })}
                                          value={this.state.expectedDate}
                                          format={"MM-dd-y h:mm:ss a"}
                                        />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-4 right">                                      
                                      Receiver Type *:
                                    </div>
                                    <div className="col-sm-8">                                    
                                    <select value={this.state.rc_type} 
                                          onChange={(e) => this.setState({rc_type: e.target.value})}>
                                            <option value="">-- select a receiver type --</option>
                                    {receiverTypes.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                                  </select>
                                    </div>
                                  </div>


                               <div className="modalMessage">{this.state.modalMessage}</div>
                                        <div className="right">
                                          <button type="submit">Add</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closeModal} style={cancelButton}>Cancel</button>
                                        </div>               
                                        
                                 
                              </form> 

                             </div>                          
                            
                          </Modal>
                  </div>


            <div className="container-fluid">               

              <div className="row">
                    <div className="col-lg-12">
                       
                            <div className="card-body" name="card-order-list">                             
                                <div className="table-responsive">
                                  <ReactTable

                                      data={receivers}
                                      sorted={this.state.sortOptions}
                                      onSortedChange={val => {
                                      this.setState({ sortOptions: val }) }}
                                      filterable
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}          
                                      columns={[
                                        {                                          
                                          columns: [                                            
                                            {
                                              Header: "ID",
                                              accessor: "rc_id",
                                              show: false,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "cl_id",
                                              accessor: "cl_id",
                                              show: false
                                            },     
                                            {
                                              Header: "Client",
                                              accessor: "cl_name",
                                              show: false,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)          
                                            },             
                                            {
                                              Header: "Type",
                                              accessor: "rc_type",
                                              width: 75,                                              
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            },                                                                        
                                            {
                                              Header: "Receiver Name",
                                              accessor: "rc_number",
                                              width: 150,  
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)          
                                            },
                                            {
                                              Header: "Company",
                                              accessor: "rc_company",
                                              width: 150,  
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            },
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Total Items Expected</div>
                                              ),
                                              accessor: "rc_total_exp",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                                 
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Total Items Received</div>
                                              ),
                                              accessor: "rc_total_scans",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Total Hours</div>
                                              ),
                                              accessor: "rc_total_hours",
                                              width: 100,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                                       
                                            {
                                              Header: "Status",
                                              accessor: "rc_status",
                                              width: 75,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            },                                            
                                            {
                                              Header: "Date Updated",
                                              accessor: "rc_date_updated",
                                              width: 100,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            },
                                            {
                                              Header: "Date Expected",
                                              accessor: "rc_date_expected",
                                              width: 100,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            },
                                            {
                                              Header: "Date Received",
                                              accessor: "rc_date_received",
                                              width: 100,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            }
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={20}                                      
                                      className="-striped -highlight"
                                      getTdProps={(state, rowInfo, column, instance) => {
                                        return {                                          
                                          onClick: (e, handleOriginal) => {
                                            if (rowInfo) {
                                              //console.log('r: '+rowInfo.row);
                                              window.location.href = "/customer-receiver/"+rowInfo.row.cl_id+"/"+rowInfo.row.rc_id;
                                            }

                                            // IMPORTANT! React-Table uses onClick internally to trigger
                                            // events like expanding SubComponents and pivots.
                                            // By default a custom 'onClick' handler will override this functionality.
                                            // If you want to fire the original onClick handler, call the
                                            // 'handleOriginal' function.
                                            if (handleOriginal) {
                                              handleOriginal();
                                            }
                                          }
                                        };
                                      }}
                                    />
                               
                                </div>
                            </div>       

                            <hr/>
                            <h3>Recently Received Items</h3>
                            <div className="card-body" name="card-order-list">
                            <CSVLink headers={headersrecent} data={recentitems}>Download CSV of Recent Items</CSVLink>
                                <div className="table-responsive">
                                  <ReactTable
                                      data={recentitems}
                                      sorted={this.state.sortRecentOptions}
                                      onSortedChange={val => {
                                      this.setState({ sortOptions: val }) }}
                                      filterable
                                      noDataText='Loading recently received items ...'
                                      loading={this.state.loading}
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}          
                                      columns={[
                                        {                                          
                                          columns: [
                                            {
                                              Header: "Product ID",
                                              accessor: "pr_id",
                                              width: 125,
                                              filterable: false,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                                                                            
                                            {
                                              Header: "Receiver Number",
                                              accessor: "rc_number",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },                            
                                            {
                                              Header: "SKU",
                                              accessor: "pr_sku",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },
                                            {
                                              Header: "Description",
                                              accessor: "pr_desc",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },
                                            {
                                              Header: "UPC",
                                              accessor: "pr_upc",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },                                                
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Scan Count</div>
                                              ),
                                              accessor: "lg_scan_count",
                                              width: 125,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]===filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Received Date",
                                              accessor: "lg_date_updated",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            }
                                             
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={50}
                                      className="-striped -highlight"
                                      
                                    />
                               
                                </div>
                            </div>                  

                       
                    </div>
                </div>               

            </div>
        </div>
        </div>

                )
    }
}
export default ReceiverList;