import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import CustomerBar from '../Site/CustomerBar';
import ProductIgnoreDetail from './ProductIgnoreDetail';

import './style.css';

class ProductIgnoreMain extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }


  constructor(props) {
    super(props);
    
    const { location } = this.props

    var pathArray = location.pathname.split('/');
    
    this.state = {        
        cl_id : pathArray[2],
        ig_id : pathArray[3]
    };
  }


  render() {
    return (
      <div>
        <CustomerBar />
        <ProductIgnoreDetail cl_id={this.state.cl_id} ig_id={this.state.ig_id} />
      </div>    


    );
  }
}

export default withRouter(ProductIgnoreMain);