// Header.js
import React from 'react';
import 'react-table/react-table.css';
import 'react-dropdown/style.css';
import Barcode from 'react-barcode';
import './print.css';

class BlemPrintLabel extends React.Component {
   constructor(props) {
        super(props);
            this.state = {                        
        };             
    } 
    
    componentDidMount() {     
      
    }

    createLabels = () => {
                 
      let table = []
      
      for (let i = 0; i < this.props.printQty; i++) {
                
        let lblId = `lbl-${i}`;
        
        table.push(<div key={lblId} className="label-product"><span style={{fontSize:this.props.printSize + 'pt'}}>BLEM # <br/> {this.props.new_blem}</span><br/>
        <span style={{fontSize:this.props.printSize + 'pt'}}><Barcode width={Number(this.props.labelWidth)} height={Number(this.props.labelHeight)} fontSize={Number(this.props.printSize)} value={this.props.printUPC} displayValue={true} />
        </span>
        </div>);
                      
      }       
      
      return table;     

    }  

    
    render() {
        
    return (
      <div>
       
        {this.createLabels()}   
     
      </div>
    );
  }
}
export default BlemPrintLabel;