// Header.js
import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
class UserList extends Component {

    constructor(props) {
        super(props);
        
            this.state = {
            users: [],
            userToken: localStorage.getItem('userToken')
        };
       
    }
	
	componentDidMount() {
        this.getUsers(process.env.REACT_APP_NODE_ROOT_URL+'/users');	  	   
	}

    getUsers = (url) => {
        fetch(url, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(users => this.setState({ users }));        
    }

    render(){

        return (
        <div id="main">
        <div className="page-wrapper">
            <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                    <h3 className="text-primary">Users</h3> 
                             
                </div>                
                <div className="col-md-7 align-self-center">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active">Users</li>
                    </ol>
                </div>
                
            </div>  
             
            
            <div className="container-fluid">               

                <div className="row">					
                    <div className="col-lg-12">
                        <div className="card">                                                
                           <div className="card-body" name="card-order-list">
                                <div className="table-responsive">
                                   <table className="table">
                                        <thead>
                                            <tr>                                                                                            
                                                <th>User Name</th>                                                                                             
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>                                                                              
                                            </tr>
                                        </thead>
                                        <tbody>
                                       
                                        {this.state.users.map(user =>
                                          <tr key={user.us_id}>                                                                                           
                                              <td><Link to={`/user/${user.us_id}`}>{user.us_user_name}</Link></td>
                                              <td>{user.us_first_name}</td>
                                              <td>{user.us_last_name}</td>
                                              <td>{user.us_email}</td>
                                              <td>{user.us_phone_num}</td>                                              
                                          </tr>          
                                        )}                                         
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>               
                <div className="row">   
				          
                </div>


            </div>
       
        </div>
        </div>
                )
    }
}
export default UserList;