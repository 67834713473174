// Header.js
import React, {Component} from 'react';
import ReactToPrint from "react-to-print";
import 'react-dropdown/style.css';
import OrderPrintContent from './OrderPrintContent';

class OrderPrint extends Component {
    
    constructor(props) {
        super(props);
            this.state = {          
            cl_id: typeof(this.props.cl_id) === 'undefined' ? 0 : this.props.cl_id,
            or_id: typeof(this.props.or_id) === 'undefined' ? 0 : this.props.or_id,
            selectedIds: typeof(this.props.selectedIds) === 'undefined' ? 0 : this.props.selectedIds,
            checked: '',
            userToken: localStorage.getItem('userToken')
        };
    
    } 

    
    
    render(){
        
        const { cl_id, or_id, selectedIds } = this.state;
        
        return (
        <div className="page-wrapper">
            <div className="row page-titles">  
             <div className="col-md-5 align-self-center">
                    <h3 className="text-primary">Orders</h3>  
                    <hr/>

                    <ReactToPrint
                      trigger={() => <button type="button" className="btn btn-info btn-sm">Print Order</button>}
                      content={() => this.componentRef}                      
                    />
                </div>
                
            </div>

            <div className="container-fluid">
        
        
            <OrderPrintContent ref={el => (this.componentRef = el)} cl_id={cl_id} or_id={or_id} selectedIds={selectedIds} {...this.props} />
            </div>
            
        </div>

                )
    }
}
export default OrderPrint;