import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Select from "react-select";
import "react-dropdown/style.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "react-dropdown/style.css";
import matchSorter from "match-sorter";
import { CSVLink } from "react-csv";

class ProductReplaceDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prod_search: "",
      replace_search: "",
      cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'), 
      rp_id: "",
      pr_id: "",
      replaced: [],
      product: "",
      products: [],
      selectedproduct: "",
      replacedproduct: "",
      userToken: localStorage.getItem("userToken")
    };

    this.handleProductChange = this.handleProductChange.bind(this);
    this.handleReplaceProductChange = this.handleReplaceProductChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onRemoveItem = this.onRemoveItem.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.getProductReplaced = this.getProductReplaced.bind(this);
  }

  componentDidMount() {
    this.getProducts(process.env.REACT_APP_NODE_ROOT_URL+'/products/client/'+this.state.cl_id);
    this.getProductReplaced(process.env.REACT_APP_NODE_ROOT_URL+'/products/replace/'+this.state.cl_id);    
  }

  getProducts = (url) => {
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        bwToken: this.state.userToken,
      },
    })
      .then((res) => res.json())
      .then((products) => this.setState({ products }));
  };

  getProductReplaced = (url) => {
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        bwToken: this.state.userToken,
      },
    })
      .then((res) => res.json())
      .then((replaced) => this.setState({ replaced }));
  };

  handleProductChange = (e) => {
    console.log(e);
    this.setState({ selectedproduct: e });
    //this.getProductReplaced(e.value);
  };
  handleReplaceProductChange = (e) => {
    console.log(e);
    this.setState({ replacedproduct: e });
    //this.getProductReplaced(e.value);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    var replaceJSON = JSON.stringify({
      cl_id: this.state.cl_id,
      pr_id: this.state.selectedproduct.pr_id,
      pr_sku: this.state.selectedproduct.pr_sku,
      rp_pr_id: this.state.replacedproduct.rp_pr_id,
      rp_pr_sku: this.state.replacedproduct.rp_pr_sku
    });

    console.log('replace json: ' + replaceJSON);

    fetch(
      process.env.REACT_APP_NODE_ROOT_URL +
        "/products/replace/" +
        this.state.cl_id,
      {
        method: "post",
        body: replaceJSON,
        headers: {
          "Content-Type": "application/json",
          bwToken: this.state.userToken,
        },
        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        alert("Replace SKU setup successfully");
        window.location.reload();
      })
      .catch(function (error) {
        alert("Replace SKU could not be setup. Contact support.");
        //console.log('submit err: ' + error);
      });
  };

  deleteReplace = (rpID) => {
    fetch(
      process.env.REACT_APP_NODE_ROOT_URL + "/products/replace/del/" + rpID,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          bwToken: this.state.userToken,
        },
        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
      }
    )
      .then(function (response) {
        //console.log(response);
      })
      .then(function (data) {
        //console.log(data);
        window.location.reload();
      })
      .catch(function (error) {
        alert("Replace SKU could not be deleted. Contact support.");
        //console.log('submit err: ' + error);
      });
  };

  onRemoveItem = (i, rpId) => {
    this.setState((state) => {
      const replaced = state.replaced.filter((item, j) => i !== j);
      return {
        replaced,
      };
    });

    this.deleteReplace(rpId);
  };

  render() {
    const mappingFunction = (p) => ({
      label: p.pr_sku + " - " + p.pr_desc + " (" + p.pr_upc + ")",
      value: p.pr_id,
      qty: 1,
      qoh: p.pr_qty,
      qty_res: p.pr_qty_reserved,
      pr_id: p.pr_id,
      cl_id: p.cl_id,
      pr_desc: p.pr_desc,
      pr_sku: p.pr_sku,
    });

    const replaceMappingFunction = (p) => ({
      label: p.pr_sku + " - " + p.pr_desc + " (" + p.pr_upc + ")",
      value: p.pr_id,
      qty: 1,
      qoh: p.pr_qty,
      qty_res: p.pr_qty_reserved,
      rp_pr_id: p.pr_id,
      cl_id: p.cl_id,
      pr_desc: p.pr_desc,
      rp_pr_sku: p.pr_sku,
    });

    //product drop down
    const productsearch = this.state.products.map(mappingFunction);
    const productsearchreplace = this.state.products.map(replaceMappingFunction);

    const { replaced, prod_search, replace_search } = this.state;
    
    const column_link_style = {
      textAlign: "center",
      textDecoration: "underline",
    };

    return (
      <div id="main">
        <form onSubmit={this.handleSubmit}> 
        <div className="page-wrapper">
          <div className="row page-titles">
            <div className="col-md-12">
              <h3 className="text-primary">Product Replacement SKUs</h3>
            </div>
            
            <div className="container-fluid">
              <div className="card">
                <div className="card-body" name="card-order-list">
                
                  <div className="row">
                    <div className="col-md-6">
                      <h4>Replace this SKU:</h4>
                      <Select
                        options={productsearch}
                        className="product-drop"
                        name="prod_search"
                        onChange={this.handleProductChange}
                        value={prod_search}
                        placeholder="Search by SKU, Name, UPC"
                      />             
                     </div> 
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                    <strong>Selected Product:</strong> <label>{this.state.selectedproduct.label}</label><br/> 
                     </div> 
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <h4>With this SKU: </h4>
                      <Select
                        options={productsearchreplace}
                        className="product-drop"
                        name="replace_search"
                        onChange={this.handleReplaceProductChange}
                        value={replace_search}
                        placeholder="Search by SKU, Name, UPC"
                      />
                    </div>                                        
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                    <strong>Replacement Product:</strong> <label>{this.state.replacedproduct.label}</label><br/> 
                     </div> 
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                    <hr/>
                    <input type="submit" value="Add" className="btn btn-info btn-sm"/>
                     </div> 
                  </div>
                  
                  <div className="row">
                    <div className="col-md-12">
                      <CSVLink data={replaced}>
                        Download CSV of Replacement SKUs{" "}
                      </CSVLink>
                      <br />
                      <ReactTable
                        data={replaced}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                          String(row[filter.id]) === filter.value
                        }
                        columns={[
                          {
                            columns: [
                              {
                                Header: "Delete",
                                accessor: "rp_id",
                                width: 50,
                                filterable: false,
                                Cell: (props) => (
                                  <div>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            "Delete this replacement mapping?"
                                          )
                                        )
                                          this.deleteReplace(
                                            props.original.rp_id
                                          );
                                      }}
                                      className="btn btn-xs"
                                    >
                                      delete
                                    </button>
                                  </div>
                                ),
                              },
                              {
                                Header: "Original SKU",
                                accessor: "pr_sku",
                                width: 200,
                                filterMethod: (filter, rows) =>
                                  matchSorter(rows, filter.value, {
                                    keys: ["pr_sku"],
                                  }),
                                filterAll: true,
                                Cell: (row) => (
                                  <div style={{ textAlign: "left" }}>
                                    {row.value}
                                  </div>
                                ),
                              },
                              {
                                Header: "Replacement SKU",
                                accessor: "rp_pr_sku",
                                width: 200,
                                filterMethod: (filter, rows) =>
                                  matchSorter(rows, filter.value, {
                                    keys: ["rp_pr_sku"],
                                  }),
                                filterAll: true,
                                Cell: (row) => (
                                  <div style={{ textAlign: "left" }}>
                                    {row.value}
                                  </div>
                                ),
                              },
                            ],
                          },
                        ]}
                        defaultPageSize={3}
                        className="-striped -highlight"
                      />
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>

          </div>
        </div>
        </form>
      </div>
    );
  }
}
export default ProductReplaceDetail;