// Header.js
import React, {Component} from 'react';
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import Modal from 'react-modal';
import 'react-table/react-table.css'
import 'react-dropdown/style.css';
import DateTimePicker from 'react-datetime-picker';

var moment = require('moment');

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '400px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};

const printModalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '500px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};

const cancelButton = {
  content : {
    float                 : 'right'  
  }
};

const columns = [
  {
    Header: "Product ID",
    accessor: "pr_id"    
  },                              
  {
    Header: "SKU",
    accessor: "pr_sku"                                              
  },
  {
    Header: "UPC",
    accessor: "pr_upc"                                                   
  }, 
 {
    Header: "Item Qty",
    accessor: "item_qty"                                                  
},   
{
  Header: "Bill Level",
  accessor: "pr_bill_level"                                                  
},   
{
  Header: "Per Item Rate",
  accessor: "item_level_rate"                                                  
},
{
  Header: "Per Item Cost",
  accessor: "item_level_cost"                                                  
},
{
  Header: "Total Item Cost",
  accessor: "default_charge_per_item_total_cost"                                                 
},
{
  Header: "Log Date",
  accessor: "lg_entry_date"
} 
];
class ItemBillingReport extends Component {

    constructor(props) {
        super(props);        
            this.state = {
            inventoryitems: [],
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),
            us_id: localStorage.getItem('usId'),
            sortOptions: [{ id: 'pr_id', desc: true }],
            startDate: null,
            endDate: null,
            userToken: localStorage.getItem('userToken'),
            dataToDownload: []
        };
       
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.filterReport = this.filterReport.bind(this);
        this.download = this.download.bind(this);

    }

    openModal() {
      this.setState({modalIsOpen: true});
      if (this.state.cl_id =='') {
        this.setState({modalMessage: 'Please select a client first. '});
      } 

    }

    afterOpenModal() {     
    }

    closeModal() {
      this.setState({modalIsOpen: false});
    }

    filterReport = (url) => {
      this.getInventoryLog(process.env.REACT_APP_NODE_ROOT_URL+'/reports/billing/customer/item/'+this.state.cl_id);     
    }

    getInventoryLog = (url) => {
 
      let _startdate = this.state.startDate;
      let _enddate = this.state.endDate;

      if (_startdate==null) {
        _startdate = moment().startOf('month').format('YYYY-MM-DD');
        _enddate   = moment().endOf('month').format('YYYY-MM-DD');      
      } else {
        _startdate = moment(this.state.startDate).format('YYYY-MM-DD');
        _enddate = moment(this.state.endDate).format('YYYY-MM-DD');
      }
      
      var inventoryJSON = JSON.stringify({
        cl_id: this.state.cl_id,        
        start_date: _startdate,
        end_date: _enddate
      });

      //console.log('logging inventory log json...');
      //console.log(inventoryJSON);      
           
      fetch(url, {
            method: 'post',        
            body: inventoryJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
        }             
      }).then(res => res.json())
        .then(inventoryitems => this.setState({ inventoryitems })); 
    }  
    /*
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  },
    */
  prepare = () => {
    const currentRecords = this.reactTable.getResolvedState().sortedData;    
    var data_to_download = [];

    for (var index = 0; index < currentRecords.length; index++) {
       let record_to_download = {}
       for(var colIndex = 0; colIndex < columns.length ; colIndex ++) {
          record_to_download[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor]
       }
       data_to_download.push(record_to_download)
    }
    this.setState({ dataToDownload: data_to_download })
  } 

  download = () => {
    
    // click the CSVLink component to trigger the CSV download
    this.csvLink.link.click()

  } 
    
    componentDidMount() {
      Modal.setAppElement('#main');
      this.filterReport();               
    }
 
    render(){
        
        const { inventoryitems } = this.state;
        
        return (
        <div id="main">
        <div className="page-wrapper">
                <div className="row page-titles">
      
                  <div className="col-md-11 align-self-center">
                       <h3 className="text-primary">Item Billing Report</h3>                     
                  </div>
                  <div className="col-md-1 align-self-center"> 
                  
                  </div>
                </div>

                <div className="container-fluid">

                <div className="row">

                <div className="col-lg-12">                    
                            <table>                              
                              <tbody>
                              <tr>
                                <td>
                                Start Date:<br/>
                                <DateTimePicker
                                disableClock={true}
                                onChange={startDate => this.setState({ startDate })}
                                value={this.state.startDate}
                                format={"MM-dd-y"}
                              />&nbsp;&nbsp;</td>
                                <td>
                                End Date:<br/>
                                <DateTimePicker
                                disableClock={true}
                                onChange={endDate => this.setState({ endDate })}
                                onBlur={this.filterReport}
                                value={this.state.endDate}
                                format={"MM-dd-y"}
                              /></td>
                              </tr>
                              </tbody>
                            </table>
                           <br/>
                            <div className="card-body" name="card-order-list">
                            <div>               
                            <CSVLink filename="item-billing.csv" data={inventoryitems}> &#8250;&#8250;&#8250; Download item billing report &#8249;&#8249;&#8249; </CSVLink><br/>
                    
                          </div>
                          <div>
                              <CSVLink
                                  data={this.state.dataToDownload}
                                  filename="data.csv"
                                  className="hidden"
                                  ref={(r) => this.csvLink = r}
                                  target="_blank"/>

                          </div>

                                <div className="table-responsive">
                                  <ReactTable
                                      ref={(r) => this.reactTable = r}
                                      data={inventoryitems}
                                      sorted={this.state.sortOptions}
                                      onSortedChange={val => {
                                      this.setState({ sortOptions: val }) }}
                                      filterable
                                      noDataText='Loading item billing report...'
                                      loading={this.state.loading}
                                      defaultFilterMethod={(filter, row) =>
                                        String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}                                    
                                      columns={[
                                        {                                          
                                          columns: [
                                            {
                                              Header: "Product ID",
                                              accessor: "pr_id",
                                              width: 10,
                                              show: false,
                                              filterable: false,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                              
                                            {
                                              Header: "SKU",
                                              accessor: "pr_sku",
                                              width: 125,                                                                                          
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },
                                            {
                                              Header: "UPC",
                                              accessor: "pr_upc",
                                              width: 125,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            }, 
                                            {
                                                Header: "Item Qty",
                                                accessor: "item_qty",
                                                width: 125,                                               
                                                filterMethod: (filter, row) =>
                                                    row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            }, 
                                            {
                                                Header: "Bill Level",
                                                accessor: "pr_bill_level",
                                                width: 125,                                               
                                                filterMethod: (filter, row) =>
                                                    row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },                                            
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Per Item Level Rate</div>
                                              ),
                                              accessor: "item_level_rate",
                                              width: 100,
                                              show: false,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]==filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            }, 
                                            {
                                                Header: () => (
                                                  <div style={{ textAlign: "center" }}>Total Item Level Cost</div>
                                                ),
                                                accessor: "item_level_cost",
                                                width: 100,
                                                show: false,
                                                filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? row[filter.id]==filter.value : true,
                                                Cell: row => (
                                                  <div style={{ textAlign: "center" }}>{row.value}</div>
                                                )
                                              }, 
                                              {
                                                Header: () => (
                                                  <div style={{ textAlign: "center" }}>Default Item Rate Cost</div>
                                                ),
                                                accessor: "default_charge_per_item_total_cost",
                                                width: 100,
                                                show: false,
                                                filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? row[filter.id]==filter.value : true,
                                                Cell: row => (
                                                  <div style={{ textAlign: "center" }}>{row.value}</div>
                                                )
                                              }
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={50}
                                      className="-striped -highlight"
                                      
                                    />
                               
                                </div>
                            </div>
                       
                    </div>
                </div>               
                <div className="row">   
                    <div className="col-lg-4">
                       
                    </div>
                </div>


            </div>
        </div>
        </div>

                )
    }
}
export default ItemBillingReport;