import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Select from 'react-select';
import Modal from 'react-modal';
import 'react-dropdown/style.css';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import 'react-dropdown/style.css';
import matchSorter from 'match-sorter';
import { CSVLink } from "react-csv";

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '400px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};

const printModalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '500px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};
class ProductOtherDetail extends Component {

    constructor(props) {
        super(props);          

        this.state = {
            upc_search: '',              
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'), 
            ot_id: typeof(this.props.ot_id) === 'undefined' ? '' : this.props.ot_id, 
            pr_id: '',           
            others: [],            
            product: '',
            products: [],
            selectedproduct:'',
            productothers: [],
            ot_sku:'',            
            editModalMessage: '',
            editModalIsOpen: false,
            disableAddButton: true,
            edit_ot_id: '',            
            edit_ot_sku: '',
            edit_ot_prid: '',           
            labelModalIsOpen: false,
            importOtherModalIsOpen: false,
            printQty: 1,            
            userToken: localStorage.getItem('userToken')
        };               
        
        //TODO: finish all event handlers Add clock in and clock out to the login/logout actions. Do we auto log out people? 
        
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.getProductOthers = this.getProductOthers.bind(this);
        this.updateOthers = this.updateOthers.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleLabelPrint = this.handleLabelPrint.bind(this);
        this.getSku = this.getSku.bind(this);
        this.goSku = this.goSku.bind(this);

        this.openImportOtherModal = this.openImportOtherModal.bind(this);
        this.afterOpenImportOtherModal = this.afterOpenImportOtherModal.bind(this);
        this.closeImportOtherModal = this.closeImportOtherModal.bind(this);

        this.openLabelModal = this.openLabelModal.bind(this);
        this.afterOpenLabelModal = this.afterOpenLabelModal.bind(this);
        this.closeLabelModal = this.closeLabelModal.bind(this);

        this.afterOpenEditModal = this.afterOpenEditModal.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
               
    }

    componentDidMount() {
      Modal.setAppElement('#main');
      this.handleClientChange(this.props.cl_id);      
    }
   
    componentDidUpdate(prevProps, prevState) {
     
    }

    handleLabelPrint = (e) => {
      //console.log('print qty: ' + this.state.printQty);
      e.preventDefault();      
      //alert('print label ');
      return false;
    }

    //LABEL
    openLabelModal = (e) => {
     
        this.setState({labelModalIsOpen: true});
        this.setState({ot_sku: e.ot_sku});
        this.setState({printUPC: e.ot_upc});  

    }

    afterOpenLabelModal = (e) => {

    }

    closeLabelModal() {
      this.setState({labelModalIsOpen: false});
      this.setState({printQty:1});
    } 
    
    afterOpenEditModal() {
    }
    
    //EDIT
    //
    openEditModal = (e) => {    

      this.setState({editModalIsOpen: true});    

      console.log(JSON.stringify(e.original));
      
      this.setState({edit_ot_id: e.original.ot_id});
      this.setState({edit_ot_sku: e.original.ot_sku});
      this.setState({edit_pr_id: e.original.pr_id});

    }
    
    
    closeEditModal() {
      this.setState({editModalIsOpen: false});      
    }

    handleUpdateOther = (event) => {
      event.preventDefault();
      this.setState({editModalIsOpen: false}); 

      var otherJSON = JSON.stringify({
        ot_id: this.state.edit_ot_id,
        ot_sku: this.state.edit_ot_sku,
        pr_id: this.state.edit_ot_upc
      });

     //console.log(otherJSON)      
     
     fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/other/u/'+this.state.edit_ot_id, {
            method: 'post',
            body: otherJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            //console.log(response);
        }).then(function(data) { 
            //console.log(data);      
            alert('Case updated');  
            window.location.reload();     
        }).catch(function(error) {
            alert('Case could not be updated'); 
            //console.log('submit err: ' + error);       
        });          
        
    }

    //IMPORT OTHER
    openImportOtherModal() {
      this.setState({importOtherModalIsOpen: true});
    }

    afterOpenImportOtherModal() {
    }

    closeImportOtherModal() {
      this.setState({importOtherModalIsOpen: false});
    } 

    // EVENT HANDLERS
    goBack() {
      window.history.back();
    }

    //fix up these calls

    getSku = () => {

        var otherSku = this.state.ot_sku;

        var otherJSON = JSON.stringify({
            cl_id: this.state.cl_id,
            sku: otherSku            
        });

        console.log('getSku: ' + otherJSON)
                
        if (otherSku !== '')
        {        
            fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/sku/'+this.state.cl_id, {
                method: 'post',
                body: otherJSON,      
                headers: {            
                'Content-Type': 'application/json',
                'bwToken': this.state.userToken          
                }
                //TODO:
                //credentials: 'same-origin', // send cookies
                //credentials: 'include'   // send cookies, even in CORS
            
            }).then(res => res.json())
            .then(product => this.setState({ product }))
            .then(this.goSku).catch(function(error) {
                alert('Other SKU could not be updated. SKU should be unique or contact support.'); 
                //console.log('submit err: ' + error);       
            });  
        }
        
      }
    
      goSku = () => {
    
        const product = this.state.product; 
        console.log('issku: ' + JSON.stringify(product))

        let somevar = product[0];

        let ind = somevar[0];

        if (typeof ind==='undefined') {
          ind = somevar[1];          
        }          
        
        if (ind === 1) {
          alert('SKU is in use. Check your value and try again.');
          this.setState({ot_sku:''});
          return false;
        } else {
          return true;
        }    
        
      }

    getProducts = (url) => {

        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(products => this.setState({ products })); 

    }

    getProductOthers = (url) => {
      fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(productothers => this.setState({ productothers }));
    }
    
    getOthers(prId) {
      //console.log('get others');
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/other/'+prId,
      {
          method: 'GET',      
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }

          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(res => res.json())
      .then(others => this.setState({ others }));
    }

    handleOtherFileUpload = (e) => {
      e.preventDefault(); 
      
      const myFile = document.getElementById('trojanUpload');
      
      this.setState({
        selectedFile: myFile.files[0],
        loaded: 0,
      });
    }

    processOtherFileUpload = (e) => {

      e.preventDefault();

      this.setState({importOtherModalIsOpen: false});
      
      const data = new FormData();
      data.append('file',this.state.selectedFile);

      var fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/upload/trojan/'+this.state.cl_id;
      
      this.setState({activity_note: 'New other skus uploaded'});
      this.setState({activity_time: 1});

      fetch(fetchURL, {
        method: 'POST',
        body: data,
        headers: {
            'bwToken': this.state.userToken
        }      
        }).then(function(response) {
              if (response.statusText === 'OK'){               
                  alert('File uploaded successfully.');                                 
              } else {
                  alert('Upload Failed. Check file format and try again.')
              }
              return response.json();
        }).then(function(data) { 
              window.location.reload();               
        }).catch(function(error) {            
              alert('File not uploaded! ' + error);        
        });
  
       e.target.value = null; 
      
    }

    handleClientChange = (cl_id) => {
               
        if (cl_id === 0) {
            this.getProducts(process.env.REACT_APP_NODE_ROOT_URL+'/products');
            this.getProductOthers(process.env.REACT_APP_NODE_ROOT_URL+'/products/other');
            this.setState({disableAddButton: true});

        } else {
            this.getProducts(process.env.REACT_APP_NODE_ROOT_URL+'/products/client/'+cl_id);       
            this.getProductOthers(process.env.REACT_APP_NODE_ROOT_URL+'/products/other/client/'+cl_id);     
            this.setState({disableAddButton: false});
        }    
    }    
    
    handleProductChange = (e) => {
      console.log(e);      
      this.setState({selectedproduct: e});
      this.getOthers(e.value);
           
    }    

    handleSubmit = (e) => {

      e.preventDefault();

      var caseJSON = JSON.stringify({        
        cl_id: this.state.cl_id,
        ot_sku: this.state.ot_sku,  
        pr_id: this.state.selectedproduct.value
      });

      //console.log(caseJSON);
      
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/other/'+this.state.cl_id, {
            method: 'post',        
            body: caseJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            return response.json();
        }).then(function(data) {            
            alert('Other SKU added successfully'); 
            window.location.reload();   
        }).catch(function(error) {
            alert('Other SKU could not be added. SKU should be unique or contact support.'); 
            //console.log('submit err: ' + error);       
        }); 
        
    }

    handleUpdate = (event) => {

      event.preventDefault();

      if (this.state.others.length > 0) {
        //console.log('update others')
     
        const updaterequests = this.state.others.map((otheritem) =>         
          this.updateOthers(otheritem.ot_id,otheritem.ot_sku,otheritem.pr_id)          
        );
      
        Promise.all(updaterequests).then(() => {
          //console.log('Case updates done')
          alert('Other SKUs updated successfully')
          window.location.reload();
        });      
        
      } else { console.log('No other skus to update') }

      
    }
    
    updateOthers = (otId,otSku,prId) => {

      var otherJSON = JSON.stringify({  
        ot_id: otId,
        ot_sku: otSku,
        pr_id: prId
      });

     //console.log(caseJSON)     
    
     fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/other/u/'+otId, {
            method: 'post',
            body: otherJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            //console.log(response);
        }).then(function(data) { 
            //console.log(data);            
        }).catch(function(error) {
            alert('Other SKU could not be updated. SKU should be unique or contact support.'); 
            //console.log('submit err: ' + error);       
        });
        
    }
            
    deleteOther = (otID) => {

      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/other/del/'+otID, {
            method: 'post',             
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            //console.log(response);
        }).then(function(data) { 
            //console.log(data);
          window.location.reload();           
        }).catch(function(error) {
            alert('Other SKU could not be deleted. Contact support.'); 
            //console.log('submit err: ' + error);       
        });                
    }      

    handleQtyInputChange(event,i) {

      const target = event.target;
      const value = target.value;

      let {others} = this.state;  
      
      this.setState({others});
    
    }  


    handleInputChange(event) {      
   
      const target = event.target;
      const value = target.value;
      const name = target.name;
      
      this.setState({
       [name]: value
      });

    }

    handleKeyPress = (event) => {
      if(event.key === 'Enter'){
          //set upc from search box
          var find_upc = this.state.upc_search;
          
          this.findUPC(find_upc);       
          
          //used to track total scans if needed
          //this.setState((state) => ({ scans: state.scans + 1}));
          
          //TODO: check case value 
          
      }
    } 

    onRemoveItem = (i,cId) => {      
            
      this.setState(state => {
      const others = state.others.filter((item, j) => i !== j);
        return {
          others,
        };
      });     
      
      this.deleteOther(cId);
      
    }    

    render(){     

      const mappingFunction = p => ({ label: p.pr_sku + ' - ' + p.pr_desc + ' (' + p.pr_upc + ')', value: p.pr_id, qty:1, qoh: p.pr_qty, qty_res: p.pr_qty_reserved, pr_id: p.pr_id, cl_id: p.cl_id, pr_desc: p.pr_desc, pr_sku: p.pr_sku });


      //product drop down
      const productsearch = this.state.products.map(mappingFunction);
           
      const { upc_search, productothers, cl_id } = this.state;
      const _template_trojan = process.env.REACT_APP_ROOT_URL + '/files/serial-import.csv';

  
        return (
          <div id="main">
            <div className="page-wrapper">
               <div className="row page-titles">
               <div className="col-md-12">
                       <h3 className="text-primary">Product Other SKUs</h3>                       
               </div>
                       
            <div className="container-fluid">   
                   
            <div className="row">  
                  <div className="col-md-12">      
                  <CSVLink data={productothers}>Download CSV of Other SKUs </CSVLink><br/>
                    <ReactTable                
                      data={productothers}
                      filterable                      
                      defaultFilterMethod={(filter, row) =>
                      String(row[filter.id]) === filter.value}          
                      columns={[
                        {                                          
                          columns: [                                            
                            {
                              Header: "Delete",
                              accessor: "ot_id",
                              width: 50,
                              filterable: false,
                              Cell: props => (
                                <div><button type="button" onClick={() => { if (window.confirm('Delete this other SKU?')) this.deleteOther(props.original.ot_id) }} className="btn btn-xs">del</button></div> 
                              )
                            },
                            {
                              Header: "Edit",
                              accessor: "ot_id",
                              width: 50,
                              filterable: false,
                              show:false,
                              Cell: props => (
                                <div><button onClick={(e) => this.openEditModal(props)} className="btn btn-xs">edit</button></div>                                                
                              )
                            },
                            {
                              Header: "Other SKU ID",
                              accessor: "ot_id",
                              width: 25,
                              show: false,
                              filterMethod: (filter, row) =>
                                row[filter.id].startsWith(filter.value)          
                            },
                            {
                              Header: "Client ID",
                              accessor: "cl_id",
                              width: 25,
                              show: false,
                              filterMethod: (filter, row) =>
                                row[filter.id].startsWith(filter.value),
                              Cell: row => (
                                <div style={{ textAlign: "center"}}>{row.value}</div>
                              )
                            },
                            {
                              Header: "Product SKU",
                              accessor: "pr_sku",
                              width: 100,
                              filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, { keys: ["pr_sku"] }),
                              filterAll: true,
                              Cell: row => (
                                <div style={{ textAlign: "left"}}>{row.value}</div>
                              )
                            },
                            {
                              Header: "Other SKU",
                              accessor: "ot_sku",
                              width: 100,
                              filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, { keys: ["ot_sku"] }),
                              filterAll: true,
                              Cell: row => (
                                <div style={{ textAlign: "left"}}>{row.value}</div>
                              )
                            }
                          ]
                        }
                      ]}
                      defaultPageSize={3}
                      className="-striped -highlight"
                      
                    />
                  </div>
              </div>
            <div className="card">
              <div className="card-body" name="card-order-list">
              <div className="row">  
                  <div className="col-md-6">
                    <h4>Find a Product</h4>
                    <p>Search a product by name, sku, or upc to add an additional SKU.</p>  
                    <Select options={productsearch} className="product-drop" name="upc_search" onChange={this.handleProductChange} value={upc_search} placeholder="Search by SKU, Name, UPC" />
                  </div>
              </div>
                <div className="row">  
                    <div className="col-md-12">  
                    <strong>Product:</strong> <label>{this.state.selectedproduct.label}</label><br/>
                                          
                        {this.state.others.length > 0 ? ( 
                          <div id="div-order-items" className="form-group">

                        <form onSubmit={this.handleUpdate}>   

                          <h3>Other SKUs</h3>
                            <table className="table-order-items">
                              <thead> 
                                <tr>                          
                                
                                <td className="td-oi-sku"><u>Other SKU</u></td>                                  
                                <td className="td-oi-rem"><u>Delete</u></td>                             
                                </tr>
                              </thead>
                              {this.state.others.map((newitem, idx) => {                        
                                let tblId = `tbl-${idx}`;
                                let nameId = `item-${idx}`;

                                return (
                                    <tbody key={tblId}>
                                    <tr>
                                      
                                      <td className="td-oi-sku">{this.state.others[idx].ot_sku}</td>                                                             
                                      <td><button
                                              type="button"
                                              onClick={() => { if (window.confirm('Delete this other SKU?')) this.onRemoveItem(idx,this.state.others[idx].ot_id) }}
                                            >
                                              X
                                            </button>
                                      </td>
                                    </tr>
                                    </tbody>                                 
                                  )
                                })                
                              }                          

                                              
                          </table>                         
                         
                        </form>
                        </div>
                    
                        ) : (<div></div>) }
                    
                    </div> 
                   </div>
                </div>   
                <hr/>
                <div className="row">  
                  <div className="col-md-9">  
                  <form onSubmit={this.handleSubmit}>     
                      
                      <div className="form-group">                         
                         <div key="divId">                         
                          <label>
                          <label htmlFor="printQty" className="form-label">Other SKU: </label>&nbsp; 
                          <input
                            className="form-input"
                            id="ot_sku"
                            name="ot_sku"
                            type="text"
                            value={this.state.ot_sku}
                            onChange={(e) => {this.handleInputChange(e)}}
                            onBlur={this.getSku}
                            placeholder="" 
                          />
                          </label>                        
                         </div>
                        </div>
                       <input type="submit" value="Add" className="btn btn-info btn-sm"/>
                        </form>   
                 <br/>
                 <button type="button" data-toggle="collapse" className="btn btn-info btn-sm" onClick={this.openImportOtherModal}>Import Other SKUs</button>                 
                </div> 

              </div>
              </div>
              </div>
           
            </div>                      
  
         </div>
    
          <Modal
              isOpen={this.state.importOtherModalIsOpen}
              onAfterOpen={this.afterOpenImportOtherModal}            
              style={customStyles}
              contentLabel="Import Serials"
          >                   
            
            <div className="container-fluid">               
              <form>
                <h2>Import Other SKUs</h2>
                  <div className="row">
                    <div className="col-sm-12">  
                      1. Download the current import template. <br/>
                      a. pr_sku is the product sku you want your other sku to point to.<br/>
                      b. ot_sku is the other sku you want to add.<br/>
                      c. Don't add duplicates. Search first.<br/><br/>                            
                      2. Select and import a file. <br/>
                      <input type="file" id="trojanUpload" name="trojanUpload" onChange={(e) => this.handleOtherFileUpload(e)} />                                                                 
                      <label id="prUploadResponse"></label>
                    </div>
                                                      
                  </div>

                <div className="modalMessage">{this.state.importModalMessage}</div>
                <div className="right">
                  <button type="submit" onClick={(e) => this.processOtherFileUpload(e)}>Import</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closeImportOtherModal} className="btn btn-info btn-sm">Cancel</button>
                </div> 
                    
              </form> 

              <p> &gt; <a href={_template_trojan} target="_new">download import template</a></p>

            </div>                          
              
          </Modal>			
          

         </div>

                )
    }
}
export default ProductOtherDetail;