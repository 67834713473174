import React, { Component } from 'react';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { Table } from 'react-bootstrap';
import 'react-table/react-table.css'
import 'react-dropdown/style.css';
import './style.css';

class Content extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
      }

    constructor(props) {
        super(props);              

            this.state = {
            orders: [],
            orderStatus: 'all',
            userToken: localStorage.getItem('userToken')
        };       
              
        const { location } = this.props
        
        if (location.pathname !=='/') {

            var pathArray = location.pathname.split('/');

            //pathArray[1] = product
            //pathArray[2] = status value
                
            //console.log('arr: '+  pathArray[1])
            
            this.state = {        
                orderStatus : pathArray[1]
            };
        }
       
    }    

    componentDidMount() {     
      this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/reports/dash-orders/');     
    }   

    getOrders = (url) => {
        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(orders => this.setState({ orders }));       
    }

    handleStatusClick = (e) => {
    e.preventDefault();    
    
        const target = e.target;
    
        e.target.value = null; 
    }

    onStatusClick = i => {      
      this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/orders/o/status/'+i);
    }

    onMethodClick = i => {      
      this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/orders/o/method/'+i);
    }

    render(){
      
        return (
        <div className="page-wrapper">
            
            <div className="container-fluid">
               
                <div className="row">					
                    <div className="col-lg-12">
                                 
                        <div className="card">
                        
                            <div className="card-title"> 
                            <h3>
                              <a data-toggle="collapse" href="#collapseOutgoing" aria-expanded="true" aria-controls="collapseOutgoing" id="heading-example" className="d-block">                                
                                Orders
                                <i className="fa fa-chevron-down pull-right"></i>
                              </a>
                            </h3>
                            </div>
                            <div className="collapse show" id="collapseOutgoing">
                            
                            <div className="card-body">                            
                            <div className="table-responsive">
                               
                               <Table striped bordered hover size="sm">
                               <thead>
                                 <tr>
                                   <th>Client</th>
                                   <th>Total (New+Pro+Ready)</th>
                                   <th>New </th>                                   
                                   <th>Processing</th>    
                                   <th>Ready for Pick Up</th>     
                                   <th>Prime</th>  
                                   <th>Expedited</th>
                                   <th>Urgent</th> 
                                   <th>Shipped</th>
                                   <th>On Hold</th>
                                   <th>Canceled</th>
                                   <th>Mis-shipped</th>
                                 </tr>
                               </thead>
                               <tbody>
                               {this.state.orders.map(order =>
                                 <tr key={order.cl_id}>       
                                   <th><strong>{order.cl_name}</strong></th>                               
                                   <th><strong>{order.count_total}</strong></th>                                                                     
                                   <th><strong>{order.count_new}</strong></th>
                                   <th><strong>{order.count_process}</strong></th>
                                   <th><strong>{order.count_ready}</strong></th>
                                   <th><strong>{order.count_prime}</strong></th>
                                   <th><strong>{order.count_expedited}</strong></th>
                                   <th><strong>{order.count_urgent}</strong></th>
                                   <th><strong>{order.count_shipped}</strong></th>
                                   <th><strong>{order.count_hold}</strong></th>
                                   <th><strong>{order.count_canceled}</strong></th>
                                   <th><strong>{order.count_missed}</strong></th>
                                 </tr>
                               )}
                               </tbody>
                               </Table>
                           </div>

                            </div>
                        </div>
                    </div>
                </div>               
                <div className="row">   
                <div className="col-lg-4">
                        
                      </div>
                  </div>


              </div>

              <footer className="footer"> © 2024 All rights reserved. Brand Warehouse</footer>

            </div>
        </div>
                )
    }
}
export default withRouter(Content);