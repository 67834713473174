// Header.js
import React, {Component} from 'react';
class UserProfile extends Component {

    constructor(props) {
        super(props);
        
          this.state = {          
            us_id:this.props.userId,
            us_user_name:'',
            us_user_id:'',
            us_first_name:'',
            us_last_name:'',
            us_phone_num:'',
            us_email:'',
            userToken: localStorage.getItem('userToken')
          };
       
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }
	
	  componentDidMount() {
      this.getUser();	      
	  }
    
    getUser() {
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/users/'+this.props.userId, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
      }).then(res => res.json())      
      .then(user => this.setState({           
          us_user_name:user[0].us_user_name || '',
          us_user_id:user[0].us_user_id || '',
          us_first_name:user[0].us_first_name || '',
          us_last_name:user[0].us_last_name || '',
          us_phone_num:user[0].us_phone_num || '',
          us_email:user[0].us_email || ''
      }));      
    }
   
    handleInputChange(event) {     
      
      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.setState({
       [name]: value
      });

    }

    handleSubmit(event) {  
      //event.preventDefault();
      var myJSON = JSON.stringify(this.state);
      console.log(myJSON);
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/users/'+this.props.userId+'/', {
            method: 'post',        
            body: myJSON,      
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken        
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            alert('User updated successfully'); 
            //return response.json();
            return true;
        }).then(function(data) { 
            console.log(data);            
        }).catch(function(error) {
            alert('User details could not be updated'); 
            console.log('submit err: ' + error);       
        });
      

    }

    render(){
       
        return (

        <div className="page-wrapper" id="main">
            <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                    <h3 className="text-primary">User Details</h3> </div>
                <div className="col-md-7 align-self-center">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active"><a href="/Users">Users</a></li>
                    </ol>
                </div>
            </div>

            <div className="container-fluid">               
            <form onSubmit={this.handleSubmit}>
                <div className="row">  
                    <div className="col-md-4">           

                      <div className="form-group">                         
                          <label>
                            User ID:<br/>
                            {this.state.us_user_id}
                          </label>
                          <br />
                          <label>
                            User Name:<br/>
                            {this.state.us_user_name}
                          </label>
                          <br />
                          <label>
                            First Name:<br/>
                            <input
                              name="us_first_name"
                              type="text"
                              value={this.state.us_first_name}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Last Name:<br/>
                            <input
                              name="us_last_name"
                              type="text"
                              value={this.state.us_last_name}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Phone:<br/>
                            <input
                              name="us_phone_num"
                              type="text"
                              value={this.state.us_phone_num}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Email:<br/>
                            <input
                              name="us_email"
                              type="text"
                              value={this.state.us_email}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                      </div>
                </div>
                <div className="btn-div">
                  <input type="submit" value="Submit" className="btn btn-primary btn-md"/>
                </div>
              </div>
            </form>           

            </div>                      
        </div>

                )
    }
}
export default UserProfile;