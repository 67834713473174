// Header.js
import React from 'react';
import 'react-table/react-table.css';
import 'react-dropdown/style.css';
import Barcode from 'react-barcode';
import './print.css';

class TrojanPrintLabel extends React.Component {
    constructor(props) {
        super(props);
            this.state = {
                        
        };
             
    }

    createLabels = () => {
        
        let table = [];
        var _printSizeValue = this.props.printSizeValue
        var _printSerialValue = this.props.printSerialValue
  
        //180/72/39-20091726st+jd0729-4

        var _printLocation = _printSizeValue+'-'+_printSerialValue

        for (let i = 0; i < this.props.printQty; i++) {
        
            let lblId = `lbl-${i}`;

            table.push(<div key={lblId} className="label-product">
            <span style={{fontSize:this.props.printSize + 'pt'}}>{_printLocation}<br/>     
            <Barcode width={Number(this.props.labelWidth)} height={Number(this.props.labelHeight)} fontSize={Number(this.props.printSize)} value={_printLocation} displayValue={false} />
            </span>
            </div>);
            
        }      
      
      return table;     

    }
    
    render() {
        
    return (
      <div className="print">
             
        {this.createLabels()}   
     
      </div>
    );
  }
}
export default TrojanPrintLabel;