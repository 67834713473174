import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import matchSorter from 'match-sorter';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import 'react-dropdown/style.css';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '400px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};

const printModalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '500px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};
class ProductIgnoreDetail extends Component {

    constructor(props) {
        super(props);          

        this.state = {
            upc_search: '',              
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'), 
            ig_id: typeof(this.props.ig_id) === 'undefined' ? '' : this.props.ig_id, 
            ignores: [],
            selectedproduct:'',
            productignores: [],
            ig_sku:'',            
            editModalMessage: '',
            editModalIsOpen: false,
            disableAddButton: true,                  
            labelModalIsOpen: false,
            printQty: 1,            
            userToken: localStorage.getItem('userToken')
        };               
        
        //TODO: finish all event handlers Add clock in and clock out to the login/logout actions. Do we auto log out people? 
        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.getProductIgnores = this.getProductIgnores.bind(this);
               
    }

    componentDidMount() {
      this.handleClientChange(this.props.cl_id);      
    }
   
    componentDidUpdate(prevProps, prevState) {
     
    }
   
    // EVENT HANDLERS
    goBack() {
      window.history.back();
    }   

    getProductIgnores = (url) => {
      fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(productignores => this.setState({ productignores }));
    }
    
    getIgnores(prId) {
      //console.log('get others');
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/ignore/'+prId,
      {
          method: 'GET',      
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }

          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(res => res.json())
      .then(others => this.setState({ others }));
    }

    handleClientChange = (cl_id) => {
               
        if (cl_id === 0) {
            this.getProductIgnores(process.env.REACT_APP_NODE_ROOT_URL+'/products/ignore');
            this.setState({disableAddButton: true});
        } else {                
            this.getProductIgnores(process.env.REACT_APP_NODE_ROOT_URL+'/products/ignore/client/'+cl_id);     
            this.setState({disableAddButton: false});
        }    
    }     
    
    handleSubmit = (e) => {

      e.preventDefault();

      var ignoreJSON = JSON.stringify({        
        cl_id: this.state.cl_id,
        ig_sku: this.state.ig_sku
      });
    
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/ignore/'+this.state.cl_id, {
            method: 'post',        
            body: ignoreJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            return response.json();
        }).then(function(data) {            
            alert('Ignore SKU added successfully'); 
            window.location.reload();   
        }).catch(function(error) {
            alert('Ignore SKU could not be added'); 
            //console.log('submit err: ' + error);       
        }); 
        
    }  
            
    deleteIgnore = (igID) => {

      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/ignore/del/'+igID, {
            method: 'post',             
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            //console.log(response);
        }).then(function(data) { 
            //console.log(data);
          window.location.reload();           
        }).catch(function(error) {
            alert('Ignore SKU could not be deleted. Contact support.'); 
            //console.log('submit err: ' + error);       
        });                
    }      

    handleInputChange(event) {      
   
      const target = event.target;
      const value = target.value;
      const name = target.name;
      
      this.setState({
       [name]: value
      });

    }

    onRemoveItem = (i,cId) => {      
            
      this.setState(state => {
      const ignores = state.ignores.filter((item, j) => i !== j);
        return {
          ignores,
        };
      });     
      
      this.deleteIgnore(cId);
      
    }    

    render(){    

        const { productignores } = this.state; 
    
        return (
          <div id="main">
            <div className="page-wrapper">
               <div className="row page-titles">
               <div className="col-md-12">
                       <h3 className="text-primary">Ignored SKUs</h3> 
               </div>    
              </div>              
            <div className="container-fluid">  
                <div className="row">  
                    <div className="col-md-9">  
                        <p>Use the form below to add SKUs that you want ignored during order imports.</p>
                        <form onSubmit={this.handleSubmit}>     
                            
                            <div className="form-group">                         
                                <div key="divId">                         
                                    <label htmlFor="printQty" className="form-label">SKU to ignore: </label>&nbsp; 
                                    <input
                                        className="form-input"
                                        id="ig_sku"
                                        name="ig_sku"
                                        type="text"
                                        value={this.state.ig_sku}
                                        onChange={(e) => {this.handleInputChange(e)}}                            
                                        placeholder="" 
                                    />
                            
                                </div> 
                                <input type="submit" value="Add" className="btn btn-primary btn-sm"/>
                            </div>                           
                        </form>   
                    </div> 
                </div>  
            
                <div className="row">  
                  <div className="col-md-12">
       
                
                    <ReactTable                
                      data={productignores}
                      filterable                      
                      defaultFilterMethod={(filter, row) =>
                      String(row[filter.id]) === filter.value}          
                      columns={[
                        {                                          
                          columns: [                                            
                            {
                              Header: "Delete",
                              accessor: "ig_id",
                              width: 50,
                              filterable: false,
                              Cell: props => (
                                <div><button type="button" onClick={() => { if (window.confirm('Delete this ignored SKU?')) this.deleteIgnore(props.original.ig_id) }} className="btn btn-xs">del</button></div> 
                              )
                            },
                            {
                              Header: "Ignore SKU",
                              accessor: "ig_sku",
                              width: 100,
                              filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, { keys: ["ig_sku"] }),
                              filterAll: true,
                              Cell: row => (
                                <div style={{ textAlign: "left"}}>{row.value}</div>
                              )
                            }
                          ]
                        }
                      ]}
                      defaultPageSize={10}
                      className="-striped -highlight"
                      
                    />
                </div>
            </div>
            </div> 
        </div>
       </div>
 
        )
    }
}
export default ProductIgnoreDetail;