import React, { Component } from 'react';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { Table } from 'react-bootstrap';
import 'react-table/react-table.css'
import 'react-dropdown/style.css';
import './style.css';
import moment from 'moment';

class FulfillmentDash extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
      }

    constructor(props) {
        super(props);
            this.state = {
            reports: [],
            orderStatus: 'all',
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),
            us_id: localStorage.getItem('usId'),            
            startDate: '2024-03-01',
            endDate: '2024-03-31',
            startTime: null,
            endTime: null,
            userToken: localStorage.getItem('userToken')
        };       

        this.getFulfillment = this.getFulfillment.bind(this);
              
        
       
    }    

    componentDidMount() {   
      var _test = process.env.REACT_APP_NODE_ROOT_URL+'/reports/fulfillment/dash/'+this.state.cl_id;
      console.log('url: ' + _test);

      this.getFulfillment(process.env.REACT_APP_NODE_ROOT_URL+'/reports/fulfillment/dash/'+this.state.cl_id);     
    }   

    getFulfillment = (url) => {
      console.log('test')
      let _startdate = this.state.startDate;
      let _enddate = this.state.endDate;

      if (_startdate==null) {
        _startdate = moment().startOf('month').format('YYYY-MM-DD');
        _enddate   = moment().endOf('month').format('YYYY-MM-DD');      
      } else {
        _startdate = moment(this.state.startDate).format('YYYY-MM-DD');
        _enddate = moment(this.state.endDate).format('YYYY-MM-DD');
      }
      
      var inventoryJSON = JSON.stringify({
        cl_id: this.state.cl_id,        
        start_date: _startdate,
        end_date: _enddate
      });

      console.log('logging inventory log json...');
      console.log(inventoryJSON);      
           
      fetch(url, {
            method: 'post',        
            body: inventoryJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
        }             
      }).then(res => res.json())
        .then(reports => this.setState({ reports }));

    }  

    render(){
      
        return (
        <div className="page-wrapper">
            
            <div className="container-fluid">
               
              <div className="row">					
                    <div className="col-lg-12">                                 
                      <div className="card">                        
                      <div className="card-title"> 
                      <h3>
                        <a data-toggle="collapse" href="#collapseOutgoing" aria-expanded="true" aria-controls="collapseOutgoing" id="heading-example" className="d-block">                                
                          Returns
                          <i className="fa fa-chevron-down pull-right"></i>
                        </a>
                      </h3>
                      </div>
                      <div className="collapse show" id="collapseOutgoing">                            
                        <div className="card-body">                            
                      <div className="table-responsive">
                          
                      <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                            <th>Quantity of RMA's</th>
                            <th>Quantity of RMA's received</th>
                            <th>Quantity of RMA's checked-in within 1 business day</th>
                            <th>Quantity of units</th>
                            <th>Quantity of units received</th>                            
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.reports.map(report =>
                            <tr key={report.cl_id}>       
                              <th><strong>{report.totalReturns}</strong></th>                               
                              <th><strong>{report.totalReturnsReceived}</strong></th>                                                                     
                              <th><strong>{report.totalReturnsReceivedOneDay}</strong></th>
                              <th><strong>{report.totalReturnItems}</strong></th>
                              <th><strong>{report.totalReturnItemsReceived}</strong></th>
                              
                            </tr>
                          )}
                          </tbody>
                          </Table>
                      </div>

                      </div>
                      </div>
                    </div>
                </div>               
                <div className="row">   
                <div className="col-lg-4">
                        
                      </div>
                  </div>


              </div>

              <div className="row">					
                    <div className="col-lg-12">                                 
                      <div className="card">                        
                      <div className="card-title"> 
                      <h3>
                        <a data-toggle="collapse" href="#collapseOutgoing" aria-expanded="true" aria-controls="collapseOutgoing" id="heading-example" className="d-block">                                
                          Incoming Product
                          <i className="fa fa-chevron-down pull-right"></i>
                        </a>
                      </h3>
                      </div>
                      <div className="collapse show" id="collapseOutgoing">                            
                        <div className="card-body">                            
                      <div className="table-responsive">
                          
                          <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th>Quantity Pallet (Bimecc)</th>
                              <th>Quantity & percentage received within 1 business day</th>
                              <th>Quantity Small Package</th>
                              <th>Quantity & percentage received within 1 business day</th>
                              <th>Quantity Container(s)</th>
                              <th>SKUs on container (average)</th>
                              <th>Quantity of units</th>
                              <th>Quantity & percentage checked-in within 1 business day</th>                                                    
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.reports.map(report =>
                            <tr key={report.cl_id}>       
                              <th><strong>{report.totalPalletsReceived}</strong></th>                               
                              <th><strong>{report.totalPalletItemsReceivedOneDay} ({report.totalPalletItemsReceivedOneDayPercent}%)</strong></th>                                                                     
                              <th><strong>{report.totalParcelsReceived}</strong></th>
                              <th><strong>{report.totalParcelItemsReceivedOneDay} ({report.totalParcelItemsReceivedOneDayPercent}%)</strong></th>                                                                     
                              <th><strong>{report.totalContainersReceived}</strong></th>
                              <th><strong>{report.AverageItemsOnContainer}</strong></th>                               
                              <th><strong>{report.totalContainerItems}</strong></th>                                                                     
                              <th><strong>{report.totalContainerItemsReceivedOneDay} ({report.totalContainerItemsReceivedOneDayPercent}%)</strong></th>                                                                     
                                                            
                            </tr>
                          )}
                          </tbody>
                          </Table>
                      </div>

                      </div>
                      </div>
                    </div>
                </div>               
                <div className="row">   
                <div className="col-lg-4">
                        
                      </div>
                  </div>


              </div>

              <div className="row">					
                    <div className="col-lg-12">                                 
                      <div className="card">                        
                      <div className="card-title"> 
                      <h3>
                        <a data-toggle="collapse" href="#collapseOutgoing" aria-expanded="true" aria-controls="collapseOutgoing" id="heading-example" className="d-block">                                
                          Outgoing Product
                          <i className="fa fa-chevron-down pull-right"></i>
                        </a>
                      </h3>
                      </div>
                      <div className="collapse show" id="collapseOutgoing">                            
                        <div className="card-body">                            
                      <div className="table-responsive">
                          
                          <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                            <th>Quantity of Small Package Orders </th>
                            <th>Quantity of Orders Shipped </th>
                            <th>Quantity & percentage of orders shipped same day or next business day </th>
                            <th>Quantity of Units </th>
                            <th>Quantity of mis-shipped </th>
                            <th>Accuracy of Orders Shipped (%) </th>
                            <th>Quantity of Expedited Orders </th>
                            <th>Quantity of Expedited Orders Shipped Orders shipped same Day </th>
                            <th>Quantity of Palletized Orders</th>
                            <th>Quantity of Orders Shipped</th>
                            <th>Quantity & percentage of Orders Shipped within two business days</th>
                            <th>Quantity of Units</th>
                            <th>Quantity of mis-shipped</th>
                            <th>Accuracy of Orders Shipped (%)</th>
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.reports.map(report =>
                            <tr key={report.cl_id}>       
                              <th><strong>{report.totalOnTimeEcommerce}</strong></th>                               
                              <th><strong>{report.totalShipped}</strong></th>                                                                     
                              <th><strong>{report.count_new}</strong></th>
                              <th><strong>{report.count_process}</strong></th>
                              <th><strong>{report.count_ready}</strong></th>
                              <th><strong>{report.count_prime}</strong></th>
                              <th><strong>{report.count_expedited}</strong></th>
                              <th><strong>{report.count_urgent}</strong></th>
                              <th><strong>{report.count_shipped}</strong></th>
                              <th><strong>{report.count_hold}</strong></th>
                              <th><strong>{report.count_canceled}</strong></th>
                              <th><strong>{report.count_missed}</strong></th>
                            </tr>
                          )}
                          </tbody>
                          </Table>
                      </div>

                      </div>
                      </div>
                    </div>
                </div>               
                <div className="row">   
                <div className="col-lg-4">
                        
                      </div>
                  </div>


              </div>

              <footer className="footer"> © 2024 All rights reserved. Brand Warehouse</footer>

            </div>
        </div>
                )
    }
}
export default withRouter(FulfillmentDash);