import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Select from 'react-select';
import Modal from 'react-modal';
import 'react-dropdown/style.css';
import ReactToPrint from "react-to-print";
import ProductPrintLabel from './ProductPrintLabel';

const printModalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '500px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};
class ProductKitDetail extends Component {

    constructor(props) {
        super(props);          

        this.state = {
            upc_search: '',              
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'), 
            kt_id: typeof(this.props.kt_id) === 'undefined' ? '' : this.props.kt_id,           
            kititems: [],
            newitems: [],
            products: [],
            kitdetail: [{kt_sku:'',kt_upc:'',kt_active:'Yes',kt_name:''}],
            labelModalIsOpen: false,
            printQty: 1,
            userToken: localStorage.getItem('userToken')
        };               
        
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.handleNewQtyInputChange = this.handleNewQtyInputChange.bind(this);
        this.handleUpdateQtyInputChange = this.handleUpdateQtyInputChange.bind(this);
        this.handleKitSkuInputChange = this.handleKitSkuInputChange.bind(this);
        this.handleKitUpcInputChange = this.handleKitUpcInputChange.bind(this);
        this.handleStatusInputChange = this.handleStatusInputChange.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.onRemoveNewItem = this.onRemoveNewItem.bind(this);
        this.addKit = this.addKit.bind(this);
        this.updateKit = this.updateKit.bind(this);
        this.getSku = this.getSku.bind(this);
        this.goSku = this.goSku.bind(this);

        this.handleLabelPrint = this.handleLabelPrint.bind(this);

        this.openLabelModal = this.openLabelModal.bind(this);
        this.afterOpenLabelModal = this.afterOpenLabelModal.bind(this);
        this.closeLabelModal = this.closeLabelModal.bind(this);
               
    }

    componentDidMount() {
      Modal.setAppElement('#main');
      this.handleClientChange(this.props.cl_id);
      if (typeof(this.props.kt_id) !== 'undefined') {
        console.log('get kit info: ' + this.props.kt_id);
        this.getKitDetail();
        this.getKitItems();
        console.log(this.state.kititems)
      } else {
        console.log('new kit')
      }    
    }
   
    componentDidUpdate(prevProps, prevState) {
     
    }

    handleLabelPrint = (e) => {
      console.log('print qty: ' + this.state.printQty);
      e.preventDefault();      
      alert('print label ');
      return false;
    }

    //LABEL
    openLabelModal = (e) => {
      //console.log('e: ' + JSON.stringify(e));
      var _prsku = e.pr_sku;

      if (_prsku === '') {
          //console.log('printid: ' + e.pr_id);
          this.setState({labelModalIsOpen: true});
          this.setState({kt_sku: e.kt_sku});
          this.setState({printUPC: e.kt_upc}); 
  
      } else {
        //console.log('printid: ' + e.pr_id);
        this.setState({labelModalIsOpen: true});
        this.setState({kt_sku: e.pr_sku});
        this.setState({printUPC: e.pr_upc}); 
      }

    }

    afterOpenLabelModal = (e) => {

    }

    closeLabelModal() {
      this.setState({labelModalIsOpen: false});
      this.setState({printQty:1});
    } 
    
    
    // EVENT HANDLERS

    getSku = () => {

      var otherSku = this.state.kitdetail[0].kt_sku;

      var otherJSON = JSON.stringify({
          cl_id: this.state.cl_id,
          sku: otherSku            
      });

      if (otherSku !== '')
      {        
          fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/sku/'+this.state.cl_id, {
              method: 'post',
              body: otherJSON,      
              headers: {            
              'Content-Type': 'application/json',
              'bwToken': this.state.userToken          
              }
              //TODO:
              //credentials: 'same-origin', // send cookies
              //credentials: 'include'   // send cookies, even in CORS
          
          }).then(res => res.json())
          .then(product => this.setState({ product }))
          .then(this.goSku).catch(function(error) {
              alert('Other SKU could not be updated. Contact support.'); 
              //console.log('submit err: ' + error);       
          });  
      }
    }
  
    goSku = () => {
    
      const product = this.state.product; 
      console.log('issku: ' + JSON.stringify(product))

      let somevar = product[0];

      let ind = somevar[0];

      if (typeof ind==='undefined') {
        ind = somevar[1];          
      }          
      
      if (ind === 1) {
        alert('SKU is in use. Check your value and try again.');
        this.setState({kt_sku:''});
        return false;
      } else {
        return true;
      }    
      
    }


    goBack() {
      window.history.back();
    }

    getProducts = (url) => {

        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(products => this.setState({ products })); 

    }

    getKitDetail() {
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits/d/'+this.props.kt_id,
      {
          method: 'GET',      
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }

          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(res => res.json())
      .then(kitdetail => this.setState({ kitdetail }));
    }

    getKitItems() {
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits/items/'+this.props.kt_id,
      {
          method: 'GET',      
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }

          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(res => res.json())
      .then(kititems => this.setState({ kititems }));
    }

    handleClientChange = (cl_id) => {               
       
        this.setState({cl_id: cl_id});   
                
        if (cl_id === 0) {
            this.getProducts(process.env.REACT_APP_NODE_ROOT_URL+'/products');
        } else {
            this.getProducts(process.env.REACT_APP_NODE_ROOT_URL+'/products/client/'+cl_id);            
        }

        this.setState({kititems: [], newitems: []});       

    }    
    
    handleProductChange = (e) => {
      console.log(e);

      const _newitems = this.state.newitems;

      console.log(_newitems)

      if(_newitems.some(_newitem => _newitem.value === e.value)){
        alert("Item already in kit. Adjust quantity if needed.");
      } else{
        this.setState((prevState) => ({
          newitems: [...prevState.newitems,e],
      })); 
      }

    }    
   
    handleSubmit = (event) => {
      event.preventDefault();

      console.log('logging kititems length...');
      console.log(this.state.kititems.length);

      console.log('logging newitems length...');
      console.log(this.state.newitems.length);

      if (this.state.kititems.length > 0) {
        console.log('update kits')
     
        this.updateKit()
        const updaterequests = this.state.kititems.map((kititem) =>         
          this.updateKitItems(kititem.ki_id,kititem.ki_qty,kititem.pr_id)
          
        );
      
        Promise.all(updaterequests).then(() => {
          console.log('kit updates done')
          //alert('Existing kit items updated successfully')
          //window.history.back();
        });      
        
      } else { console.log('no kit items to update') }

      if (this.state.newitems.length > 0) {
        
        if (this.state.kititems.length === 0) {
          console.log('add new kit')        
          this.addKit();
          alert('New kit added successfully');
          window.location.replace("/product-kits/"+this.state.cl_id);
        } else {

          console.log('add new items to existing kit.')
          const addItemsRequest = this.state.newitems.map((kititem) =>            
            this.addKitItem(kititem.qty,kititem.value)
          );

          Promise.all(addItemsRequest).then(() => {
            console.log('new items have been added')                      
          }); 
        }
        
      }
    }

    addKit = () => {

      var kitJSON = JSON.stringify({
        kt_name: this.state.kitdetail[0].kt_name,
        kt_sku: this.state.kitdetail[0].kt_sku,
        kt_upc: this.state.kitdetail[0].kt_upc,   
        kt_active: this.state.kitdetail[0].kt_active,     
        cl_id: this.state.cl_id,
        kititems: this.state.newitems
      });

      console.log(kitJSON)
      console.log('kit testing above');
      
     fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits/'+this.state.cl_id, {
            method: 'post',        
            body: kitJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            console.log(response);
        }).then(function(data) { 
            console.log(data);   
            //alert('Kit added successfully');             
        }).catch(function(error) {
            //alert('Kit could not be added'); 
            console.log('submit err: ' + error);       
        }); 
    }

    addKitItem = (ktQty,prId) => {

      var kitJSON = JSON.stringify({  
        kt_id: this.state.kt_id,          
        ki_qty: ktQty,        
        pr_id: prId
      });

     console.log('kit item json:' + kitJSON)
    
     fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits/items/'+this.state.kt_id, {
            method: 'post',
            body: kitJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            console.log(response);
        }).then(function(data) { 
            console.log(data);
            //alert('Kit item added successfully');                  
        }).catch(function(error) {
            alert('Kit item could not be added'); 
            console.log('submit err: ' + error);       
        });  
        
    }


    updateKit = () => {

      var kitJSON = JSON.stringify({  
        kt_id: this.state.kt_id,  
        kt_name: this.state.kitdetail[0].kt_name,
        kt_sku: this.state.kitdetail[0].kt_sku,
        kt_upc: this.state.kitdetail[0].kt_upc,
        kt_active: this.state.kitdetail[0].kt_active
      });

     console.log(kitJSON)
    
     fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits/u/'+this.state.kt_id, {
            method: 'post',
            body: kitJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            console.log(response);
        }).then(function(data) { 
            console.log(data);  
            alert('Kit updated successfully');               
        }).catch(function(error) {
            alert('Kit could not be updated'); 
            console.log('submit err: ' + error);       
        });  
        
    }      

    updateKitItems = (itemId,ktQty,prId) => {

      var kitJSON = JSON.stringify({  
        ki_id: itemId,          
        ki_qty: ktQty,        
        pr_id: prId
      });

     console.log(kitJSON)
    
     fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits/items/u/'+itemId, {
            method: 'post',
            body: kitJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            console.log(response);
        }).then(function(data) { 
            console.log(data);
            //alert('Kit items updated successfully');             
        }).catch(function(error) {
            alert('Kit items could not be updated'); 
            console.log('submit err: ' + error);       
        });  
        
    }
        
    deleteKitItem = (prId) => {

      var delJSON = JSON.stringify({  
        kt_id: this.state.kt_id,
        pr_id: prId
      });

      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits/items/del/'+this.state.kt_id, {
            method: 'post', 
            body: delJSON,  
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            console.log(response);
        }).then(function(data) { 
            console.log(data);           
        }).catch(function(error) {
            alert('Kit items could not be removed. Contact support.'); 
            console.log('submit err: ' + error);       
        });  
        
    }      

    handleKitNameInputChange(event,i) {

      const target = event.target;
      const value = target.value;     

      let {kitdetail} = this.state;  

      kitdetail[i].kt_name = value;

      this.setState({kitdetail});         
    }

    handleKitSkuInputChange(event,i) {

      const target = event.target;
      const value = target.value;

      let {kitdetail} = this.state;  

      kitdetail[i].kt_sku = value;

      this.setState({kitdetail});         
    }

    handleKitUpcInputChange(event,i) {

      const target = event.target;
      const value = target.value;     

      let {kitdetail} = this.state;  

      kitdetail[i].kt_upc = value;

      this.setState({kitdetail});         
    }

    handleUpdateQtyInputChange(event,i) {

      const target = event.target;
      const value = target.value;

      let {kititems} = this.state;  

      kititems[i].ki_qty = Number(value);

      this.setState({kititems});
    
    }  

    handleNewQtyInputChange(event,i) {

      const target = event.target;
      const value = target.value;

      let {newitems} = this.state;  

      newitems[i].qty = Number(value);

      this.setState({newitems});
    
    }

    handleStatusInputChange(event,i) {

      const target = event.target;
      const value = target.value;     

      let {kitdetail} = this.state;  

      kitdetail[i].kt_active = value;

      this.setState({kitdetail});         
    
    }

    handleInputChange(event) {
   
      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.setState({
       [name]: value
      });

      //if (name=='or_ship_cost') {
      //  this.calculateOrderTotal();
      //}
    }

    handleKeyPress = (event) => {
      if(event.key === 'Enter'){
          //set upc from search box
          var find_upc = this.state.upc_search;
          
          this.findUPC(find_upc);       
          
          //used to track total scans if needed
          //this.setState((state) => ({ scans: state.scans + 1}));
          
          //TODO: check case value 
          
      }
    } 

    onRemoveItem = (i,kId) => {      
            
      this.setState(state => {
      const kititems = state.kititems.filter((item, j) => i !== j);
        return {
          kititems,
        };
      });
      
      
      this.deleteKitItem(kId);
      
    } 

    onRemoveNewItem = i => {
      
      this.setState(state => {
      const newitems = state.newitems.filter((item, j) => i !== j);
        return {
          newitems,
        };
      });      
    } 

    render(){     

      //kit items
      //const mappingFunction = p => ({ id: p.pr_id, label: p.pr_desc, sku: p.pr_sku, price: p.pr_price, qty: 0 });

      const mappingFunction = p => ({ label: p.pr_sku + ' - ' + p.pr_desc + ' (' + p.pr_upc + ')', value: p.pr_id, qty:1, qoh: p.pr_qty, qty_res: p.pr_qty_reserved, pr_id: p.pr_id, cl_id: p.cl_id, pr_desc: p.pr_desc, pr_sku: p.pr_sku });


      //product drop down
      const productsearch = this.state.products.map(mappingFunction);
      //const kititems = this.state.kititems.map(mappingFunction);
           
      const { cl_id, upc_search } = this.state;

      const notifyOptions = [
        'Yes','No'
      ]
      //`cl_id`,`kt_id`,`kt_sku`,`kt_upc`,`kt_qty`,`pr_id`,`pr_sku`,`pr_desc`,`pr_upc`,`pr_ean`,`pr_price`,`pr_qty`

        return (
          <div id="main">
            <div className="page-wrapper">
               <div className="row page-titles">
               <div className="col-md-2 align-self-center">
                       <h3 className="text-primary">Product Kits</h3>
                    
                  </div>
                  <div className="col-md-10 align-self-center"> 
                  <Link to={`/product-kits/${cl_id}`}><button type="button" className="btn btn-info btn-sm" disabled={this.state.disableAddButton} >View All Kits</button></Link>
                         
                  </div>
            </div>
            <div className="container-fluid">               
            <form onSubmit={this.handleSubmit}>
            <div className="card">
              <div className="card-body" name="card-order-list">
                <div className="row">  
                    <div className="col-md-12">            

                      <div className="form-group">
                         <h3>Kit Information</h3>
                         <p>Kits should be used to create a one to many relationship between a unique incoming SKU and a set of existing SKUs.</p>
                         <p>We do not receive Kit SKUs. We do receive Case SKUs. See Manage Cases for more details.</p>
                         <p>Kits cannot be deleted at this time and should be marked as Active = No. Alerts will be created for imported orders containing inactive kits.</p>
                         <p>A Kit SKU CANNOT be added to another Kit at this time.</p>
                         
                         {this.state.kitdetail.map((detail, idx) => {
                          
                          let divId = `div-${idx}`;
                         
                          return (
                          <div key={divId}>
                            <label>
                            Kit Name:<br/>
                            <input
                              name="kt_name"
                              type="text"
                              value={this.state.kitdetail[idx].kt_name}
                              onChange={(e) => {this.handleKitNameInputChange(e, idx)}} />
                          </label>
                          <br />
                          <label>
                            Kit SKU:<br/>
                            <input
                              name="kt_sku"
                              type="text"
                              value={this.state.kitdetail[idx].kt_sku}
                              onChange={(e) => {this.handleKitSkuInputChange(e, idx)}}
                              onBlur={this.getSku} />
                          </label>
                          <br />
                          <label>
                            Kit UPC:<br/>
                            <input
                              name="kt_upc"
                              type="text"
                              value={this.state.kitdetail[idx].kt_upc}
                              onChange={(e) => {this.handleKitUpcInputChange(e, idx)}} />
                          </label>
                          <br />
                          <label>
                          Active?<br/>
                          <select value={this.state.kitdetail[idx].kt_active} 
                                  onChange={(e) => {this.handleStatusInputChange(e, idx)}}>
                            {notifyOptions.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                          </select>
                          </label>
                          </div>                                                      
                            )
                          })                
                        }              
                        </div>
                    </div> 
                </div>                
                <div className="row">  
                  <div className="col-md-6">
                    <h3>Select a Kit item to add ...</h3>
                    <Select options={productsearch} className="product-drop" name="upc_search" onChange={this.handleProductChange} value={upc_search} placeholder="Search products" />
                  </div>
                </div>
                <div className="row">  
                  <div className="col-md-9">  
                  <div id="div-order-items" className="form-group">
                     
                     <h3>Kit Items</h3>
                      <table className="table-order-items">
                        <thead> 
                          <tr>                          
                            <td className="td-oi-print"><u>Print Labels</u></td>
                            <td className="td-oi-sku"><u>SKU</u></td>
                            <td className="td-oi-label"><u>Desc</u></td>                            
                            <td className="td-oi-price"><u>Qty Avail</u></td>
                            <td className="td-oi-qty"><u>Qty in Kit</u></td>                            
                            <td className="td-oi-rem"><u>Remove</u></td>                              
                          </tr>
                        </thead>
                        {this.state.newitems.map((newitem, idx) => {
                 
                          let tblId = `tbl-${idx}`;
                          let nameId = `item-${idx}`;

                          return (
                              <tbody key={tblId}>
                              <tr>
                                <td className="td-oi-print">
                                  <a href="#main" onClick={(e) => this.openLabelModal(this.state.newitems[idx])} title="print labels">print</a>
                                </td>                              
                                <td className="td-oi-sku">{this.state.newitems[idx].pr_sku}</td>
                                <td className="td-oi-label">{this.state.newitems[idx].pr_desc}</td>                                
                                <td className="td-oi-avail">{this.state.newitems[idx].qoh-this.state.newitems[idx].qty_res}</td>
                                <td className="td-oi-qty"><input name={nameId} 
                                  type="text" 
                                  value={this.state.newitems[idx].qty || 0} 
                                  onChange={(e) => {this.handleNewQtyInputChange(e, idx)}}                                      
                                  className="qty_field" />
                                  
                                </td>                                
                                <td><button
                                        type="button"
                                        onClick={() => this.onRemoveNewItem(idx)}
                                      >
                                        X
                                      </button>
                                </td>
                              </tr>
                              </tbody>                                 
                            )
                          })                
                        }                          
                  
                        {this.state.kititems.map((kititem, idx) => {
                
                          let ktblId = `tb-${idx}`;
                          let knameId = `ite-${idx}`;

                          return (
                              <tbody key={ktblId}>
                              <tr>
                                <td className="td-oi-print">
                                  <a href="#main" onClick={(e) => this.openLabelModal(this.state.kititems[idx])} title="print labels">print</a>
                                </td>
                                <td className="td-oi-sku">{this.state.kititems[idx].pr_sku}</td>
                                <td className="td-oi-label">{this.state.kititems[idx].pr_desc}</td>
                                <td className="td-oi-price">{this.state.kititems[idx].pr_qty_avail}</td>
                                <td className="td-oi-qty"><input name={knameId} 
                                  type="text" 
                                  value={this.state.kititems[idx].ki_qty} 
                                  onChange={(e) => {this.handleUpdateQtyInputChange(e, idx)}}                                      
                                  className="qty_field" />
                                  
                                </td>                                
                                <td><button
                                        type="button"
                                        onClick={() => { if (window.confirm('Remove this item?')) this.onRemoveItem(idx,this.state.kititems[idx].pr_id) }}
                                      >
                                        X
                                      </button>
                                </td>
                              </tr>
                              </tbody>                      
                            )
                          })                
                        }                          
                    </table>             


                  </div>
                   <input type="submit" value="Add/Update Kit" className="btn btn-primary btn-sm"/>
                </div> 
              </div>
              </div>
              </div>
            </form>
            </div>                      
          </div>
          <Modal
            isOpen={this.state.labelModalIsOpen}
            onAfterOpen={this.afterOpenLabelModal}                            
            style={printModalStyle}
            contentLabel="Print Case Label"
          >                   
          <div className="frame">
              <div className="scroll"> 
            <div className="container-fluid">               
              <form onSubmit={this.handleLabelPrint}>
                  <div className="row">
                      <div className="col-md-6">            
                        <h3>Print Case Label</h3>
                        <div className="form-group" >
                          <label htmlFor="printQty" className="form-label">Label Qty: </label>&nbsp; 
                          <input
                            className="form-input"
                            id="printQty"
                            name="printQty"
                            type="text"
                            value={this.state.printQty}
                            onChange={this.handleInputChange}
                            placeholder="" 
                          />
                          
                        </div>
                        <div className="modalMessage">{this.state.modalMessage}</div>
                        <div>
                          <ReactToPrint
                            trigger={() => <Link to="#"><button type="button" className="btn btn-info btn-sm">Print</button></Link>}
                            content={() => this.componentRef}
                          /> &nbsp;&nbsp;
                          <button className="btn btn-info btn-sm" onClick={this.closeLabelModal}>Cancel</button>
                        </div> 
                        <ProductPrintLabel ref={el => (this.componentRef = el)} printQty={this.state.printQty} pr_sku={this.state.kt_sku} printUPC={this.state.printUPC} {...this.props} />
                      
                      </div>
                      
                  </div>
                  
              </form> 

              </div>                          
              </div>
            </div>
          </Modal>



        </div>


                )
    }
}
export default ProductKitDetail;