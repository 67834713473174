import React, { Component } from 'react';
import LeftBar from '../Site/LeftBar';
import UserList from './UserList';

import './style.css';

class Users extends Component {

  render() {
    return (
      <div>
        <LeftBar />
        <UserList />
      </div>    


    );
  }
}

export default Users;