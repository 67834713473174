// Header.js
import React, {Component} from 'react';
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import Modal from 'react-modal';
import 'react-table/react-table.css'
import 'react-dropdown/style.css';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
class OtherAvailReport extends Component {

    constructor(props) {
        super(props);        
            this.state = {
            availitems: [],
            recentitems: [],
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),
            us_id: localStorage.getItem('usId'),
            sortOptions: [{ id: 'pr_sku', desc: true }],
            userToken: localStorage.getItem('userToken')
        };
       
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

    }

    openModal() {
      this.setState({modalIsOpen: true});
      if (this.state.cl_id ==='') {
        this.setState({modalMessage: 'Please select a client first. '});
      } 

    }

    afterOpenModal() {     
    }

    closeModal() {
      this.setState({modalIsOpen: false});
    }

    getRecentItems = (url) => {
      
      var productJSON = JSON.stringify({
        cl_id: this.state.cl_id,
        pr_id: 0
      });

      console.log('logging inventory json...');
      console.log(productJSON);      
      
      fetch(url, {
            method: 'post',        
            body: productJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
        }             
      }).then(res => res.json())
        .then(recentitems => this.setState({ recentitems }));       
      
    }  

    getAvail = (url) => {

      var productJSON = JSON.stringify({
        cl_id: this.state.cl_id
      });

      console.log('logging avail json...');
      console.log(productJSON);      
      
      fetch(url, {
            method: 'post',        
            body: productJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
        }             
      }).then(res => res.json())
        .then(availitems => this.setState({ availitems }));       
      
    }  
    
    componentDidMount() {
      Modal.setAppElement('#main');      
      this.getAvail(process.env.REACT_APP_NODE_ROOT_URL+'/reports/other/avail/'+localStorage.getItem('headerClientId'));          
      this.getRecentItems(process.env.REACT_APP_NODE_ROOT_URL+'/reports/receiveritems/'+localStorage.getItem('headerClientId')); 
      
    }
 
    render(){
                
        
      const { availitems, recentitems } = this.state;

      const headersrecent = [
        { label: "Client ID", key: "lg_cl_id" },
        { label: "Product ID", key: "pr_id" },
        { label: "Receiver Number", key: "rc_number" },
        { label: "SKU", key: "pr_sku" },
        { label: "Desc", key: "pr_desc" },
        { label: "UPC", key: "pr_upc" },
        { label: "Scan Count", key: "lg_scan_count" },
        { label: "Date Last Received", key: "lg_date_updated" }  
      ];
        
        return (
        <div id="main">
        <div className="page-wrapper">
                <div className="row page-titles">
      
                  <div className="col-md-11 align-self-center">
                       <h3 className="text-primary">Additional SKU Inventory</h3>                     
                  </div>
                  <div className="col-md-1 align-self-center"> 
                  
                  </div>
                </div>

                <div className="container-fluid">

                <div className="row">
                    <div className="col-lg-12">
                       
                            <div className="card-body" name="card-order-list">
                            <CSVLink data={availitems}>Download CSV of Additional SKU Inventory</CSVLink>
                                <div className="table-responsive">
                                  <ReactTable
                                      data={availitems}
                                      sorted={this.state.sortOptions}
                                      onSortedChange={val => {
                                      this.setState({ sortOptions: val }) }}
                                      filterable
                                      noDataText='Loading available other skus...'
                                      loading={this.state.loading}
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}       
                                     
                                      columns={[
                                        {                                          
                                          columns: [
                                                                      
                                            {
                                              Header: "Product Sku",
                                              accessor: "pr_sku",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            }, 
                                            {
                                              Header: "Other Sku",
                                              accessor: "ot_sku",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Current QOH</div>
                                              ),
                                              accessor: "pr_qty",
                                              width: 125,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]===filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            }                                            
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={50}
                                      className="-striped -highlight"
                                      
                                    />
                               
                                </div>
                            </div>

                            <hr/>
                            <h3>Recently Received Items</h3>
                            <div className="card-body" name="card-order-list">
                            <CSVLink headers={headersrecent} data={recentitems}>Download CSV of Recent Items</CSVLink>
                                <div className="table-responsive">
                                  <ReactTable
                                      data={recentitems}
                                      sorted={this.state.sortRecentOptions}
                                      onSortedChange={val => {
                                      this.setState({ sortOptions: val }) }}
                                      filterable
                                      noDataText='Loading recently received items ...'
                                      loading={this.state.loading}
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}          
                                      columns={[
                                        {                                          
                                          columns: [
                                            {
                                              Header: "Product ID",
                                              accessor: "pr_id",
                                              width: 125,
                                              filterable: false,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                                                                            
                                            {
                                              Header: "Receiver Number",
                                              accessor: "rc_number",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },                            
                                            {
                                              Header: "SKU",
                                              accessor: "pr_sku",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },
                                            {
                                              Header: "Description",
                                              accessor: "pr_desc",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },
                                            {
                                              Header: "UPC",
                                              accessor: "pr_upc",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },                                                
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Scan Count</div>
                                              ),
                                              accessor: "lg_scan_count",
                                              width: 125,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]===filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Received Date",
                                              accessor: "lg_date_updated",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            }
                                             
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={50}
                                      className="-striped -highlight"
                                      
                                    />
                               
                                </div>
                            </div>                  
                       
                    </div>
                </div>               
                <div className="row">   
                    <div className="col-lg-4">
                       
                    </div>
                </div>


            </div>
        </div>
        </div>

                )
    }
}
export default OtherAvailReport;