import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import LeftBar from '../Site/LeftBar';
import TrojanPrintLabel from './TrojanPrintLabel';
import TrojanPrintAllUpload from './TrojanPrintAllUpload';
import Modal from 'react-modal';
import ReactToPrint from "react-to-print";
import './style.css';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '400px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};

const printModalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '500px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};

class TrojanPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            printSizeValue: '',
            printSerialValue: '',     
            printSize: 48,
            labelWidth: 6,
            labelHeight: 200,    
            labelModalIsOpen: false,
            printQty: 1,
            printRows: [],
            shouldPrint: false,
            serialLabelModalIsOpen: false,
            importOtherModalIsOpen: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.printHandler = this.printHandler.bind(this);
        this.handleOtherFileUpload = this.handleOtherFileUpload.bind(this);
        this.processOtherFileUpload = this.processOtherFileUpload.bind(this);

        this.openLabelModal = this.openLabelModal.bind(this);
        this.afterOpenLabelModal = this.afterOpenLabelModal.bind(this);
        this.closeLabelModal = this.closeLabelModal.bind(this);

        this.openImportOtherModal = this.openImportOtherModal.bind(this);
        this.afterOpenImportOtherModal = this.afterOpenImportOtherModal.bind(this);
        this.closeImportOtherModal = this.closeImportOtherModal.bind(this);

        this.openSerialLabelModal = this.openSerialLabelModal.bind(this);
        this.afterOpenSerialLabelModal = this.afterOpenSerialLabelModal.bind(this);
        this.closeSerialLabelModal = this.closeSerialLabelModal.bind(this);
               
    } 

    componentDidMount() {      
        Modal.setAppElement('#main');
        //this.handleClientChange(this.state.cl_id);
        //console.log('refresh: ' + this.state.refresh);
    }

    handleInputChange = (e) => {     
      
        const target = e.target;
        const value = target.value;
        const name = target.name;
      
        console.log('name: ' + name);
        console.log('value: ' + value);
      
        this.setState({
         [name]: value
        });
      
      }

     //LABEL
    openLabelModal = (bin) => {    
      this.setState({labelModalIsOpen: true});
    }

    afterOpenLabelModal = (e) => {

    }

    closeLabelModal() {
      this.setState({labelModalIsOpen: false});
      this.setState({printQty:1});
    } 

    //IMPORT OTHER
    openImportOtherModal() {
      this.setState({importOtherModalIsOpen: true});
    }

    afterOpenImportOtherModal() {
    }

    closeImportOtherModal() {
      this.setState({importOtherModalIsOpen: false});
    } 


    handleSerialLabelPrint = (e) => {
      //console.log('print qty: ' + this.state.printQty);
      e.preventDefault();      
      //alert('print label ');
      return false;
    }

    //LABEL
    openSerialLabelModal = (e) => {     
        this.setState({serialLabelModalIsOpen: true});
    }

    afterOpenSerialLabelModal = (e) => {

    }

    closeSerialLabelModal() {
      this.setState({serialLabelModalIsOpen: false});
      this.setState({printQty:1});
    } 

    handleOtherFileUpload = (e) => {

      e.preventDefault(); 
      const myFile = document.getElementById('trojanUpload');
            
      this.setState({
        selectedFile: myFile.files[0],
        loaded: 0,
      });
      
    }

    printHandler = () => {      
        this.setState({serialLabelModalIsOpen:true})
        this.setState({importOtherModalIsOpen:false})
     
    }

    processOtherFileUpload = (e) => {

      e.preventDefault();

      this.setState({importOtherModalIsOpen: false});
      
      const data = new FormData();
      data.append('file',this.state.selectedFile);

      var fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/upload/tmultiple';
    
      fetch(fetchURL, {
        method: 'POST',
        body: data,
        headers: {
            'bwToken': this.state.userToken
        }      
        }).then(res => res.json())
        .then(printRows => this.setState({ printRows }))
        .then(this.printHandler)
        .catch(function(error) {            
              alert('File not uploaded! ' + error);        
        });
  
       e.target.value = null; 
      
    }

  render() {

    const _template_trojan = process.env.REACT_APP_ROOT_URL + '/files/serial-import.csv';

    return (
      <div>
        <LeftBar />
        <div id="main">
            <div className="page-wrapper">
              <div className="row page-titles">
                <div className="col-md-12 align-self-center">
                  <h3 className="text-primary">Print Replacement Trojan Labels</h3> 
                </div>
               
              </div>
              <div className="container-fluid">         
              <div className="card">
              <div className="card-body" name="card-order-list">      
                <div className="row">  
                  <div className="col-md-12">
                          <label>
                          <span>Enter Size ...  and Serial: </span><br/>
                            <input
                              name="printSizeValue"
                              type="text"
                              style={{width:"75px"}}
                              value={this.state.printSizeValue}
                              onChange={this.handleInputChange} /> - <input
                              name="printSerialValue"
                              type="text"
                              style={{width:"100px"}}
                              value={this.state.printSerialValue}
                              onChange={this.handleInputChange} />
                          </label><br/>
                          <button className="btn btn-info btn-sm" onClick={(e) => this.openLabelModal()}>Print</button>
                          <br/><br/>
                          <button type="button" data-toggle="collapse" className="btn btn-info btn-sm" onClick={this.openImportOtherModal}>Upload and Print Multiple</button>
                          
                  </div>
                </div>
                   
             </div>
              </div>
              <div id="modalMain">
              <Modal
                isOpen={this.state.labelModalIsOpen}
                onAfterOpen={this.afterOpenLabelModal}                            
                style={printModalStyle}
                contentLabel="Print Label"
                >                   
                <div className="frame">
                <div className="scroll"> 
                <div className="container-fluid">               
                    <form onSubmit={this.handleLabelPrint}>
                        <div className="row">
                            <div className="col-md-12">            
                            <h3>Print Replacement Trojan Labels</h3>
                            <div className="form-group" >
                            <label htmlFor="printQty" className="form-label">Label Qty: </label>&nbsp; 
                            <input
                            className="form-input"
                            id="printQty"
                            name="printQty"
                            type="text"
                            value={this.state.printQty}
                            onChange={this.handleInputChange}
                            placeholder="" 
                            style={{width:"200px"}}
                            />
                                
                            </div>
                            <label htmlFor="printQty" className="form-label">Font Size: </label>&nbsp; 
                            <input
                            className="form-input"
                            id="printSize"
                            name="printSize"
                            type="text"
                            value={this.state.printSize}
                            onChange={this.handleInputChange}
                            placeholder="" 
                            />

                            <label htmlFor="labelHeight" className="form-label">Label Height: </label>&nbsp; 
                            <input
                            className="form-input"
                            id="labelHeight"
                            name="labelHeight"
                            type="text"
                            value={this.state.labelHeight}
                            onChange={this.handleInputChange}
                            placeholder="" 
                            />
                            <label htmlFor="labelWidth" className="form-label">Label Width: </label>&nbsp; 
                            <input
                            className="form-input"
                            id="labelWidth"
                            name="labelWidth"
                            type="text"
                            value={this.state.labelWidth}
                            onChange={this.handleInputChange}
                            placeholder="" 
                            />
                            <div className="modalMessage">{this.state.modalMessage}</div>
                            <div>
                                <ReactToPrint
                                trigger={() => <Link to="#"><button type="button" className="btn btn-info btn-sm">Print</button></Link>}
                                content={() => this.componentRef}
                                /> &nbsp;&nbsp;
                                <button className="btn btn-info btn-sm" onClick={this.closeLabelModal}>Cancel</button>
                            </div> 
                            <TrojanPrintLabel ref={el => (this.componentRef = el)} 
                            printQty={this.state.printQty} 
                            printSizeValue={this.state.printSizeValue} 
                            printSerialValue={this.state.printSerialValue}  
                            labelHeight={this.state.labelHeight} 
                            labelWidth={this.state.labelWidth} 
                            printSize={this.state.printSize}                           
                            {...this.props} />
                                                                
                            </div>
                            
                        </div>
                        
                    </form> 

                    </div>                          
                    </div>
                    </div>
                </Modal>

                <Modal
                  isOpen={this.state.importOtherModalIsOpen}
                  onAfterOpen={this.afterOpenImportOtherModal}            
                  style={customStyles}
                  contentLabel="Import Serials"
                >                   
                  
                  <div className="container-fluid">               
                    <form>
                      <h2>Print Multiple Trojan Labels</h2>
                        <div className="row">
                          <div className="col-sm-12">                          
                            Select and import a Trojan serial file. 
                            <br/><br/>
                            <input type="file" id="trojanUpload" name="trojanUpload" onChange={(e) => this.handleOtherFileUpload(e)} />                                                                 
                            <label id="prUploadResponse"></label>
                            
                          </div>
                                            
                        </div>

                      <div className="modalMessage">{this.state.importModalMessage}</div>
                      <div className="right">
                        <button type="submit" onClick={(e) => this.processOtherFileUpload(e)}>Print</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closeImportOtherModal} className="btn btn-info btn-sm">Cancel</button>
                      </div> 
                          
                    </form> 

                    <p> &gt; <a href={_template_trojan} target="_new">download import template</a></p>

                  </div>                          
                    
                </Modal>	

                <Modal
                  isOpen={this.state.serialLabelModalIsOpen}
                  onAfterOpen={this.afterOpenSerialLabelModal}                            
                  style={printModalStyle}
                  contentLabel="Print Label"
                  >                   
                  <div className="frame">
                  <div className="scroll"> 
                  <div className="container-fluid">               
                      <form onSubmit={this.handleSerialLabelPrint}>
                          <div className="row">
                              <div className="col-md-12">            
                              
                              <label htmlFor="printSize" className="form-label">Font Size: </label>&nbsp; 
                              <input
                              className="form-input"
                              id="printSize"
                              name="printSize"
                              type="text"
                              value={this.state.printSize}
                              onChange={this.handleInputChange}
                              placeholder="" 
                              />

                              <label htmlFor="labelHeight" className="form-label">Label Height: </label>&nbsp; 
                              <input
                              className="form-input"
                              id="labelHeight"
                              name="labelHeight"
                              type="text"
                              value={this.state.labelHeight}
                              onChange={this.handleInputChange}
                              placeholder="" 
                              />
                              <label htmlFor="labelWidth" className="form-label">Label Width: </label>&nbsp; 
                              <input
                              className="form-input"
                              id="labelWidth"
                              name="labelWidth"
                              type="text"
                              value={this.state.labelWidth}
                              onChange={this.handleInputChange}
                              placeholder="" 
                              />
                              <div className="modalMessage">{this.state.modalMessage}</div>
                              <div>
                                  <ReactToPrint
                                    trigger={() => <Link to="#"><button type="button" className="btn btn-info btn-sm">Print</button></Link>}
                                    content={() => this.componentRef}
                                  /> &nbsp;&nbsp;
                                  <button className="btn btn-info btn-sm" onClick={this.closeSerialLabelModal}>Cancel</button>
                              </div> 
                              <TrojanPrintAllUpload ref={el => (this.componentRef = el)} 
                                printQty={1}                        
                                labelHeight={this.state.labelHeight} 
                                labelWidth={this.state.labelWidth} 
                                printSize={this.state.printSize}  
                                printRows={this.state.printRows}                         
                                {...this.props} />
                                                                    
                              </div>
                              
                          </div>
                          
                      </form> 

                      </div>                          
                      </div>
                      </div>
                  </Modal>		


              </div>
        </div>
      </div>    
</div>
</div>

    );
  }
}

export default TrojanPrint;