import React, { Component } from 'react';
import LeftBar from '../Site/LeftBar';
import ClientList from './ClientList';

import './style.css';

class Clients extends Component {

  render() {
    return (
      <div>
        <LeftBar />
        <ClientList />
      </div>    


    );
  }
}

export default Clients;