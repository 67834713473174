// Header.js
import React, {Component} from 'react';

class Uploader extends Component {

  constructor(props) {
      super(props);
      this.state = {   
        fileUploaded: false,
        fileMessage: '',
        cl_id: '',
        or_id: '',
        pr_id: ''
      };
  
      this.handleFileUpload = this.handleFileUpload.bind(this);

  }  

  handleFileUpload(fileType,e) {
    e.preventDefault();
    
    console.log('fileType: '+fileType);
    const myFile = document.getElementById(fileType+'Upload');
    const data = new FormData();
    data.append('file', myFile.files[0]);

    var fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/upload/';

    /*
    cl = client
    pr = product
    rc = receiver
    tr = tracking
     */
    switch(fileType) {
    case 'cl':
        fetchURL = fetchURL + 'cl/';
        break;
    case 'pr':
        fetchURL = fetchURL + 'pr/';
        break;
    case 'rc':
        fetchURL = fetchURL + 'rc/';
        break;
    case 'tr':
        fetchURL = fetchURL + 'tr/';
        break;
    default:
        return false;
    }
   
    fetch(fetchURL, {
    method: 'POST',
    body: data
     }).then(function(response) { 
            
            if (response.statusText === 'OK'){
               alert('File uploaded successfully!'); 
            } else {
                alert('Upload Failed. Check file format and try again.')
            }                 

            //return response;
        }).then(function(data) { 
            //console.log(data);
        }).catch(function(error) {            
            alert('File not uploaded! ' + error);        
        });
    
    e.target.value = null; 
  }

  componentDidUpdate(prevProps, prevState) {
    // this.getOrderDetails();
  }  
 
  componentDidMount() {
    //get query string values     
  }

  
  render(){
        return (
            <div className="page-wrapper">
               <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                    <h3 className="text-primary">Dashboard</h3> </div>
                <div className="col-md-7 align-self-center">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">                   
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-title">
                                <h4>Client Upload </h4>                                
                           </div>
                           <div className="card-body" name="card-clientupload">
                                <div className="table-responsive">
                                  <form>
                                    <input type="file" id="clUpload" name="clUpload" onChange={(e) => this.handleFileUpload('cl', e)} />                                                                 
                                    <label id="clUploadResponse"></label>
                                  </form>
                                </div>

                            </div>

                        </div>

                        <div className="card">
                            <div className="card-title">
                                <h4>Product Upload </h4>                                
                           </div>
                           <div className="card-body" name="card-productupload">
                                <div className="table-responsive">
                                  <form>
                                    <input type="file" id="prUpload" name="prUpload" onChange={(e) => this.handleFileUpload('pr', e)} />                                                                 
                                    <label id="prUploadResponse"></label>
                                  </form>
                                </div>

                            </div>

                        </div>

                        <div className="card">
                            <div className="card-title">
                                <h4>Receiver Upload </h4>                                
                           </div>
                           <div className="card-body" name="card-receiverupload">
                                <div className="table-responsive">
                                  <form>
                                    <input type="file" id="rcUpload" name="rcUpload" onChange={(e) => this.handleFileUpload('rc', e)} />                                                                 
                                    <label id="rcUploadResponse"></label>
                                  </form>
                                </div>

                            </div>

                        </div>

                        <div className="card">
                            <div className="card-title">
                                <h4>Shipment Tracking Upload </h4>                                
                           </div>
                           <div className="card-body" name="card-trackingupload">
                                <div className="table-responsive">
                                  <form>
                                    <input type="file" id="trUpload" name="trUpload" onChange={(e) => this.handleFileUpload('tr', e)} />                                                                 
                                    <label id="trUploadResponse"></label>
                                  </form>
                                </div>

                            </div>
                        </div>                     
                        
                    </div>
                </div>               
                <div className="row">   
                    <div className="col-lg-4">
                       
                    </div>
                </div>

            </div>
         </div>


                )
    }
}
export default Uploader;