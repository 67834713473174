import React, { Component } from 'react';
import LeftBar from '../Site/LeftBar';
import TimeclockEntry from './TimeclockEntry';

import './style.css';

class Clock extends Component { 

  render() {
    return (
      <div>
        <LeftBar />
        <TimeclockEntry />
      </div>

    );
  }
}

export default Clock;