import React, { Component } from 'react';
import LeftBar from '../Site/LeftBar';
import Uploader from './Uploader';


class Upload extends Component {

  render() {
    return (
      <div>
        <LeftBar />
        <Uploader />
      </div>    


    );
  }
}

export default Upload;