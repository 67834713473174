// Header.js
import React, {Component} from 'react';
import DateTimePicker from 'react-datetime-picker';
import Dropdown from 'react-dropdown';

var moment = require('moment');

class TimeclockEntry extends Component {

    constructor(props) {
        super(props);
        
          this.state = {          
            cl_name: '',
            userIpAddress: '', //typeof(localStorage.getItem('userIpAddress')) === 'undefined' ? 0 : localStorage.getItem('userIpAddress'), 
            tc_notes: '',
            tc_hours: 0,
            tc_items: 0,
            tc_flat: 0,
            tcId: '',
            users: [],            
            disableAddButton: true,
            disableClockInButton: false,
            disableClockOutButton: true,
            firstName: localStorage.getItem('firstName'),
            lastName: localStorage.getItem('lastName'),
            userId: localStorage.getItem('userId'),
            userName: localStorage.getItem('userName'),
            clockIn: '',
            clockOut: '',
            timeEntries: [],
            addHourDate: new Date(),  
            addType: '',
            addAmount: 0,
            us_id: typeof(localStorage.getItem('usId')) === 'undefined' ? 0 : localStorage.getItem('usId'),       
            customerClientId: typeof(localStorage.getItem('customerClientId')) === 'undefined' ? 0 : localStorage.getItem('customerClientId'),            
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),
            userToken: localStorage.getItem('userToken')            
          };
       
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.clockIn = this.clockIn.bind(this);
        this.clockOut = this.clockOut.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        //this.addHours = this.addHours.bind(this);
        //this.addItems = this.addItems.bind(this);
        //this.addFlats = this.addFlats.bind(this);
    }
	
	  componentDidMount() {      
      this.handleClientChange(this.state.cl_id);	       
	  }    

    timeStamp(event){
  
      /*
      var timeStamp = moment().format("[Date: ]DD/MM/YYYY [Time: ] HH:mm:ss");
      var id = event.target.id;
      
      if(id === 'clockOut') {
        var localClockIn = localStorage.getItem("clockIn");
        var clockOutStamp = moment().format("DD/MM/YYYY HH:mm:ss");

        var diff = moment.utc(moment(clockOutStamp, "DD/MM/YYYY HH:mm:ss").diff(moment(localClockIn, "DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss");

        var node = document.createElement('li');
          node.append(timeStamp);
          document.getElementById("list2").appendChild(node);
        
        var node = document.createElement('li');
          node.append("Total: " + diff);
          document.getElementById("list3").appendChild(node);
        
          document.getElementById('clockOut').id = 'clockIn';

        
      } else {
          localStorage.setItem(id, timeStamp);
          var node = document.createElement('li');
          node.append(timeStamp);
          document.getElementById("list").appendChild(node);
          clockIn.id = 'clockOut';
      }
      */
      
    }

    handleClientChange = (cl_id) => {
        //this.setState({cl_id: cl_id});  
        if (cl_id === 0) {
            //this.getReceivers(process.env.REACT_APP_NODE_ROOT_URL+'/receivers');
            this.setState({disableAddButton:true});
        } else {
            //this.getReceivers(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/client/'+cl_id); 
            this.setState({disableAddButton:false});
        }    
    }

    clockIn(event) {

          var clockInValue = moment().format('YYYY-MM-DD HH:mm:ss');   
          var clockIn = moment().format("MM/DD/YYYY h:mm:ss A");
          
          var clockInText = this.state.userName + ' clocked in at ' + clockIn + ' from IP ' + this.state.userIpAddress;
          
          this.setState({disableClockInButton:true});
          this.setState({disableClockOutButton:false}); 

          //Timeclock.tc_user_id,Timeclock.tc_in_time,Timeclock.tc_ip_address,Timeclock.tc_notes,Timeclock.tc_hours,Timeclock.tc_items,Timeclock.tc_flat
          var obj = { tc_user_id: this.state.us_id, tc_in_time: clockInValue, tc_ip_address: this.state.userIpAddress, tc_notes: this.state.tc_notes, tc_hours: this.state.tc_hours,tc_items: this.state.tc_items,tc_flat: this.state.tc_flat };
          var tcJSON = JSON.stringify(obj);

          fetch(process.env.REACT_APP_NODE_ROOT_URL+'/timeclock/in/'+this.state.us_id, {
            method: 'post',        
            body: tcJSON,
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken       
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
          }).then(res => res.json())
          .then(tcid => this.setState({ tcid }));
          
          /*
          .then(function(response) {
              //alert('Timeclock entry successful!'); 
              console.log(response.json());
              //return true;
          }).then(function(data) { 
              console.log(data);            
          }).catch(function(error) {
              alert('Timeclock entry could not be added. Contact support.'); 
              console.log('submit err: ' + error);       
          });
            */ 
          alert(clockInText);
          console.log(tcJSON);

      
    }

    clockOut(event) {

      var clockOutValue = moment().format('YYYY-MM-DD HH:mm:ss');   
      var clockOut = moment().format("MM/DD/YYYY h:mm:ss A");
          
      var clockOutText = this.state.userName + ' clocked out at ' + clockOut + ' from IP ' + this.state.userIpAddress;
      
      this.setState({disableClockInButton:false});
      this.setState({disableClockOutButton:true}); 

      //Timeclock.tc_user_id,Timeclock.tc_in_time,Timeclock.tc_ip_address,Timeclock.tc_notes,Timeclock.tc_hours,Timeclock.tc_items,Timeclock.tc_flat
      var obj = { tc_user_id: this.state.us_id, tc_out_time: clockOutValue, tc_ip_address: this.state.userIpAddress, tc_notes: this.state.tc_notes, tc_hours: this.state.tc_hours,tc_items: this.state.tc_items,tc_flat: this.state.tc_flat };
      var tcJSON = JSON.stringify(obj);

      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/timeclock/out/'+this.state.tcid, {
        method: 'post',        
        body: tcJSON,
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken       
        }
        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
    
      }).then(function(response) {
          //alert('Timeclock entry successful!'); 
          console.log(response.json());
          //return true;
      }).then(function(data) { 
          console.log(data);            
      }).catch(function(error) {
          alert('Timeclock entry could not be added. Contact support.'); 
          console.log('submit err: ' + error);       
      });
         
      alert(clockOutText);
      console.log(tcJSON);

    }
      
    handleInputChange(event) {      
     
      const target = event.target;
      const value = target.value;
      const name = target.name;
      console.log('name: ' + name);
      console.log('value: ' + value);
      
      this.setState({
       [name]: value
      });

    }

    handleSubmit(event) {  
      //event.preventDefault();
      var myJSON = JSON.stringify(this.state);
      console.log(myJSON);

      /*
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/timeclock/'+us_id+'/', {
            method: 'post',        
            body: myJSON,      
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            alert('Timeclock entry successful!'); 
            //return response.json();
            return true;
        }).then(function(data) { 
            console.log(data);            
        }).catch(function(error) {
            alert('Timeclock entry could not be added. Contact support.'); 
            console.log('submit err: ' + error);       
        });
        */
      

    }   

    render(){
        
        const options = [
         { value: '0', label: 'Hours' },
         { value: '1', label: 'Items' },
         { value: '2', label: 'Flat Charge' }
        ];

       let {disableAddButton, disableClockInButton, disableClockOutButton } = this.state

        return (

        <div className="page-wrapper" id="main">
            
            <div className="row page-titles">
                <div className="col-md-12 align-self-center">
                    <h3 className="text-primary">Time Entry</h3> 
                </div>
            </div>

            <div className="container-fluid">
             <div className="card">
              <div className="card-body" name="card-order-list">              
               <div className="form-group">
               <div className="row"> 
                  <div className="col-md-6">
                    <h3>Daily Time Clock</h3><br/>
                    <button className="btn btn-info btn-sm" name="addHours" disabled={disableClockInButton} onClick={this.clockIn}>Clock In</button> 
                    &nbsp;&nbsp;&nbsp; 
                    <button className="btn btn-info btn-sm" name="addHours" disabled={disableClockOutButton} onClick={this.clockOut}>Clock Out</button>

                    </div>
                </div>
                <br/><br/>
                <div className="row"> 
                  <div className="col-md-6">                  
                      <h3>Manual Time Entry</h3>
                      <p>Select a client to add specific time to bill. Hour and Item charges will be calculated using the settings in Client Details.</p>
                      <div className="form-group row indent-left">
                        <div className="col-xs-2">
                          <h4>Add Hours, Items, or a Flat Charge</h4>
                          <p>Enter hours in decimals (ex: 1.75):</p>
                          <DateTimePicker
                            disableClock={true}
                            onChange={addHourDate => this.setState({ addHourDate })}
                            value={this.state.addHourDate}
                          /> <br/> <br/>
                          <Dropdown options={options} onChange={this.handleChange} value={this.state.addType} placeholder="Select a type" className="dropType"/><br/> 
                          Amount: <input name="addAmount" className="text-sm" type="text" value={this.state.addAmount} onChange={this.handleInputChange}/>&nbsp;
                          Note: <input name="tc_notes" type="text" value={this.state.tc_notes} onChange={this.handleInputChange} />&nbsp;
                          <button className="btn btn-info btn-sm" name="addHours" disabled={disableAddButton} onClick={this.handleSubmit}>Add</button>
                       <br/>

                        </div>
                        
                      </div>  
                      
                
                    </div>
                  </div>               

                </div>
                </div>
                </div>
            </div>
        </div>

                )
    }
}
export default TimeclockEntry;