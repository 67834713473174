import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import LeftBar from '../Site/LeftBar';
import ProductReplaceDetail from './ProductReplaceDetail';

import './style.css';

class ProductReplaceMain extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }


  constructor(props) {
    super(props);
    
    const { location } = this.props

    var pathArray = location.pathname.split('/');
    
    this.state = {        
        cl_id : pathArray[2],
        ot_id : pathArray[3]
    };
  }


  render() {
    return (
      <div>
        <LeftBar />
        <ProductReplaceDetail cl_id={this.state.cl_id} ot_id={this.state.ot_id} />
      </div>    


    );
  }
}

export default withRouter(ProductReplaceMain);