// Header.js
import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link, } from "react-router-dom";
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import Modal from 'react-modal';
import 'react-table/react-table.css'
import 'react-dropdown/style.css';
import ReactToPrint from "react-to-print";
import ProductPrintLabel from './ProductPrintLabel';
var moment = require('moment');


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '400px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};

const printModalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '500px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};

class ProductList extends Component {

    constructor(props) {
        super(props);        
            this.state = {
            products: [],            
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),
            upc_search: '',
            pr_sku: '',
            pr_upc: '',
            pr_desc: '',
            modalMessage: '',
            modalIsOpen: false,
            importModalMessage: '',
            importModalIsOpen: false,
            labelModalIsOpen: false,
            kitModalIsOpen: false,
            binModalIsOpen: false,
            disableAddButton:true,
            selectedFile: null,
            printQty: 1,
            printUPC: '',
            printDesc: '',
            printSize: 48,
            labelWidth: 6,
            labelHeight: 200,
            loading: false,
            activity_type: 'pr_id',
            activity_type_id: 0,           
            activity_note: '',
            activity_time: 0,
            activity_read: 0,
            activity_entry_date: new Date(),
            us_id: localStorage.getItem('usId'),
            sortOptions: [{ id: 'pr_id', desc: true }],
            userToken: localStorage.getItem('userToken'),
            us_staff: localStorage.getItem('us_staff')
        };
       
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getSku = this.getSku.bind(this);
        this.goSku = this.goSku.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.logActivity = this.logActivity.bind(this);

        this.openImportModal = this.openImportModal.bind(this);
        this.afterOpenImportModal = this.afterOpenImportModal.bind(this);
        this.closeImportModal = this.closeImportModal.bind(this);        
       
        this.openLabelModal = this.openLabelModal.bind(this);
        this.afterOpenLabelModal = this.afterOpenLabelModal.bind(this);
        this.closeLabelModal = this.closeLabelModal.bind(this);

        this.openBinModal = this.openBinModal.bind(this);
        this.afterOpenBinModal = this.afterOpenBinModal.bind(this);
        this.closeBinModal = this.closeBinModal.bind(this);

    }

    openModal() {
      this.setState({modalIsOpen: true});
      if (this.state.cl_id ==='') {
        this.setState({modalMessage: 'Please select a client first. '});
      } 

    }

    afterOpenModal() {     
    }

    closeModal() {
      this.setState({modalIsOpen: false});
    }

    //IMPORT
    openImportModal() {
      this.setState({importModalIsOpen: true});
    }

    afterOpenImportModal() {
    }

    closeImportModal() {
      this.setState({importModalIsOpen: false});
    }    

   
    //LABEL
    openLabelModal = (e) => {
      //console.log('printid: ' + e.original.pr_id);

      this.setState({labelModalIsOpen: true});
      this.setState({pr_sku: e.original.pr_sku});
      this.setState({printUPC: e.original.pr_upc});
      this.setState({printDesc: e.original.pr_desc});


    }

    afterOpenLabelModal = (e) => {

    }

    closeLabelModal() {
      this.setState({labelModalIsOpen: false});
      this.setState({printQty:1});

    } 

    //BIN
    openBinModal() {
      this.setState({binModalIsOpen: true});
    }

    afterOpenBinModal() {
    }

    closeBinModal() {
      this.setState({binModalIsOpen: false});
    }
    
    
    getProducts = (url) => {
      fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(products => this.setState({ products }));
    }

    handleInputChange(event) {
   
      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.setState({
       [name]: value
      });     
    }
   
    getSku = () => {

      var otherSku = this.state.pr_sku;

      var otherJSON = JSON.stringify({
          cl_id: this.state.cl_id,
          sku: otherSku            
      });

      console.log('getSku: ' + otherJSON)
              
      if (otherSku !== '')
      {        
          fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/sku/'+this.state.cl_id, {
              method: 'post',
              body: otherJSON,      
              headers: {            
              'Content-Type': 'application/json',
              'bwToken': this.state.userToken          
              }
              //TODO:
              //credentials: 'same-origin', // send cookies
              //credentials: 'include'   // send cookies, even in CORS
          
          }).then(res => res.json())
          .then(product => this.setState({ product }))
          .then(this.goSku).catch(function(error) {
              alert('Other SKU could not be updated. SKU should be unique or contact support.'); 
              //console.log('submit err: ' + error);       
          });  
      }
      
    }
  
    goSku = () => {
  
      const product = this.state.product; 
      console.log('issku: ' + JSON.stringify(product))

      let somevar = product[0];

      let ind = somevar[0];

      if (typeof ind==='undefined') {
        ind = somevar[1];          
      }          
      
      if (ind === 1) {
        alert('SKU is in use. Check your value and try again.');
        this.setState({pr_sku:''});
        return false;
      } else {
        return true;
      }    
      
    }

    handleSubmit(event) {
      //alert('Client name: ' + this.state.cl_name);
      event.preventDefault();

      if (this.state.pr_sku==='') {
        alert('SKU cannot be blank.');
        return false;
      } else if (this.state.pr_upc==='') {
        alert('UPC cannot be blank and must be unique.');
        return false;
      }

      this.setState({activity_note: 'New Product Added. SKU: ' + this.state.pr_sku.trim() });
      this.setState({activity_time: 1});
      
      var obj = { cl_id: this.state.cl_id, pr_sku: this.state.pr_sku.trim(), pr_upc: this.state.pr_upc.trim(), pr_desc: this.state.pr_desc.trim() };
      var myJSON = JSON.stringify(obj);

      /*
      try {
        const response = await fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/', {
            method: 'post',        
            body: myJSON,      
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
            }
        });
        const json = await response.json();
        const log = await this.logActivity();
        console.log('async resp: ' + json);
        //this.setState({ data: json });
        
      } catch (error) {
        console.log(error);
      }
      */

      /* TODO: Replace below with async function above. Implement logActivity call with some values. 
      Need a generic activity type for other crap?? */
   
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/', {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken
          }
      }).then(function(response) {  
          return response.json();
      }).then(function(data) { 
          //console.log(data);
          if (data.code==='ER_DUP_ENTRY') {
            alert('UPC already exists. Please check and try again.');     
            return false;       
          } else {
            alert('SKU added. Refresh and click on Product ID to edit details.');            
          }         
      }).then(this.logActivity).catch(function(error) {
          console.log(error);   
          //alert('UPC already exists or cannot be blank. Please check and try again.');          
      });           

    }
    
    logActivity = () => {
      //console.log('log some stuff');

      var _entrydate = moment(this.state.activity_entry_date).format('YYYY-MM-DD HH:mm:ss')
      
      let sActivity = {
        in_type: this.state.activity_type,
        in_note: this.state.activity_note,
        is_read: this.state.activity_read,
        us_id: this.state.us_id,
        in_time: this.state.activity_time,
        in_entry_date: _entrydate,
        in_type_id: this.state.activity_type_id
      };
      
      var sActivityJSON = JSON.stringify(sActivity);

      //console.log(sActivityJSON);
          
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/timeclock/activity/'+this.state.cl_id, {
        method: 'POST',        
        body: sActivityJSON,      
        headers: {            
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken          
        }
        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
    
      }).then(function(response) {
          if (response.status===200) {
            //console.log('Activity added successfully');
            window.location.reload();                 
          }
      }).then(function(data) { 
          //console.log(JSON.stringify(data))
      }).catch(function(error) {
          console.log('Activity could not be added'); 
          console.log('activity err: ' + error);       
      });
      
    }
   
    handleLabelPrint = (e) => {
      //console.log('print qty: ' + this.state.printQty);
      e.preventDefault();      
      //alert('print label ');
      return false;
    }

    handleFileUpload = (e) => {
      e.preventDefault(); 
      
      const myFile = document.getElementById('prUpload');
      
      this.setState({
        selectedFile: myFile.files[0],
        loaded: 0,
      });
    }

    processFileUpload = (e) => {

      e.preventDefault();
      
      const data = new FormData();
      data.append('file',this.state.selectedFile);

      var fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/upload/pr/'+this.state.cl_id;
      
      this.setState({activity_note: 'New Product uploaded for client #: ' + this.state.cl_id });
      this.setState({activity_time: 1});

      fetch(fetchURL, {
        method: 'POST',
        body: data,
        headers: {
            'bwToken': this.state.userToken
        }      
        }).then(function(response) {
              if (response.statusText === 'OK'){               
                  alert('File uploaded successfully.');                  
              } else {
                  alert('Upload Failed. Check file format and try again.')
              }
              return response.json();
        }).then(function(data) { 
              console.log('log data ' + JSON.stringify(data));              
        }).then(this.logActivity).catch(function(error) {            
              alert('File not uploaded! ' + error);        
        });
  
       e.target.value = null; 
      
    }

   

    fillGrid() {
      this.setState({ loading: true }, () => {
        setTimeout(() => {
          if (localStorage.getItem('headerClientId') !=='23') {
            this.setState({cl_id: localStorage.getItem('headerClientId')});
            this.getProducts(process.env.REACT_APP_NODE_ROOT_URL+'/products/client/'+localStorage.getItem('headerClientId'));
            this.setState({disableAddButton:false});
          } else {
            this.getProducts(process.env.REACT_APP_NODE_ROOT_URL+'/products');       
          }

          this.setState({ loading: false });
        }, 100);
      });
    }


    componentDidMount() {
      Modal.setAppElement('#main');      
      this.fillGrid();          
    }
 
    render(){
        
        const column_link_style = {
          textAlign: "center",
          textDecoration: "underline"
        };

        const _template = process.env.REACT_APP_ROOT_URL + '/files/product-import.csv';
        
        const { cl_id, products } = this.state;
        
        return (
        <div id="main">
        <div className="page-wrapper">
                <div className="row page-titles">
      
                  <div className="col-md-1 align-self-center">
                       <h3 className="text-primary">Products</h3>                     
                  </div>
                  {this.state.us_staff!=='1' ? (
                  <div className="col-md-11 align-self-center"> 
                  <button type="button" className="btn btn-info btn-sm" onClick={this.openModal} disabled={this.state.disableAddButton}>Add Product</button>&nbsp;
                      <button type="button" data-toggle="collapse" className="btn btn-info btn-sm" onClick={this.openImportModal} disabled={this.state.disableAddButton}>Import Products</button>&nbsp;                     
                      <Link to={`/product-case/${cl_id}`}><button type="button" className="btn btn-info btn-sm" disabled={this.state.disableAddButton} >Manage Cases</button></Link>&nbsp;
                      <Link to={`/product-kits/${cl_id}`}><button type="button" className="btn btn-info btn-sm" disabled={this.state.disableAddButton} >Manage Kits</button></Link>&nbsp;
                      <Link to={`/product-replace/${cl_id}`}><button type="button" className="btn btn-info btn-sm" disabled={this.state.disableAddButton} >Replace SKUs</button></Link>&nbsp;
                      <Link to={`/product-other/${cl_id}`}><button type="button" className="btn btn-info btn-sm" disabled={this.state.disableAddButton} >Other SKUs</button></Link>&nbsp;
                      <Link to={`/product-ignore/${cl_id}`}><button type="button" className="btn btn-info btn-sm" disabled={this.state.disableAddButton} >Ignore SKUs</button></Link>&nbsp;
                  </div>
                  ) : (<div></div>) } 
                </div>

                <div id="modalMain" className="col-lg-4 align-self-left">
                       
                          <Modal
                            isOpen={this.state.modalIsOpen}
                            onAfterOpen={this.afterOpenModal}
                            style={customStyles}
                            contentLabel="New Product"
                          >                   
                         
                            <div className="container-fluid">               
                              <form onSubmit={this.handleSubmit}>
                                  <div className="row">
                                      <div className="col-md-6">            

                                        <div className="form-group" >
                                          <label htmlFor="pr_sku" className="form-label">SKU: </label>
                                          <input
                                            className="form-input"
                                            id="pr_sku"
                                            name="pr_sku"
                                            type="text"
                                            value={this.state.pr_sku}
                                            onChange={this.handleInputChange}
                                            onBlur={this.getSku}
                                            placeholder="" 
                                          />
                                          <br/><br/>
                                          <label htmlFor="pr_desc" className="form-label">Description: </label>
                                          <input
                                            className="form-input"
                                            id="pr_desc"
                                            name="pr_desc"
                                            type="text"
                                            value={this.state.pr_desc}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />
                                          <br/><br/>
                                          <label htmlFor="pr_upc" className="form-label">UPC: </label>
                                          <input
                                            className="form-input"
                                            id="pr_upc"
                                            name="pr_upc"
                                            type="text"
                                            value={this.state.pr_upc}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />
                                          
                                        </div>
                                        <div className="modalMessage">{this.state.modalMessage}</div>
                                        <div>
                                          <button type="submit" className="btn btn-info btn-sm">Add</button>&nbsp;<button onClick={this.closeModal} className="btn btn-info btn-sm">Cancel</button>
                                        </div>               
                                        
                                      </div>

                                      
                                  </div>
                                 
                              </form> 

                             </div>                          
                            
                          </Modal>

                          <Modal
                              isOpen={this.state.importModalIsOpen}
                              onAfterOpen={this.afterOpenImportModal}            
                              style={customStyles}
                              contentLabel="Import Products"
                          >                   
                           
                            <div className="container-fluid">               
                              <form>
                                <h2>Import Products</h2>
                                  <div className="row">
                                    <div className="col-sm-12">                                      
                                      Select and import a file. <br/>
                                      <input type="file" id="prUpload" accept=".csv" name="prUpload" onChange={(e) => this.handleFileUpload(e)} />                                                                 
                                      <label id="prUploadResponse"></label>
                                    </div>
                                                                     
                                  </div>

                                <div className="modalMessage">{this.state.importModalMessage}</div>
                                <div className="right">
                                  <button type="submit" onClick={(e) => this.processFileUpload(e)}>Import</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closeImportModal} className="btn btn-info btn-sm">Cancel</button>
                                </div> 
                                   
                              </form> 

                              <p> <a href={_template} target="_new">NOTE! The product import template has changed. Download new product import template</a></p>

                            </div>                          
                              
                          </Modal>		

                          						

                          <Modal
                            isOpen={this.state.labelModalIsOpen}
                            onAfterOpen={this.afterOpenLabelModal}                            
                            style={printModalStyle}
                            contentLabel="Print Label"
                          >                   
                         <div className="frame">
													    <div className="scroll"> 
                            <div className="container-fluid">               
                              <form onSubmit={this.handleLabelPrint}>
                                  <div className="row">
                                      <div className="col-md-6">            
																				<h3>Print Product Labels</h3>
                                        <div className="form-group" >
                                          <label htmlFor="printQty" className="form-label">Label Qty: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="printQty"
                                            name="printQty"
                                            type="text"
                                            value={this.state.printQty}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />
                                          <label htmlFor="printQty" className="form-label">Font Size: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="printSize"
                                            name="printSize"
                                            type="text"
                                            value={this.state.printSize}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />

                                          <label htmlFor="labelHeight" className="form-label">Label Height: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="labelHeight"
                                            name="labelHeight"
                                            type="text"
                                            value={this.state.labelHeight}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />
                                          <label htmlFor="labelWidth" className="form-label">Label Width: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="labelWidth"
                                            name="labelWidth"
                                            type="text"
                                            value={this.state.labelWidth}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />
                                          
                                        </div>
                                        <div className="modalMessage">{this.state.modalMessage}</div>
                                        <div>
                                          <ReactToPrint
                                            trigger={() => <Link to="#"><button type="button" className="btn btn-info btn-sm">Print</button></Link>}
                                            content={() => this.componentRef}
                                          /> &nbsp;&nbsp;
                                          <button className="btn btn-info btn-sm" onClick={this.closeLabelModal}>Cancel</button>
                                        </div> 
                                        <ProductPrintLabel ref={el => (this.componentRef = el)} labelHeight={this.state.labelHeight} labelWidth={this.state.labelWidth} printSize={this.state.printSize} printQty={this.state.printQty} pr_sku={this.state.pr_sku} printDesc={this.state.printDesc} printUPC={this.state.printUPC} {...this.props} />
																			
                                      </div>
                                      
                                  </div>
                                 
                              </form> 

                             </div>                          
                             </div>
													  </div>
                          </Modal>

                          <Modal
                            isOpen={this.state.binModalIsOpen}
                            onAfterOpen={this.afterBinOpenModal}                            
                            style={customStyles}
                            contentLabel="Set Current BIN Location"
                          >                   
                         
                            <div className="container-fluid">               
                              <form onSubmit={this.handleBinSubmit}>
                                  <div className="row">
                                      <div className="col-md-6">            
																				<h3>Update Product BIN</h3>
                                        <div className="form-group" >
                                          <label htmlFor="pr_sku" className="form-label" ref={subtitle => this.subtitle = subtitle}>Current BIN: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="bn_bin"
                                            name="bn_bin"
                                            type="text"
                                            value={this.state.bn_bin}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />
                                          
                                        </div>
                                        <div className="modalMessage">{this.state.modalMessage}</div>
                                        <div>
                                          <button  className="btn btn-info btn-sm" type="submit">Update</button>&nbsp;<button className="btn btn-info btn-sm" onClick={this.closeBinModal} >Cancel</button>
                                        </div>               
                                        
                                      </div>

                                      
                                  </div>
                                 
                              </form> 

                             </div>                          
                            
                          </Modal>





                  </div>
                <div className="container-fluid">

                <div className="row">
                    <div className="col-lg-12">
                       
                            <div className="card-body" name="card-order-list">
                            <CSVLink data={products}>Download CSV of Product</CSVLink>
                                <div className="table-responsive">
                                  <ReactTable
                                      data={products}
                                      sorted={this.state.sortOptions}
                                      onSortedChange={val => {
                                      this.setState({ sortOptions: val }) }}
                                      filterable
                                      noDataText='Looking for products...'
                                      loading={this.state.loading}
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}          
                                      columns={[
                                        {                                          
                                          columns: [                                            
                                            {
                                              Header: "ID",
                                              accessor: "pr_id",
                                              width: 50,
                                              Cell: row => (
                                                <div style={column_link_style} onClick={(e) => window.location.href = "/product/"+row.value}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Print Label",
                                              accessor: "pr_id",
                                              width: 50,
                                              filterable: false,
                                              Cell: props => (
                                                <div style={{ textAlign: "center" }}><a href="#" onClick={(e) => this.openLabelModal(props)} title="print labels">print</a></div>
                                              )
                                            },                                            
                                            {
                                              Header: "Client Name",
                                              accessor: "cl_name",
                                              width: 100,
                                              show: false,
                                              filterMethod: (filter, row) =>                                                
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center"}}>{row.value}</div>
                                              )
                                            },                                            
                                            {
                                              Header: "SKU",
                                              accessor: "pr_sku",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },
                                            {
                                              Header: "Description",
                                              accessor: "pr_desc",
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) || String(row[filter.id].toLowerCase()).endsWith(filter.value.toLowerCase()) : true                                            
                                            },                                            
                                            {
                                              Header: "UPC",
                                              accessor: "pr_upc",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
                                            },            
                                            {
                                              Header: "EAN",
                                              accessor: "pr_ean",
                                              show: this.state.cl_id==22,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            },                                                                          
                                            {
                                              Header: "Weight",
                                              accessor: "pr_weight",
                                              width: 75,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]===filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Qty Avail</div>
                                              ),
                                              accessor: "pr_qty",
                                              width: 75,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]===filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                                               
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Qty In Process</div>
                                              ),
                                              accessor: "pr_qty_reserved",
                                              width: 75,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]==filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                                              
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Total Qty On Hand</div>
                                              ),
                                              accessor: "pr_qty_onhand",
                                              width: 75,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]===filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            }, 
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Locations</div>
                                              ),
                                              accessor: "all_bins",
                                              style: { 'whiteSpace': 'unset' },
                                              width: 200,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]===filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "left" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Other 1</div>
                                              ),
                                              accessor: "pr_other1",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]===filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            }
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={20}
                                      className="-striped -highlight"
                                      
                                    />
                               
                                </div>
                            </div>
                       
                    </div>
                </div>               
                <div className="row">   
                    <div className="col-lg-4">
                       
                    </div>
                </div>


            </div>
        </div>
        </div>

                )
    }
}
export default ProductList;