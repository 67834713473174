import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import CustomerBar from '../Site/CustomerBar';
import ProductCaseDetail from './ProductCaseDetail';

import './style.css';

class ProductCaseMain extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }


  constructor(props) {
    super(props);
    
    const { location } = this.props

    var pathArray = location.pathname.split('/');
    
    this.state = {        
        cl_id : pathArray[2],
        cs_id : pathArray[3]
    };
  }


  render() {
    return (
      <div>
        <CustomerBar />
        <ProductCaseDetail cl_id={this.state.cl_id} cs_id={this.state.cs_id} />
      </div>    


    );
  }
}

export default withRouter(ProductCaseMain);