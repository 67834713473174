// Header.js
import React from 'react';
import 'react-table/react-table.css';
import 'react-dropdown/style.css';
import Barcode from 'react-barcode';
import './print.css';

class ProductPrintLabel extends React.Component {
   constructor(props) {
        super(props);
            this.state = {                        
        };             
    } 
    
    componentDidMount() {     
      
    }

    createLabels = () => {
                 
      let table = []
      
      if (this.props.printAll) {
        let dataObj = this.props.allData;
        let x=0;

        console.log(JSON.stringify(dataObj));

        
        for (const key in dataObj) {

          for (let i = 0; i < dataObj[key]["lg_scan_count"]; i++) {

          x++;
          
          let lblId = `lbl-${x}`;
          
          if (this.props.showDesc==true) {
            table.push(<div key={lblId} className="label-product"><span style={{fontSize:this.props.printSize + 'pt'}}>{dataObj[key]["pr_sku"]}</span><br/>
            <span style={{fontSize:this.props.printSize + 'pt'}}>{dataObj[key]["pr_desc"]}<br/>     
            <Barcode required={false} width={Number(this.props.labelWidth)} height={Number(this.props.labelHeight)} fontSize={Number(this.props.printSize)} value={dataObj[key]["pr_upc"]} displayValue={true} />
            </span>
            </div>);
          } else {
            table.push(<div key={lblId} className="label-product"><span style={{fontSize:this.props.printSize + 'pt'}}>{dataObj[key]["pr_sku"]}</span><br/>
            <span style={{fontSize:this.props.printSize + 'pt'}}>   
            <Barcode required={false} width={Number(this.props.labelWidth)} height={Number(this.props.labelHeight)} fontSize={Number(this.props.printSize)} value={dataObj[key]["pr_upc"]} displayValue={true} />
            </span>
            </div>);
          } 

        }

        }
        

        /*
        Object.keys(this.props.words).forEach(function(key, index) {
          this.props.words[key]
        }, this)

        */
        /*
        Object.keys(dataObj,this.props).forEach(function(item) {
          x++;
         
        }, this);       
        */
      } else {

        for (let i = 0; i < this.props.printQty; i++) {
                
          let lblId = `lbl-${i}`;
          
          if (this.props.showDesc==true) {
            table.push(<div key={lblId} className="label-product"><span style={{fontSize:this.props.printSize + 'pt'}}>{this.props.pr_sku}</span><br/>
            <span style={{fontSize:this.props.printSize + 'pt'}}>{this.props.printDesc}<br/>     
            <Barcode width={Number(this.props.labelWidth)} height={Number(this.props.labelHeight)} fontSize={Number(this.props.printSize)} value={this.props.printUPC} displayValue={true} />
            </span>
            </div>);
          } else {
            table.push(<div key={lblId} className="label-product"><span style={{fontSize:this.props.printSize + 'pt'}}>{this.props.pr_sku}</span><br/>
          <span style={{fontSize:this.props.printSize + 'pt'}}><Barcode width={Number(this.props.labelWidth)} height={Number(this.props.labelHeight)} fontSize={Number(this.props.printSize)} value={this.props.printUPC} displayValue={true} />
          </span>
          </div>);
          }                 
        }        

      }
        /*

        for (let i = 0; i < this.props.printAll; i++) {
                
          let lblId = `lbl-${i}`;
          
          if (this.props.showDesc==true) {
            table.push(<div key={lblId} className="label-product"><span style={{fontSize:this.props.printSize + 'pt'}}>{this.props.pr_sku}</span><br/>
            <span style={{fontSize:this.props.printSize + 'pt'}}>{this.props.printDesc}<br/>     
            <Barcode width={Number(this.props.labelWidth)} height={Number(this.props.labelHeight)} fontSize={Number(this.props.printSize)} value={this.props.printUPC} displayValue={true} />
            </span>
            </div>);
          } else {
            table.push(<div key={lblId} className="label-product"><span style={{fontSize:this.props.printSize + 'pt'}}>{this.props.pr_sku}</span><br/>
          <span style={{fontSize:this.props.printSize + 'pt'}}><Barcode width={Number(this.props.labelWidth)} height={Number(this.props.labelHeight)} fontSize={Number(this.props.printSize)} value={this.props.printUPC} displayValue={true} />
          </span>
          </div>);
          }                 
        }        

      } else {


      }
      

      for (let i = 0; i < this.props.printQty; i++) {

        let lblId = `lbl-${i}`;
        
        if (this.props.showDesc==true) {
          table.push(<div key={lblId} className="label-product"><span style={{fontSize:this.props.printSize + 'pt'}}>{this.props.pr_sku}</span><br/>
          <span style={{fontSize:this.props.printSize + 'pt'}}>{this.props.printDesc}<br/>     
          <Barcode width={Number(this.props.labelWidth)} height={Number(this.props.labelHeight)} fontSize={Number(this.props.printSize)} value={this.props.printUPC} displayValue={true} />
          </span>
          </div>);
        } else {
          table.push(<div key={lblId} className="label-product"><span style={{fontSize:this.props.printSize + 'pt'}}>{this.props.pr_sku}</span><br/>
        <span style={{fontSize:this.props.printSize + 'pt'}}><Barcode width={Number(this.props.labelWidth)} height={Number(this.props.labelHeight)} fontSize={Number(this.props.printSize)} value={this.props.printUPC} displayValue={true} />
        </span>
        </div>);
        }                 
      }        

      */
      
      return table;     

    }  

    
    render() {
        
    return (
      <div>
       
        {this.createLabels()}   
     
      </div>
    );
  }
}
export default ProductPrintLabel;