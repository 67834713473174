// Header.js
import React from 'react';
import 'react-table/react-table.css';
import 'react-dropdown/style.css';
import OrderItems from './OrderItems';
import Barcode from 'react-barcode';

class OrderPrintContent extends React.Component {
   constructor(props) {
        super(props);
            this.state = {
            orders: [],
            cl_id: typeof(this.props.cl_id) === 'undefined' ? 0 : this.props.cl_id,
            or_id: typeof(this.props.or_id) === 'undefined' ? 0 : this.props.or_id,
            selectedIds: typeof(this.props.selectedIds) === 'undefined' ? 0 : this.props.selectedIds,
            userToken: localStorage.getItem('userToken'),
            printStatus:'New'
        };
        
        this.getOrderData = this.getOrderData.bind(this);
        this.getAllOrderData = this.getAllOrderData.bind(this);
                             
    } 

    componentDidMount() {
      //TODO: need to handle loading existing ship methods
      //need to pass auth to route
      //
      this.getOrderData();
	      
    }      
    
    getAllOrderData = () => {
      
      if (this.state.printStatus==='New') {           
        this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/orders/client/print/new/'+this.state.cl_id);  
      } else if (this.state.printStatus==='In Process') {
        this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/orders/client/print/process/'+this.state.cl_id);                   
      } else if (this.state.printStatus==='Both') {
        this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/orders/client/print/both/'+this.state.cl_id);                 
      } 
      
    }

    getOrderData = () => {
      /*
      if (this.state.printStatus==='New') {           
        this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/orders/client/print/new/'+this.state.cl_id);  
      } else if (this.state.printStatus==='In Process') {
        this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/orders/client/print/process/'+this.state.cl_id);                   
      } else if (this.state.printStatus==='Both') {
        this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/orders/client/print/both/'+this.state.cl_id);                 
      } 
      */

      //TODO Update this for handling multiselected orders. Post the IDS to the route

      if (this.state.selectedIds.length > 0) {
        this.getSelectedOrders();
      } else if (this.state.or_id !=='' && this.state.cl_id !=='') {
        //console.log('1 hit');
        this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/orders/o/'+this.state.or_id);
      } else {
        //console.log('2 hit');
        var _url = process.env.REACT_APP_NODE_ROOT_URL+'/orders/'+this.state.or_id;
        this.getOrders(_url);
      }  
      
    }

    getSelectedOrders = () => {
      
      
      //var obj = { rc_id: parseInt(this.state.edit_rc_id), rc_number: this.state.edit_rc_number, rc_company: this.state.edit_rc_company, rc_date_expected: _expdate, rc_date_final: _finaldate };
      var myJSON = JSON.stringify(this.state.selectedIds);

      //console.log('new json: ' + myJSON);          
            
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/client/print/selected/'+this.state.cl_id, {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken       
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(res => res.json())
      .then(orders => this.setState({ orders }))
      .catch(function(error) {
        alert(error);        
      });     

  }

    getOrders = (url) => {
        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(
          orders => this.setState({ orders })
        ).catch(function(error) {
          alert(error);        
      });            
    }

   
  render() {

    const { selectedIds } = this.state;
    const _logo = 'https://bw-admin-files.s3.amazonaws.com/files/logos/' + this.state.cl_id + '/logo.png';
    
    return (
      <div>
      <div className="noprint">
      {selectedIds.length > 0 &&
        <p><strong>Currently showing your {selectedIds.length} selected orders.</strong></p>        
      }
          
      </div>    
      <div className="print">
       {this.state.orders.map(order =>        
          <div key={order.or_id}>
          <br/><br/>
          <table border="0" cellPadding="0" cellSpacing="0" width="80%">
          <tbody>
            <tr valign="top">
              <td width="90%" valign="top">              
              {this.state.cl_id==20 ? (
              <span>
                <img src={_logo} width="400px" alt="" />
                <br/><br/>
                <span style={{fontSize:"14pt"}}>Phone: 707.996.4070</span>     
              </span>
              ) : (<img src={_logo} width="200px" alt="" />  ) } 
              </td>
              <td>&nbsp;</td>
              <td width="10%" align="right" valign="top">
              <Barcode width={3} value={order.or_actual_order_id} /><br/>
              </td>              
            </tr>
           
          </tbody>
          </table>         

          <table border="0" cellPadding="0" cellSpacing="0" width="80%" style={{fontSize:"14pt"}}>
          <tbody>
            <tr>
              <td valign="top"><strong>Ship To</strong><br />
                {order.or_ship_fname}&nbsp;  {order.or_ship_lname} <br/>
                {order.or_ship_company}<br/>
                {order.or_ship_attention} <br/>
                {order.or_ship_email}  <br />
                {order.or_ship_address}  <br />
                {order.or_ship_address1}  <br />
                {order.or_city}, {order.or_state}&nbsp; {order.or_zip} &nbsp; {order.or_country} <br />
                {order.or_other} 
              </td>
              <td>
              <strong>Ship Method</strong><br />
                {order.or_ship_method}     
                {order.or_type === 'Will Call' ? ( 
                  <div>
                  Contact Name: {order.or_wc_name}<br/>
                  Contact Phone:  {order.or_wc_phone}<br/> 
                  Pick Up Time:  {order.or_wc_time}<br/>  <br/>
                  
                  </div>
                ) : (<div></div>)}        
              </td>
            </tr>
          </tbody>
          </table> 
          
          <OrderItems or_id={order.or_id} />          
        
          {order.or_ship_method==="Will Call" &&
            <span>
            <h3>Please verify everything looks correct and then sign:</h3>
            <h3>x____________________________________________</h3>
            <table>
              <tbody>
                <tr>
                  <td>Driver Name:</td>
                  <td>________________________________________________</td>
                </tr>
                <tr>
                  <td>Date:</td>
                  <td>________________________________________________</td>
                </tr>
                <tr>
                  <td>Phone:</td>
                  <td>________________________________________________</td>
                </tr>
                <tr>
                  <td>License Plate:</td>
                  <td>________________________________________________</td>
                </tr>
              </tbody>
            </table>
            
            </span>
          }
          {order.cl_id==20 && 
            
            <div style={{fontSize:"14pt", width:"80%", color:"black"}}>
            <br/><br/>            
            Thank you very much for your order! <br/><br/>
We hope you enjoy infusing Sonoma Syrup Co. flavors into your creations. If you have any
questions, need additional information or recipe recommendations, please email us:
contact@sonomasyrup.com or call our office (707) 996-4070. <br/><br/>
Being kind to the earth and our local communities is at the heart of our mission at Sonoma Syrup Co. Since 2003, our bottles have always been glass, never plastic. The packing peanuts used in this shipment are eco-friendly (100% starch based and will dissolve in water; you can recycle or compost). Our boxes are recyclable as well – please reduce, reuse, and recycle.<br/><br/>
We greatly appreciate your support!<br/><br/>
Cheers,<br/>
The Sonoma Syrup Co. Team<br/>
Infusing Flavor. Infusing Happiness.&reg;<br/>
Sonomasyrup.com | @sonomasyrupco – Instagram | Facebook | TikTok | Pinterest
           </div>
          
          }
          {order.cl_id==17 && 
            
            <div style={{fontSize:"14pt", width:"80%", color:"black"}}>
            <br/><br/>            
            SHIPPING AND HANDLING/ RETURN POLICY:<br/>
            NO RETURNS WILL BE ACCEPTED without a "RETURN AUTHORIZATION NUMBER" obtained through Customer Service:<br/>
            800-235-1559 ext.706<br/>
            1.	Customer will be responsible for shipping costs on all returns.<br/>
            2.	There is a re-stocking fee of 20% of the retail product price.<br/>
            3.	Products must be returned in their original condition within 30 days from date of shipment.<br/>
            4.	We will replace any item defective under normal use at no additional cost during the warranty period.<br/>
            5.	All returns should be addressed to:<br/><br/>

            Exertools<br/>
            130 Cyber Ct, Suite A<br/> 
            Rocklin, CA 95765<br/><br/>

            Have Questions, please email billing@pargencompanies.com<br/>
            Thank you for your business!

           </div>
          
          }
          <p className="page-break-after"> </p>
          
          </div>

      )}   
      </div>
      </div>
    );
  }
}
export default OrderPrintContent;