 // LeftBar.js
 import React, {Component} from 'react';

 export default class LeftBar extends Component {
     render(){
         return (
        
         <div className="left-sidebar" >
            
             <div className="scroll-sidebar">
                    <br/>
                 <nav className="sidebar-nav">
                     
                     <ul id="sidebarnav">                        
                         <li className="nav-divider"></li>
                         <li> <a href="/customer-products" aria-expanded="false">&nbsp;Products</a> </li>
                         <li> <a href="/customer" aria-expanded="false">&nbsp;Orders</a> </li>
                         <li> <a href="/customer-pickups" aria-expanded="false">&nbsp;Pickups</a> </li>
                         <li> <a href="/customer-receivers" aria-expanded="false">&nbsp;Receivers</a> </li>     
                         <li> <a href="/customer-returns" aria-expanded="false">&nbsp;Returns</a> </li>                    
                         <li> <a href="https://form.asana.com?k=jIjGB8lftUjdp6ay89NcUQ&d=1200785620313429" target="_new" aria-expanded="false">&nbsp;Requests</a> </li>
                     </ul>

                <div className="panel-heading">
                    <h4 className="panel-title">
                    <a data-toggle="collapse" href="#collapse5">Reports</a>
                    </h4>
                </div>
                <div id="collapse5" className="panel-collapse collapse">
                    <ul className="list-group">        
                        <li> <a href="/customer-report-billing" aria-expanded="false">&nbsp;Billing</a> </li> 
                        <li> <a href="/customer-report-item-billing" aria-expanded="false">&nbsp;Item Billing</a> </li>        
                         <li className="nav-label">Other SKU</li>                    
                         <li> <a href="/customer-report-otherstatus" aria-expanded="false">&nbsp;Shipped </a> </li>  
                    </ul>                   
                </div>

                </nav>
               
             </div>
             
         </div>
        
                 )
     }
 }