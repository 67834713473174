import React from 'react'
import PropTypes from 'prop-types'
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar'
import { Link } from 'react-router-dom'
import { state } from 'aws-cognito-redux-saga'
import Cookies from 'universal-cookie'
import Cryptr from 'cryptr'
import ClientSelector from '../Clients/ClientSelector';
import './style.css';
export default class HeaderComponent extends React.Component {
  static propTypes = {
    isSignedIn: PropTypes.string,
    signUpError: PropTypes.bool,
    signOut: PropTypes.func,
    signIn: PropTypes.func,
    signUp: PropTypes.func,
    auth: PropTypes.object,
    cl_id: PropTypes.string,
    isDisabled: PropTypes.bool
  }

    //how to handle the clientID prop?? 
  constructor(props) {
        super(props);
        this.state = {   
            bwToken: '',
            alerts: [],
            order: [],
            user: [],
            txtsearch:'',
            userData: [],
            alertUrl: '',
            scannerUrl: '',
            us_id: typeof(localStorage.getItem('usId')) === 'undefined' ? 0 : localStorage.getItem('usId'),       
            customerClientId: typeof(localStorage.getItem('customerClientId')) === 'undefined' ? 0 : localStorage.getItem('customerClientId'),            
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),
            customerOrderEntry:  typeof(localStorage.getItem('customerOrderEntry')) === 'undefined' ? 0 : localStorage.getItem('customerOrderEntry'),
            userToken: localStorage.getItem('userToken'),
            userIpAddress: '',
            isMobile: false,
            isScan: false
        }; 

        this.handleClientChange = this.handleClientChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getOrder = this.getOrder.bind(this);
        this.goOrder = this.goOrder.bind(this);
        //this.getUser = this.getUser.bind(this);
        this.getAlerts = this.getAlerts.bind(this);
        this.get_cookie = this.get_cookie.bind(this);
        this.delete_cookie = this.delete_cookie.bind(this);
  }       

  componentDidUpdate(prevProps, prevState) {
       
    if (this.props.auth === prevProps.auth) {     
      //console.log('props.auth is equal to prevProps.auth');
      if (this.props.cl_id < 1) {
        window.location.href('/signIn');
      }
      //this.validateUser();
    } else {
           
      const cookies = new Cookies();
      var jwtToken = localStorage.getItem('userToken');
      
      //console.log('jwt token: '+ jwtToken);

      //console.log('jwt: '+ JSON.stringify(this.props.auth));

      if (this.props.auth.isSignedIn === state.AUTH_SUCCESS) {

        if (!jwtToken) {

          const userStr = JSON.stringify(this.props.auth);

            JSON.parse(userStr, (key, value) => {
              if (typeof value === 'string') {
                if (key==='jwtToken') {

                    //var _fname = 'Ryan';
                    //var _lname = 'Milbrath';
                                     
                    const cryptr = new Cryptr('!Vlp13131'); 
                    const encryptedString = cryptr.encrypt(value);                    
                    
                    cookies.set('bwToken', encryptedString);                  
                    //console.log(cookies.get('bwToken'));
                    
                    localStorage.setItem('userToken', encryptedString);                  
                }
                if (key==='username') {
                    localStorage.setItem('userName', value);
                }
                if (key==='sub') {
                    localStorage.setItem('userId', value);
                }
                if (key==='isSignedIn') {
                    localStorage.setItem('isSignedIn', value);
                }            
              } 

            });

            this.logUser();         

        } else {
          //TODO: can we use the cookie instead of local storage? 
          //set this to get customer alerts if customer acct
          //
          //console.log('token already stored');
          //console.log('cookie get: ' + cookies.get('bwToken'));
          const _customerClientId = localStorage.getItem('customerClientId');  
          //console.log('hit: ' + _customerClientId);         
                
          /* errors in browser
          (async () => {
            var pIp = await publicIp.v4();
            localStorage.setItem('userIpAddress', pIp);                    
          })();
          */

          if (_customerClientId === '23') {
            //this.getAlerts(process.env.REACT_APP_NODE_ROOT_URL+'/alerts'); 
            this.setState({ alertUrl: '/alerts' })
            this.setState({ scannerUrl: 'scan-order/'+this.state.cl_id })
          } else {
            //this.getAlerts(process.env.REACT_APP_NODE_ROOT_URL+'/alerts/client/'+_customerClientId);
            this.setState({ alertUrl: '/customer-alerts' })
            
          } 

        }

      } else {

        localStorage.setItem('userToken', '');
        localStorage.setItem('userName', '');
        localStorage.setItem('userId', '');                
        localStorage.setItem('isSignedIn', '');         
        
      } 

    }


  }

  componentDidMount() {
       
    const _customerClientId = localStorage.getItem('customerClientId');    
    const posScan = window.location.href.indexOf('scan');
    if (posScan > 0) {
      this.setState({isScan: true});
    }

    //console.log('isScan state: '+ this.state.isScan);    
    
    if (_customerClientId =='') {
      window.location.reload();
    }

    if (_customerClientId !=='23') {
      if (window.location.href.indexOf('customer') <= 0) {
        window.location.replace('/customer')
      }
    } else {

      var userName = localStorage.getItem('userName');
      //console.log('_userName: '+ userName);

      const _is_staff = localStorage.getItem('is_staff');  
      //console.log('_is_staff: '+ _is_staff);

      if (_is_staff =="1" && window.location.href.indexOf('mobile') <= 0) {
          this.setState({isMobile: true});
          if (userName != 'gun1@bwadminaccess.com') {
            window.location.replace('/mobile-order/'+ _customerClientId);
          }
      }

      if (_is_staff =="1" && window.location.href.indexOf('scan') <= 0) {        
        if (userName == 'gun1@bwadminaccess.com') {          
          //console.log('_gun1: '+ userName);
          window.location.replace('/scan-order/'+ _customerClientId);
        }         
      }
    }

  }  

  handleClientChange = (cl_id) => {
    
      this.setState({cl_id: cl_id});
      localStorage.setItem('headerClientId', cl_id);
      if (window.location.href.indexOf('mobile') > 0) {
       //go no where
       this.setState({isMobile: true});
       window.location.replace('/mobile-order/'+cl_id);
      } else if (window.location.href.indexOf('scan') > 0) {
        //go no where
        this.setState({isScan: true});
        window.location.replace('/scan-order/'+cl_id);
       } else {
        window.location.replace('/orders');
      }
      
  }

  getAlerts = (url) => {
        fetch(url, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(alerts => this.setState({ alerts }));        
    }

    //.replace(/\//g, "|||")
  getOrder = (orderId) => {
    
    if (orderId=='SCANNER') {
      var scanUrl = '/scan-order/'+this.state.cl_id;
      window.location.assign(scanUrl);
    } else { 
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/s/'+this.state.cl_id+'/'+encodeURIComponent(orderId.trim()),
      {
          method: 'GET',      
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }

          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(res => res.json())
      .then(order => this.setState({ order })).then(this.goOrder);

    }    
  }

  goOrder = () => {

    const order = this.state.order;  

    if (order.length > 0) {
      //console.log('order search: ' + order);
      var _url = '/order/'+order[0].cl_id+'/'+order[0].or_id;
      this.handleClientChange(order[0].cl_id);
      //console.log('_url: ' + _url);
      window.location.assign(_url);
    } else {
      alert('Order Number not found. Try again.');
    }

    
  }

  validateUser = () => {
    //console.log('hit validate user');
    
    const userData = this.state.userData;
    //console.log('userData: ' + JSON.stringify(userData));
    
    /*
    let _cl_id = userData[0];
    if (_cl_id) {
      console.log('has client id');
    } else {
      console.log('no client id');
    }
    */

    /*getUserById:function(id,callback){
	    return db.query("select us_id,us_user_name,us_user_id,us_first_name,us_last_name,us_phone_num, us_email, cl_id, cl_name, us_order_entry, us_admin, us_staff from vw_user where us_id=?",[id],callback);
	    },
    */

      /*
      cl_id,
      us_id,                        
      _user_name,
      _user_id,                        
      _first_name,
      _last_name,
      _phone_num,
      _email,
      _order_entry,
      _is_admin,
      _is_staff
      */

      /*
    
    console.log('cl_id - 0: ' + userData[0] );
    console.log('us_id - 1: ' + userData[1] );
    console.log('us_user_name - 2: ' + userData[2] );
    console.log('us_user_id - 3: ' + userData[3] );
    console.log('us_first_name - 4: ' + userData[4] );
    console.log('us_last_name - 5: ' + userData[5] );
    console.log('us_phone_num - 6: ' + userData[6] );
    console.log('us_email - 7: ' + userData[7] );
    console.log('us_order_entry - 8: ' + userData[8] );
    console.log('us_admin - 9: ' + userData[9] );
    console.log('us_staff - 10: ' + userData[10] );
*/
    const us_id = userData[1];
    const customerClientId =userData[0];
    //const first_name =userData[2];
    //const last_name =userData[3];
    //const phone_num =userData[4];
    //const email =userData[5];
    //const order_entry =userData[9];
    const is_staff = userData[10];

    localStorage.setItem('usId', userData[1]);
    localStorage.setItem('customerClientId', userData[0]);  
    localStorage.setItem('customerOrderEntry', userData[8]);
    localStorage.setItem('us_user_name', userData[2] );
    localStorage.setItem('us_user_id',userData[3] );
    localStorage.setItem('us_first_name', userData[4] );
    localStorage.setItem('us_last_name',userData[5] );
    localStorage.setItem('us_phone_num', userData[6] );
    localStorage.setItem('us_email', userData[7] );
    localStorage.setItem('us_order_entry', userData[8] );
    localStorage.setItem('us_admin', userData[9] );
    localStorage.setItem('us_staff', userData[10] );

    if (is_staff=="1") {
      //console.log('staff account');
      this.setState({cl_id: customerClientId});
      localStorage.setItem('is_staff', is_staff);  
      localStorage.setItem('headerClientId', customerClientId);
      
      window.location.replace('/mobile-orders');
    } else {
      if (customerClientId ===23) {
        this.handleClientChange(customerClientId);
      } else {
        this.setState({cl_id: customerClientId});      
        localStorage.setItem('headerClientId', customerClientId);    
        window.location.replace('/customer');
      }
    }     
  }

  /*
  getUser = () => {

      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/users/'+this.state.us_id, {
      method: 'GET',   
      headers: {
      'Accept': 'application/json',
      'bwToken': this.state.userToken
      }      
  }).then(res => res.json())
  .then(user => this.setState({ user })).then(this.validateUser);
  }
*/
  //REDO THE USE LOGIN PROCESS

  logUser() {
           
      var firstName = '';
      var lastName = '';
      var phoneNum = '';
      
      var userId = localStorage.getItem('userId');
      var userName = localStorage.getItem('userName');
      var userToken = localStorage.getItem('userToken');

      var obj = { us_user_id: userId, us_user_name: userName, us_first_name: firstName, us_last_name: lastName, us_phone_num: phoneNum, us_email: userName };
      var myJSON = JSON.stringify(obj);

      //console.log('user crap: ' + myJSON);     

      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/userentry/', {
          method: 'post',
          body: myJSON,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'bwToken': userToken
          }
      
      }).then(res => res.json())
      .then(userData => this.setState({ userData }))
      .then(this.validateUser)      
      .catch(function(error) {
        alert(error);        
    });
     
    }

  delete_cookie = ( name, path, domain ) => {
    if( this.get_cookie( name ) ) {
      document.cookie = name + "=" +
        ((path) ? ";path="+path:"")+
        ((domain)?";domain="+domain:"") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
      //console.log('cookie deleted')
    }
  }

  get_cookie = (name) => {
    return document.cookie.split(';').some(c => {
        return c.trim().startsWith(name + '=');
    });
  }

  signOut = () => {
    localStorage.clear();
    this.props.signOut();
    this.delete_cookie('bwToken','/','bwadminaccess.com');
    window.location.reload();
  }

  handleChange(event){
    //set upc value searched for
    this.setState({txtsearch: event.target.value});
  }

  handleSubmit(event){
    event.preventDefault();    
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){               
        var OrderNumber = this.state.txtsearch;
        this.getOrder(OrderNumber);                
    }
  }

  render() {

    const { auth } = this.props;
        const _url = process.env.REACT_APP_ROOT_URL + '/images/logo.png';
    
    const toolbarStyle = {
      backgroundColor: '#FFFFFF'
    }
    let clientmenu = "";
    if (this.state.customerClientId === '23') {
      clientmenu = <ClientSelector cl_id={this.state.cl_id} onClientChange={this.handleClientChange}/>;
    }
    
    //const customerClientName = localStorage.getItem('customerClientName');
    //const customerClientId = localStorage.getItem('customerClientId');
    //<span style={{marginLeft:"20px"}}>Please note: Admin will be down for maintenance between 9/24/21 6:00PM PST - 9/25/21 6:00AM PST </span>
    const customerClientId = this.state.customerClientId;
    const isMobile = this.state.isMobile;
    const isScan = this.state.isScan;
    const alertUrl = this.state.alertUrl;
    const scannerUrl = this.state.scannerUrl;

    return (            
            <div className="header">    
            {auth.isSignedIn === state.AUTH_SUCCESS ? (
            <nav className="navbar top-navbar navbar-expand-md navbar-light">         
                   
                   {isScan ? (
                    <div>                      
                      <div id="testdiv" style={{float: 'left'}}>
                      {clientmenu} 
                      </div>
                      <button type="button" className="btn btn-info btn-sm" onClick={this.signOut}>Sign Out</button>
                    </div>
                   ) : (
                   <div>
                    <div style={{float: 'left'}}><a className="navbar-brand" href="/">                       
                        <b><img src={_url} alt="homepage" className="dark-logo" /></b>                                                                    
                    </a> </div>
                                      
                    <div style={{float: 'right'}}>    <ul className="navbar-nav my-lg-0" style={{backgroundColor: 'white'}}>
                      {customerClientId==='23' ? (  
                          <li className="nav-item">                        
                            <form id="formUpcSearch" onSubmit={this.handleSubmit}>
                            <input type="text" id="txtsearch" ref="txtsearch" name="txtsearch" className="form-control" onKeyPress={this.handleKeyPress} value={this.state.textsearch} onChange={this.handleChange} placeholder="Place cursor here to find order "/>
                          </form>      
                          </li>     
                      ) : (<li></li>) }
                            &nbsp;&nbsp;   
                          <li className="nav-item dropdown clientselect">
                            {clientmenu}                              
                          </li>                         
                          {customerClientId==='23' ? (
                            <li className="nav-item dropdown mega-dropdown" style={{width:'50px'}}> <a className="nav-link dropdown-toggle text-muted" title="View Tools" href="/" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-tools" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M0 1l1-1 3.081 2.2a1 1 0 0 1 .419.815v.07a1 1 0 0 0 .293.708L10.5 9.5l.914-.305a1 1 0 0 1 1.023.242l3.356 3.356a1 1 0 0 1 0 1.414l-1.586 1.586a1 1 0 0 1-1.414 0l-3.356-3.356a1 1 0 0 1-.242-1.023L9.5 10.5 3.793 4.793a1 1 0 0 0-.707-.293h-.071a1 1 0 0 1-.814-.419L0 1zm11.354 9.646a.5.5 0 0 0-.708.708l3 3a.5.5 0 0 0 .708-.708l-3-3z"/>
    <path fillRule="evenodd" d="M15.898 2.223a3.003 3.003 0 0 1-3.679 3.674L5.878 12.15a3 3 0 1 1-2.027-2.027l6.252-6.341A3 3 0 0 1 13.778.1l-2.142 2.142L12 4l1.757.364 2.141-2.141zm-13.37 9.019L3.001 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026z"/>
    </svg></a><div className="dropdown-menu animated zoomIn">
                                  <ul className="mega-dropdown-menu row">                                  
                                      <li className="col-lg-4 col-xlg-4 m-b-30">
                                          <h4 className="m-b-20">Tools</h4>                                        
                                          <ul className="list-style-none">
                                              <li><a href="/rackprint">Print Rack Labels</a></li> 
                                              <li><a href="/trojanprint">Print Trojan Labels</a></li>
                                              <li><a href="/reports-bol">Find Order by BOL</a></li>                                                                          
                                          </ul>
                                      </li>
                                      
                                  </ul>
                              </div>
                          </li>       
                          ) : (<li></li>) }    

                          <li className="nav-item dropdown">
                              <a className="nav-link text-center" href={alertUrl} data-toggle="tooltip" title="View Alerts"><svg width="1em" height="1em" viewBox="0 0 16 16" style={{paddingleft:"10px"}} className="bi bi-broadcast-pin" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 0 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM6 8a2 2 0 1 1 2.5 1.937V15.5a.5.5 0 0 1-1 0V9.937A2 2 0 0 1 6 8z"/>
                            </svg></a>
                          </li>

                          <li className="nav-item dropdown">
                              <a className="nav-link text-center" href={scannerUrl} data-toggle="tooltip" title="Scanner"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" className="bi bi-upc-scan" viewBox="0 0 16 16">
                                <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
                              </svg></a>
                          </li>
              
                          <li className="nav-item dropdown">
                              
                                <Toolbar style={toolbarStyle}>
                                                                      
                                  <ToolbarGroup style={toolbarStyle}>
                                    {auth.isSignedIn !== state.AUTH_SUCCESS ? (
                                      <Link to={`/signin`}><button type="button" className="btn btn-info btn-sm" onClick={this.signIn}>Sign In</button></Link>
                                                          
                                    ) : (
                                      <div>
                                        {auth.info.username} &nbsp;&nbsp;
                                        <button type="button" className="btn btn-info btn-sm" onClick={this.signOut}>Sign Out</button>                                      
                                      </div>
                                    )}
                                  </ToolbarGroup>
                                </Toolbar>
                          </li>

                          
                      </ul></div>

                   
                  
                  </div>
                  ) }                
                
            </nav>
            
            ) : (
            <div>
            &nbsp;
            </div>
            )}
               
            </div>
            
        )
  }
}
