// Header.js
import React, {Component} from 'react';
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import Modal from 'react-modal';
import 'react-table/react-table.css'
import 'react-dropdown/style.css';
class InventoryReport extends Component {

    constructor(props) {
        super(props);        
            this.state = {
            inventoryitems: [],
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),
            us_id: localStorage.getItem('usId'),
            sortOptions: [{ id: 'pr_id', desc: true }],
            userToken: localStorage.getItem('userToken')
        };
       
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

    }

    openModal() {
      this.setState({modalIsOpen: true});
      if (this.state.cl_id ==='') {
        this.setState({modalMessage: 'Please select a client first. '});
      } 

    }

    afterOpenModal() {     
    }

    closeModal() {
      this.setState({modalIsOpen: false});
    }

    getInventory = (url) => {

      var _startdate = '2020-10-7';

      if (this.state.cl_id === 2) {
        _startdate  = '2021-1-6';
      }
      
      var productJSON = JSON.stringify({
        cl_id: this.state.cl_id,
        pr_id: 0,
        start_date: _startdate
      });

      console.log('logging inventory json...');
      console.log(productJSON);      
      
      fetch(url, {
            method: 'post',        
            body: productJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
        }             
      }).then(res => res.json())
        .then(inventoryitems => this.setState({ inventoryitems }));       
      
    }  
    
    componentDidMount() {
      Modal.setAppElement('#main');      
      this.getInventory(process.env.REACT_APP_NODE_ROOT_URL+'/reports/inventory/'+localStorage.getItem('headerClientId'));          
    }
 
    render(){
                
        
        const { inventoryitems } = this.state;

        const headers = [
          { label: "Product ID", key: "pr_id" },
          { label: "SKU", key: "pr_sku" },
          { label: "Received Qty", key: "received_qty" },
          { label: "Shipped Qty", key: "shipped_qty" },
          { label: "Canceled Qty", key: "canceled_qty" },
          { label: "New/In Process Qty", key: "processing_qty" },
          { label: "Expected QOH", key: "diff_qty" },
          { label: "Current QOH", key: "current_qoh" }
        ];
        
        return (
        <div id="main">
        <div className="page-wrapper">
                <div className="row page-titles">
      
                  <div className="col-md-11 align-self-center">
                       <h3 className="text-primary">Product Inventory</h3>                     
                  </div>
                  <div className="col-md-1 align-self-center"> 
                  
                  </div>
                </div>

                <div className="container-fluid">

                <div className="row">
                    <div className="col-lg-12">
                       
                            <div className="card-body" name="card-order-list">
                            <CSVLink headers={headers} data={inventoryitems}>Download CSV of Product Inventory</CSVLink>
                                <div className="table-responsive">
                                  <ReactTable
                                      data={inventoryitems}
                                      sorted={this.state.sortOptions}
                                      onSortedChange={val => {
                                      this.setState({ sortOptions: val }) }}
                                      filterable
                                      noDataText='Loading product inventory...'
                                      loading={this.state.loading}
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}          
                                      columns={[
                                        {                                          
                                          columns: [
                                            {
                                              Header: "Product ID",
                                              accessor: "pr_id",
                                              width: 125,
                                              filterable: false,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                                            
                                                                            
                                            {
                                              Header: "SKU",
                                              accessor: "pr_sku",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },                                            
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Received</div>
                                              ),
                                              accessor: "received_qty",
                                              width: 125,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]===filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Shipped</div>
                                              ),
                                              accessor: "shipped_qty",
                                              width: 125,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]===filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Canceled</div>
                                              ),
                                              accessor: "canceled_qty",
                                              width: 125,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]==filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>New/In Process</div>
                                              ),
                                              accessor: "processing_qty",
                                              width: 125,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]===filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Expected QOH</div>
                                              ),
                                              accessor: "diff_qty",
                                              width: 125,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]==filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Current QOH</div>
                                              ),
                                              accessor: "current_qoh",
                                              width: 125,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]===filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                                            
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={50}
                                      className="-striped -highlight"
                                      
                                    />
                               
                                </div>
                            </div>
                       
                    </div>
                </div>               
                <div className="row">   
                    <div className="col-lg-4">
                       
                    </div>
                </div>


            </div>
        </div>
        </div>

                )
    }
}
export default InventoryReport;