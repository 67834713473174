import React, { Component } from 'react';
import LeftBar from '../Site/LeftBar';
import ActivityEntry from './ActivityEntry';

import './style.css';

class Activity extends Component { 

  render() {
    return (
      <div>
        <LeftBar />
        <ActivityEntry />
      </div>

    );
  }
}

export default Activity;