// Header.js
import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import ReactTable from "react-table";
import Modal from 'react-modal';
import DateTimePicker from 'react-datetime-picker';
import Webcam from "react-webcam";
import 'react-html5-camera-photo/build/css/index.css';
import 'react-table/react-table.css'
import 'react-dropdown/style.css';
import bwipjs from 'bwip-js';
import ReactToPrint from "react-to-print";
import ProductPrintLabel from '../Products/ProductPrintLabel';
import matchSorter from 'match-sorter';
import moment from 'moment';
import { CSVLink } from "react-csv";

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '600px',
    color                 : 'black'
  }
};

const printModalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '500px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};

const cancelButton = {
  content : {
    float                 : 'right'  
  }
};

class ReceiverContent extends Component {

  constructor(props) {
      super(props);
      
      this.state = {
          upc_search: '',            
          receiver: [], 
          receiveritems: [],
          receiveritem: [],
          receivernotes: [],
          scanitem: [],
          cl_id: this.props.clientId,
          rc_id: this.props.receiverId,
          pr_id: 0,
          scans: 0,
          new_prod_id:0,
          rc_number: '',
          rc_company: '',
          rc_status: '',
          rc_contact_name: '',
          rc_contact_phone: '',
          rc_contact_email: '',
          rc_note: '',
          rc_date_added: null,
          rc_date_updated: null,
          rc_date_expected: null,
          rc_date_final: '',
          rc_total_items: '',
          rc_total_pallets: '',
          rc_total_boxes: '',
          rc_total_hours: '',
          rc_total_weight: '',
          rc_total_cost: '',
          rc_total_items_expected: '',
          editModalIsOpen:false,
          importModalIsOpen:false,          
          hoursModalIsOpen: false,
          statusModalIsOpen: false,
          photoModalIsOpen: false,
          filesModalIsOpen: false,
          labelModalIsOpen: false,
          binModalIsOpen: false,
          qtyModalIsOpen: false,
          expModalIsOpen: false,
          editModalMessage:'',
          importModalMessage:'',          
          hoursModalMessage:'',
          statusModalMessage:'',
          photoModalMessage:'',
          filesModalMessage:'',
          attachments: [],
          missingitems: [],
          file: null,
          rc_hours: 0,
          rc_boxes: 0,
          rc_pallets: 0,          
          rn_note: '',
          printQty: 1,
          printUPC: '',
          printDesc:'',
          adj_qty: 0,
          lg_qty: 0,
          lg_pr_id: 0,
          lg_rc_id: 0,
          edit_rc_id: '',
          edit_rc_number: '',
          edit_rc_company: '',
          edit_rc_date_exp: new Date(),
          edit_rc_date_max: new Date(),
          new_bin: '',
          new_bin_qty: 0,
          ri_id: 0,
          ri_qty: 0,
          disableAddButton: true,
          selectedFile: null,
          scanResponse: null,
          scannedItem: [],
          addItemData: null,
          userToken: localStorage.getItem('userToken'),
          us_id: localStorage.getItem('usId'),
          result: 'Start scanning ...'
      };

      this.handleInputChange = this.handleInputChange.bind(this);

      this.handleScan = this.handleScan.bind(this)
     
      this.openEditModal = this.openEditModal.bind(this);
      this.afterOpenEditModal = this.afterOpenEditModal.bind(this);
      this.closeEditModal = this.closeEditModal.bind(this);
      
      this.openImportModal = this.openImportModal.bind(this);
      this.afterOpenImportModal = this.afterOpenImportModal.bind(this);
      this.closeImportModal = this.closeImportModal.bind(this);
     
      this.openHoursModal = this.openHoursModal.bind(this);
      this.afterOpenHoursModal = this.afterOpenHoursModal.bind(this);
      this.closeHoursModal = this.closeHoursModal.bind(this);

      this.openStatusModal = this.openStatusModal.bind(this);
      this.afterOpenStatusModal = this.afterOpenStatusModal.bind(this);
      this.closeStatusModal = this.closeStatusModal.bind(this);

      this.openPhotoModal = this.openPhotoModal.bind(this);
      this.afterOpenPhotoModal = this.afterOpenPhotoModal.bind(this);
      this.closePhotoModal = this.closePhotoModal.bind(this);

      this.openFilesModal = this.openFilesModal.bind(this);
      this.afterOpenFilesModal = this.afterOpenFilesModal.bind(this);
      this.closeFilesModal = this.closeFilesModal.bind(this);

      this.openLabelModal = this.openLabelModal.bind(this);
      this.afterOpenLabelModal = this.afterOpenLabelModal.bind(this);
      this.closeLabelModal = this.closeLabelModal.bind(this);

      this.openBinModal = this.openBinModal.bind(this);
      this.afterOpenBinModal = this.afterOpenBinModal.bind(this);
      this.closeBinModal = this.closeBinModal.bind(this);

      this.openQtyModal = this.openQtyModal.bind(this);
      this.afterOpenQtyModal = this.afterOpenQtyModal.bind(this);
      this.closeQtyModal = this.closeQtyModal.bind(this);
      this.handleSubmitQty = this.handleSubmitQty.bind(this);

      this.openExpModal = this.openExpModal.bind(this);
      this.afterOpenExpModal = this.afterOpenExpModal.bind(this);
      this.closeExpModal = this.closeExpModal.bind(this);
      this.handleSubmitExp = this.handleSubmitExp.bind(this);
      this.handleSubmitDate = this.handleSubmitDate.bind(this);
   
      this.findUPC = this.findUPC.bind(this);
      this.addItem = this.addItem.bind(this);
      this.handleFileUpload = this.handleFileUpload.bind(this);
      this.handleAttach = this.handleAttach.bind(this);
      this.handleImportFileUpload = this.handleImportFileUpload.bind(this);
      this.processImportFileUpload = this.processImportFileUpload.bind(this);
      this.handlePhoto = this.handlePhoto.bind(this);
      this.handleDeleteItem = this.handleDeleteItem.bind(this);
      this.getReceiverItems = this.getReceiverItems.bind(this);
      this.getReceiverMissingItems = this.getReceiverMissingItems.bind(this);
      this.handleDeleteFile = this.handleDeleteFile.bind(this);
      this.getAttachments = this.getAttachments.bind(this);

      this.playGood = this.playGood.bind(this);
      this.playBad = this.playBad.bind(this);
      this.playComplete = this.playComplete.bind(this);
      this.playResult = this.playResult.bind(this);

  } 

  setRef = webcam => {
    this.webcam = webcam;
  };
 
  handlePhoto = (e) => {
    e.preventDefault();        
    
    const imageSrc = this.webcam.getScreenshot();

    //console.log(imageSrc);    

    var fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/files/receiver/photo/'+this.state.rc_id;

    var obj = { cl_id: this.state.cl_id, img: imageSrc };
    var myJSON = JSON.stringify(obj);

    //console.log(myJSON);
    
    fetch(fetchURL, {
        method: 'post',        
        body: myJSON,      
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken          
        }        
    
    }).then(function(response) {  
        return response.json();
    }).then(function(data) { 
        alert('Photo uploaded');
        window.location.reload();            
    }).catch(function(error) {
        alert(error);        
    });
    
    e.target.value = null; 

  }

  getReceiver() {

    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/r/'+this.props.receiverId, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(receiver => this.setState({ receiver }));    
  }

  getReceiverItems() {
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/'+this.props.receiverId, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(receiveritems => this.setState({ receiveritems }));
  }

  getReceiverNotes() {
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/notes/'+this.props.receiverId, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(receivernotes => this.setState({ receivernotes }));
  }

  getReceiverMissingItems() {
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/log/'+this.props.receiverId, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(missingitems => this.setState({ missingitems }));
  }

  getAttachments() {

    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/files/receiver/'+this.props.receiverId, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(attachments => this.setState({ attachments }));
   
  }

    //LIFECYCLE METHODS
    //     
  componentDidUpdate(prevProps, prevState) {
    // console.log('componentDidUpdate fired');
    //console.log('scans:' + this.state.scans); 
  }

  componentDidMount() {

    this.getReceiver();
    this.getReceiverItems(); 
    this.getReceiverMissingItems();
    this.getReceiverNotes();
    
    //TODO: errors
    this.getAttachments();  
  
    Modal.setAppElement('#main');

  }

  //EDIT
  //
  openEditModal = (e) => {    

    this.setState({editModalIsOpen: true});    
    
    this.setState({edit_rc_id: this.state.receiver[0].rc_id});
    this.setState({edit_rc_number: this.state.receiver[0].rc_number});
    this.setState({edit_rc_company: this.state.receiver[0].rc_company});
    this.setState({edit_rc_date_exp: new Date(this.state.receiver[0].rc_date_expected)});
    this.setState({edit_rc_date_max: new Date(this.state.receiver[0].rc_date_final)});    

  }
  
  afterOpenEditModal() {
  }

  closeEditModal() {
    this.setState({editModalIsOpen: false});
    this.handleSubmitDate();
  }

  //IMPORT
  openImportModal() {
    this.setState({importModalIsOpen: true});    
  }

  afterOpenImportModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closeImportModal() {
    this.setState({importModalIsOpen: false});
    this.handleSubmitDate();
  } 

  //Hours
  openHoursModal() {
    this.setState({hoursModalIsOpen: true});
  }

  afterOpenHoursModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closeHoursModal() {
    this.setState({hoursModalIsOpen: false});
  }

  //Status
  openStatusModal() {
    //set dropdown values
     this.setState({rc_status: this.state.receiver[0].rc_status});
    //
    
    this.setState({statusModalIsOpen: true});
  }

  afterOpenStatusModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closeStatusModal() {
    this.setState({statusModalIsOpen: false});
  }

  //Photo
  openPhotoModal() {
    this.setState({photoModalIsOpen: true});
  }

  afterOpenPhotoModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closePhotoModal() {
    this.setState({photoModalIsOpen: false});
  }

  //Files
  openFilesModal() {
    this.setState({filesModalIsOpen: true});
  }

  afterOpenFilesModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closeFilesModal() {
    this.setState({filesModalIsOpen: false});
  }

  //LABEL
  openLabelModal = (e) => {
    //console.log('printid: ' + e.original.pr_id);

    this.setState({labelModalIsOpen: true});
    this.setState({pr_sku: e.original.pr_sku});
    this.setState({printUPC: e.original.pr_upc});
    this.setState({printQty: e.original.lg_scan_count})
    this.setState({printDesc: e.original.pr_desc});

  }

  afterOpenLabelModal = (e) => {

    try {
      // The return value is the canvas element
      let canvas = bwipjs.toCanvas('mycanvas', {
                bcid:        'code128',       // Barcode type
                text:        this.state.printUPC,    // Text to encode
                scale:       3,               // 3x scaling factor
                height:      10,              // Bar height, in millimeters
                includetext: true,            // Show human-readable text
                textxalign:  'center',        // Always good to set this
            });
    } catch (err) {
        // `e` may be a string or Error object
    }


  }

  closeLabelModal() {
    this.setState({labelModalIsOpen: false});
    this.setState({printQty:1});
  } 

  //BIN 
  //TODO: get current bin from product grid? Or get all bins based on PR ID???
  openBinModal = (e) => {
   
    this.setState({binModalIsOpen: true});
    //this.setState({current_bin: e.original.pr_bin});
  }

  afterOpenBinModal() {

  }

  closeBinModal() {
    this.setState({binModalIsOpen: false});
  }

  //QTY ADJUSTMENT
  openQtyModal = (e) => {
    //console.log('e-qty: ' + JSON.stringify(e));
    //var _obj = JSON.parse
    //console.log('rc_id: ' + e.original.rc_id );
    //console.log('pr_id: ' + e.original.pr_id );
    
    this.setState({lg_qty: e.original.lg_scan_count});
    this.setState({lg_rc_id: e.original.rc_id});
    this.setState({lg_pr_id: e.original.pr_id});
    //this.setState({pr_upc: e.original.pr_upc});
   
    this.setState({qtyModalIsOpen: true});
    //this.setState({pr_sku: e.original.pr_sku});
    //this.setState({printUPC: e.original.pr_upc});

  }

  afterOpenQtyModal() {
  }

  closeQtyModal() {
    this.setState({qtyModalIsOpen: false});
  }

  //EXP QTY ADJUSTMENT
  openExpModal = (e) => {
    console.log('exp-qty: ' + JSON.stringify(e));
    
    //console.log('ri_id: ' + e.original.ri_id );
    //console.log('ri_qty: ' + e.original.ri_qty );
    
    this.setState({ri_qty: e.original.ri_qty});
    this.setState({ri_id: e.original.ri_id});
    this.setState({pr_id: e.original.pr_id});
     
    this.setState({expModalIsOpen: true});

  }

  afterOpenExpModal() {
  }

  closeExpModal() {
    this.setState({expModalIsOpen: false});
  }

  playGood() {
    const audioEl = document.getElementsByClassName("good-audio-element")[0]
    audioEl.play()
  }

  playBad() {
    const audioEl = document.getElementsByClassName("bad-audio-element")[0]
    audioEl.play()
  }

  playComplete() {
    const audioEl = document.getElementsByClassName("complete-audio-element")[0]
    audioEl.play()
  }

  
   // EVENT HANDLERS

handleInputChange = (e) => {     
      
  const target = e.target;
  const value = target.value;
  const name = target.name;

  console.log('name: ' + name);
  console.log('value: ' + value);

  this.setState({
   [name]: value
  });

}


  handleAttach = (e) => {
    e.preventDefault();    

    this.setState({filesModalMessage:'Uploading file...please wait.'})

    const myFile = document.getElementById('myFile');
    const data = new FormData();
    data.append('myFile', myFile.files[0]);

    var fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/files/receiver/'+this.props.receiverId;

    fetch(fetchURL, {
    method: 'POST',
    body: data,
    headers: {            
      'bwToken': this.state.userToken          
    }
    }).then(function(response) { 
            
            //console.log(response);
            //console.log(fetchURL);

            if (response.statusText === 'OK'){
              alert('File attached successfully.')
              //this.setState({filesModalMessage:'File uploaded successfully'});
            } else {
              alert('File attachment failed. Try again.')
              //this.setState({filesModalMessage:'Upload failed. Try again.'});
            }
            window.location.reload();
        }).then(function(data) { 
            //console.log(data);
        }).catch(function(error) {            
            alert('File not uploaded! ' + error);        
        });
    
    e.target.value = null; 
  }

  handleFileUpload = (event) => {
    this.setState({file: event.target.files});
  }

  handleImportFileUpload = (e) => {
    e.preventDefault(); 
    
    const myFile = document.getElementById('rcUpload');
    
    this.setState({
      selectedFile: myFile.files[0],
      loaded: 0,
    });
  }


  processImportFileUpload = (e) => {
    e.preventDefault();    
    
    //const myFile = document.getElementById('rcUpload');
    const data = new FormData();
    data.append('file', this.state.selectedFile);

    var fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/upload/rc/'+this.props.clientId+'/'+this.props.receiverId;
     
    fetch(fetchURL, {
    method: 'POST',
    body: data,
    headers: {            
      'bwToken': this.state.userToken          
    }
     }).then(function(response) { 
            /*
            console.log(response);
            console.log(fetchURL);

            if (response.statusText == 'OK'){               
                //alert('success');
                
            } else {
                alert('Upload Failed. Check file format and try again.')
            } 
            */                

            return response.json();
        }).then(function(data) {
          console.log('upload: ' + JSON.stringify(data));
          alert('File uploaded. ' + JSON.stringify(data));
          window.location.reload();
        }).catch(function(error) {            
            alert('File not uploaded! ' + error);        
        });
    
    e.target.value = null; 
  }

  handleSubmitHours = (e) => {
      //alert('Client name: ' + this.state.cl_name);
      e.preventDefault();

      //console.log('cl_id: ' + this.state.cl_id);
            
      var obj = { rc_id: parseInt(this.state.rc_id), rc_hours: parseInt(this.state.rc_hours), rc_boxes: parseInt(this.state.rc_boxes), rc_pallets: parseInt(this.state.rc_pallets), rn_note: this.state.rn_note, us_id: this.state.us_id };
      var myJSON = JSON.stringify(obj);

      console.log(myJSON);
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/hours/'+this.state.cl_id, {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken       
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {  
          return response.json();
      }).then(function(data) { 
          //console.log(data);
        alert("Hours added successfully");
        window.location.reload();         
      }).catch(function(error) {
          alert(error);        
      });

  }

  handleSubmitDate = () => {
    //alert('Client name: ' + this.state.cl_name);
   
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/date/'+this.state.rc_id, {
        method: 'post',   
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken       
        }
        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
    
    }).then(function(response) {  
        //
    }).then(function(data) { 
      window.location.reload();        
    }).catch(function(error) {
        alert(error);        
    });

}

  handleSubmitEdit = (e) => {
      
      e.preventDefault();      
      
      var _expdate = moment(this.state.edit_rc_date_exp).format("YYYY-MM-DD HH:mm:ss"); 
      var _finaldate = moment(this.state.edit_rc_date_max).format("YYYY-MM-DD HH:mm:ss"); 
      var _editdate = moment().format("YYYY-MM-DD HH:mm:ss"); 

      var obj = { rc_id: parseInt(this.state.edit_rc_id), rc_number: this.state.edit_rc_number, rc_company: this.state.edit_rc_company, rc_date_expected: _expdate, rc_date_final: _finaldate,  rc_date_updated: _editdate };
      var myJSON = JSON.stringify(obj);

      //console.log('new json: ' + myJSON);          
            
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/edit/'+this.state.cl_id, {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken       
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {  
          return response.json();
      }).then(function(data) { 
        console.log(data);
        alert("Receiver details updated successfully");
        window.location.reload();         
      }).catch(function(error) {
          alert(error);        
      });      

  }

  handleSubmitQty = (e) => {
      
      e.preventDefault();
      
      var obj = {cl_id: this.state.cl_id, rc_id: this.state.lg_rc_id, pr_id: this.state.lg_pr_id, ri_qty: parseInt(this.state.lg_qty)+parseInt(this.state.adj_qty), adj_qty: parseInt(this.state.adj_qty) };
      var myJSON = JSON.stringify(obj);

      console.log('handleSubmitQty: ' + myJSON);
            
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/qty/'+this.state.cl_id, {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken       
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {
          return response.json();
      }).then(function(data) { 
        console.log(data);
        alert("Qty updated successfully");
        window.location.reload();         
      }).catch(function(error) {
          alert(error);        
      });
           
  }

  handleSubmitExp = (e) => {

    //console.log(JSON.stringify(e.original))
      
    e.preventDefault();
    
    var obj = {ri_qty: this.state.ri_qty, pr_id: e.original.pr_id };
    var myJSON = JSON.stringify(obj);
   
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/exp/'+this.state.ri_id, {
        method: 'post',        
        body: myJSON,      
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken       
        }
        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
    
    }).then(function(response) {
        return response.json();
    }).then(function(data) { 
      //console.log(data);
      alert("Expected Qty updated successfully");
      window.location.reload();         
    }).catch(function(error) {
        alert(error);        
    });

   
}

  handleSubmitStatus = (e) => {
      
      e.preventDefault();

      if (this.state.rc_contact_email.indexOf(",")>0) {
        this.state.rc_contact_email = this.state.rc_contact_email.replace(',',';');
      }
      
      //var obj = { rc_id: parseInt(this.state.rc_id), rc_notes: this.state.rc_notes, rc_status: this.state.rc_status, rc_contact_email: this.state.rc_contact_email, us_id: this.state.us_id };
      var obj = { rc_id: parseInt(this.state.rc_id), rc_note: this.state.rc_note, rc_status: this.state.rc_status, rc_contact_email: this.state.rc_contact_email, us_id: this.state.us_id, us_send_email: this.state.statusemailchecked, rc_number: this.state.receiver[0].rc_number };
      
      var myJSON = JSON.stringify(obj);

      console.log('test: ' + myJSON);
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/status/'+this.state.cl_id, {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken       
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {
          return response.json();
      }).then(function(data) { 
          //console.log(data);
        alert("Status updated successfully");
        window.location.reload();         
      }).catch(function(error) {
          alert(error);        
      });
  }

  handleDeleteFile = (filePath) => {
    
    //console.log('filePath: ' + filePath)  
    var obj = { filePath: filePath };
    var myJSON = JSON.stringify(obj);
    var fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/files/del/receiver/'+this.state.rc_id;

    fetch(fetchURL, {
      method: 'post',        
      body: myJSON,      
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'bwToken': this.state.userToken          
      }        
  
    }).then(function(response) {  
        return response.json();
    }).then(function(data) { 
        alert('File deleted');
        //window.location.reload();            
    }).then(this.getAttachments).catch(function(error) {
        alert(error);        
    });
     
  }
  
  handleDeleteItem = (e) => {

    var indate = moment().format("YYYY-MM-DD HH:mm:ss"); 
  
    var obj = { rc_id: e.original.rc_id, pr_id: e.original.pr_id,  ri_id: e.original.ri_id, us_id:this.state.us_id,in_date:indate };
    var myJSON = JSON.stringify(obj);

    console.log('handleDeleteItemJSON: ' + myJSON);
   
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/item/del/'+this.state.cl_id, {
        method: 'post',        
        body: myJSON,      
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken       
        }
        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
    
    }).then(function(response) {
        return response.json();
    }).then(function(data) { 
        //console.log(data);
        //alert("Receiver Item deleted successfully");
      window.location.reload();         
    }).catch(function(error) {
        alert(error);        
    });

  }

  runReport(event) { 
    console.log('run report');
  };

  handleScan(data){    
    this.setState({
      result: data,
    })
    const _upc = data.trim().replace(/^0+/, '');
    
    this.findUPC(_upc);

  }
  
  handleError(err){
    console.error(err)
  }


  findUPC = (upc) => {      

    (async () => {
    
    
    //console.log('upc: ' + upc)
        
    //find result
    const result = this.state.receiveritems.find( receiveritem => receiveritem.pr_upc === upc);

    //if found, then log scan
    if (result) {
      //copy array
      var newStateArray = this.state.receiveritems.slice();
          
      //get pr_id of scanned item
      var pr_id = result['pr_id'];      
      var ri_qty = result['ri_qty']

      /* fix QOH
      var pr_qty = result['pr_qty']
      //console.log('pr_id: ' + pr_id);
      var _newqty = pr_qty+ri_qty;

      result['pr_qty'] = _newqty;
      */
      //get current scan cnt
      var curScans = result['lg_scan_count'];
      //console.log('curScans: ' + curScans);

      //increment scan by 1
      var newScans = curScans+1;
      //console.log('newScans: ' + newScans);

      //set value which will trigger render
      result['lg_scan_count'] = newScans;
   
      //get current diff cnt
      var expCount = result['ri_qty'];
      
      //do the math 1
      var newDiffScans = expCount-newScans;
     
      //set value which will trigger render
      result['qty_diff'] = newDiffScans;        
      
      //console.log('new result: ' + JSON.stringify(result));
      //get index for value swap
      //swap result row into new array at same position
      
      var ind = newStateArray.indexOf(result);
      
      //console.log('ind: ' + ind);

      //swap values with splice
      var replaced = newStateArray.splice(ind, 1, result);
      //console.log('newest arr: ' + JSON.stringify(newStateArray));

      this.scanItem(this.state.cl_id,this.state.rc_id,pr_id, this.state.us_id);
      
      this.setState({receiveritems: newStateArray});

      //TODO: how to color the row??
      ////this.colorRow(upc);

      //this.setState({upc_search: ''});
     
      this.setState({
        result: "updated - " + upc
      })

      this.playGood();
 
    } else {

      console.log('UPC not found on receiver! Try adding.. '); 
      
      this.addItem(this.props.clientId,this.props.receiverId,0,1,'In Process',upc,this.state.us_id);


      //const _res = _add + ': ' + upc;

      //this.setState({
      //  result: _res
      //})

      /*
      this.setState({
        result: "added - " + upc
      })
      */

    }
  })();
  
}

playResult = () => {
  console.log('sound to play: ' + this.state.addItemData)
}

addItem = (cl_id,rc_id,pr_id,ri_qty,ri_status,pr_upc,us_id) => {

  var obj = { cl_id: cl_id, rc_id: rc_id, pr_id: pr_id, ri_qty: ri_qty, ri_status: ri_status, pr_upc: pr_upc, us_id: us_id };
  var myJSON = JSON.stringify(obj);
  
  //console.log(myJSON);

  fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/item/'+rc_id, {
      method: 'post',        
      body: myJSON,      
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'bwToken': this.state.userToken          
      }
      //TODO:
      //credentials: 'same-origin', // send cookies
      //credentials: 'include'   // send cookies, even in CORS
  
//.then(addItemData => this.setState({ addItemData })).then(this.playResult())

  }).then(function(response) {    
      return response.json();      
  }).then(function(data) {
    var _res = JSON.stringify(data);
    if (_res ==='"missing"') {
      const audioEl = document.getElementsByClassName("bad-audio-element")[0]
      audioEl.play()
    } else {
      const audioEl = document.getElementsByClassName("good-audio-element")[0]
      audioEl.play()
    }    
    //console.log('scan success: ' + _res)
}).then(this.getReceiverItems).then(this.getReceiverMissingItems).catch(function(error) {
    console.log('Scan not logged for item! Please re-scan: ' + error);    
});
}

scanItem = (cl_id,rc_id,pr_id,us_id) => {

  var obj = { cl_id: cl_id, rc_id: rc_id, pr_id: pr_id, us_id: us_id };
  var myJSON = JSON.stringify(obj);
  
  //console.log(myJSON);

  fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/scan/'+rc_id, {
      method: 'post',        
      body: myJSON,      
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'bwToken': this.state.userToken          
      }
      //TODO:
      //credentials: 'same-origin', // send cookies
      //credentials: 'include'   // send cookies, even in CORS
  
  }).then(function(response) {  
    return response.json();
  }).then(function(data) { 
      //console.log('scan success: ' + JSON.stringify(data));            
  }).then(this.getReceiverItems).catch(function(error) {
      alert('Scan not logged for item! Please re-scan: ' + error);
  });
}
  
  
  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          background: this.setRowColor(rowInfo.row.lg_scan_count,rowInfo.row.ri_qty),
          color: this.setTextColor(rowInfo.row.lg_scan_count,rowInfo.row.ri_qty),
        }
      }
    }
    return {};
  }

  setRowColor = (scanQty,itemQty) => {
      if (scanQty===itemQty) {
        return '#0F6';
      } else if (scanQty > itemQty) {
        return '#0F6';
      } else if (scanQty < itemQty) {
        return '#FFC1C1';
      } else {
        return 'lightblue';
      }     
  }

  setTextColor = (scanQty,itemQty) => {    
      return 'black';
  }

  render(){
        const { receiveritems } = this.state;
        const videoConstraints = {
          width: 1280,
          height: 720,
          facingMode: "user"
        };

        const statusOptions = [
          'Open','Canceled','On Hold','Delivery Scheduled'
        ]

        const headers = [
          { label: "Product ID", key: "pr_id" },
          { label: "SKU", key: "pr_sku" },
          { label: "Name", key: "pr_desc" },
          { label: "UPC", key: "pr_upc" },
          { label: "Current QOH", key: "pr_qty" },
          { label: "Received Item Qty", key: "lg_scan_count" },
          { label: "Received Case Qty", key: "cs_qty" },
          { label: "Expected Qty", key: "ri_qty" },
          { label: "Diff Qty", key: "qty_diff" }
          
        ];

        const _template = process.env.REACT_APP_ROOT_URL + '/files/receiver-import.csv';

        return (
<div id="main">
    <div className="page-wrapper">
               <div className="row page-titles">
                <div className="col-md-9">
                    <h3 className="text-primary">Receiver Details </h3>                           
                </div>
            </div>
            <div className="container-fluid">          
            <div className="card">  
              <div className="card-body" name="card-order-list">
              <div className="span-buttons">

                <button className="btn btn-info btn-sm" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                View Details
                </button>
                <button type="button" className="btn btn-info btn-sm" onClick={this.openFilesModal}>Attach Files</button>
                <button type="button" className="btn btn-info btn-sm" onClick={this.openPhotoModal}>Capture Photo</button>
                <button type="button" className="btn btn-info btn-sm" onClick={this.openEditModal}>Edit Receiver</button>                
                <button type="button" className="btn btn-info btn-sm" onClick={this.openImportModal}>Import Items</button>                  
                <button type="button" className="btn btn-info btn-sm" onClick={this.openStatusModal}>Update Status</button>              
              </div>
                           
              </div>
              <div className="collapse" id="collapseExample">
                <div className="card card-body">
                  {this.state.receiver.map(receiverheader =>            
                    <div id="invoice-top" className="row" key={receiverheader.rc_id}>
                      <div className="col-sm-6">
                        Type: {receiverheader.rc_type} <br/>
                        Company: {receiverheader.rc_company} <br/>
                        Number: {receiverheader.rc_number} <br/>
                        Status: {receiverheader.rc_status} <br/>
                        Date Added: {moment(receiverheader.rc_date_added).local().format('MM-DD-YYYY hh:mm:ss a')} <br/>
                        Date Modified: {moment(receiverheader.rc_date_updated).local().format('MM-DD-YYYY hh:mm:ss a')}  <br/> 
                        Date Expected Ship: {moment(receiverheader.rc_date_expected).local().format('MM-DD-YYYY hh:mm:ss a')} <br/>
                        Date Final Allow Ship: {moment(receiverheader.rc_date_final).local().format('MM-DD-YYYY hh:mm:ss a')} <br/>                       
                        <u>Current attachments</u>
                        <ul>
                        {this.state.attachments.map((attachment,idx) =>
                          <li key={idx}><button type="button" className="btn btn-info btn-sm" onClick={() => { if (window.confirm('Are you sure you want to delete this file?')) this.handleDeleteFile(attachment)}}>x</button> <a href={"https://bw-admin-files.s3.amazonaws.com/".concat(attachment)} target="_new">{attachment.substr(attachment.lastIndexOf('/') + 1)}</a></li>
                        )}
                        </ul> 
                      </div>
                      <div className="col-sm-6">
                        Total Items: {receiverheader.rc_total_items} <br/>
                        Total Weight: {receiverheader.rc_total_weight} <br/>                        
                        Total Boxes: {receiverheader.rc_total_boxes} <br/>
                        Total Pallets: {receiverheader.rc_total_pallets} <br/>                        
                        Notifications Sent To: {receiverheader.rc_contact_name} {receiverheader.rc_contact_email} (in dev) <br/>
                        Contact Phone: {receiverheader.rc_contact_phone} <br/>                       

                      </div>
                    </div>

                  )}
                </div>
              </div>            
           
            </div>   
            <div className="row">   
                    <div className="col-lg-4">
                    <u>Missing Items on import and scan</u>
                        <ul>
                        {this.state.missingitems.map((missing,idx) =>
                          <li key={idx}>{missing.rm_sku} ({missing.rm_cnt})</li>
                          )}
                        </ul> 
                    </div>
                </div>
                <div className="row">  
                    <div className="col-lg-12">
                       
                        <div className="card">
                           
                           <div className="card-body" name="card-receiveritem-list">
                           <CSVLink headers={headers} data={receiveritems}>Download CSV of Receiver Items</CSVLink>
                                <div className="table-responsive">                                  

                                    <ReactTable

                                      data={receiveritems}
                                      filterable
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}          
                                      columns={[
                                        {                                          
                                          columns: [
                                            {
                                              Header: "Print",
                                              accessor: "pr_id",
                                              width: 50,
                                              filterable: false,
                                              Cell: props => (
                                                <div><button onClick={(e) => this.openLabelModal(props)} className="btn btn-sm">print</button></div>                                                
                                              )
                                            },
                                            {
                                              Header: "Bin",
                                              width: 50,
                                              show: false,                                              
                                              filterable: false,                                              
                                              Cell: props => (
                                                <div style={{ textAlign: "center" }}><i onClick={(e) => this.openBinModal(props)} className="fas fa-inbox" title="quick bin"></i></div>
                                              )
                                            },
                                            {
                                              Header: "SKU",
                                              accessor: "pr_sku",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Name",
                                              accessor: "pr_desc",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            },                                            
                                            {
                                              Header: "UPC",
                                              accessor: "pr_upc",
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["pr_upc"] }),
                                              filterAll: true                                              
                                            },
                                            {
                                              Header: "Other SKU",
                                              accessor: "ot_sku",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "EAN",
                                              accessor: "pr_ean",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            },
                                            {
                                              Header: "QOH",
                                              accessor: "pr_qty",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),                                              
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                                            
                                            {
                                              Header: "QTY REC",
                                              accessor: "lg_scan_count",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: props => (
                                                <div style={{ textAlign: "center" }}>{props.original.lg_scan_count}</div>
                                              )
                                            },    
                                            {
                                              Header: "CASES REC",
                                              accessor: "cs_qty",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),                                              
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                                      
                                            {
                                              Header: "QTY EXP",
                                              accessor: "ri_qty",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                                Cell: props => (
                                                  <div style={{ textAlign: "center" }}>{props.original.ri_qty}</div>
                                              )
                                            },
                                            {
                                              Header: "QTY DIFF",
                                              accessor: "qty_diff",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{Math.abs(row.value)}</div>
                                              )
                                            },
                                            {
                                              Header: "Delete",
                                              accessor: "pr_id",
                                              width: 50,
                                              filterable: false,
                                              show: false,
                                              Cell: props => (
                                                <div><button onClick={() => { if (window.confirm('Are you sure you want to delete this receiver item?')) this.handleDeleteItem(props) } } className="btn btn-sm">X</button></div>                                                
                                              )
                                            },
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={100}
                                      className="-striped -highlight"
                                      getTrProps={this.getTrProps}
                                      getTdProps={(state, rowInfo, column, instance) => {
                                        return {                                          
                                          onClick: (e, handleOriginal) => {
                                            if (rowInfo) {
                                              //console.log('r: '+rowInfo.rwo);
                                              //window.location.href = "/receiver/"+rowInfo.row.pr_id;
                                            }

                                            // IMPORTANT! React-Table uses onClick internally to trigger
                                            // events like expanding SubComponents and pivots.
                                            // By default a custom 'onClick' handler will override this functionality.
                                            // If you want to fire the original onClick handler, call the
                                            // 'handleOriginal' function.
                                            if (handleOriginal) {
                                              handleOriginal();
                                            }
                                          }
                                        };
                                      }}
                                    />

                                </div>
                            </div>

                        </div>
                    </div>
                </div>               
             
            </div>
         </div>

         <Modal
            isOpen={this.state.importModalIsOpen}
            onAfterOpen={this.afterOpenImportModal}            
            style={customStyles}
            contentLabel="Import Receiver Items"
          >                   
         
            <div className="container-fluid">               
              <form>
              <h2>Import Receiver Items</h2>
                  <div className="row">
                    <div className="col-sm-12">                                      
                      Select and import a file. <br/>
                       <input type="file" id="rcUpload" name="rcUpload" accept=".csv" onChange={(e) => this.handleImportFileUpload(e)} />                                                                 
                       <label id="rcUploadResponse"></label>
                    </div>
                                                   
                  </div>

              <div className="modalMessage">{this.state.importModalMessage}</div>
              <div className="right">
              <button type="submit" onClick={(e) => this.processImportFileUpload(e)}>Import</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closeImportModal} style={cancelButton}>Cancel</button>
              </div> 
                 
              </form> 
              <p> <a href={_template} target="_new">download receiver import template</a></p>
             </div>                          
            
          </Modal>

          <Modal
            isOpen={this.state.hoursModalIsOpen}
            onAfterOpen={this.afterOpenHoursModal}
            style={customStyles}
            contentLabel="Add Hours"
          >                   
         
            <div className="container-fluid">               
              <form onSubmit={this.handleSubmitHours}>
              <h2>Add Hours</h2>
                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Hours: 
                    </div>
                    <div className="col-sm-8">
                     <input
                        className="form-input"
                        id="rc_hours"
                        name="rc_hours"
                        type="text"
                        value={this.state.rc_hours}
                        onChange={this.handleInputChange}
                        placeholder="" 
                      />
                    </div>                                    
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Boxes:
                    </div>
                    <div className="col-sm-8">                                    
                     <input
                        className="form-input"
                        id="rc_boxes"
                        name="rc_boxes"
                        type="text"
                        value={this.state.rc_boxes}
                        onChange={this.handleInputChange}
                        placeholder="" 
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Pallets:
                    </div>
                    <div className="col-sm-8">                                    
                      <input
                        className="form-input"
                        id="rc_pallets"
                        name="rc_pallets"
                        type="text"
                        value={this.state.rc_pallets}
                        onChange={this.handleInputChange}
                        placeholder="" 
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Notes:
                    </div>
                    <div className="col-sm-8">                                    
                       <input
                        className="form-input"
                        id="rn_note"
                        name="rn_note"
                        type="text"
                        value={this.state.rn_note}
                        onChange={this.handleInputChange}
                        placeholder="" 
                      />
                    </div>
                  </div>

               <div className="modalMessage">{this.state.hoursModalMessage}</div>
                        <div className="right">
                          <button type="submit">Add</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closeHoursModal} style={cancelButton}>Cancel</button>
                        </div>               
                        
                 
              </form> 

             </div>                          
            
          </Modal>

           <Modal
            isOpen={this.state.statusModalIsOpen}
            onAfterOpen={this.afterOpenStatusModal}
            onRequestClose={this.closeStatusModal}
            style={customStyles}
            contentLabel="Update Status"
          >                   
         
            <div className="container-fluid">               
              <form onSubmit={this.handleSubmitStatus}>
              <h2>Update Status</h2>
                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Status: 
                    </div>
                    <div className="col-sm-8">
                     <select value={this.state.rc_status} 
                                  onChange={(e) => this.setState({rc_status: e.target.value})}>
                            {statusOptions.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                          </select>                        
                    </div>                                    
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Note *:
                    </div>
                    <div className="col-sm-8">                                    
                     <input
                        className="form-input"
                        id="rc_note"
                        name="rc_note"
                        type="text"
                        value={this.state.rc_note}
                        onChange={this.handleInputChange}
                        placeholder="" 
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Email To:
                    </div>
                    <div className="col-sm-8">      
                      <input
                        className="form-input"
                        id="rc_contact_email"
                        name="rc_contact_email"
                        type="text"
                        value={this.state.rc_contact_email}
                        onChange={this.handleInputChange}
                        placeholder="" 
                      />
                      <br/>
                      Separate multiple emails with a comma or semi-colon. 
                    </div>
                  </div>

                 

              <div className="modalMessage">{this.state.statusModalMessage}</div>
              <div className="right">
                <button type="submit">Add</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closeStatusModal} style={cancelButton}>Cancel</button>
              </div>               
                        
                 
              </form> 

             </div>                          
            
          </Modal>
            
        <Modal
            isOpen={this.state.photoModalIsOpen}
            onAfterOpen={this.afterOpenPhotoModal}
            onRequestClose={this.closePhotoModal}
            style={customStyles}
            contentLabel="Add Photos"
          >                   
         
            <div className="container-fluid">
              <h2>Add Photos</h2>
                  <div className="row">
                    <div className="col-sm-12">                                      
                      Click button to start camera. <br/>
                      <Webcam
                        audio={false}
                        height={350}
                        ref={this.setRef}
                        screenshotFormat="image/jpeg"
                        width={350}
                        videoConstraints={videoConstraints}
                      />
                      <button onClick={this.handlePhoto}>Capture photo</button>
                    </div>
                                                   
                  </div>

              <div className="modalMessage">{this.state.photoModalMessage}</div>
              <div className="right">
                <button type="submit">Upload</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closePhotoModal} style={cancelButton}>Cancel</button>
              </div> 

             </div>                          
            
          </Modal>

          <Modal
            isOpen={this.state.filesModalIsOpen}
            onAfterOpen={this.afterOpenFilesModal}
            onRequestClose={this.closeFilesModal}
            style={customStyles}
            contentLabel="Add Files"
          >                   
         
            <div className="container-fluid">               
            
              <h2>Add Files</h2>
                  <div className="row">
                    <div className="col-sm-12">                                      
                      
                       <form onSubmit={this.handleAttach}> 
                         <input type="file" id="myFile" name="myFile" /><br/><br/>
                         <input type="submit" value="Upload a file"/>&nbsp;&nbsp;
                         <button onClick={this.closeFilesModal} style={cancelButton}>Cancel</button>
                      </form>                   
                                     
                    </div>
                        
                  </div>

              <div className="modalMessage">{this.state.filesModalMessage}</div>              

             </div>                          
            
          </Modal>


         <Modal
            isOpen={this.state.editModalIsOpen}
            onAfterOpen={this.afterOpenEditModal}
            style={customStyles}
            contentLabel="Edit Receiver"
          >                   
         
            <div className="container-fluid">               
              <form onSubmit={this.handleSubmitEdit}>
              <h2>Edit Receiver</h2>
                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Name/Number:
                    </div>
                    <div className="col-sm-8">
                     <input
                              className="form-input"
                              id="edit_rc_number"
                              name="edit_rc_number"
                              type="text"
                              value={this.state.edit_rc_number}
                              onChange={this.handleInputChange}
                              placeholder="" 
                            />
                    </div>                                    
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Company:
                    </div>
                    <div className="col-sm-8">                                    
                     <input
                              className="form-input"
                              id="edit_rc_company"
                              name="edit_rc_company"
                              type="text"
                              value={this.state.edit_rc_company}
                              onChange={this.handleInputChange}
                              placeholder="" 
                            />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Expected Date:
                    </div>
                    <div className="col-sm-8">                                    
                      <DateTimePicker
                        disableClock={true}
                        onChange={edit_rc_date_exp => this.setState({ edit_rc_date_exp })}
                        value={this.state.edit_rc_date_exp}                        
                        format={"MM-dd-y h:mm:ss a"}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Max Date:
                    </div>
                    <div className="col-sm-8">                                    
                       <DateTimePicker
                        disableClock={true}
                        onChange={edit_rc_date_max => this.setState({ edit_rc_date_max })}
                        value={this.state.edit_rc_date_max}
                        format={"MM-dd-y h:mm:ss a"}
                      />
                    </div>
                  </div>

               <div className="modalMessage">{this.state.editModalMessage}</div>
                        <div className="right">
                          <button type="submit">Update</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closeEditModal} style={cancelButton}>Cancel</button>
                        </div>               
                        
                 
              </form> 

             </div>                          
            
          </Modal>

          <Modal
              isOpen={this.state.labelModalIsOpen}
              onAfterOpen={this.afterOpenLabelModal}                            
              style={printModalStyle}
              contentLabel="Print Label"
            >                   
           <div className="frame">
                <div className="scroll"> 
              <div className="container-fluid">               
                <form onSubmit={this.handleLabelPrint}>
                    <div className="row">
                        <div className="col-md-6">            
                          <h3>Print Product Labels</h3>
                          <div className="form-group" >
                            <label htmlFor="printQty" className="form-label">Label Qty: </label>&nbsp; 
                            <input
                              className="form-input"
                              id="printQty"
                              name="printQty"
                              type="text"
                              value={this.state.printQty}
                              onChange={this.handleInputChange}
                              placeholder="" 
                            />
                            
                          </div>
                          <div className="modalMessage">{this.state.modalMessage}</div>
                          <div>
                            <ReactToPrint
                              trigger={() => <Link to="#"><button type="button" className="btn btn-info btn-sm">Print</button></Link>}
                              content={() => this.componentRef}
                            /> &nbsp;&nbsp;
                            <button className="btn btn-info btn-sm" onClick={this.closeLabelModal}>Cancel</button>
                          </div> 
                          <ProductPrintLabel ref={el => (this.componentRef = el)} printQty={this.state.printQty} pr_sku={this.state.pr_sku} printDesc={this.state.printDesc} printUPC={this.state.printUPC} {...this.props} />
                        
                        </div>
                        
                    </div>
                   
                </form> 

               </div>                          
               </div>
              </div>
            </Modal>

            <Modal
              isOpen={this.state.binModalIsOpen}
              onAfterOpen={this.afterBinOpenModal}                            
              style={customStyles}
              contentLabel="Set Current BIN Location"
            >                   
           
              <div className="container-fluid">               
                <form onSubmit={this.handleBinSubmit}>
                    <div className="row">
                        <div className="col-md-6">            
                          <h3>Update Product BIN</h3>
                          <div className="form-group" >
                            <label className="form-label" ref={subtitle => this.subtitle = subtitle}>Current BIN: </label>&nbsp; 
                            <input
                              className="form-input"
                              id="bn_bin"
                              name="bn_bin"
                              type="text"
                              value={this.state.bn_bin}
                              onChange={this.handleInputChange}
                              placeholder="" 
                            />
                            
                          </div>
                          <div className="modalMessage">{this.state.modalMessage}</div>
                          <div>
                            <button  className="btn btn-info btn-sm" type="submit">Update</button>&nbsp;<button className="btn btn-info btn-sm" onClick={this.closeBinModal} >Cancel</button>
                          </div>               
                          
                        </div>

                        
                    </div>
                   
                </form> 

               </div>                          
              
            </Modal>

            <Modal
              isOpen={this.state.expModalIsOpen}
              onAfterOpen={this.afterExpOpenModal}                            
              style={customStyles}
              contentLabel="Adjust Expected Item Quantity"
            >                   
           
              <div className="container-fluid">               
                <form onSubmit={this.handleSubmitExp}>
                    <div className="row">
                        <div className="col-md-6">            
                          <h3>Adjust Expected Item Quantity</h3>
                          <div className="form-group" >
                            <label htmlFor="ri_qty" className="form-label">CURRENT EXPECTED QTY: <strong>{this.state.ri_qty}</strong> </label><br/>
                            <label htmlFor="adj_qty" className="form-label">SET EXPECTED QTY TO: </label>&nbsp; 
                            <input
                              className="form-input"
                              id="ri_qty"
                              name="ri_qty"
                              type="text"
                              value={this.state.ri_qty}
                              onChange={this.handleInputChange}
                              placeholder="" 
                            />
                            
                          </div>
                          <div className="modalMessage">{this.state.modalMessage}</div>
                          <div>
                            <button className="btn btn-info btn-sm" type="submit">Update</button>&nbsp;<button className="btn btn-info btn-sm" onClick={this.closeExpModal} >Cancel</button>
                          </div>               
                          
                        </div>

                        
                    </div>
                   
                </form> 

               </div>                          
              
            </Modal>

            <Modal
              isOpen={this.state.qtyModalIsOpen}
              onAfterOpen={this.afterQtyOpenModal}                            
              style={customStyles}
              contentLabel="Adjust Received Item Quantity"
            >                   
           
              <div className="container-fluid">               
                <form onSubmit={this.handleSubmitQty}>
                    <div className="row">
                        <div className="col-md-6">            
                          <h3>Adjust Received Item Quantity</h3>
                          <div className="form-group" >
                            <label htmlFor="lg_scan_count" className="form-label">CURRENT RECEIVED ITEM QTY: <strong>{this.state.lg_qty}</strong> </label><br/>
                            <label htmlFor="adj_qty" className="form-label">ADJUST RECEIVED ITEM QTY BY: </label>&nbsp; 
                            <input
                              className="form-input"
                              id="adj_qty"
                              name="adj_qty"
                              type="text"
                              value={this.state.adj_qty}
                              onChange={this.handleInputChange}
                              placeholder="" 
                            />
                            
                          </div>
                          <div className="modalMessage">{this.state.modalMessage}</div>
                          <div>
                            <button className="btn btn-info btn-sm" type="submit">Update</button>&nbsp;<button className="btn btn-info btn-sm" onClick={this.closeQtyModal} >Cancel</button>
                          </div>               
                          
                        </div>

                        
                    </div>
                   
                </form> 

               </div>                          
              
            </Modal>

        <audio className="good-audio-element">
          <source src={process.env.REACT_APP_ROOT_URL+'/sound/good-noise.mp3'}></source>
        </audio>

        <audio className="bad-audio-element">
          <source src={process.env.REACT_APP_ROOT_URL+'/sound/bad-noise.mp3'}></source>
        </audio>

        <audio className="complete-audio-element">
          <source src={process.env.REACT_APP_ROOT_URL+'/sound/complete-noise.mp3'}></source>
        </audio>





        </div>

                )
    }
}
export default ReceiverContent;