import React, { Component } from 'react';
import LeftBar from '../Site/LeftBar';
import ProductList from './ProductList';

import './style.css';

class Products extends Component {

  render() {
    return (
      <div>
        <LeftBar />
        <ProductList />
      </div>    


    );
  }
}

export default Products;