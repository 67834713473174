// Header.js
import React, {Component} from 'react';
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import Modal from 'react-modal';
import 'react-table/react-table.css'
import 'react-dropdown/style.css';
import DateTimePicker from 'react-datetime-picker';

var moment = require('moment');

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '400px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};

const printModalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '500px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};

const cancelButton = {
  content : {
    float                 : 'right'  
  }
};

var date = new Date();

class InventoryLogReport extends Component {

    constructor(props) {
        super(props);        
            this.state = {
            inventoryitems: [],
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),
            us_id: localStorage.getItem('usId'),
            sortOptions: [{ id: 'pr_id', desc: true }],
            startDate: null,
            endDate: null,
            userToken: localStorage.getItem('userToken')
        };
       
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.filterReport = this.filterReport.bind(this);

    }

    openModal() {
      this.setState({modalIsOpen: true});
      if (this.state.cl_id =='') {
        this.setState({modalMessage: 'Please select a client first. '});
      } 

    }

    afterOpenModal() {     
    }

    closeModal() {
      this.setState({modalIsOpen: false});
    }

    filterReport = (url) => {
      this.getInventoryLog(process.env.REACT_APP_NODE_ROOT_URL+'/reports/inventorylog/'+this.state.cl_id);     
    }

    getInventoryLog = (url) => {
      
     //Left of here with creating billing report

      const _startdate = moment().startOf('month').format('YYYY-MM-DD');
      const _enddate   = moment().endOf('month').format('YYYY-MM-DD');
      
      var inventoryJSON = JSON.stringify({
        cl_id: this.state.cl_id,
        pr_id: 0,
        start_date: _startdate,
        end_date: _enddate
      });

      console.log('logging inventory log json...');
      console.log(inventoryJSON);      
      
      
      fetch(url, {
            method: 'post',        
            body: inventoryJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
        }             
      }).then(res => res.json())
        .then(inventoryitems => this.setState({ inventoryitems })); 
    }  
    /*
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  },
    */
    
    componentDidMount() {
      Modal.setAppElement('#main');
      this.filterReport();               
    }
 
    render(){
        
        const { inventoryitems } = this.state;
        
        return (
        <div id="main">
        <div className="page-wrapper">
                <div className="row page-titles">
      
                  <div className="col-md-11 align-self-center">
                       <h3 className="text-primary">Inventory Tracking Log</h3>                     
                  </div>
                  <div className="col-md-1 align-self-center"> 
                  
                  </div>
                </div>

                <div className="container-fluid">

                <div className="row">

                <div className="col-lg-12">
                    Select a Start and End Date for the log to see results.
                            <table>                              
                              <tbody>
                              <tr>
                                <td>
                                Start Date:<br/>
                                <DateTimePicker
                                disableClock={true}
                                onChange={startDate => this.setState({ startDate })}
                                value={this.state.startDate}
                                format={"MM-dd-y"}
                              />&nbsp;&nbsp;</td>
                                <td>
                                End Date:<br/>
                                <DateTimePicker
                                disableClock={true}
                                onChange={endDate => this.setState({ endDate })}
                                onBlur={this.filterReport}
                                value={this.state.endDate}
                                format={"MM-dd-y"}
                              /></td>
                              </tr>
                              </tbody>
                            </table>
                           <br/>
                            <div className="card-body" name="card-order-list">
                            <CSVLink data={inventoryitems}>Download CSV of Inventory Tracking</CSVLink>
                                <div className="table-responsive">
                                  <ReactTable
                                      data={inventoryitems}
                                      sorted={this.state.sortOptions}
                                      onSortedChange={val => {
                                      this.setState({ sortOptions: val }) }}
                                      filterable
                                      noDataText='Loading inventory tracking report...'
                                      loading={this.state.loading}
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}       
   
                                      columns={[
                                        {                                          
                                          columns: [
                                            {
                                              Header: "Product ID",
                                              accessor: "pr_id",
                                              width: 10,
                                              show: false,
                                              filterable: false,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                              
                                            {
                                              Header: "SKU",
                                              accessor: "pr_sku",
                                              width: 125,                                                                                          
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },
                                            {
                                              Header: "UPC",
                                              accessor: "pr_upc",
                                              width: 125,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            }, 
                                            {
                                                Header: "Receiver Number",
                                                accessor: "rc_number",
                                                width: 125,                                               
                                                filterMethod: (filter, row) =>
                                                    row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            }, 
                                            {
                                                Header: "Order Number",
                                                accessor: "or_ss_order_id",
                                                width: 125,                                               
                                                filterMethod: (filter, row) =>
                                                    row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },                                            
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Qty In</div>
                                              ),
                                              accessor: "lg_qty_in",
                                              width: 100,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]==filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            }, 
                                            {
                                                Header: () => (
                                                  <div style={{ textAlign: "center" }}>Qty Out</div>
                                                ),
                                                accessor: "lg_qty_out",
                                                width: 100,
                                                filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? row[filter.id]==filter.value : true,
                                                Cell: row => (
                                                  <div style={{ textAlign: "center" }}>{row.value}</div>
                                                )
                                              }, 
                                              {
                                                Header: () => (
                                                  <div style={{ textAlign: "center" }}>Qty Avail Old</div>
                                                ),
                                                accessor: "lg_qoh_old",
                                                width: 100,
                                                filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? row[filter.id]==filter.value : true,
                                                Cell: row => (
                                                  <div style={{ textAlign: "center" }}>{row.value}</div>
                                                )
                                              },
                                              {
                                                Header: () => (
                                                  <div style={{ textAlign: "center" }}>Qty Avail New</div>
                                                ),
                                                accessor: "lg_qoh_new",
                                                width: 100,
                                                filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? row[filter.id]==filter.value : true,
                                                Cell: row => (
                                                  <div style={{ textAlign: "center" }}>{row.value}</div>
                                                )
                                              },                                              
                                              {
                                                Header: "User",
                                                accessor: "us_first_name",
                                                width: 80,                                               
                                                filterMethod: (filter, row) =>
                                                    row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                              },
                                              {
                                                Header: "Note",
                                                accessor: "lg_note",
                                                width: 120,                                               
                                                filterMethod: (filter, row) =>
                                                    row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                              },
                                              {
                                                Header: "Log Date",
                                                accessor: "lg_entry_date",
                                                width: 140,
                                                filterMethod: (filter, row) =>
                                                  row[filter.id].startsWith(filter.value),
                                                Cell: row => (
                                                  <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                                )
                                              } 
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={50}
                                      className="-striped -highlight"
                                      
                                    />
                               
                                </div>
                            </div>
                       
                    </div>
                </div>               
                <div className="row">   
                    <div className="col-lg-4">
                       
                    </div>
                </div>


            </div>
        </div>
        </div>

                )
    }
}
export default InventoryLogReport;