 import React, {Component} from 'react';

 export default class MobileBar extends Component {
    // add in client id
    constructor(props) {
        super(props);
        
          this.state = {                    
            cl_id: localStorage.getItem('headerClientId')
          };
       

    }
    
     render(){

        const link1 = '/mobile-product-bins/'+this.state.cl_id;
        const link2 = '/mobile-order/'+this.state.cl_id;
        const link3 = '/mobile-packing-detail/'+this.state.cl_id;

         return (
        
         <div className="left-sidebar" >
            
             <div className="scroll-sidebar">
             <nav className="sidebar-nav">
                    <ul id="sidebarnav">                        
                    <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href={link1} aria-expanded="false"><button type="button" className="btn btn-info">Binning</button></a><br/><br/>                 
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href={link2} aria-expanded="false"><button type="button" className="btn btn-success">Picking</button></a><br/><br/>                
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href={link3} aria-expanded="false"><button type="button" className="btn btn-warning">Packing</button></a><br/><br/>                
                                        
                    </ul>
             
               </nav>
             </div>
             
         </div>
        
                 )
     }
 }