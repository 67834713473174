import React, {Component} from 'react';
import DateTimePicker from 'react-datetime-picker';
var moment = require('moment');

class ActivityEntry extends Component {

    constructor(props) {
        super(props);
        
          this.state = {
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),
            in_type: 'or_id',
            in_type_id: '',           
            in_note: '',
            in_time: 0,
            is_read: 0,
            in_entry_date: new Date(),
            us_id: localStorage.getItem('us_id'),
            tc_ip_address: '',
            userId: localStorage.getItem('userId'),
            userName: localStorage.getItem('userName'),
            userToken: localStorage.getItem('userToken')
          };
       
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);       
        this.handleClientChange = this.handleClientChange.bind(this);
        
    }
	
	  componentDidMount() {      
      //this.handleClientChange(this.state.cl_id);	       
	  }    

    handleClientChange = (cl_id) => {
        //console.log(cl_id); 
    }
 
    handleInputChange(event) {      
     
      const target = event.target;
      const value = target.value;
      const name = target.name;
      //console.log('name: ' + name);
      //console.log('value: ' + value);
      
      this.setState({
       [name]: value
      });

    }

    handleSubmit(event) {  
      event.preventDefault();
      //submit date in this format moment().format('YYYY-MM-DD HH:mm:ss')
      var _thedate = moment(this.state.in_entry_date).format('YYYY-MM-DD HH:mm:ss');

      this.setState({in_entry_date: _thedate});

      /*
        Activity.cl_id,Activity.in_type,Activity.in_note,Activity.is_read,Activity.us_id,Activity.in_time,Activity.in_entry_date,Activity.in_type_id
      */

      let sActivity = {
        in_type: this.state.in_type,
        in_note: this.state.in_note,
        is_read: this.state.is_read,
        us_id: this.state.us_id,
        in_time: this.state.in_time,
        in_entry_date: _thedate,
        in_type_id: this.state.in_type_id
      };
      
      var sActivityJSON = JSON.stringify(sActivity);

      console.log(sActivityJSON);
          
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/timeclock/activity/'+this.state.cl_id, {
        method: 'POST',        
        body: sActivityJSON,      
        headers: {            
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken          
        }
        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
    
      }).then(function(response) {
          if (response.status==200) {
            alert('Activity added successfully');                      
          }
      }).then(function(data) { 
          
      }).catch(function(error) {
          alert('Activity could not be added'); 
          console.log('submit err: ' + error);       
      });
      

    }   

    render(){
        
        const options = [
            { value: 'or_id', label: 'Order' },
            { value: 'rc_id', label: 'Receiver' }
           ];

        return (

        <div className="page-wrapper" id="main">
            
            <div className="row page-titles">
                <div className="col-md-12 align-self-center">
                    <h3 className="text-primary">Activity Entry</h3> 
                </div>
            </div>

            <div className="container-fluid">
             <div className="card">
              <div className="card-body" name="card-order-list">              
               <div className="form-group">
              
                <div className="row"> 
                  <div className="col-md-6">
              
                      <h3>Manual Activity Entry</h3>
                      <p>Select a feature. Specify the date worked, time spent in hours and note with entry reason.  </p>
                      <div className="form-group row indent-left">                      
                        <div className="col-xs-2">
                          Activity Date: <br/>
                          <DateTimePicker
                            disableClock={true}
                            onChange={in_entry_date => this.setState({ in_entry_date })}
                            value={this.state.in_entry_date}
                            format={"MM-dd-y h:mm:ss a"}
                          /> <br/><br/>
                          Select an Activity: <br/>
                          <select value={this.state.in_type} 
                                  onChange={(e) => this.setState({in_type: e.target.value})}>
                            {options.map((_opt) => <option key={_opt.value} value={_opt.value}>{_opt.label}</option>)}
                          </select> &nbsp;&nbsp;&nbsp; ID: <input name="in_type_id" className="text-sm" type="text" value={this.state.in_type_id} onChange={this.handleInputChange}/>
                          <br/><br/>
                          Enter time spent in minutes. (ex. 3, 5, 15, 45, 60, 180):<br/>
                          <input name="in_time" className="text-sm" type="text" value={this.state.in_time} onChange={this.handleInputChange}/><br/><br/>
                          Note:<br/>
                          <input name="in_note" type="text" value={this.state.in_note} onChange={this.handleInputChange} /><br/><br/>
                          <input type="submit" value="Submit" className="btn btn-primary btn-sm" onClick={this.handleSubmit}/>  
                          <br/>
                        </div>                        
                      </div>
                    </div>
                  </div>               

                </div>
                </div>
                </div>
            </div>
        </div>

                )
    }
}
export default ActivityEntry;