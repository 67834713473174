import React, { Component } from 'react';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import matchSorter from 'match-sorter'
import ReactTable from "react-table";
import 'react-table/react-table.css'
import 'react-dropdown/style.css';
import moment from 'moment';
import './style.css';
import TrojanPrint from '../Products/TrojanPrint';

class Pickup extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
      }

    constructor(props) {
        super(props);              

            this.state = {
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),
            orders: [],
            orderStatus: 'Ready for Pick Up',
            userToken: localStorage.getItem('userToken')
        };
       
        //this.handleStatusClick = this.handleStatusClick.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);
        //
        
        const { location } = this.props
        console.log('path: '+ location.pathname )

        if (location.pathname !=='/') {

            var pathArray = location.pathname.split('/');

            //pathArray[1] = product
            //pathArray[2] = status value
                
            console.log('arr: '+  pathArray[1])
            /*
            this.state = {        
                orderStatus : pathArray[1]
            };
            */
        }
       
    }    

    componentDidMount() {        
      this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/orders/ready/'+this.state.cl_id);        
    }   

    getOrders = (url) => {
        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(orders => this.setState({ orders }));       
    }

    handleStatusClick = (e) => {
    e.preventDefault();    
    
        const target = e.target;
        const value = target.value;
        const name = target.name;

        console.log('name: ' + name);
        console.log('value: ' + value);
    
        e.target.value = null; 
    }

    onStatusClick = i => {      
      this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/orders/o/status/'+i);
    }

    onMethodClick = i => {      
      this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/orders/o/method/'+i);
    }

    render(){
        const { orders } = this.state;
        return (
        <div className="page-wrapper">
            <div className="row page-titles">
                <div className="col-md-12 align-self-center">
                    <h3 className="text-primary">Orders - Ready for Pick Up</h3> 
                </div>                
            </div>
            <div className="container-fluid">
               
                 <div className="row">					
                    <div className="col-lg-12">
                        <div className="card">
                            
                            <div className="card-body">
                                <div className="table-responsive">
                                   
                                     <ReactTable
                                      data={orders}
                                      filterable
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value} 
                                      columns={[
                                        {                                          
                                          columns: [                                            
                                            {
                                              Header: "cl_id",
                                              accessor: "cl_id",
                                              show: false
                                            },
                                            {
                                              Header: "or_id",
                                              accessor: "or_id",
                                              show: false
                                            },
                                            {
                                              Header: "Client",
                                              accessor: "cl_name",
                                              width: 100,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Order ID",
                                              accessor: "or_actual_order_id",
                                              width: 100,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["or_actual_order_id"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Order Date",
                                              accessor: "or_date",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            },                                           
                                            {
                                              Header: "First Name",
                                              accessor: "or_ship_fname",
                                              width: 150,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["or_ship_fname"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "left"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Last Name",
                                              accessor: "or_ship_lname",
                                              width: 150,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["or_ship_lname"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "left"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Company",
                                              accessor: "or_ship_company",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value) &&
                                                row[filter.id].endsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "left"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Ship Method",
                                              accessor: "or_ship_method",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value) &&
                                                row[filter.id].endsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "left"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Status",
                                              accessor: "or_status",
                                              width: 100,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value) &&
                                                row[filter.id].endsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "left"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Order Amount",
                                              accessor: "or_total",
                                              width: 100,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["or_ship_lname"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "left"}}>${row.value}</div>
                                              )
                                            }                                       
                                          ]
                                        }
                                      ]}
                                      defaultSorted={[
                                        {
                                          id: "or_date",
                                          desc: true
                                        }
                                      ]}
                                      defaultPageSize={20}
                                      className="-striped -highlight"
                                      getTdProps={(state, rowInfo, column, instance) => {
                                        return {
                                          onClick: (e) => {
                                            window.open("/order/"+rowInfo.row.cl_id+"/"+rowInfo.row.or_id, "_new");
                                            //window.location.href = "/order/"+rowInfo.row.cl_id+"/"+rowInfo.row.or_id;
                                            //var _url = "/order/"+rowInfo.row.cl_id+"/"+rowInfo.row.or_id;
                                            //this.handleOriginal(_url);
                                          }
                                        };
                                      }}
                                    />

                                   


                                </div>
                            </div>
                        </div>
                    </div>
                </div>               
                <div className="row">   
				    <div className="col-lg-4">
                       
                    </div>
                </div>


            </div>

            <footer className="footer"> © 2020 All rights reserved. Brand Warehouse</footer>

        </div>

                )
    }
}
export default withRouter(Pickup);