import React from 'react'
import PropTypes from 'prop-types'

import { config } from 'aws-cognito-redux-saga'

class Auth extends React.Component {
  static propTypes = {
    getUser: PropTypes.func
  }

  componentWillMount() {
    config.config.set({
      region: 'us-west-2',
      IdentityPoolId: 'us-west-2:0db83b31-2591-4d3c-94a3-0549c1c09903',
      UserPoolId: 'us-west-2_YVSmiYafa',
      ClientId: '7tc3mr1dtdlk4g30ps58ijm604'
    })

    this.props.getUser()
  }

  render() {
    return null
  }
}

export default Auth

//TEST
//region: 'us-west-2',
//IdentityPoolId: 'us-west-2:0db83b31-2591-4d3c-94a3-0549c1c09903',
//UserPoolId: 'us-west-2_YVSmiYafa',
//ClientId: '7tc3mr1dtdlk4g30ps58ijm604'
//

//EXTENDED ATTRIBUTES
//region: 'us-west-2',
//IdentityPoolId: '',
//UserPoolId: 'us-west-2_clyy68E1F',
//ClientId: '3on220pca560cs24h7gjjghi76'