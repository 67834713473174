import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import PackingContent from './PackingContent';

import './style.css';

class PackingDetail extends Component { 
  
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }


  constructor(props) {
    super(props);
    
    const { location } = this.props
    console.log('path: '+ location.pathname )

    var pathArray = location.pathname.split('/');
    //pathArray[1] = order
    //pathArray[2] = client id
    //pathArray[3] = order id
    
    console.log('arr: '+ pathArray)
    this.state = {
        clientId: pathArray[2],
        orderId : pathArray[3]
    };
  }
  render() {      

    const link1 = '/scan-product-bins/'+this.state.clientId;
    const link2 = '/scan-order/'+this.state.clientId;
    const link3 = '/scan-packing/'+this.state.clientId;
    const link4 = '/scan-processing/'+this.state.clientId;

    return (
      <div>       
        <PackingContent clientId={this.state.clientId} orderId={this.state.orderId} />
        <div id="footer">
          <a href={link1} aria-expanded="false"><button type="button" className="btn btn-info">Binning</button></a>
          <a href={link2} aria-expanded="false"><button type="button" className="btn btn-success">Picking</button></a>
          <a href={link3} aria-expanded="false"><button type="button" className="btn btn-warning">Packing</button></a>  
                       
        </div>
      </div>    
    );
  }
}

export default withRouter(PackingDetail);