// Header.js
import React, {Component} from 'react';
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import Modal from 'react-modal';
import 'react-table/react-table.css'
import 'react-dropdown/style.css';
import DateTimePicker from 'react-datetime-picker';

var moment = require('moment');

const columns = [
  {
    Header: "Client Id",
    accessor: "cl_id"    
  },                              
  {
    Header: "Total Shipped",
    accessor: "totalShipped"                                              
  },
  {
    Header: "Total On Time E-Commerce",
    accessor: "totalOnTimeEcommerce"                                                   
  }, 
 {
    Header: "Total Late E-Commerce",
    accessor: "totalLateEcommerce"                                                  
},   
{
  Header: "Total On Time Will Call",
  accessor: "totalOnTimeWillCall"                                                  
},   
{
  Header: "Total On Time Orders",
  accessor: "totalOnTimeOrders"
},
{
  Header: "Orders Before Noon - Shipped Same Day",
  accessor: "totalOrdersPlacedBeforeNoonShippedSameDay"                                                  
},
{
  Header: "Orders Between 12-2pm - Shipped Same Day",
  accessor: "totalOnTimeOrdersBetween12-2pm"                                                 
},
{
  Header: "Orders After 2pm - Shipped Same Day",
  accessor: "totalOnTimeOrdersAfter2pm"
} 
];
class FulfillmentReport extends Component {

    constructor(props) {
        super(props);        
            this.state = {
            inventoryitems: [],
            lateitems: [],
            hourlyitems: [],
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),
            us_id: localStorage.getItem('usId'),
            sortOptions: [{ id: 'pr_id', desc: true }],
            startDate: null,
            endDate: null,
            startTime: null,
            endTime: null,
            userToken: localStorage.getItem('userToken'),
            dataToDownload: []
        };
       
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.filterReport = this.filterReport.bind(this);
        this.filterHourly = this.filterHourly.bind(this);
        this.download = this.download.bind(this);
        this.handleSelectEnd = this.handleSelectEnd.bind(this);
        this.handleSelectStart = this.handleSelectStart.bind(this);

    }

    openModal() {
      this.setState({modalIsOpen: true});
      if (this.state.cl_id =='') {
        this.setState({modalMessage: 'Please select a client first. '});
      } 

    }

    afterOpenModal() {     
    }

    closeModal() {
      this.setState({modalIsOpen: false});
    }

    filterReport = () => {
      this.getFulfillment(process.env.REACT_APP_NODE_ROOT_URL+'/reports/fulfillment/'+this.state.cl_id);     
      this.getFulfillmentLate(process.env.REACT_APP_NODE_ROOT_URL+'/reports/fulfillment/late/'+this.state.cl_id);
      
    }

    filterHourly = () => {
      this.getFulfillmentHourly(process.env.REACT_APP_NODE_ROOT_URL+'/reports/fulfillment/hourly/'+this.state.cl_id);
    }

    getFulfillment = (url) => {
 
      let _startdate = this.state.startDate;
      let _enddate = this.state.endDate;

      if (_startdate==null) {
        _startdate = moment().startOf('month').format('YYYY-MM-DD');
        _enddate   = moment().endOf('month').format('YYYY-MM-DD');      
      } else {
        _startdate = moment(this.state.startDate).format('YYYY-MM-DD');
        _enddate = moment(this.state.endDate).format('YYYY-MM-DD');
      }
      
      var inventoryJSON = JSON.stringify({
        cl_id: this.state.cl_id,        
        start_date: _startdate,
        end_date: _enddate
      });

      //console.log('logging inventory log json...');
      //console.log(inventoryJSON);      
           
      fetch(url, {
            method: 'post',        
            body: inventoryJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
        }             
      }).then(res => res.json())
        .then(inventoryitems => this.setState({ inventoryitems })); 
    }  

    getFulfillmentLate = (url) => {
 
      let _startdate = this.state.startDate;
      let _enddate = this.state.endDate;

      if (_startdate==null) {
        _startdate = moment().startOf('month').format('YYYY-MM-DD');
        _enddate   = moment().endOf('month').format('YYYY-MM-DD');      
      } else {
        _startdate = moment(this.state.startDate).format('YYYY-MM-DD');
        _enddate = moment(this.state.endDate).format('YYYY-MM-DD');
      }
      
      var recentJSON = JSON.stringify({
        cl_id: this.state.cl_id,        
        start_date: _startdate,
        end_date: _enddate
      });

      //console.log('logging inventory log json...');
      //console.log(inventoryJSON);      
           
      fetch(url, {
            method: 'post',        
            body: recentJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
        }             
      }).then(res => res.json())
        .then(lateitems => this.setState({ lateitems })); 
    }  

    getFulfillmentHourly = (url) => {
 
      let _startdate = this.state.startDate;
      let _enddate = this.state.endDate;

      if (_startdate==null) {
        _startdate = moment().startOf('month').format('YYYY-MM-DD');
        _enddate   = moment().endOf('month').format('YYYY-MM-DD');      
      } else {
        _startdate = moment(this.state.startDate).format('YYYY-MM-DD');
        _enddate = moment(this.state.endDate).format('YYYY-MM-DD');
      }
      
      var recentJSON = JSON.stringify({
        cl_id: this.state.cl_id,        
        start_date: _startdate,
        end_date: _enddate,
        start_time: this.state.startTime,
        end_time: this.state.endTime
      });

      //console.log('logging inventory log json...');
      //console.log(inventoryJSON);      
           
      fetch(url, {
            method: 'post',        
            body: recentJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
        }             
      }).then(res => res.json())
        .then(hourlyitems => this.setState({ hourlyitems })); 
    } 


    /*
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  },
    */
  prepare = () => {
    const currentRecords = this.reactTable.getResolvedState().sortedData;    
    var data_to_download = [];

    for (var index = 0; index < currentRecords.length; index++) {
       let record_to_download = {}
       for(var colIndex = 0; colIndex < columns.length ; colIndex ++) {
          record_to_download[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor]
       }
       data_to_download.push(record_to_download)
    }
    this.setState({ dataToDownload: data_to_download })
  } 

  download = () => {
    
    // click the CSVLink component to trigger the CSV download
    this.csvLink.link.click()

  } 

  handleSelectStart = (event) => {
    var _val = event.target.value;
    //console.log('_val: ' + _val);

    this.setState({startTime: _val})
    
  }
  handleSelectEnd = (event) => {
    var _val = event.target.value;
    //console.log('_val: ' + _val);

    this.setState({endTime: _val})    
  }
    
    componentDidMount() {
      Modal.setAppElement('#main');
      this.filterReport();
          
    }
 
    render(){
        
        const { inventoryitems, lateitems, hourlyitems } = this.state;

        //cl_id, or_type or_ss_order_id, or_ship_fname, or_ship_lname, or_date, or_ship_by, sh_date, sh_tracking
        const headersrecent = [
          { label: "Client ID", key: "cl_id" },
          { label: "Order Type", key: "or_type" },
          { label: "Order Date", key: "or_date" },
          { label: "Order Number", key: "or_ss_order_id" },
          { label: "First Name", key: "or_ship_fname" },
          { label: "Last Name", key: "or_ship_lname" },
          { label: "Ship By Date", key: "or_ship_by" },
          { label: "Shipped Date", key: "sh_date" },
          { label: "Tracking Number", key: "sh_tracking" }  
        ];

        const startTimeOptions = [
          '01:00:00','02:00:00','03:00:00', '04:00:00', '05:00:00', '06:00:00', '07:00:00','08:00:00','09:00:00','10:00:00','11:00:00','12:00:00','13:00:00','14:00:00','15:00:00','16:00:00','17:00:00','18:00:00','19:00:00','20:00:00','21:00:00','22:00:00','23:00:00','24:00:00'
        ];
        
        return (
        <div id="main">
        <div className="page-wrapper">
                <div className="row page-titles">
      
                  <div className="col-md-11 align-self-center">
                       <h3 className="text-primary">Fulfillment Report</h3>                     
                  </div>
                  <div className="col-md-1 align-self-center"> 
                  
                  </div>
                </div>

                <div className="container-fluid">

                <div className="row">

                  <div className="col-lg-12">

                    <div className="card-body" name="card-order-list">   
                    Select a Start and End Date for the log to see results.
                    <table>                              
                              <tbody>
                              <tr>
                                <td>
                                Start Date:<br/>
                                <DateTimePicker
                                disableClock={true}
                                onChange={startDate => this.setState({ startDate })}
                                value={this.state.startDate}
                                format={"MM-dd-y"}
                              />&nbsp;&nbsp;</td>
                                <td>
                                End Date:<br/>
                                <DateTimePicker
                                disableClock={true}
                                onChange={endDate => this.setState({ endDate })}
                                onBlur={this.filterReport}
                                value={this.state.endDate}
                                format={"MM-dd-y"}
                              /></td>
                              </tr>
                              </tbody>
                    </table>
                    <br/>
                    <div className="card-body" name="card-order-list">
                        <div>                            
                        <CSVLink filename="fulfillment.csv" data={inventoryitems}>Download fulfillment report</CSVLink><br/>
                
                      </div>
                      <div>
                          <CSVLink
                              data={this.state.dataToDownload}
                              filename="data.csv"
                              className="hidden"
                              ref={(r) => this.csvLink = r}
                              target="_blank"/>

                      </div>
                        <div className="table-responsive">
                          <ReactTable
                              ref={(r) => this.reactTable = r}
                              data={inventoryitems}
                              sorted={this.state.sortOptions}
                              onSortedChange={val => {this.setState({ sortOptions: val }) }, this.download}
                              filterable
                              noDataText='Loading fulfillment report...'
                              loading={this.state.loading}
                              defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}                                    
                              columns={[
                                {                                          
                                  columns: [                                                                          
                                    {
                                      Header: "totalShipped",
                                      accessor: "totalShipped",
                                      width: 125,                                                                                          
                                      filterMethod: (filter, row) =>
                                        row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                    },
                                    {
                                      Header: "totalOnTimeEcommerce",
                                      accessor: "totalOnTimeEcommerce",
                                      width: 125,
                                      filterMethod: (filter, row) =>
                                        row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                    }, 
                                    {
                                        Header: "totalLateEcommerce",
                                        accessor: "totalLateEcommerce",
                                        width: 125,                                               
                                        filterMethod: (filter, row) =>
                                            row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                    }, 
                                    {
                                        Header: "totalOnTimeWillCall",
                                        accessor: "totalOnTimeWillCall",
                                        width: 125,                                               
                                        filterMethod: (filter, row) =>
                                            row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                    },                                            
                                    {
                                      Header: "totalOnTimeOrders",
                                      accessor: "totalOnTimeOrders",
                                      width: 125,                                               
                                      filterMethod: (filter, row) =>
                                          row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                    }, 
                                    {
                                      Header: "totalOrdersPlacedBeforeNoonShippedSameDay",
                                      accessor: "totalOrdersPlacedBeforeNoonShippedSameDay",
                                      width: 125,                                               
                                      filterMethod: (filter, row) =>
                                          row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                  }, 
                                  {
                                    Header: "totalOnTimeOrdersBetween12-2pm",
                                    accessor: "totalOnTimeOrdersBetween12-2pm",
                                    width: 125,                                               
                                    filterMethod: (filter, row) =>
                                        row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                  }, 
                                  {
                                    Header: "totalOnTimeOrdersAfter2pm",
                                    accessor: "totalOnTimeOrdersAfter2pm",
                                    width: 125,                                               
                                    filterMethod: (filter, row) =>
                                        row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                  }
                                  ]
                                }
                              ]}
                              defaultPageSize={50}
                              className="-striped -highlight"
                              
                            />
                        
                        </div>
                    </div>    
                    </div>

                    <hr/>
                            <h3>Late Shipments</h3>
                            <div className="card-body" name="card-order-list">
                            <CSVLink headers={headersrecent} data={lateitems}>Download CSV of Recent Items</CSVLink>
                                <div className="table-responsive">
                                  <ReactTable
                                      data={lateitems}
                                      sorted={this.state.sortRecentOptions}
                                      onSortedChange={val => {
                                      this.setState({ sortOptions: val }) }}
                                      filterable
                                      noDataText='Loading recently late orders ...'
                                      loading={this.state.loading}
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}          
                                      columns={[
                                        {                                          
                                          columns: [
                                            {
                                              Header: "Order Type",
                                              accessor: "or_type",
                                              width: 125,
                                              filterable: false,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },       
                                            {
                                              Header: "Order Date",
                                              accessor: "or_date",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            },                                                                     
                                            {
                                              Header: "Order Number",
                                              accessor: "or_ss_order_id",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },                            
                                            {
                                              Header: "First Name",
                                              accessor: "or_ship_fname",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },
                                            {
                                              Header: "Last Name",
                                              accessor: "or_ship_lname",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },
                                            {
                                              Header: "Ship By Date",
                                              accessor: "or_ship_by",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            },
                                            {
                                              Header: "Shipped Date",
                                              accessor: "sh_date",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            },
                                            {
                                              Header: "Tracking Number",
                                              accessor: "sh_tracking",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },                                                
                                            
                                            
                                             
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={50}
                                      className="-striped -highlight"
                                      
                                    />
                               
                                </div>
                            </div> 
                            <hr/>
                            <h3>Hourly Shipments</h3>
                            Select a Start and End Date for the report to see results.
                            <table>
                                      <tbody>
                                      <tr>
                                        <td>
                                        Start Date:<br/>
                                        <DateTimePicker
                                        disableClock={true}
                                        onChange={startDate => this.setState({ startDate })}
                                        value={this.state.startDate}
                                        format={"MM-dd-y"}
                                      />&nbsp;&nbsp;</td>
                                        <td>
                                        End Date:<br/>
                                        <DateTimePicker
                                        disableClock={true}
                                        onChange={endDate => this.setState({ endDate })}                                        
                                        value={this.state.endDate}
                                        format={"MM-dd-y"}
                                      /></td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Start Time:<br/>
                                          <select value={this.state.startTime} 
                                                  onChange={(e) => this.handleSelectStart(e)}>
                                            {startTimeOptions.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                                          </select>   
                                        </td>
                                        <td>
                                          End Time:<br/>
                                          <select value={this.state.endTime} 
                                                  onBlur={this.filterHourly} onChange={(e) => this.handleSelectEnd(e)}>
                                            {startTimeOptions.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                                          </select>   
                                        </td>
                                      </tr>
                                      </tbody>
                            </table>
                            <br/>
                            <div className="card-body" name="card-order-list">
                            <CSVLink headers={headersrecent} data={hourlyitems}>Download CSV of Hourly Items</CSVLink>
                                <div className="table-responsive">
                                  <ReactTable
                                      data={hourlyitems}
                                      sorted={this.state.sortRecentOptions}
                                      onSortedChange={val => {
                                      this.setState({ sortOptions: val }) }}
                                      filterable
                                      noDataText='Loading selected hourly shipments ...'
                                      loading={this.state.loading}
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}          
                                      columns={[
                                        {                                          
                                          columns: [
                                            {
                                              Header: "Order Type",
                                              accessor: "or_type",
                                              width: 125,
                                              filterable: false,
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },       
                                            {
                                              Header: "Order Date",
                                              accessor: "or_date",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            },                                                                     
                                            {
                                              Header: "Order Number",
                                              accessor: "or_ss_order_id",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },                            
                                            {
                                              Header: "First Name",
                                              accessor: "or_ship_fname",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },
                                            {
                                              Header: "Last Name",
                                              accessor: "or_ship_lname",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },
                                            {
                                              Header: "Ship By Date",
                                              accessor: "or_ship_by",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            },
                                            {
                                              Header: "Shipped Date",
                                              accessor: "sh_date",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            },
                                            {
                                              Header: "Tracking Number",
                                              accessor: "sh_tracking",
                                              width: 175,
                                              filterMethod: (filter, row) =>
                                                row[filter.id] !== null ? String(row[filter.id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true                                                     
                                            },                                                
                                            
                                            
                                             
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={50}
                                      className="-striped -highlight"
                                      
                                    />
                               
                                </div>
                            </div> 
                    
                </div>
              </div>               
                
            </div>
        </div>
        </div>

                )
    }
}
export default FulfillmentReport;