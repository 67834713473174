import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import MobileBar from '../Site/MobileBar';
import PackingList from './PackingList';

import './style.css';

class Packing extends Component {

	static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props);
    
    const { location } = this.props
    console.log('path: '+ location.pathname )

    var pathArray = location.pathname.split('/');
    //pathArray[1] = order
    //pathArray[2] = client id
    //pathArray[3] = order id    
    console.log('arr: '+ pathArray)
    this.state = {
        cl_id: pathArray[3]
    };
  }

  render() {
    return (
      <div>   
        <MobileBar />     
        <PackingList cl_id={this.state.cl_id} />
      </div>    


    );
  }
}

export default withRouter(Packing);