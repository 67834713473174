import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import LeftBar from '../Site/LeftBar';
import RackPrintLabel from './RackPrintLabel';
import Modal from 'react-modal';
import ReactToPrint from "react-to-print";
import './style.css';

const printModalStyle = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      width                 : '600px',
      color                 : 'black'
    },
    overlay: {zIndex: 1000}
  };  
class RackPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            printLocationFromA: '',
            printLocationFromB: '',
            printLocationFromC: '',
            printLocationFromD: '',
            printLocationToA: '',
            printLocationToB: '',
            printLocationToC: '',
            printLocationToD: '',
            labelModalIsOpen: false,
            printQty: 1
        };

        this.handleInputChange = this.handleInputChange.bind(this);

        this.openLabelModal = this.openLabelModal.bind(this);
        this.afterOpenLabelModal = this.afterOpenLabelModal.bind(this);
        this.closeLabelModal = this.closeLabelModal.bind(this);
    } 

    componentDidMount() {      
        Modal.setAppElement('#main');
        //this.handleClientChange(this.state.cl_id);
        //console.log('refresh: ' + this.state.refresh);
    }

    handleInputChange = (e) => {     
      
        const target = e.target;
        const value = target.value;
        const name = target.name;
      
        console.log('name: ' + name);
        console.log('value: ' + value);
      
        this.setState({
         [name]: value
        });
      
      }

     //LABEL
     openLabelModal = (bin) => {
     
        this.setState({labelModalIsOpen: true});     
       
      }
  
      afterOpenLabelModal = (e) => {
  
      }
  
      closeLabelModal() {
        this.setState({labelModalIsOpen: false});
        this.setState({printQty:1});
      } 

  render() {
    return (
      <div>
        <LeftBar />
        <div id="main">
            <div className="page-wrapper">
              <div className="row page-titles">
                <div className="col-md-12 align-self-center">
                  <h3 className="text-primary">Print Rack Labels</h3> 
                </div>
               
              </div>
              <div className="container-fluid">         
              <div className="card">
              <div className="card-body" name="card-order-list">      
                <div className="row">  
                  <div className="col-md-12">
                          <label>
                          <span>Enter a single location, or any range combination in this format: <br/>
                            from: 1A 1  100 <br/>
                            to:   1A 27 103  </span><br/>
                            From Location:<br/>
                            <input
                              name="printLocationFromA"
                              type="text"
                              style={{width:"25px"}}
                              value={this.state.printLocationFromA}
                              onChange={this.handleInputChange} />
                            <input
                              name="printLocationFromB"
                              type="text"
                              style={{width:"20px"}}
                              value={this.state.printLocationFromB}
                              onChange={this.handleInputChange} />-<input
                              name="printLocationFromC"
                              type="text"
                              style={{width:"25px"}}
                              value={this.state.printLocationFromC}
                              onChange={this.handleInputChange} />-<input
                              name="printLocationFromD"
                              type="text"
                              style={{width:"30px"}}
                              value={this.state.printLocationFromD}
                              onChange={this.handleInputChange} />
                          </label><br/>
                          <label>
                            To Location:<br/>
                            <input
                              name="printLocationToA"
                              type="text"
                              style={{width:"25px"}}
                              value={this.state.printLocationToA}
                              onChange={this.handleInputChange} />
                              <input
                              name="printLocationToB"
                              type="text"
                              style={{width:"20px"}}
                              value={this.state.printLocationFromB}
                              onChange={this.handleInputChange} />-
                              <input
                              name="printLocationToC"
                              type="text"
                              style={{width:"25px"}}
                              value={this.state.printLocationToC}
                              onChange={this.handleInputChange} />-
                              <input
                              name="printLocationToD"
                              type="text"
                              style={{width:"30px"}}
                              value={this.state.printLocationToD}
                              onChange={this.handleInputChange} />
                          </label><br/>
                          <button className="btn btn-info btn-sm" onClick={(e) => this.openLabelModal()}>Print</button>
                          
                  </div>
                </div>
             </div>
              </div>
              <div id="modalMain">
              <Modal
                isOpen={this.state.labelModalIsOpen}
                onAfterOpen={this.afterOpenLabelModal}                            
                style={printModalStyle}
                contentLabel="Print Label"
                >                   
                <div className="frame">
                <div className="scroll"> 
                <div className="container-fluid">               
                    <form onSubmit={this.handleLabelPrint}>
                        <div className="row">
                            <div className="col-md-12">            
                            <h3>Print Rack Tag Labels</h3>
                            <div className="form-group" >
                                <label htmlFor="printQty" className="form-label">Label Qty: </label>&nbsp; 
                                <input
                                className="form-input"
                                id="printQty"
                                name="printQty"
                                type="text"
                                value={this.state.printQty}
                                onChange={this.handleInputChange}
                                placeholder="" 
                                style={{width:"200px"}}
                                />
                                
                            </div>
                            <div className="modalMessage">{this.state.modalMessage}</div>
                            <div>
                                <ReactToPrint
                                trigger={() => <Link to="#"><button type="button" className="btn btn-info btn-sm">Print</button></Link>}
                                content={() => this.componentRef}
                                /> &nbsp;&nbsp;
                                <button className="btn btn-info btn-sm" onClick={this.closeLabelModal}>Cancel</button>
                            </div> 
                            <RackPrintLabel ref={el => (this.componentRef = el)} 
                            printQty={this.state.printQty} 
                            printLocationFromA={this.state.printLocationFromA} 
                            printLocationFromB={this.state.printLocationFromB} 
                            printLocationFromC={this.state.printLocationFromC} 
                            printLocationFromD={this.state.printLocationFromD} 
                            printLocationToA={this.state.printLocationToA} 
                            printLocationToB={this.state.printLocationToB} 
                            printLocationToC={this.state.printLocationToC} 
                            printLocationToD={this.state.printLocationToD} 
                            {...this.props} />
                                                                
                            </div>
                            
                        </div>
                        
                    </form> 

                    </div>                          
                    </div>
                    </div>
                </Modal>
              </div>
        </div>
      </div>    
</div>
</div>

    );
  }
}

export default RackPrint;