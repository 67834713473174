// Header.js
import React, {Component} from 'react'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import matchSorter from 'match-sorter'
import ReactTable from 'react-table'
import Modal from 'react-modal';
import 'react-table/react-table.css'
import 'react-dropdown/style.css'
import moment from 'moment';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '300px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};
class CustomerOrders extends Component {

    constructor(props) {
        super(props);              

            this.state = {
            attachments:[],
            orders: [],            
            orderitems: [],
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),    
            customerOrderEntry: typeof(localStorage.getItem('customerOrderEntry')) === 'undefined' ? 0 : localStorage.getItem('customerOrderEntry'),
            or_id: '',
            del_or_id: '',
            disableAddButton:true,
            filesModalIsOpen: false,
            importstatus: '',
            loading: false,
            userToken: localStorage.getItem('userToken')
        };      

        this.getAttachments = this.getAttachments.bind(this);
             
        this.openFilesModal = this.openFilesModal.bind(this);
        this.closeFilesModal = this.closeFilesModal.bind(this);
       
    }    

	  componentDidMount() {
      this.handleClientChange(this.state.cl_id);       
    }

    //Files
    openFilesModal(e) {
      let _or_id = e.original.or_id;
      this.getAttachments(_or_id);
      this.setState({filesModalIsOpen: true});
    }

    afterOpenFilesModal() {
      // references are now sync'd and can be accessed.
      // this.subtitle.style.color = '#f00';
    }

    closeFilesModal() {
      this.setState({filesModalIsOpen: false});
    }

    getAttachments(or_id) {

      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/files/order/'+or_id, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(attachments => this.setState({ attachments }));
    
    }
    
    getOrders = (url) => {
      this.setState({loading:true});
        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(orders => this.setState({ orders })).then(this.setState({loading:false}));       
    }    

    handleClientChange = (cl_id) => {
        
        if (cl_id === 0) {
            this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/orders');
            this.setState({disableAddButton:true});
        } else {
            this.getOrders(process.env.REACT_APP_NODE_ROOT_URL+'/orders/client/'+cl_id);
            this.setState({disableAddButton:false});
        }        
    }
        
    render(){
                
        const { cl_id, orders } = this.state;
        
        return (
        <div id="main">
        <div className="page-wrapper">
            <div className="row page-titles">  
             <div className="col-md-1 align-self-center">
                    <h3 className="text-primary">Orders</h3>  
                </div>
                <div className="col-md-11 align-self-center">
                    <Link to={`/customer-orderentry/${cl_id}`}><button type="button" className="btn btn-info btn-sm" disabled={this.state.customerOrderEntry===0}>Add Order</button></Link>&nbsp;
                </div>
            </div>

            <div className="container-fluid">               

                <div className="row">					
                    <div className="col-lg-12">
                        <div className="card">                          
                            <div className="card-body" name="card-order-list">
                                <div className="table-responsive">
                                    <ReactTable
                                      data={orders}
                                      filterable  
                                      width="80%" 
                                      noDataText='Looking for orders...'
                                      loading={this.state.loading}                                       
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value} 
                                      columns={[
                                        {                                          
                                          columns: [                                            
                                            {
                                              Header: "cl_id",
                                              accessor: "cl_id",
                                              show: false
                                            },
                                            {
                                              Header: "Details",
                                              accessor: "or_id",
                                              width: 50,
                                              show: true,
                                              Cell: props => (
                                                <div style={{ textAlign: "center" }}><button onClick={() => {  window.open("/customer-order/"+props.original.cl_id+"/"+props.original.or_id, "_blank"); } } className="btn btn-info btn-sm" title="view order detail">view</button></div>
                                              )
                                            },                                            
                                            {
                                              Header: "Order Key",
                                              accessor: "or_id",
                                              width: 75,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["or_id"] }),
                                              filterAll: true,
                                              show: false,
                                              Cell: row => (
                                                <div style={{ textAlign: "center"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Client",
                                              accessor: "cl_name",
                                              width: 75,
                                              show: false,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center"}}>{row.value}</div>
                                              )
                                            },                                            
                                            {
                                              Header: "Order ID",
                                              accessor: "or_actual_order_id",
                                              width: 75,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["or_actual_order_id"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "center"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Order Date",
                                              accessor: "or_date",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            },
                                            {
                                              Header: "Ship By Date",
                                              accessor: "or_ship_by",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).isValid() ? moment(row.value).format("MM/DD/YYYY hh:mm:ss a") : ""}</div>
                                              )
                                            },                                      
                                            {
                                              Header: "First Name",
                                              accessor: "or_ship_fname",
                                              width: 100,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["or_ship_fname"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "left"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Last Name",
                                              accessor: "or_ship_lname",
                                              width: 100,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["or_ship_lname"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "left"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Company",
                                              accessor: "or_ship_company",
                                              width: 100,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["or_ship_company"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "left"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Attention",
                                              accessor: "or_ship_attention",
                                              width: 100,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["or_ship_attention"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "left"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Order Type",
                                              accessor: "or_type",
                                              width: 100,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["or_type"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "left"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Other",
                                              accessor: "or_other",
                                              width: 100,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["or_other"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "left", color: "red"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Ship Method",
                                              accessor: "or_ship_method",
                                              width: 125,
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["or_ship_method"] }),
                                              filterAll: true,
                                              Cell: row => (
                                                <div style={{ textAlign: "left"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "BOL",
                                              accessor: "or_bol",
                                              width: 50,
                                              show: true,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center"}}>{row.value==1?"Yes":"No"}</div>
                                              )
                                            }, 
                                            {
                                              Header: "Status",
                                              accessor: "or_status",
                                              width:150,
                                              filterable: true,                                         
                                              filterMethod: (filter, row) => {
                                                if (filter.value === "all") {
                                                  return true;
                                                }
                                                if (filter.value === "New") {
                                                  return row[filter.id] === "New";
                                                }
                                                if (filter.value === "In Process") {
                                                  return row[filter.id] === "In Process";
                                                }
                                                if (filter.value === "Canceled") {
                                                  return row[filter.id] === "Canceled";
                                                }
                                                if (filter.value === "On Hold") {
                                                  return row[filter.id] === "On Hold";
                                                }
                                                if (filter.value === "Ready for Pick Up") {
                                                  return row[filter.id] === "Ready for Pick Up";
                                                }
                                                if (filter.value === "Shipped") {
                                                  return row[filter.id] === "Shipped";
                                                }                                            
                                              },
                                              Filter: ({ filter, onChange }) => (
                                                <select
                                                  onChange={event => onChange(event.target.value)}
                                                  style={{ width: "150" }}
                                                  value={filter ? filter.value : "all"}
                                                >
                                                  <option value="all">Show All</option>
                                                  <option value="New">New</option>
                                                  <option value="In Process">In Process</option>
                                                  <option value="Canceled">Canceled</option>
                                                  <option value="On Hold">On Hold</option>
                                                  <option value="Ready for Pick Up">Ready for Pick Up</option>
                                                  <option value="Shipped">Shipped</option>
                                                </select>
                                              )
                                            }                  
                                          ]
                                        }
                                      ]}
                                      defaultSorted={[
                                        {
                                          id: "or_id",
                                          desc: true
                                        }
                                      ]}
                                      defaultPageSize={30}
                                      className="-striped -highlight"
                                      getTdProps={(state, rowInfo, column, instance) => {
                                        return {
                                          onClick: (e) => {
                                            
                                            if (rowInfo) {
                                           // window.open("/order/"+rowInfo.row.cl_id+"/"+rowInfo.row.or_id, "_new");
                                            //window.location.href = "/order/"+rowInfo.row.cl_id+"/"+rowInfo.row.or_id;
                                            //var _url = "/order/"+rowInfo.row.cl_id+"/"+rowInfo.row.or_id;
                                            //this.handleOriginal(_url);
                                            }                                             
                                          }
                                        };
                                      }}
                                    />

                                   
                                </div>
                            </div>                         

                        </div>
                    </div>
                </div>               
                <div className="row">   
				    <div className="col-lg-4">
                       
                    </div>
                </div>


            </div>
        <div id="modalMain">
          <Modal
            isOpen={this.state.filesModalIsOpen}                                      
            style={customStyles}
            contentLabel="View Order Files"
          >                   
         
            <div className="container-fluid">               
             
                  <div className="row">
                      <div className="col-md-12">            

                        <div className="form-group" >
                        <u><strong>Attachments</strong></u><br/>
                          <ul>
                          {this.state.attachments.map((attachment,idx) =>
                            <li key={idx}><a href={"https://bw-admin-files.s3.amazonaws.com/".concat(attachment)} target="_new">{attachment.substr(attachment.lastIndexOf('/') + 1)}</a></li>
                            )}
                          </ul>                          
                        </div>
                        <div className="modalMessage">{this.state.modalMessage}</div>
                        <div>
                          <button onClick={this.closeFilesModal} className="btn btn-info btn-sm">Close</button>
                        </div>               
                        
                      </div>

                      
                  </div>
                 
         
             </div>                          
            
          </Modal>
        </div>
    
        </div>
</div>
        
                )
    }
}
export default CustomerOrders;