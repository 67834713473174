import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import LeftBar from '../Site/LeftBar';

import './style.css';

class FindBol extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }


  constructor(props) {
    super(props);
    
    const { location } = this.props
    console.log('path: '+ location.pathname )

    var pathArray = location.pathname.split('/');
    //pathArray[1] = order
    //pathArray[2] = client id
    //pathArray[3] = order id
    
    console.log('arr: '+ pathArray)
    this.state = {
        cl_id: pathArray[3],
        bolsearch:'',
        userToken: localStorage.getItem('userToken')
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getBol = this.getBol.bind(this);
    this.goBol = this.goBol.bind(this);
}

handleClientChange = (cl_id) => {
    this.setState({cl_id: cl_id});
    localStorage.setItem('headerClientId', cl_id);    
}

getBol = (bol) => {
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/bol/'+bol.trim(),
    {
        method: 'GET',      
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }

        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
    
    }).then(res => res.json())
    .then(order => this.setState({ order })).then(this.goBol);
  }

  goBol = () => {

    const order = this.state.order;   

    if (order.length > 0) {
      console.log('BOL search: ' + order);
      var _url = '/order/'+order[0].sh_cl_id+'/'+order[0].sh_or_id;
      this.handleClientChange(order[0].sh_cl_id);
      //console.log('_url: ' + _url);
      window.location.assign(_url);
    } else {
      alert('Order Number not found. Try again.');
    }    
  }

  handleChange(event){
    //set upc value searched for
    this.setState({bolsearch: event.target.value});
  }

  handleSubmit(event){
    event.preventDefault();    
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
               
        var bolNumber = this.state.bolsearch;
        this.getBol(bolNumber);
                
    }
  }

  render() {
    return (
        <div>
            <LeftBar />
            <div id="main">
                <div className="page-wrapper">
                    <div className="row page-titles">
                        <div className="col-md-12 align-self-center">
                            <h3 className="text-primary">Find Order by BOL</h3> 
                        </div>           
                    </div>
                    <div className="container-fluid">         
                        <div className="card">
                            <div className="card-body" name="card-order-list">      
                                <div className="row">  
                                    <div className="col-md-12">
                                        <p>Enter a BOL Number for any client and hit "Enter" to go to the linked Order.</p>
                                            <form id="formBolSearch" onSubmit={this.handleSubmit}>
                                                <input type="text" id="bolsearch" ref="bolsearch" name="bolsearch" className="form-control" onKeyPress={this.handleKeyPress} value={this.state.bolsearch} onChange={this.handleChange} placeholder="Place cursor here to find order using BOL "/>
                                            </form> 
                                    </div>
                                </div>                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
  }
}

export default withRouter(FindBol);