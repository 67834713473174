import React, {Component} from 'react';
import { CSVLink } from "react-csv";
import ReactTable from "react-table";
import Modal from 'react-modal';
import 'react-table/react-table.css'
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '600px'
  }
};


const cancelButton = {
  content : {
    float                 : 'right'  
  }
};

class ReturnList extends Component {
 constructor(props) {
        super(props);              

            this.state = {
            returns: [],            
            recentitems: [],
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),            
            or_id: '',
            rt_rma_int: '',
            rt_order_number: '',
            rt_tracking_number: '',
            rt_date_open: moment().format("YYYY-MM-DD HH:mm:ss"),
            rt_contact_email: '',
            modalMessage: '',
            expectedDate: new Date(),            
            disableAddButton:true,
            modalIsOpen:false,
            sortOptions: [{ id: 'rt_id', desc: true }],            
            userToken: localStorage.getItem('userToken')
        };
       
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }    

    componentDidMount() {
      this.handleClientChange(this.state.cl_id);           
      Modal.setAppElement('#main');
    }   

    openModal() {
      this.setState({modalIsOpen: true});
      if (this.state.cl_id =='') {
        this.setState({modalMessage: 'Please select a client first. '});
      } 

    }

    afterOpenModal() {
      // references are now sync'd and can be accessed.
      // this.subtitle.style.color = '#f00';
    }

    closeModal() {
      this.setState({modalIsOpen: false});
    }

    getRecentItems = (url) => {
      
      var productJSON = JSON.stringify({
        cl_id: this.state.cl_id,
        pr_id: 0
      });

      console.log('logging inventory json...');
      console.log(productJSON);      
      
      fetch(url, {
            method: 'post',        
            body: productJSON,      
            headers: {            
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken
        }             
      }).then(res => res.json())
        .then(recentitems => this.setState({ recentitems }));       
      
    }  

    getReturns = (url) => {
        fetch(url, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(returns => this.setState({ returns }));        
    }

    handleChange (event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.setState({
       [name]: value
      });
    }

    handleClientChange = (cl_id) => {     
        
        this.getReturns(process.env.REACT_APP_NODE_ROOT_URL+'/returns/'+cl_id); 
        this.setState({disableAddButton:false});
       
    }

    handleDateChange = (date) => {
      console.log('exp-date: ' + date);
    }

    handleSubmit(event) {
      //alert('Client name: ' + this.state.cl_name);
      event.preventDefault();

        var obj = { cl_id: this.state.cl_id, rt_rma_int: this.state.rt_rma_int, rt_tracking_number: this.state.rt_tracking_number, rt_order_number: this.state.rt_order_number, rt_status: 'Open', rt_contact_email: this.state.rt_contact_email, rt_date_open: this.state.rt_date_open };
        var myJSON = JSON.stringify(obj);

        console.log(myJSON);

        fetch(process.env.REACT_APP_NODE_ROOT_URL+'/returns/new/'+this.state.cl_id, {
            method: 'post',        
            body: myJSON,      
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {  
            return response.json();
        }).then(function(data) { 
            window.location.reload();            
        }).catch(function(error) {
            alert(error);        
        });

    }
 
    render(){
        
        const { returns, recentitems } = this.state;

        const returnTypes = [
          'Container', 'Return', 'Pallet', 'Parcel' 
        ]

        const headersrecent = [
          { label: "Client ID", key: "lg_cl_id" },
          { label: "Product ID", key: "pr_id" },
          { label: "Return Number", key: "rc_number" },
          { label: "SKU", key: "pr_sku" },
          { label: "Desc", key: "pr_desc" },
          { label: "UPC", key: "pr_upc" },
          { label: "Scan Count", key: "lg_scan_count" },
          { label: "Date Last Received", key: "lg_date_updated" }  
        ];
        
        return (
        <div id="main">
        <div className="page-wrapper">
               <div className="row page-titles">
                <div className="col-md-1">
                    <h3 className="text-primary">Returns</h3>                    
                </div>
                <div className="col-md-11 align-self-center">
                   <button type="button" className="btn btn-info btn-sm" onClick={this.openModal}>Add Return</button>
                      
                </div>
            </div>

            <div id="modalMain" className="col-lg-4 align-self-left">
                       
                          <Modal
                            isOpen={this.state.modalIsOpen}
                            onAfterOpen={this.afterOpenModal}
                            onRequestClose={this.handleSubmit}
                            style={customStyles}
                            contentLabel="New Return"
                          >                   
                         
                            <div className="container-fluid">               
                              <form onSubmit={this.handleSubmit}>
                              <h2>Add New Return</h2>
                                  <div className="row">
                                    <div className="col-sm-4 right">                                      
                                      * RMA Number:
                                    </div>
                                    <div className="col-sm-8">
                                     
                                      <input
                                            className="form-input"
                                            id="rt_rma_int"
                                            name="rt_rma_int"
                                            type="text"
                                            value={this.state.rt_rma_int}
                                            onChange={this.handleChange}
                                            placeholder="" 
                                          />
                                    </div>                                    
                                  </div>
                                  
                                  <div className="row">
                                    <div className="col-sm-4 right">                                      
                                      Tracking Number: 
                                    </div>
                                    <div className="col-sm-8">                                    
                                      <input
                                            className="form-input"
                                            id="rt_tracking_number"
                                            name="rt_tracking_number"
                                            type="text"
                                            value={this.state.rt_tracking_number}
                                            onChange={this.handleChange}
                                            placeholder="" 
                                          />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-4 right">                                      
                                      Order Number:
                                    </div>
                                    <div className="col-sm-8">                                    
                                      <input
                                            className="form-input"
                                            id="rt_order_number"
                                            name="rt_order_number"
                                            type="text"
                                            value={this.state.rt_order_number}
                                            onChange={this.handleChange}
                                            placeholder="" 
                                          />
                                    </div>
                                  </div>                      

                               <div className="modalMessage">{this.state.modalMessage}</div>
                                <div className="right">
                                  <button type="submit">Add</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closeModal} style={cancelButton}>Cancel</button>
                                </div>               
                                
                                 
                              </form> 

                             </div>                          
                            
                          </Modal>
                  </div>


            <div className="container-fluid">               

              <div className="row">
                    <div className="col-lg-12">
                       
                            <div className="card-body" name="card-order-list">                             
                                <div className="table-responsive">
                                  <ReactTable

                                      data={returns}
                                      sorted={this.state.sortOptions}
                                      onSortedChange={val => {
                                      this.setState({ sortOptions: val }) }}
                                      filterable
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}          
                                      columns={[
                                        {                                          
                                          columns: [                                            
                                            {
                                              Header: "ID",
                                              accessor: "rt_id",
                                              show: false,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "cl_id",
                                              accessor: "cl_id",
                                              show: false
                                            },                                                                                                                                                        
                                            {
                                              Header: "RMA Number",
                                              accessor: "rt_rma_int",
                                              width: 200,  
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)          
                                            },    
                                            {
                                              Header: "Order Number",
                                              accessor: "or_ss_order_id",
                                              width: 100,  
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)          
                                            },                                       
                                            {
                                              Header: "Date Opened",
                                              accessor: "rt_date_open",
                                              width: 200,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            },
                                            {
                                              Header: "Date Closed",
                                              accessor: "rt_date_closed",
                                              width: 200,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{moment(row.value).format("MM-DD-YYYY hh:mm:ss a")}</div>
                                              )
                                            },
                                            {
                                              Header: "Status",
                                              accessor: "rt_status",
                                              width: 200,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            },
                                            {
                                              Header: "Tracking",
                                              accessor: "rt_tracking",
                                              width: 200,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            } 
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={20}                                      
                                      className="-striped -highlight"
                                      getTdProps={(state, rowInfo, column, instance) => {
                                        return {                                          
                                          onClick: (e, handleOriginal) => {
                                            if (rowInfo) {
                                              console.log('r: '+rowInfo.row);
                                              window.location.href = "/return/"+rowInfo.row.cl_id+"/"+rowInfo.row.rt_id;
                                            }

                                            // IMPORTANT! React-Table uses onClick internally to trigger
                                            // events like expanding SubComponents and pivots.
                                            // By default a custom 'onClick' handler will override this functionality.
                                            // If you want to fire the original onClick handler, call the
                                            // 'handleOriginal' function.
                                            if (handleOriginal) {
                                              handleOriginal();
                                            }
                                          }
                                        };
                                      }}
                                    />
                               
                                </div>
                            </div>  
                    </div>
                </div>               

            </div>
        </div>
        </div>

                )
    }
}
export default ReturnList;