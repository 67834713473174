 // LeftBar.js
 import React, {Component} from 'react';

 export default class LeftBar extends Component {
    constructor(props) {
        super(props);
        
          this.state = {                    
            us_staff: localStorage.getItem('us_staff'),
            us_admin: localStorage.getItem('us_admin'),                              
            cl_id: localStorage.getItem('headerClientId')
            
          };
    }
     render(){

        const link2 = '/mobile-order/'+this.state.cl_id;
        const link3 = '/scan-order/'+this.state.cl_id;

         return (
        
         <div className="left-sidebar" >
            
             <div className="scroll-sidebar">
             <nav className="sidebar-nav">
                    <ul id="sidebarnav">                        
                        <li className="nav-devider"></li>
                        <li className="nav-label">Products</li>                        
                        <li> <a href="/products" aria-expanded="false">&nbsp;Products</a> </li>
                        <li> <a href="/product-bins" aria-expanded="false">&nbsp;Locations</a> </li> 
                        {this.state.us_staff!=='1' ? (  
                        <li className="nav-label">Incoming</li>  
                         ) : (<li></li>) } 
                        {this.state.us_staff!=='1' ? (  
                        <li> <a href="/receivers" aria-expanded="false">&nbsp;Receiving</a> </li>                       
                        ) : (<li></li>) }
                         {this.state.us_staff!=='1' ? (  
                        <li> <a href="/returns" aria-expanded="false">&nbsp;Returns</a> </li>                       
                        ) : (<li></li>) }
                                           
                        <li className="nav-label">Outgoing</li>
                        <li> <a href="/orders" aria-expanded="false">&nbsp;Orders</a> </li>
                        <li> <a href="/orders-amazon" aria-expanded="false">&nbsp;Expedite</a> </li>                        
                        {this.state.us_staff!=='1' ? (  
                        <li> <a href="/pickups" aria-expanded="false">&nbsp;Pickups</a> </li>                        
                        ) : (<li></li>) }                       

                        {this.state.us_staff!=='1' ? (   
                        <li className="nav-label">Admin</li>
                        ) : (<li></li>) }
                        {this.state.us_staff!=='1' ? ( 
                        <li> <a href="/clients" aria-expanded="false">&nbsp;Clients</a> </li>
                        ) : (<li></li>) }
                        {this.state.us_staff!=='1' ? ( 
                        <li> <a href={link2} aria-expanded="false">&nbsp;Mobile</a> </li>                           
                        ) : (<li></li>) }
                        {this.state.us_staff!=='1' ? ( 
                        <li> <a href={link3} aria-expanded="false">&nbsp;Scanner</a> </li>                           
                        ) : (<li></li>) }
                        {this.state.us_staff!=='1' ? (                                                               
                        <li> <a href="/users" aria-expanded="false">&nbsp;Users</a> </li>  
                        ) : (<li></li>) }                      
                      
                    </ul>
                {this.state.us_staff!=='1' ? ( 
                <div className="panel-heading">
                    <h4 className="panel-title">
                    <a data-toggle="collapse" href="#collapse5">Reports</a>
                    </h4>
                </div>
                ) : (<div></div>) }
                {this.state.us_staff!=='1' ? ( 
                <div id="collapse5" className="panel-collapse collapse">
                    <ul className="list-group">
                        <li> <a href="/reports-fulfillment" aria-expanded="false">&nbsp;Fulfillment</a> </li>
                        <li> <a href="/reports-inventory-log" aria-expanded="false">&nbsp;Inventory</a> </li>
                        <li> <a href="/reports-billing" aria-expanded="false">&nbsp;Billing</a> </li>
                        <li> <a href="/reports-item-billing" aria-expanded="false">&nbsp;Item Billing</a> </li>   
                        <li className="nav-label">Other SKU</li>
                        <li> <a href="/reports-other-avail" aria-expanded="false">&nbsp;Avail </a> </li> 
                        <li> <a href="/reports-other-status" aria-expanded="false">&nbsp;Shipped </a> </li>  
                    </ul>                   
                </div>
                ) : (<div></div>) }

               </nav>
             </div>
             
         </div>
        
                 )
     }
 }