// Header.js
import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import ReactTable from "react-table";
import Modal from 'react-modal';
import Webcam from "react-webcam";
import 'react-html5-camera-photo/build/css/index.css';
import 'react-table/react-table.css'
import 'react-dropdown/style.css';
import moment from 'moment';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '600px',
    color                 : 'black'
  }
};

const returnStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '800px',
    color                 : 'black'
  }
};

const cancelButton = {
  content : {
    float                 : 'right'  
  }
};

class OrderContent extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            upc_search: '',            
            order: [], 
            orderitems: [],
            ordernotes: [],
            orderotherskus: [],
            ordershipments: [],
            ordertracking: [],
            orderweight:[],
            returnitems:[],
            orderreturns: [],
            cl_id: this.props.clientId,
            or_id: this.props.orderId,
            pr_id: '',
            lastOtId:0,
            scans: 0,
            previous_scan:'',
            or_status: '',
            or_note: '',
            or_hours: 0,
            packingModalIsOpen:false,
            filesModalIsOpen: false,
            photoModalIsOpen: false,
            editModalIsOpen:false,            
            statusModalIsOpen: false,
            noteModalIsOpen: false, 
            returnModalIsOpen: false,       
            shipModalIsOpen: false,
            carrierModalIsOpen: false,
            bolModalIsOpen: false,
            carrierModalMessage:'',
            packingModalMessage:'',
            filesModalMessage:'',
            photoModalMessage:'',            
            editModalMessage:'',
            statusModalMessage:'',
            noteModalMessage:'',
            returnModalMessage: '',
            bolModalMessage: '',
            attachments: [],
            file: null,
            cl_ship_methods: [{name:''}],
            or_ship_method: '',
            sh_cost: 0,
            sh_tracking: '',
            sh_notes: '',
            sh_bol:'',
            sh_pallets: 0,
            rma_number: '',
            rma_tracking: '',
            /*oi_rma_reason: '',
            oi_rma_number: '',
            oi_rma_complete: 0,
            oi_rma_details: '',
            */
            all_items_scanned: false,
            disableShip: true,
            disableDelete: true,            
            disableDeleteItem: true,
            trojanSerial:'',
            userToken: localStorage.getItem('userToken'),
            us_id: localStorage.getItem('usId'),
            lastScanned: '',
            update_message: '',
            update_message_pick: '',
            update_message_pack: '',
            ordersearch: '',
            checkmisshipped: '',
            checkexpedited: '',
            checkpalletized: ''        
        };
               
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitStatus = this.handleSubmitStatus.bind(this);
        this.handleAddNote = this.handleAddNote.bind(this);
        //this.handleAddCarrier = this.handleAddCarrier.bind(this);
        //this.findUPC = this.findUPC.bind(this);
        this.handleDeleteOrder = this.handleDeleteOrder.bind(this);
        this.handleDeleteOrderItem = this.handleDeleteOrderItem.bind(this);
        this.handleDeleteOrderItems = this.handleDeleteOrderItems.bind(this);
        this.handleDeleteOrderItemScans = this.handleDeleteOrderItemScans.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleAttach = this.handleAttach.bind(this);
        this.handleAttachBOL = this.handleAttachBOL.bind(this);
        //this.handlePhoto = this.handlePhoto.bind(this);
        //this.checkOrderNotes = this.checkOrderNotes.bind(this);
        //this.handleShipOrder = this.handleShipOrder.bind(this);
        //this.handleShipEmail = this.handleShipEmail.bind(this);
        //this.handleCheckExpedited = this.handleCheckExpedited.bind(this);
        //this.handleCheckMissed = this.handleCheckMissed.bind(this);
   
        this.handleNoteInputChange = this.handleNoteInputChange.bind(this);
        this.handleRmaQtyInputChange = this.handleRmaQtyInputChange.bind(this);
        this.handleExpConditionInputChange = this.handleExpConditionInputChange.bind(this);
        this.handleBrandActionInputChange = this.handleBrandActionInputChange.bind(this);

        this.handleDeleteFile = this.handleDeleteFile.bind(this);
        this.getAttachments = this.getAttachments.bind(this);
        this.createContact = this.createContact.bind(this);
        //this.handleSaveShipment = this.handleSaveShipment.bind(this);
        //this.findTrojanSerial = this.findTrojanSerial.bind(this);
        //this.scanOther = this.scanOther.bind(this);
        this.getRma = this.getRma.bind(this);
        this.goRma = this.goRma.bind(this);
            
        this.openPackingModal = this.openPackingModal.bind(this);
        this.afterOpenPackingModal = this.afterOpenPackingModal.bind(this);
        this.closePackingModal = this.closePackingModal.bind(this);
       
        this.openBolModal = this.openBolModal.bind(this);
        this.afterOpenBolModal = this.afterOpenBolModal.bind(this);
        this.closeBolModal = this.closeBolModal.bind(this);

        this.openFilesModal = this.openFilesModal.bind(this);
        this.afterOpenFilesModal = this.afterOpenFilesModal.bind(this);
        this.closeFilesModal = this.closeFilesModal.bind(this);

        this.openStatusModal = this.openStatusModal.bind(this);
        this.afterOpenStatusModal = this.afterOpenStatusModal.bind(this);
        this.closeStatusModal = this.closeStatusModal.bind(this);
       
        this.openReturnModal = this.openReturnModal.bind(this);
        this.afterOpenReturnModal = this.afterOpenReturnModal.bind(this);
        this.closeReturnModal = this.closeReturnModal.bind(this);

        this.playGood = this.playGood.bind(this);
        this.playBad = this.playBad.bind(this);
        this.playComplete = this.playComplete.bind(this);

        this.checkRowTotals = this.checkRowTotals.bind(this);
    
        this.getOrder = this.getOrder.bind(this);
        this.getOrderItems = this.getOrderItems.bind(this);
        this.getOrderWeight = this.getOrderWeight.bind(this);

    }

    playGood() {
      const audioEl = document.getElementsByClassName("good-audio-element")[0]
      audioEl.play()
    }
  
    playBad() {
      const audioEl = document.getElementsByClassName("bad-audio-element")[0]
      audioEl.play()
    }
  
    playComplete() {
      const audioEl = document.getElementsByClassName("complete-audio-element")[0]
      audioEl.play()
    }

    setRef = webcam => {
      this.webcam = webcam;
    };

    getRma = () => {

      var rma = this.state.rma_number;
     
      if (rma.indexOf('/') > -1) {
        rma = rma.replaceAll('/','%2F');       
      }
  
      if (rma !== '')
      {        
        var _url = process.env.REACT_APP_NODE_ROOT_URL+'/returns/s/'+this.state.cl_id+'/'+rma;
        
        fetch(_url, {
            method: 'GET',
            headers: {
            'Accept': 'application/json',
            'bwToken': this.state.userToken
            }      
        }).then(res => res.json())
        .then(rma => this.setState({ rma }))
        .then(this.goRma);
  
      }
    }
  
    goRma = () => {
  
      const rma = this.state.rma;   
  
      if (rma.length > 0) {
        if (rma[0].cl_id === this.state.cl_id) {
          alert('RMA Number in use. Please enter a unique RMA Number.');
          this.setState({rma_number:''});
          return false;
        } else {
          return true;
        }
        
      } else {
        return true;
      }
  
      
    }
   
    
    getOrder() {
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/o/'+this.state.or_id,
      {
          method: 'GET',      
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }

          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(res => res.json())
      .then(order => this.setState({ order }));
    }

    getOrderReturns() {
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/returns/order/'+this.state.or_id,
      {
          method: 'GET',      
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }

          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(res => res.json())
      .then(orderreturns => this.setState({ orderreturns }));
    }

    getOrderWeight() {
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/weight/'+this.props.orderId,
      {
          method: 'GET',      
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }

          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(res => res.json())
      .then(orderweight => this.setState({ orderweight }));
    }

    getOrderItems() {
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/'+this.state.or_id,
      {
          method: 'GET',      
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }

          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(res => res.json())
      .then(orderitems => this.setState({ orderitems })).then(this.checkRowTotals);
    }

    getOrderTracking() {
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/tracking/'+this.props.orderId,
      {
          method: 'GET',      
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }

          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(res => res.json())
      .then(ordertracking => this.setState({ ordertracking }));
    }

    getOrderOtherSkus() {
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/other/'+this.props.orderId,
      {
          method: 'GET',      
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }

          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(res => res.json())
      .then(orderotherskus => this.setState({ orderotherskus }));
    }

    getOrderShipments() {
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/shipments/'+this.props.orderId,
      {
          method: 'GET',      
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }
      
      }).then(res => res.json())
      .then(ordershipments => this.setState({ ordershipments }));
    }

    //LIFECYCLE METHODS
      //     
    componentDidUpdate(prevProps, prevState) {
            
    }

    componentDidMount() {

      this.getOrder();
      this.getOrderItems();
      //this.getOrderNotes();
      this.getOrderOtherSkus();
      this.getOrderShipments();
      this.getOrderTracking();
      this.getAttachments();
      this.getOrderWeight();
      this.getOrderReturns();
      
      Modal.setAppElement('#main');

      

    }

    createContact = () => {
      //alert('create contact');

      let objContact = {
        cl_id: this.state.cl_id,
        cn_firstname: this.state.order[0].or_ship_fname, //1
        cn_lastname: this.state.order[0].or_ship_lname, //2
        cn_company: this.state.order[0].or_ship_company, //3
        cn_attention: this.state.order[0].or_ship_attention, //4
        cn_ship_address: this.state.order[0].or_ship_address, //5
        cn_ship_address1: this.state.order[0].or_ship_address1, //6            
        cn_city: this.state.order[0].or_city, //7
        cn_state: this.state.order[0].or_state, //8
        cn_zip: this.state.order[0].or_zip, //9
        cn_country: this.state.order[0].or_country, //10
        cn_phone: this.state.order[0].or_ship_phone, //11               
        cn_email: this.state.order[0].or_ship_email, //12            
        cn_other: this.state.order[0].or_other
      }; 

      var contactJSON = JSON.stringify(objContact);

      console.log('logging contact json...');
      console.log(contactJSON);

      //return true;
    
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/contacts/create/'+this.state.cl_id, {
            method: 'post',        
            body: contactJSON,      
            headers: {            
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
      }).then(function(response) {  
          return response.json();
      }).then(alert('Contact created successfully.'))
        .catch(function(error) {
          console.log(error);                   
      });

    }

     tryParse(input) {
      try {        
          return JSON.parse(input);
      } catch (e) {          
          return undefined;
      }
    }
    
    
    //PACKING SLIP
    openPackingModal() {
      this.setState({packingModalIsOpen: true});
    }

    afterOpenPackingModal() {
      // references are now sync'd and can be accessed.
      // this.subtitle.style.color = '#f00';
    }

    closePackingModal() {
      this.setState({packingModalIsOpen: false});
    } 

    //BOL
    openBolModal() {
      this.setState({bolModalIsOpen: true});
    }

    afterOpenBolModal() {
      // references are now sync'd and can be accessed.
      // this.subtitle.style.color = '#f00';
    }

    closeBolModal() {
      this.setState({bolModalIsOpen: false});
    }

    //Files
    openFilesModal() {
      this.setState({filesModalIsOpen: true});
    }

    afterOpenFilesModal() {
      // references are now sync'd and can be accessed.
      // this.subtitle.style.color = '#f00';
    }

    closeFilesModal() {
      this.setState({filesModalIsOpen: false});
    }
   
    //Status
    openStatusModal() {
      this.setState({statusModalIsOpen: true});
    }

    afterOpenStatusModal() {
      // references are now sync'd and can be accessed.
      // this.subtitle.style.color = '#f00';
    }

    closeStatusModal() {
      this.setState({statusModalIsOpen: false});
    }

    //RETURN
    openReturnModal() {
      this.setState({returnModalIsOpen: true});
      this.setState({returnitems: this.state.orderitems});

    }
    
    afterOpenReturnModal() {
      
    }

    closeReturnModal() {
      this.setState({returnModalIsOpen: false});
    }

    // METHODS 

    checkRowTotals() {   
        
      var item_total = 0;
      var scan_total = 0;
      var pick_total = 0;
      var pack_total = 0;
      var scan_date = '';
      var pick_date = '';
      var pack_date = '';


      this.state.orderitems.map((orderitem) =>
        {
          item_total+=orderitem.oi_qty;
          scan_total+=orderitem.lg_scan_count;
          pick_total+=orderitem.lg_pick_count;
          pack_total+=orderitem.lg_pack_count;
          scan_date = moment(orderitem.lg_scan_date).local().format('MM/DD/YYYY hh:mm:ss A');          
          pick_date = moment(orderitem.lg_pick_date).local().format('MM/DD/YYYY hh:mm:ss A');
          pack_date = moment(orderitem.lg_pack_date).local().format('MM/DD/YYYY hh:mm:ss A');

        }
            
      );
      //console.log('item_total: ' + item_total);
      //console.log('scan_total: ' + scan_total);

      if (item_total===pick_total && pick_total !==0 ) {
        this.setState({update_message_pick: 'Scan 1 - All Items Picked - Last Scan: ' + pick_date});
      }     

      if (item_total===scan_total && scan_total !==0 ) {
        let _status = this.state.order[0].or_status;

        this.setState({update_message_pack: 'Scan 2 - All Items Processed - Last Scan: ' + scan_date});

        if (_status !== "Shipped" && _status !== "Canceled" && _status !== "On Hold" && _status !== "Ready for Pick Up" ) {

          var obj = { cl_id: this.state.cl_id, or_id: this.props.orderId, or_status: 'Ready for Pick Up', or_notes: this.state.or_notes, us_id: this.state.us_id };
          var myJSON = JSON.stringify(obj);

          console.log(myJSON);
          
          fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/status/'+this.props.orderId, {
              method: 'post',        
              body: myJSON,      
              headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'bwToken': this.state.userToken       
              }
              //TODO:
              //credentials: 'same-origin', // send cookies
              //credentials: 'include'   // send cookies, even in CORS
          
          }).then(function(response) {  
              return response.json();
          }).then(this.getOrder).catch(function(error) {
              console.log(error);        
          }); 

        }
        this.playComplete();  
        this.setState({disableShip: false});         
        
        const input = document.querySelector('#txtsearch')
        input.focus();
      }

      if (item_total===pack_total && pack_total !==0 ) {
        this.setState({update_message: 'Scan 3 - All Items Packed - Last Scan: ' + pack_date});
        
      }
      
    }

    // EVENT HANDLERS
    handleSubmitStatus = (e) => {
      e.preventDefault();
  //Order.cl_id,Order.or_id,Order.or_status,Order.or_notes,Order.us_id
      var obj = { cl_id: this.state.cl_id, or_id: this.props.orderId, or_status: this.state.or_status, or_notes: this.state.or_notes, us_id: this.state.us_id, in_date: moment().format("YYYY-MM-DD HH:mm:ss")  };
      var myJSON = JSON.stringify(obj);

      console.log(myJSON);
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/status/'+this.props.orderId, {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken       
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {  
          return response.json();
      }).then(function(data) { 
          //console.log(data);
        alert("Status updated");
        window.location.reload();         
      }).catch(function(error) {
          console.log(error);        
      });      

  }

    handleInputChange = (e) => {     
        
    const target = e.target;
    const value = target.value;
    const name = target.name;

    //console.log('name: ' + name);
  // console.log('value: ' + value);

    this.setState({
    [name]: value
    });

  }

    getAttachments() {

      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/files/order/'+this.props.orderId, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(attachments => this.setState({ attachments }));
    
    }

    handleAttach = (e) => {
      e.preventDefault();    

      this.setState({filesModalMessage:'Uploading file...please wait.'})
    
      const myFile = document.getElementById('myFile');
      const data = new FormData();
      data.append('myFile', myFile.files[0]);
    
      var fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/files/order/'+this.props.orderId;

      fetch(fetchURL, {
      method: 'POST',
      body: data,
      headers: {            
        'bwToken': this.state.userToken          
      }
      }).then(function(response) { 
              
              //console.log(response);
              //console.log(fetchURL);

              if (response.statusText === 'OK'){
                alert('File attached successfully.')
                //this.setState({filesModalMessage:'File uploaded successfully'});
              } else {
                alert('File attachment failed. Try again.')
                //this.setState({filesModalMessage:'Upload failed. Try again.'});
              }
              window.location.reload();
          }).then(function(data) { 
              //console.log(data);
          }).catch(function(error) {            
              alert('File not uploaded! ' + error);        
          });
      
      e.target.value = null; 
    }

    handleAttachBOL = (e) => {
      e.preventDefault();    

      this.setState({filesModalMessage:'Uploading file...please wait.'})
    
      const myFile = document.getElementById('myFile');
      const data = new FormData();
      data.append('myFile', myFile.files[0]);
    
      var fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/files/bol/'+this.props.orderId;

      fetch(fetchURL, {
      method: 'POST',
      body: data,
      headers: {            
        'bwToken': this.state.userToken          
      }
      }).then(function(response) { 
              
              //console.log(response);
              //console.log(fetchURL);

              if (response.statusText === 'OK'){
                alert('File attached successfully.')
                //this.setState({filesModalMessage:'File uploaded successfully'});
              } else {
                alert('File attachment failed. Try again.')
                //this.setState({filesModalMessage:'Upload failed. Try again.'});
              }
              window.location.reload();
          }).then(function(data) { 
              //console.log(data);
          }).catch(function(error) {            
              alert('File not uploaded! ' + error);        
          });
      
      e.target.value = null; 
    }

    handleFileUpload = (event) => {
      this.setState({file: event.target.files});
    }

    handleAddNote = (e) => {
      
      e.preventDefault();
      
      var obj = { or_id: this.props.orderId, or_note: this.state.or_note, us_id: this.state.us_id, or_hours: this.state.or_hours, or_expedited: this.state.checkexpedited, or_misshipped: this.state.checkmisshipped, or_palletized: this.state.checkpalletized };
      var myJSON = JSON.stringify(obj);

      console.log(myJSON);
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/note/'+this.props.orderId, {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken       
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {  
          return response.json();
      }).then(function(data) { 
          //console.log(data);
        alert("Note added successfully");
        window.location.reload();         
      }).catch(function(error) {
          alert(error);        
      });

    }
   
    handleNoteInputChange(event,i) {

      const target = event.target;
      const value = target.value;

      let {returnitems} = this.state;
      returnitems[i].ri_details = value;

      this.setState({returnitems});

    }

    handleRmaQtyInputChange(event,i) {

      const target = event.target;
      const value = target.value;
      
      let {returnitems} = this.state;
      let oi_qty = returnitems[i].oi_qty;
      if (value > oi_qty) {
        alert('You cannot return more than the order qty for any item.');
        return false;
      } else {

        returnitems[i].ri_qty = value;
        this.setState({returnitems});

      }
     

    }

    handleExpConditionInputChange(event,i) {

      const target = event.target;
      const value = target.value;

      let {returnitems} = this.state;
      returnitems[i].ri_exp_condition = value;

      this.setState({returnitems});

    }

    handleBrandActionInputChange(event,i) {

      const target = event.target;
      const value = target.value;

      if (value=='Other') {
        alert('A note is required for an Other reason');
      }

      let {returnitems} = this.state;
      returnitems[i].ri_brand_action = value;

      this.setState({returnitems});

    }

    handleDeleteFile = (filePath) => {
      
      //console.log('filePath: ' + filePath)  
      var obj = { filePath: filePath };
      var myJSON = JSON.stringify(obj);
      var fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/files/del/order/'+this.props.orderId;

      fetch(fetchURL, {
        method: 'post',        
        body: myJSON,      
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken          
        }        
    
      }).then(function(response) {  
          return response.json();
      }).then(function(data) { 
          alert('File deleted');
          //window.location.reload();            
      }).then(this.getAttachments).catch(function(error) {
          alert(error);        
      });
      
    }

    handleDeleteOrder = () => {
      //e.preventDefault();
  
      var obj = { cl_id: this.props.clientId, or_id: this.props.orderId };
      var myJSON = JSON.stringify(obj);

      //console.log(myJSON);
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/delete/'+this.props.orderId, {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken       
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {  
          return response.json();
      }).then(function(data) { 
          window.location.replace('/customer');   
      }).catch(function(error) {
          alert(error);        
      });

    }

    handleDeleteOrderItem = (e) => {
      
      console.log(JSON.stringify(e));
      let _in_date = moment().format("YYYY-MM-DD HH:mm:ss");

      var obj = { cl_id: this.props.clientId, oi_id: e.original.oi_id, pr_id: e.original.pr_id, oi_qty: e.original.oi_qty, us_id: this.state.us_id, in_date: _in_date };
      var myJSON = JSON.stringify(obj);

      console.log(myJSON);
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/delete/item/'+e.original.oi_id, {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken       
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {  
          return response.json();
      }).then(function(data) { 
        window.location.reload();  
      }).catch(function(error) {
          alert(error);        
      });     

    }

    handleDeleteOrderItems = () => {

      let _order = this.state.order;
      let _orderitems = this.state.orderitems;
      let _in_date = moment().format("YYYY-MM-DD HH:mm:ss");
      let _cl_id = this.props.clientId;         
     
      if (_cl_id == 22 && this.state.order[0].or_status =='On Hold') {

        console.log('delete order trojan');

        var obj = { cl_id: this.state.cl_id, or_id: this.props.orderId, or_status: 'Canceled', or_notes: this.state.or_notes, us_id: this.state.us_id, in_date: moment().format("YYYY-MM-DD HH:mm:ss")  };
    
        var myJSON = JSON.stringify(obj);

        console.log(myJSON);
        
        fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/status/'+this.props.orderId, {
            method: 'post',        
            body: myJSON,      
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken       
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {  
            return response.json();
        }).then(function(data) { 
            //console.log(data);
          alert("Status updated");
          window.location.reload();         
        }).catch(function(error) {
            console.log(error);        
        });      

      } else {
        
        const requests = _orderitems.map((orderitem, idx) => {
  
          //Order.cl_id,Order.oi_id,Order.pr_id,Order.oi_qty,Order.us_id,Order.in_date
          var obj = { cl_id: _cl_id, oi_id: orderitem.oi_id, pr_id: orderitem.pr_id, oi_qty: orderitem.oi_qty, us_id: this.state.us_id, in_date: _in_date };
          var myJSON = JSON.stringify(obj);
  
          console.log(myJSON);
          
          fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/delete/item/'+orderitem.oi_id, {
              method: 'post',        
              body: myJSON,      
              headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'bwToken': this.state.userToken       
              }
              //TODO:
              //credentials: 'same-origin', // send cookies
              //credentials: 'include'   // send cookies, even in CORS
          
          }).then(function(response) {  
              return response.json();
          }).then(function(data) { 
              console.log(data);
          }).catch(function(error) {
              alert(error);        
          });
          
        })
    
        // Wait for all requests, and then setState
        return Promise.all(requests).then(this.handleDeleteOrder);    

      }
  
    }

    handleDeleteOrderItemScans = (e) => {
      
      console.log(JSON.stringify(e));

      var obj = { cl_id: this.props.clientId, oi_id: e.original.oi_id };
      var myJSON = JSON.stringify(obj);

      console.log(myJSON);
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/delete/scans/'+e.original.oi_id, {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken       
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {  
          return response.json();
      }).then(function(data) { 
        window.location.reload();  
      }).catch(function(error) {
          alert(error);        
      });
      

    }


    //TODO: Create a Returns table and routes.
    //TODO: Update status on order and build new Returns grid to 
    //TODO: view status and process return for a given order item

    generateRandomString = function(length=6){
      return Math.random().toString(20).substr(2, length);
    }  

    handleReturnOrder = (event) => {

      event.preventDefault();
      //alert('-- IN DEVELOPMENT --')

      //console.log('add returns biz logic')
      // IN clid INT(11), IN orid INT(11), IN rmaint varchar(45), IN rmaext varchar(45), details varchar(800), datein datetime
      
      let _rma = this.state.rma_number;
      let _tracking = this.state.rma_tracking;
      
      if (_tracking == '') {
        alert('Tracking Number for the Return must be entered.');
        return false;
      } else { 

        let _in_date = moment().format("YYYY-MM-DD HH:mm:ss");

        let _rmaNumber = this.generateRandomString();

        if (_rma !=='') {
          _rmaNumber = _rma;
        }  

        var obj = { 
          cl_id: this.props.clientId, 
          or_id: this.props.orderId, 
          rma_number: _rmaNumber,         
          in_date: _in_date,
          rt_tracking: _tracking,
          items: this.state.returnitems
        };
        
        var myJSON = JSON.stringify(obj);

        console.log(myJSON);
        
        fetch(process.env.REACT_APP_NODE_ROOT_URL+'/returns/add/'+this.props.clientId, {
            method: 'post',        
            body: myJSON,      
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken       
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {  
            return response.json();
        }).then(function(data) { 
          window.location.reload();  
        }).catch(function(error) {
            alert(error);        
        });
      

      }
    
    }

    handleShipInputChange(event,i) {

      const target = event.target;
      const value = target.value;
      const _name = target.name;

      //console.log(_name + " : " + value);

      let {ordershipments} = this.state;
      ordershipments[i][_name] = value;

      this.setState({ordershipments});

    }
 
    handleChange(event){
      //set upc value searched for
      this.setState({upc_search: event.target.value});
    }

    handleSubmit(event){
      event.preventDefault();    
    }
      
    getTrProps = (state, rowInfo, instance) => {
      if (rowInfo) {
        return {
          style: {
            background: this.setRowColor(rowInfo.row.lg_pick_count,rowInfo.row.lg_pack_count,rowInfo.row.lg_scan_count,rowInfo.row.oi_qty),
            color: this.setTextColor(rowInfo.row.lg_pick_count,rowInfo.row.lg_pack_count,rowInfo.row.lg_scan_count,rowInfo.row.oi_qty),
          }
        }
      }
      return {};
    }

    setRowColor = (pickQty,packQty,scanQty,itemQty) => {
      if (pickQty>0 && scanQty==0) {
        if (pickQty==itemQty) {
          return '#e8c15f';
        } else if (pickQty < itemQty) {
          return 'lightblue';
        } else  if (pickQty > itemQty) {
          return 'lightred';
        }
      }

      if (packQty>0 && scanQty==0) {
        if (packQty==itemQty) {
          return '#e8c15f';
        } else if (packQty < itemQty) {
          return 'lightblue';
        } else  if (packQty > itemQty) {
          return 'lightred';
        }
      }

      if (scanQty>0) {
        if (scanQty==itemQty) {
          return '#0F6';
        } else if (scanQty < itemQty) {
          return 'lightblue';
        } else  if (scanQty > itemQty) {
          return 'lightred';
        }
      }      
    }

    setTextColor = (scanQty,itemQty) => {
        return 'black';
        /*
        if (scanQty<=itemQty) {
          return 'black';
        } else {
          return 'white';
        } 
        */    
    }

    render(){

        const videoConstraints = {
          width: 1280,
          height: 720,
          facingMode: "user"
        };

        const { ordershipments, orderitems, orderreturns, cl_ship_methods, ordernotes, orderweight, orderotherskus, ordertracking, cl_id } = this.state;

        const statusOptions = [
          'New', 'On Hold'
        ]
        
        /*
          <option value="" selected="">Select Reason</option>
              	<option value="Damaged">Damaged (will not return inventory)</option>
                <option value="Wrong Item">Wrong Item (will return inventory)</option>
                <option value="Bad Fit">Didn't Fit (will return inventory)</option>
                <option value="Other">Other (will not return inventory)</option>
        */


       const rmaReasons = [
        'New',
        'Damaged',
        'Defective',
        'BLEM',
        'Used',
        'Other'
      ]  

      const brandActions = [
        'Return to Main Inventory',
        'Recycle',
        'Assign BLEM#',
        'Quality Control',
        'Contact Client on Arrival'
      ]  

        return (
          <div id="main">
            <div className="page-wrapper">
               <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                    <h3 className="text-primary">Order Detail</h3> </div>
                <div className="col-md-7 align-self-center">
                    
                </div>
            </div>
            <div className="container-fluid">
            <div className="card">  
              <div className="card-body" name="card-order-list">
                <div className="row">                
                    <div className="col-lg-12">
                      <div className="card">
                            <div className="card-body"> 
                            {this.state.order.map(orderheader =>
                              <div id="invoice-top" key={orderheader.or_id} className="row">
                                <div className="col-lg-3">
                                  <h4><strong>Ship To</strong></h4>
                                  <h4>
                                    {orderheader.or_ship_fname} {orderheader.or_ship_lname}<br/>
                                    {orderheader.or_ship_company}<br/>
                                    {orderheader.or_ship_attention}<br/>
                                    {orderheader.or_ship_address}<br/>
                                    {orderheader.or_ship_address1}<br/>
                                    {orderheader.or_city} {orderheader.or_state} {orderheader.or_zip} {orderheader.or_country}<br/>
                                    {orderheader.or_ship_email}<br/>
                                    {orderheader.or_other}
                                  </h4>
                                  <br/>
                                  <button type="button" className="btn btn-info btn-sm" onClick={() => { if (window.confirm('Save this customer as a new contact?')) this.createContact() } }>Save as New Contact</button>
                                  
                                   
                              </div>
                              <div className="col-lg-3">

                                   <h4><strong>Details</strong></h4>
                                  <h4>                                    
                                    Order ID: {orderheader.or_actual_order_id}<br/>  
                                    Order Date: {moment(orderheader.or_date).format('MM/DD/YYYY hh:mm:ss a')}<br/>
                                    Ship By Date: {moment(orderheader.or_ship_by).isValid() ? moment(orderheader.or_ship_by).format("MM/DD/YYYY hh:mm:ss a") : ""}<br/>
                                    Order Status: {orderheader.or_status} <br/> 
                                    {orderweight.map(orderweight =>   
                                    <div id="weight-top" style={{paddingLeft:"15px"}} key={orderweight.total_order_weight} className="row">
                                       Order Weight: {orderweight.total_order_weight} lbs. <br/>
                                    </div>                        
                                    )}       
                                    <br/><br/>
                                     {orderreturns.length > 0 ? (       
                                    <div>     
                                    <strong>Returns</strong>
                                    {orderreturns.map(orderreturn =>   
                                    <div id="other-top" style={{paddingLeft:"15px"}} key={orderreturn.rt_id} className="row">                                 
                                      <a href={process.env.REACT_APP_ROOT_URL + '/customer-return/'+this.state.cl_id+'/'+orderreturn.rt_id} target="_new">{orderreturn.rt_rma_int}</a> ({orderreturn.rt_status})<br/>   
                                    </div>                               
                                    )}                                    
                                    </div>
                                    ) : (
                                      <div></div>
                                    )}  
                                    </h4>                                   
                                  
                                </div>
                                <div className="col-lg-3">                                                                     
                                <h4><strong>Shipment</strong></h4>
                                    <h4>
                                    {ordershipments.length > 0 ? (       
                                    <div>                             
                                      <div id="shipment-top" style={{paddingLeft:"15px"}} className="row">                                 
                                      Ship Date: {moment(ordershipments[0].sh_date).format('MM/DD/YYYY hh:mm:ss a')} <br/>
                                      Ship Method: {ordershipments[0].sh_method} <br/>
                                      Shipment Cost: ${typeof(ordershipments[0].sh_cost_adj)=='undefined' ? 0 : ordershipments[0].sh_cost_adj}<br/>                                       
                                      Pallet Qty: {ordershipments[0].sh_pallets} <br/>
                                      Notes: {ordershipments[0].sh_notes} <br/>
                                      BOL #: {ordershipments[0].sh_bol} <br/>
                                      Tracking #:
                                      </div>                                      
                                     {ordertracking.map(tracking =>   
                                      <div id="tracking-top" key={tracking.sh_id}>                                 
                                      <div>{tracking.sh_tracking}</div>
                                      {tracking.sh_scan_date==undefined ? ( 
                                        <div style={{color:"red"}}>not verified</div>
                                      ) : (
                                        <div style={{color:"green"}}>verified: {moment(tracking.sh_scan_date).format('MM/DD/YYYY hh:mm:ss a')}</div>
                                      )}                     
                                      </div>                      
                                      )}
                                      <br/><br/>
                                    <button type="button" className="btn btn-info btn-sm" onClick={() => { if (window.confirm('Are you sure you want to delete this shipment? This does not delete the shipment data in Shipstation. Make sure it is done in both.')) this.deleteShipment(ordershipments[0])}}>Delete Shipment</button>
                                    </div>
                                    ) : (
                                      <div>None</div>
                                    )}
                                    <br/><br/>
                                     {orderotherskus.length > 0 && this.state.cl_id==22 ? (       
                                    <div>     
                                    <strong>Serials</strong>
                                    {orderotherskus.map(othersku =>   
                                    <div id="other-top" style={{paddingLeft:"15px"}} key={othersku.ot_sku} className="row">                                 
                                       {othersku.ot_sku} ({othersku.pr_sku})<br/>   
                                    </div>                               
                                    )}                                    
                                    </div>
                                    ) : (
                                      <div></div>
                                    )}  
                                    </h4> 
                                </div>
                                <div className="col-lg-3">
                                <h4><strong>Requested Shipping</strong></h4>
                                   <h4>                                      
                                   Order Type: {orderheader.or_type}<br/><br/>
                                   {orderheader.or_urgent === 1 && cl_id === 6 ? ( 
                                    <div style={{color:"Red"}}>Amazon Prime - Check Shipping Account!<br/><br/></div>
                                  ) : (<div>&nbsp;</div>)}
                                   {orderheader.or_urgent === 1 && cl_id === 2 ? ( 
                                    <div style={{color:"Red"}}>Urgent Order - Please Expedite Shipping!<br/><br/></div>
                                  ) : (<div>&nbsp;</div>)}
                                   {orderheader.or_freight_bol === 'Yes' ? ( 
                                    <div>
                                    Client Provided BOL <br/>
                                    Ship Method: {orderheader.or_ship_method} <br/>                                    
                                    </div>
                                  ) : (<div>
                                    {orderheader.or_type === 'Freight' ? ( 
                                    <div>
                                    BW Freight Account <br/>   
                                    Express Del. Date: {moment(orderheader.or_freight_express_date).format('MM/DD/YYYY hh:mm:ss a')}<br/>
                                    NMFC#/Desc: {orderheader.or_freight_nmfc}<br/> 
                                    Freight Class: {orderheader.or_freight_class}<br/>       
                                    Ship Method: {orderheader.or_ship_method} <br/>  
                                    Contact Name: {orderheader.or_wc_name}<br/>
                                    Contact Phone:  {orderheader.or_wc_phone}<br/> 
                                    Carrier: {orderheader.or_carrier}<br/>
                                    Pick Up Time:  {orderheader.or_wc_time}<br/>     
                                             
                                    </div>
                                    
                                  ) : (<div>
                                   Ship Method: {orderheader.or_ship_method} <br/>
                                   Carrier: {orderheader.or_carrier}<br/>
                                   Service:  {orderheader.or_carrier_service}<br/><br/>
                                  </div>)}       
                                  </div>)}                                 
                                                               
                                   </h4>                                  
                                  {orderheader.or_small_acct ? ( 
                                    <div>
                                   <h4><strong>Third Party Account Details</strong></h4>
                                   <h4>
                                   Account Info: {orderheader.or_small_acct}<br/> 
                                   Billing Zip Code: {orderheader.or_small_zip}<br/>
                                   Billing Country Code: {orderheader.or_small_country}<br/> 
                                   </h4>
                                    </div>
                                  ) : (<div></div>)}
                                  {orderheader.or_type === 'Will Call' ? ( 
                                    <div>
                                    <h4><strong>Will Call Details </strong></h4>
                                    <h4>
                                    Contact Name: {orderheader.or_wc_name}<br/>
                                    Contact Phone:  {orderheader.or_wc_phone}<br/> 
                                    Pick Up Time:  {orderheader.or_wc_time}<br/>  <br/>
                                    </h4>
                                    </div>
                                  ) : (<div></div>)}
                                  <br/>
                                  {this.state.attachments.length > 0 ? ( 
                                    <div>
                                      <h4><strong>Attachments </strong></h4>
                                     <ul>
                                    {this.state.attachments.map((attachment,idx) =>
                                      <li key={idx}><button type="button" className="btn btn-info btn-sm" onClick={() => { if (window.confirm('Are you sure you want to delete this file?')) this.handleDeleteFile(attachment)}}>x</button> <a href={"https://bw-admin-files.s3.amazonaws.com/".concat(attachment)} target="_new">{attachment.substr(attachment.lastIndexOf('/') + 1)}</a></li>
                                      )}
                                    </ul>
                                    </div>
                                  ) : (<div></div>)}
                                </div>
                                {ordernotes.length > 0 ? (       
                                    <div style={{paddingLeft:"15px"}}>                             
                                      <button type="button" className="btn btn-primary btn-sm" data-toggle="collapse" data-target="#demo">View Notes</button>
                                    <br/><br/>                               
                                    <div id="demo" className="collapse">
                                    {orderheader.or_internal_notes}<br/>                                    
                                    {ordernotes.map(ordernote =>   
                                    <div id="notes-top" style={{paddingLeft:"15px"}} key={ordernote.on_id} className="row">                                 
                                       {moment(ordernote.on_date).local().format('MM/DD/YYYY hh:mm:ss A')} | {ordernote.user} | {ordernote.on_note} | Hours: {ordernote.or_hours} <br/>   
                                    </div>                                    
                                    )}                                    
                                  </div>
                                    </div>
                                    ) : (
                                      <div></div>
                                    )}


                                <div style={{paddingTop:"10px",paddingLeft:"20px",color:"green"}}><strong>{this.state.update_message_pick}
                                <br/>{this.state.update_message}
                                <br/>{this.state.update_message_pack}                                
                                </strong>
                                </div>
                                                             
                              </div>
                              
                              )} 
                              
                            </div>
                                 
                        </div>       
                                               
                        <div className="card">
                            <div className="card-title"> 
                            {this.state.order.map(orderheader =>
                            <span className="span-buttons" key={orderheader.or_id}>
                              <Link to={{                                  
                                  pathname: '/customer-orderprint/'+this.state.cl_id,
                                  state: {
                                    selectedIds: [this.state.or_id]
                                  }                                  
                                }}><button type="button" data-toggle="tooltip" data-placement="top" title="Print packing slip" className="btn btn-info btn-sm" disabled={this.state.disableAddButton}>Print Packing Slip</button></Link>  
                              
                              <button type="button" className="btn btn-info btn-sm" onClick={this.openBolModal}>Attach BOL</button>                              
                              <button type="button" className="btn btn-info btn-sm" onClick={this.openFilesModal}>Attach Files</button>                                                     
                              <button type="button" className="btn btn-info btn-sm" onClick={this.openStatusModal}>Update Status</button>
                              {orderheader.or_status ==='New' && orderheader.or_status !=='Shipped' ? ( 
                                    <span>
                                    <button type="button" className="btn btn-info btn-sm" onClick={() => { if (window.confirm('Canceling this order does not guarantee that it will not be shipped. Orders that are "In Process" or "Ready to Ship" may be too far along the fulfillment process to be pulled.')) this.handleDeleteOrderItems() } }>Cancel Order</button> 
                                    </span>
                              ) : ( <span></span>)}
                              {orderheader.or_status === 'Shipped' &&
                               <button type="button" className="btn btn-info btn-sm" onClick={this.openReturnModal}>Start Return</button>
                              }                               
                            </span>
                            )}

                           </div>
                           <div className="card-body" name="card-orderitem-list">
                                <div className="table-responsive">   
                                  <ReactTable                                      
                                      data={orderitems}
                                      filterable
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}          
                                      columns={[
                                        {                                          
                                          columns: [                                            
                                            {
                                              Header: "Item ID",
                                              accessor: "oi_id",
                                              show: false,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Product ID",
                                              accessor: "pr_id",
                                              show: false,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center"}}>{row.value}</div>
                                              )
                                            },                                                                              
                                            {
                                              Header: "SKU",
                                              accessor: "pr_sku",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Description",
                                              accessor: "pr_desc",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            },
                                            {
                                              Header: "Case Name",
                                              accessor: "cs_name",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            },
                                            {
                                              Header: "Case UPC",
                                              accessor: "cs_upc",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            },
                                            {
                                              Header: "UPC",
                                              accessor: "pr_upc",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div className="divUPC" style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "EAN",
                                              accessor: "pr_ean",
                                              show: this.state.cl_id==22,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            },
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Locations</div>
                                              ),
                                              accessor: "all_bins",
                                              style: { 'whiteSpace': 'unset' },
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]==filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "left" }}>{row.value}</div>
                                              )
                                            },      
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>BLEM Location</div>
                                              ),
                                              accessor: "ri_location",
                                              style: { 'whiteSpace': 'unset' },
                                              width: 100,                                              
                                              filterMethod: (filter, row) =>
                                              row[filter.id] !== null ? row[filter.id]==filter.value : true,
                                              Cell: row => (
                                                <div style={{ textAlign: "left" }}>{row.value}</div>
                                              )
                                            },                                                              
                                            {
                                              Header: "Weight",
                                              accessor: "pr_weight",
                                              show: false,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                                          
                                            {
                                              Header: "Item Qty",
                                              accessor: "oi_qty",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div className="divQTY" style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Item Qty in Case",
                                              accessor: "cs_qty",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div className="divQTY" style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Total Cases",
                                              accessor: "total_cases",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div className="divQTY" style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: () => (
                                                <div style={{ textAlign: "center" }}>Avail Qty</div>
                                              ),
                                              accessor: "pr_qty",
                                              show:false,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                                                            
                                            {
                                              Header: "Picked",
                                              accessor: "lg_pick_count",
                                              width: 75,
                                              filterMethod: (filter, row) =>
                                              row[filter.id].startsWith(filter.value),
                                              Cell: (props) => (
                                                <div style={{ textAlign: "center" }}>{props.original.lg_pick_count}</div>
                                              )
                                            },                                           
                                            {
                                              Header: "Processed",
                                              accessor: "lg_scan_count",
                                              width: 75,
                                              filterMethod: (filter, row) =>
                                              row[filter.id].startsWith(filter.value),
                                              Cell: (props) => (
                                                <div style={{ textAlign: "center" }}><a href="#main" onClick={() => { if (window.confirm('Reset scans for this item?')) this.handleDeleteOrderItemScans(props) } } title="reset scans">{props.original.lg_scan_count}</a></div>
                                              )
                                            },
                                            {
                                              Header: "Packed",
                                              accessor: "lg_pack_count",
                                              width: 75,
                                              filterMethod: (filter, row) =>
                                              row[filter.id].startsWith(filter.value),
                                              Cell: (props) => (
                                                <div style={{ textAlign: "center" }}>{props.original.lg_pack_count}</div>
                                              )
                                            },
                                            {
                                              Header: "Status",
                                              accessor: "oi_status",
                                              show: false,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: (row) => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            }
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={50}
                                      className="-striped -highlight"
                                      getTrProps={this.getTrProps}                      
                                      getTdProps={(state, rowInfo, column, instance) => {
                                        return {                                                                              
                                          onClick: (e, handleOriginal) => {
                                            
                                          }
                                        };
                                      }}
                                    />


                                 
                                </div>
                            </div>

                        </div>
                    </div>
                </div>               
               
            </div>
            </div>
            </div>
         </div>
          
         <Modal
            isOpen={this.state.returnModalIsOpen}
            onAfterOpen={this.afterOpenReturnModal}            
            style={returnStyles}
            contentLabel="Create a Return"
          >         
            <div className="container-fluid">               
              <form onSubmit={this.handleReturnOrder}>
              <h3>Return Order Items</h3>
              <div className="row">
                    <div className="col-sm-2 right">                                      
                      RMA Number*:
                    </div>
                    <div className="col-sm-10">                                    
                     <input
                        className="form-input-lg"
                        id="rma_number"
                        name="rma_number"
                        type="text"                        
                        value={this.state.rma_number}
                        onChange={this.handleInputChange}
                        onBlur={this.getRma} 
                        placeholder=""
                      />
                      </div>    
                      <div className="col-sm-2 right">                                      
                      Tracking Number*:
                    </div>
                    <div className="col-sm-10">                                    
                     <input
                        className="form-input-lg"
                        id="rma_tracking"
                        name="rma_tracking"
                        type="text"                        
                        value={this.state.rma_tracking}
                        onChange={this.handleInputChange}
                        placeholder=""
                      />
                      </div>                
                    </div>      
                <table className="table-order-items">
                  <thead> 
                    <tr> 
                      <td className="td-oi-sku">SKU</td>                     
                      <td className="td-oi-sku">Item Qty</td>
                      <td className="td-oi-qty">Qty to Return</td>
                      <td className="td-oi-rem">Expected Condition</td>
                      <td className="td-oi-rem">Brand Action</td>
                      
                    </tr>
                  </thead>
                  {this.state.returnitems.map((orderitem, idx) => {
          
                    let spnId = `spn-${idx}`;
                    let tblId = `tbl-${idx}`;
                    let nameId = `item-${idx}`;

                    return (
                        <tbody key={tblId}>                        
                        <tr>                          
                        <td className="td-oi-sku"><strong>{this.state.returnitems[idx].pr_sku}</strong></td>
                          <td className="td-oi-sku">{this.state.returnitems[idx].oi_qty}</td>
                          <td className="td-oi-qty"><input name={spnId} 
                            type="text"                            
                            value={this.state.returnitems[idx].ri_qty} 
                            onChange={(e) => {this.handleRmaQtyInputChange(e, idx)}}                                      
                            className="qty_field" /></td>
                          <td className="td-oi-reason"><select value={this.state.returnitems[idx].ri_exp_condition } 
                                  onChange={(e) => {this.handleExpConditionInputChange(e, idx)}}>
                            {rmaReasons.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                          </select></td>
                          <td className="td-oi-reason"><select value={this.state.returnitems[idx].ri_brand_action  } 
                                  onChange={(e) => {this.handleBrandActionInputChange(e, idx)}}>
                            {brandActions.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                          </select></td>                                                          
                          </tr>
                          <tr>
                          <td colspan="5" className="td-oi-note">                            
                            Item Notes: <br/><input name={nameId} 
                            type="text"                            
                            value={this.state.returnitems[idx].ri_details || ''} 
                            onChange={(e) => {this.handleNoteInputChange(e, idx)}}                                      
                            className="note_field" />
                            <br/> --- <br/>
                            </td>
                          </tr>
                        </tbody>                                 
                      )
                    })                
                  }                          
              </table>  

              <br/><br/>
              <div className="modalMessage">{this.state.returnModalMessage}</div>
              <div className="right">
                <button type="submit" className="btn btn-info btn-sm">Create Return</button>&nbsp;&nbsp;&nbsp;<button className="btn btn-info btn-sm" onClick={this.closeReturnModal} style={cancelButton}>Cancel</button>
              </div> 
                 
              </form> 

             </div>                          
            
          </Modal>
         
          <Modal
            isOpen={this.state.packingModalIsOpen}
            onAfterOpen={this.afterOpenPackingModal}            
            style={customStyles}
            contentLabel="Print Packing Slip"
          >                   
         
            <div className="container-fluid">               
              <form>
              <h2>Print Packing Slip</h2>
                  <div className="row">
                    <div className="col-sm-12">                                      
                      <p>Do we need any options for printing a packing slip?</p>
                    </div>
                                                   
                  </div>

              <div className="modalMessage">{this.state.packingModalMessage}</div>
              <div className="right">
                <button type="submit">Print</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closePackingModal} style={cancelButton}>Cancel</button>
              </div> 
                 
              </form> 

             </div>                          
            
          </Modal>

          <Modal
            isOpen={this.state.statusModalIsOpen}
            onAfterOpen={this.afterOpenStatusModal}            
            style={customStyles}
            contentLabel="Update Status"
          >                   
         
            <div className="container-fluid">               
              <form onSubmit={this.handleSubmitStatus}>
              <h2>Update Status</h2>
                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Status: 
                    </div>
                    <div className="col-sm-8">
                     <select value={this.state.or_status} 
                                  onChange={(e) => this.setState({or_status: e.target.value})}>
                                    <option>-- select status --</option> 
                            {statusOptions.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                          </select>                        
                    </div>                                    
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Note :
                    </div>
                    <div className="col-sm-8">                                    
                     <input
                        className="form-input"
                        id="or_note"
                        name="or_note"
                        type="text"
                        value={this.state.or_note}
                        onChange={this.handleInputChange}
                        placeholder=""                        
                      />
                    </div>
                  </div>
              <div className="modalMessage">{this.state.statusModalMessage}</div>
              <div className="right">
                <button type="submit">Update</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closeStatusModal} style={cancelButton}>Cancel</button>
              </div>
                 
              </form> 

             </div>                          
            
          </Modal>
       
          <Modal
            isOpen={this.state.filesModalIsOpen}
            onAfterOpen={this.afterOpenFilesModal}
            onRequestClose={this.closeFilesModal}
            style={customStyles}
            contentLabel="Add Files"
          >                   
         
            <div className="container-fluid">               
            
              <h2>Add Files</h2>
                  <div className="row">
                    <div className="col-sm-12">                                      
                    BOLs must be uploaded separately from other order files. <br/>
                    
                       <form onSubmit={this.handleAttach}> 
                         <input type="file" id="myFile" name="myFile" /><br/><br/>
                         <input type="submit" value="Upload a file"/>&nbsp;&nbsp;
                         <button onClick={this.closeFilesModal} style={cancelButton}>Cancel</button>
                      </form>                   
                                     
                    </div>
                        
                  </div>

              <div className="modalMessage">{this.state.filesModalMessage}</div>              

             </div>                          
            
          </Modal>

          <Modal
            isOpen={this.state.bolModalIsOpen}
            onAfterOpen={this.afterOpenBolModal}
            onRequestClose={this.closeBolModal}
            style={customStyles}
            contentLabel="Add BOL"
          >                   
         
            <div className="container-fluid">               
            
              <h2>Add BOL</h2>
                  <div className="row">
                    <div className="col-sm-12">                                      
                        BOLs must be uploaded separately from other order files. <br/>

                       <form onSubmit={this.handleAttachBOL}> 
                         <input type="file" id="myFile" name="myFile" /><br/><br/>
                         <input type="submit" value="Upload a file"/>&nbsp;&nbsp;
                         <button onClick={this.closeBolModal} style={cancelButton}>Cancel</button>
                      </form>                   
                                     
                    </div>
                        
                  </div>

              <div className="modalMessage">{this.state.bolModalMessage}</div>              

             </div>                          
            
          </Modal>

        <audio className="good-audio-element">
          <source src={process.env.REACT_APP_ROOT_URL+'/sound/good-noise.mp3'}></source>
        </audio>

        <audio className="bad-audio-element">
          <source src={process.env.REACT_APP_ROOT_URL+'/sound/bad-noise.mp3'}></source>
        </audio>

        <audio className="complete-audio-element">
          <source src={process.env.REACT_APP_ROOT_URL+'/sound/complete.mp3'}></source>
        </audio>



         </div>


                )
    }
}
export default OrderContent;