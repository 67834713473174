import React from 'react';
class OrderItems extends React.Component {
	constructor(props) {
        super(props);
            this.state = {
            orderitems: [],
            or_id: '',
            userToken: localStorage.getItem('userToken')
        };       
    }

	  getOrderItems() {

      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orders/'+this.props.or_id,
      {
          method: 'GET',      
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }

          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(res => res.json())
      .then(orderitems => this.setState({ orderitems }));

    }

    componentDidMount() {
       this.getOrderItems();

    }


  render() {
  	
   // const weightTotal = this.state.orderitems.reduce((totalWeight, orderitem) => totalWeight + (orderitem.oi_qty*orderitem.pr_weight), 0);

    return (
    	<div>
        
          <table id="order-items" className="tableprintnew" style={{fontSize:"14pt"}} width="85%">
              <thead>
                  <tr>
                      <th>Product Name</th>
                      <th>Description</th>
                      <th>Quantity</th>                      
                      <th>Case UPC</th>
                      <th>Total Cases</th>                                                    
                  </tr>
              </thead>
              <tbody>
                {this.state.orderitems.map(orderitem =>                                           
                <tr key={orderitem.oi_id} itemID={orderitem.pr_sku}>
                    <td valign="top">{orderitem.pr_desc}</td>
                    <td valign="top">
                    SKU: {orderitem.pr_sku}<br/>
                    UPC: {orderitem.pr_upc}<br/>
                    LOCATION: {orderitem.all_bins}<br/>
                    </td>
                    <td valign="top">{orderitem.oi_qty}</td>                    
                    <td valign="top">{orderitem.cs_upc}</td>
                    <td valign="top">{orderitem.total_cases}</td>
                </tr>
              )}             

              </tbody>
          </table>
          
          </div>
)
  }
}
export default OrderItems;