// Header.js
import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import ReactTable from "react-table";
import Modal from 'react-modal';
import 'react-table/react-table.css'
import 'react-dropdown/style.css';
import ReactToPrint from "react-to-print";
import ProductPrintLabel from './ProductPrintLabel';

const printModalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '500px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};

class ProductKitList extends Component {

    constructor(props) {
        super(props);        
            this.state = {
            productkits: [],            
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),
            //cl_id: typeof(this.props.cl_id) === 'undefined' ? 0 : this.props.cl_id,
            upc_search: '',
            kt_sku: '',
            labelModalIsOpen: false,
            printQty: 1,
            printUPC: '',
            printDesc: '',
            printSize: 48,
            labelWidth: 6,
            labelHeight: 200,       
            modalMessage: '',
            importModalMessage: '',
            importModalIsOpen: false,
            disableAddButton:true,
            userToken: localStorage.getItem('userToken')
        };       
        
        this.handleChange = this.handleChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

       
    getProductKits = (url) => {
      fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(productkits => this.setState({ productkits }));
    }

    handleChange (event) {
     this.setState({pr_sku: event.target.value});
    }

    //TODO: add get kit by client id route and model 
    //
    handleClientChange = (cl_id) => {
        this.setState({cl_id: cl_id});        
        if (cl_id === 0) {
            this.getProductKits(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits');
            this.setState({disableAddButton:true});
        } else {
            this.getProductKits(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits/client/'+cl_id);
            this.setState({disableAddButton:false});
        }         
    }

    handleSubmit(event) {
      //alert('Client name: ' + this.state.cl_name);
      //event.preventDefault();

      var obj = { cl_id: this.state.cl_id, pr_sku: this.state.pr_sku };
      var myJSON = JSON.stringify(obj);
   
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits/', {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {  
          return response.json();
      }).then(function(data) { 
          console.log(data);            
      }).catch(function(error) {
          alert(error);        
      });      

    }

      //LABEL
      openLabelModal = (e) => {
        //console.log('printid: ' + e.original.pr_id);
  
        this.setState({labelModalIsOpen: true});
        this.setState({kt_sku: e.original.kt_sku});
        this.setState({printUPC: e.original.kt_upc});
        this.setState({printDesc: e.original.kt_name});
  
  
      }
  
      afterOpenLabelModal = (e) => {
  
      }
  
      closeLabelModal() {
        this.setState({labelModalIsOpen: false});
        this.setState({printQty:1});
  
      } 

    componentDidMount() {
        Modal.setAppElement('#main');
        this.handleClientChange(this.state.cl_id); 
    }
 
    render(){

        const column_link_style = {
          textAlign: "center",
          textDecoration: "underline"
        };

        const { cl_id, productkits } = this.state;
        
        return (
        <div id="main">
        <div className="page-wrapper">
                <div className="row page-titles">
      
                  <div className="col-md-2 align-self-center">
                       <h3 className="text-primary">Product Kits</h3>
                    
                  </div>
                  <div className="col-md-10 align-self-center"> 
                  <Link to={`/product-kit/${cl_id}`}><button type="button" className="btn btn-info btn-sm" disabled={this.state.disableAddButton} >Add a Kit</button></Link>                 
                  
                  </div>
                 
                </div>

                <div className="container-fluid">             

                <div className="row">
                    <div className="col-lg-12">
                       
                            <div className="card-body" name="card-order-list">
                                <div className="table-responsive">
                                  <ReactTable

                                      data={productkits}
                                      filterable
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}          
                                      columns={[
                                        {                                          
                                          columns: [                     
                                            {
                                              Header: "Kit ID",
                                              accessor: "kt_id",
                                              width: 50,
                                              Cell: row => (
                                                <div style={column_link_style} onClick={(e) => window.location.href = "/product-kit/"+this.state.cl_id+"/"+row.value}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Print Label",
                                              accessor: "kt_id",
                                              width: 50,
                                              filterable: false,
                                              Cell: props => (
                                                <div style={{ textAlign: "center" }}><a href="#" onClick={(e) => this.openLabelModal(props)} title="print labels">print</a></div>
                                              )
                                            },                             
                                            {
                                              Header: "Kit ID",
                                              accessor: "kt_id",
                                              width: 50,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)          
                                            },
                                            {
                                              Header: "Cl ID",
                                              accessor: "cl_id",
                                              width: 50,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center"}}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "Kit Name",
                                              accessor: "kt_name",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)          
                                            },
                                            {
                                              Header: "Kit SKU",
                                              accessor: "kt_sku",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)          
                                            },
                                            {
                                              Header: "Kit UPC",
                                              accessor: "kt_upc",
                                              width: 150,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)          
                                            },                                            
                                            {
                                              Header: "Active?",
                                              accessor: "kt_active",
                                              width: 50,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)          
                                            }
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={10}
                                      className="-striped -highlight"
                                      
                                    />



                               
                                </div>
                            </div>                         

                       
                    </div>
                </div>               
                <div className="row">   
                    <div className="col-lg-4">
                       
                    </div>
                </div>
                <div id="modalMain" className="col-lg-4 align-self-left">
                <Modal
                            isOpen={this.state.labelModalIsOpen}
                            onAfterOpen={this.afterOpenLabelModal}                            
                            style={printModalStyle}
                            contentLabel="Print Label"
                          >                   
                         <div className="frame">
													    <div className="scroll"> 
                            <div className="container-fluid">               
                              <form onSubmit={this.handleLabelPrint}>
                                  <div className="row">
                                      <div className="col-md-6">            
																				<h3>Print Product Labels</h3>
                                        <div className="form-group" >
                                          <label htmlFor="printQty" className="form-label">Label Qty: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="printQty"
                                            name="printQty"
                                            type="text"
                                            value={this.state.printQty}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />
                                          <label htmlFor="printQty" className="form-label">Font Size: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="printSize"
                                            name="printSize"
                                            type="text"
                                            value={this.state.printSize}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />

                                          <label htmlFor="labelHeight" className="form-label">Label Height: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="labelHeight"
                                            name="labelHeight"
                                            type="text"
                                            value={this.state.labelHeight}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />
                                          <label htmlFor="labelWidth" className="form-label">Label Width: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="labelWidth"
                                            name="labelWidth"
                                            type="text"
                                            value={this.state.labelWidth}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />
                                          
                                        </div>
                                        <div className="modalMessage">{this.state.modalMessage}</div>
                                        <div>
                                          <ReactToPrint
                                            trigger={() => <Link to="#"><button type="button" className="btn btn-info btn-sm">Print</button></Link>}
                                            content={() => this.componentRef}
                                          /> &nbsp;&nbsp;
                                          <button className="btn btn-info btn-sm" onClick={this.closeLabelModal}>Cancel</button>
                                        </div> 
                                        <ProductPrintLabel ref={el => (this.componentRef = el)} labelHeight={this.state.labelHeight} labelWidth={this.state.labelWidth} printSize={this.state.printSize} printQty={this.state.printQty} pr_sku={this.state.kt_sku} printDesc={this.state.printDesc} printUPC={this.state.printUPC} {...this.props} />
																			
                                      </div>

                                      
                                  </div>
                                 
                              </form> 

                             </div>                          
                             </div>
													  </div>
                          </Modal>
                </div>


            </div>
        </div>
        </div>

                )
    }
}
export default ProductKitList;