import React, { Component } from 'react';
import LeftBar from '../Site/LeftBar';
import TimeclockList from './TimeclockList';

import './style.css';

class Timeclock extends Component {

  render() {
    return (
      <div>
        <LeftBar />
        <TimeclockList />
      </div>    


    );
  }
}

export default Timeclock;