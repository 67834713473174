// Header.js
import React, {Component} from 'react';
import Select from 'react-select';
import 'react-dropdown/style.css';
import StateSelector from '../Form/StateSelector';
import CountrySelector from '../Form/CountrySelector';
import BillingCountrySelector from '../Form/BillingCountrySelector';
import DateTimePicker from 'react-datetime-picker';
import Modal from 'react-modal';
import moment from 'moment';
import serveFavicon from 'serve-favicon';
import { Server } from 'ws';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '600px',
    color                 : 'black'
  }
};


const cancelButton = {
  content : {
    float                 : 'right'  
  }
};

const kitRow = {
  content : {
    width                 : '100%',
    marginTop             : '20px',
    clear                 : 'both',
    display               : 'inline-block'
  }
};

class OrderEntryContent extends Component {

    constructor(props) {
        super(props);

        var dateString = moment().format('YYYY-MM-DD HH:mm:ss');    
        

        this.state = {
            attachments: [],
            upc_search: '',   
            customer_search: '',    
            case_search: '',
            kit_search: '',    
            blem_search: '',
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'), 
            cl_name: '',
            cl_tax_rate: 0,            
            pr_id: '',
            title: '',
            scans: 0,
            or_ss_order_id: '',
            cn_id:'',
            or_ship_fname: '',
            or_ship_lname: '',
            or_ship_email: '',
            or_ship_address: '',
            or_ship_address1: '',
            or_status: 'New',
            or_date:  dateString,
            or_ship_by: '',
            or_city: '',
            or_state: '',
            or_zip: '',
            or_country: '',
            or_ship_phone: '',
            or_ship_company: '',
            or_key: '',           
            or_customer_notes: '',
            or_note: '',
            or_ship_cost: 0,
            or_subtotal: 0,
            or_total: 0,
            or_tax: 0,
            or_oe: 1,
            or_type: '',
            or_type_account:'',
            or_ship_method: '',
            or_carrier:'UPS',
            or_carrier_service:'', //const smallAccountCarrierService = ['Ground','3 Day','2-Day','1-Day'] or  const smallAccountCarrierServiceUSPS = ['Priority','First Class']
            or_freight_service_type:'', 
            or_freight_express_date: new Date(),
            or_freight_nmfc:'',
            or_freight_class:'',
            or_freight_bol:'No',            
            or_small_service_type:'', // const smallCustomerService = ['My Label','My Account']
            or_small_zip:'', //My Zip
            or_small_acct:'', //My Acct
            or_small_label:'No', //My Label    
            or_small_country:'',
            or_wc_name:'',
            or_wc_phone:'',
            or_wc_time: '',
            or_ship_attention:'',
            or_other: '',
            orderitems: [],
            orderblemitems: [],
            products: [],
            productcases: [],
            productkits: [],
            productkititems: [],
            productkitname: '',
            selectedkit: [],
            productkitqty: '',
            productblems: [],
            customers: [],
            fileToUpload: null,
            fileToUploadMsg: '',
            orderAddMessage: '',
            disableAddOrder:false,
            filesModalIsOpen:false,
            labelModalIsOpen:false,
            userToken: localStorage.getItem('userToken'),            
            addHourDate: new Date(),
            orderid:0,
            total_weight:0.0000,
            order:[],
            cl_shipstation_manual_id:'',
            cl_ship_methods: [{name:''}],
            cl_stores:[{name:''}],
            us_id: localStorage.getItem('usId')
        };
               
        this.handleOrderTypeChange = this.handleOrderTypeChange.bind(this);
        this.handleShipStateChange = this.handleShipStateChange.bind(this);
        this.handleShipCountryChange = this.handleShipCountryChange.bind(this);
        this.handleBillStateChange = this.handleBillStateChange.bind(this);
        this.handleBillCountryChange = this.handleBillCountryChange.bind(this);
        this.handleBillingCountryChange = this.handleBillingCountryChange.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleProductBlemChange = this.handleProductBlemChange.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.getOrder = this.getOrder.bind(this);
        this.goOrder = this.goOrder.bind(this);
        this.createContact = this.createContact.bind(this);
        this.updateContact = this.updateContact.bind(this);
        this.deleteContact = this.deleteContact.bind(this);        

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleQtyInputChange = this.handleQtyInputChange.bind(this);
        this.handleKitQtyInputChange = this.handleKitQtyInputChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
      
        this.openFilesModal = this.openFilesModal.bind(this);
        this.afterOpenFilesModal = this.afterOpenFilesModal.bind(this);
        this.closeFilesModal = this.closeFilesModal.bind(this);
        this.handleAttach = this.handleAttach.bind(this); 
        this.sendAttach = this.sendAttach.bind(this);
        this.addKitItem = this.addKitItem.bind(this);
             
    }

    componentDidMount() {
      this.handleClientChange(this.props.cl_id);      
      Modal.setAppElement('#main');
    }
   
    componentDidUpdate(prevProps, prevState) {
     
    }    
  
    // EVENT HANDLERS
    handleAttach = (e) => {
      e.preventDefault();    
      
      const myFile = document.getElementById('myFile');
      
      try {
        this.setState({fileToUpload: myFile});
        this.setState({fileToUploadMsg: 'File to upload: ' + JSON.stringify(myFile.files[0].name)});
        this.setState({filesModalIsOpen: false});
      }
      catch(err) {
        alert('Please check your file and try again');
      }
      
      e.target.value = null; 
      
    }

    sendAttach = () => {
      let _orderid = this.state.orderid;

      const myFile = this.state.fileToUpload;

      try {
        const data = new FormData();
        data.append('myFile', myFile.files[0]);      
      
        var fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/files/order/'+_orderid;

        fetch(fetchURL, {
        method: 'POST',
        body: data,
        headers: {            
          'bwToken': this.state.userToken          
        }
        }).then(function(response) {             
          if (response.statusText === 'OK'){
            console.log('File attached successfully.')
            //this.setState({filesModalMessage:'File uploaded successfully'});
          } else {
            console.log('File attachment failed. Try again.')
            //this.setState({filesModalMessage:'Upload failed. Try again.'});
          }
          //return response;
        }).then(function(data) { 
            //console.log(data);
        }).catch(function(error) {            
            console.log('File not uploaded! ' + error);        
        });
      }
      catch(err) {
        //alert('Check your file and try again or contact support: ' + err.message);
        console.log('no file to upload');
      }     
    }    
    
       //Files
    openFilesModal() {
      this.setState({filesModalIsOpen: true});
    }

    afterOpenFilesModal() {
      // references are now sync'd and can be accessed.
      // this.subtitle.style.color = '#f00';
    }

    closeFilesModal() {
      this.setState({filesModalIsOpen: false});
    }
    
    getCustomers = (url) => {

        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(customers => this.setState({ customers })); 

    }
    
    getClient = (url) => {

      fetch(url, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(client => this.setState({ client })); 

    }    

    getProducts = (url) => {

        fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(products => this.setState({ products })); 

    }

    getProductBlems = (url) => {

      fetch(url, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(productblems => this.setState({ productblems })); 

  }
    
    getProductCases = (url) => {
      fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(productcases => this.setState({ productcases }));
    }

    getProductKits = (url) => {
      fetch(url, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(productkits => this.setState({ productkits }));
    }

    getProductKitItems = (ktid) => {
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits/items/'+ktid, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
      }).then(res => res.json())
        .then(productkititems => this.setState({ productkititems }))        
        .then(this.checkKitQty);;
    }
   
    getOrder = () => {

      var orderId = this.state.or_ss_order_id;
     
      if (orderId.indexOf('/') > -1) {
        orderId = orderId.replaceAll('/','%2F');       
      }

      if (orderId !== '')
      {        
        var _url = process.env.REACT_APP_NODE_ROOT_URL+'/orders/s/'+this.state.cl_id+'/'+orderId;
        
        fetch(_url, {
            method: 'GET',
            headers: {
            'Accept': 'application/json',
            'bwToken': this.state.userToken
            }      
        }).then(res => res.json())
        .then(order => this.setState({ order }))
        .then(this.goOrder);

      }
    }
  
    goOrder = () => {
  
      const order = this.state.order;   
  
      if (order.length > 0) {
        if (order[0].cl_id === this.state.cl_id) {
          alert('Order Number in use. Please enter a unique order number.');
          this.setState({or_ss_order_id:''});
          return false;
        } else {
          return true;
        }
        
      } else {
        return true;
      }
  
      
    }

    createContact = () => {
      //alert('create contact');

      let objContact = {
        cl_id: this.state.cl_id,
        cn_firstname: this.state.or_ship_fname, //1
        cn_lastname: this.state.or_ship_lname, //2
        cn_company: this.state.or_ship_company, //3
        cn_attention: this.state.or_ship_attention, //4
        cn_ship_address: this.state.or_ship_address, //5
        cn_ship_address1: this.state.or_ship_address1, //6            
        cn_city: this.state.or_city, //7
        cn_state: this.state.or_state, //8
        cn_zip: this.state.or_zip, //9
        cn_country: this.state.or_country, //10
        cn_phone: this.state.or_ship_phone, //11               
        cn_email: this.state.or_ship_email, //12            
        cn_other: this.state.or_other
      }; 

      var contactJSON = JSON.stringify(objContact);

      console.log('logging contact json...');
      console.log(contactJSON);

      //return true;
    
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/contacts/create/'+this.state.cl_id, {
            method: 'post',        
            body: contactJSON,      
            headers: {            
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
      }).then(function(response) {  
          return response.json();
      }).then(alert('Contact created successfully.'))
        .catch(function(error) {
          console.log(error);                   
      });

    }

    updateContact = () => {
      //alert('update contact');

      let objContact = {              
        cn_firstname: this.state.or_ship_fname, //1
        cn_lastname: this.state.or_ship_lname, //2
        cn_company: this.state.or_ship_company, //3
        cn_attention: this.state.or_ship_attention, //4
        cn_ship_address: this.state.or_ship_address, //5
        cn_ship_address1: this.state.or_ship_address1, //6            
        cn_city: this.state.or_city, //7
        cn_state: this.state.or_state, //8
        cn_zip: this.state.or_zip, //9
        cn_country: this.state.or_country, //10
        cn_phone: this.state.or_ship_phone, //11               
        cn_email: this.state.or_ship_email, //12            
        cn_other: this.state.or_other,
        cn_id: this.state.cn_id,
        cl_id: this.state.cl_id
      }; 

      var contactJSON = JSON.stringify(objContact);

      console.log('logging contact json...');
      console.log(contactJSON);

      //return true;
    
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/contacts/update/'+this.state.cl_id, {
            method: 'post',        
            body: contactJSON,      
            headers: {            
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
      }).then(function(response) {  
          return response.json();
      }).then(alert('Contact updated successfully.'))
        .catch(function(error) {
          console.log(error);                   
      });
    }

    deleteContact = () => {
      let objContact = {        
        cn_id: this.state.cn_id,
        cl_id: this.state.cl_id
      }; 

      var contactJSON = JSON.stringify(objContact);

      console.log('logging contact json...');
      console.log(contactJSON);

      //return true;
    
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/contacts/del/'+this.state.cl_id, {
            method: 'post',        
            body: contactJSON,      
            headers: {            
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
      }).then(function(response) {  
          return response.json();
      }).then(alert('Contact deleted successfully.'))
        .then(window.location.reload())
        .catch(function(error) {
          console.log(error);                   
      });
    }


    handleClientChange = (cl_id) => {               
       
        this.setState({cl_id: cl_id});   

        //2. - get client details
        fetch(process.env.REACT_APP_NODE_ROOT_URL+'/clients/'+cl_id, {
          method: 'GET',   
          headers: {
          'Accept': 'application/json',
          'bwToken': this.state.userToken
          }      
        }).then(res => res.json())      
          .then(client => this.setState({
              cl_name: client[0].cl_name || '',          
              cl_tax_rate: client[0].cl_tax_rate || 0,
              cl_id: client[0].cl_id || '',
              cl_ship_methods:this.tryParse(client[0].cl_ship_methods) || [{name:''}],
              cl_stores: client[0].cl_stores,
              cl_shipstation_manual_id: client[0].cl_shipstation_manual_id
          }));  
        
        //3. - get product list
        if (cl_id === 0) {
            this.getProducts(process.env.REACT_APP_NODE_ROOT_URL+'/products');
        } else {
            this.getProducts(process.env.REACT_APP_NODE_ROOT_URL+'/products/client/'+cl_id);
            this.getProductBlems(process.env.REACT_APP_NODE_ROOT_URL+'/products/blems/'+cl_id);
            this.getProductCases(process.env.REACT_APP_NODE_ROOT_URL+'/products/cases/client/'+cl_id);  
            this.getProductKits(process.env.REACT_APP_NODE_ROOT_URL+'/products/kits/client/'+cl_id);  
            this.getCustomers(process.env.REACT_APP_NODE_ROOT_URL+'/orders/c/'+cl_id);  
        }

        this.setState({orderitems: []});
        this.setState({selectedCustomer: []});
        //this.calculateOrderTotal();     

    }

    tryParse(input) {
      try {        
          return JSON.parse(input);
      } catch (e) {          
          return undefined;
      }
    }

    handleShipStateChange = (or_state) => {
      console.log(or_state);
      this.setState({or_state});        
    }

    handleOrderTypeChange = (or_type) => {
      console.log('or_type: ' + or_type.value)
      this.setState({or_type: or_type.value});        
    }

    handleShipCountryChange = (or_country) => {
      this.setState({or_country});        
    }

    handleBillingCountryChange = (or_small_country) => {
      this.setState({or_small_country});        
    }

    handleBillStateChange = (or_bill_state) => {
      this.setState({or_bill_state});        
    }

    handleBillCountryChange = (or_bill_country) => {
      this.setState({or_bill_country});        
    }
   
    handleProductChange = (e) => {
      console.log('product data: ' + JSON.stringify(e));
      let _orderitems = this.state.orderitems;
      //console.log('oi: ' + JSON.stringify(_orderitems));

      if(_orderitems.some(_orderitem => _orderitem.pr_sku === e.pr_sku)){
        alert("Item already added to order. Adjust quantity if needed.");
      } else{
        this.setState((prevState) => ({
          orderitems: [...prevState.orderitems,e],
        }));
      } 
      
    }

    handleProductBlemChange = (e) => {
      console.log('product blem data: ' + JSON.stringify(e));
      let _orderitems = this.state.orderblemitems;
      //console.log('oi: ' + JSON.stringify(_orderitems));

      if(_orderitems.some(_orderitem => _orderitem.pr_sku === e.pr_sku)){
        alert("Item already added to order. Adjust quantity if needed.");
      } else{
        this.setState((prevState) => ({
          orderblemitems: [...prevState.orderblemitems,e],
        }));
      } 
      
    }

    handleProductCaseChange = (e) => {
      console.log('case data: ' + JSON.stringify(e));      
      let _orderitems = this.state.orderitems;

      if(_orderitems.some(_orderitem => _orderitem.pr_sku === e.pr_sku)){
        alert("Case already added to order. Adjust quantity if needed.");
      } else{
        this.setState((prevState) => ({
          orderitems: [...prevState.orderitems,e],
        }));
      }     
           
    }

    checkKitQty = () => {

      var _test = this.checkKitQoh(1);
      console.log('_test: ' + _test);

      let _orderitems = this.state.orderitems;

      let selectedKit = this.state.selectedkit;

      if (_test) {
        if(_orderitems.some(_orderitem => _orderitem.pr_sku === selectedKit.pr_sku)){
        } else{
          this.setState((prevState) => ({
            orderitems: [...prevState.orderitems,selectedKit],
          }));
        }   
      }  

      return true;
      /*
      let kititems = this.state.productkititems;
     
      console.log('made it: ' + JSON.stringify(kititems));
      var k;
      var ret=true;
   
      for (k = 0; k < kititems.length; k++) {        
        if (kititems[k].ki_qty > kititems[k].pr_qty) {
          alert(kititems[k].kt_sku + " contains an out of stock item: " + kititems[k].pr_sku);          
          ret = false;
        } 
      }
      return ret;
      */
    }

    checkKitQoh = (qty) => {

      let kititems = this.state.productkititems;
     
      console.log('kititems from checkKitQoh: ' + JSON.stringify(kititems));
      console.log('qty: ' + qty);

      var k;
      var ret=true;
      
      for (k = 0; k < kititems.length; k++) {
          console.log('made it two: ');
          var _kiQty = kititems[k].ki_qty*qty;
          console.log('kit item qty: ' + _kiQty);
          console.log('product qty: ' + kititems[k].pr_qty);
          if (_kiQty > kititems[k].pr_qty) {
            alert(kititems[k].kt_sku + " contains an item with not enough inventory: " + kititems[k].pr_sku);          
            ret = false;            
            this.setState({disableAddOrder:true});
            this.setState({orderAddMessage: "Order cannot be submitted ... please correct kit item quantities and try again."});
          } 

      }

      return ret;
    }
    

    handleProductKitChange = (e) => {

      console.log('kit data: ' + JSON.stringify(e));   
      this.setState({disableAddOrder:false});
      this.setState({orderAddMessage: ""});

      let _orderitems = this.state.orderitems;
      this.setState({productkitname: e.pr_sku + " " + e.pr_desc}); 
      this.setState({selectedkit: e}); 
      this.setState({productkitqty: 1}); 
      this.getProductKitItems(e.kt_id);  
      
      //let _productkititems  = this.state.productkititems;
      //console.log('_productkititems: ' + JSON.stringify(_productkititems));   

      return true;    
        
    }

    handleKitQtyInputChange(event,i) {

      const target = event.target;
      const value = target.value;

      this.setState({productkitqty: value});

      //let _cur_weight = Number(this.state.total_weight);
      //let total_weight = 0;
           
      console.log('kitqtychange: ' + value);
    }

    handleKitQtyKeyPress(event,i) {

      const target = event.target;
      const value = target.value;

      var _res = this.checkKitQoh(this.state.productkitqty);

      console.log('after kit qoh check');

      //var kt_id = e.kt_id;
      
      //let _orderitems = this.state.orderitems;
      //console.log('_orderitems: ' + JSON.stringify(_orderitems));

      /*
      var _success = this.checkKitQoh(1);
        console.log("_Success: " + _success);
      */      

      /*
      let _cur_qty = value;

      if (_cur_qty==='') {
        _cur_qty=0;
      }
     
      let {orderitems} = this.state;
      orderitems[i].qty = _cur_qty;
      */
      
      //this.setState({orderitems});
    }

    //TODO: Finish checking kit item qtys and adding selected kit to order

    addKitItem = (e) => {      
      
      let _orderitems = this.state.orderitems;
      let _kit = this.state.selectedkit;

      if(_orderitems.some(_orderitem => _orderitem.pr_sku === _kit.pr_sku)){
      } else{
        this.setState((prevState) => ({
          orderitems: [...prevState.orderitems,_kit],
        }));
      }     
    }

    checkKitOrderItems = (sku) => { 
      let _orderitems = this.state.orderitems;
      console.log('_orderitems: ' + JSON.stringify(_orderitems));

      /*
      var _success = this.checkKitQoh(1);
        console.log("_Success: " + _success);
      */        

    }
    
    generateRandomString = function(length=6){
      return Math.random().toString(20).substr(2, length)
    }

    handleCustomerChange = (e) => {
      console.log(e);
      /*
      address: "5333 LIKINI ST APT 1309"
      address1: ""
      city: "HONOLULU"
      company: null
      country: "US"
      firstname: "Logan"
      id: "loganjohnston185@gmail.com"
      label: "Logan Johnston ()"
      lastname: "Johnston"
      notes: undefined
      phone: null
      state: "HI"
      zip: "96818-1766"
      */

     this.setState({cn_id: e.id});
     this.setState({or_ship_fname:e.firstname==null ? '' : e.firstname});
     this.setState({or_ship_lname:e.lastname==null ? '' : e.lastname});
     this.setState({or_ship_email:e.email==null ? '' : e.email});
     this.setState({or_ship_address:e.address==null ? '' : e.address});
     this.setState({or_ship_address1:e.address1==null ? '' : e.address1});
     this.setState({or_city:e.city==null ? '' : e.city});
     this.setState({or_state:e.state==null ? '' : e.state});
     this.setState({or_country:e.country==null ? '' : e.country});
     this.setState({or_zip:e.zip==null ? '' : e.zip});
     this.setState({or_ship_company:e.company==null ? '' : e.company});
     this.setState({or_ship_attention:e.attention==null ? '' : e.attention});
     this.setState({or_ship_phone:e.phone==null ? '' : e.phone});
     this.setState({or_other: e.other==null ? '' : e.other });
    }
    
    handleSubmit = (event) => {

      event.preventDefault();

      this.setState({disableAddOrder:true});
      this.setState({orderAddMessage: "Submitting ... please wait."});

      let _orderitems = this.state.orderitems;

      let _orderblemitems = this.state._orderblemitems;

      console.log(JSON.stringify(_orderblemitems));
      console.log(JSON.stringify(_orderitems));
      console.log(JSON.stringify(this.state.productkititems));
      
      

      var kitOk;
      kitOk = this.checkKitQoh();

      console.log("kitOk: " + kitOk);

      //return true;

      if (kitOk == false) {
        alert('Not all kit items can be ordered.');
        this.setState({disableAddOrder:true});
        this.setState({orderAddMessage: "Order cannot be submitted ... please correct kit item quantities and try again."});
        return false;
      }
      
      var i;

      if (_orderitems.length < 1 && _orderblemitems < 1) {
        alert('Add an order item to continue...');
        this.setState({disableAddOrder:false});
        this.setState({orderAddMessage: ""});
        return false;          
      }

      for (i = 0; i < _orderitems.length; i++) {
        
        console.log("item detail")
        console.log(JSON.stringify(_orderitems[i]));
        
        var _sku =  _orderitems[i].pr_sku;
        var _qty =  _orderitems[i].qty;
        var _qoh =  _orderitems[i].qoh;
        
        var kitItemOk;
        kitItemOk = this.checkKitQoh(_qty);
        console.log('kitItemOk: ' + kitItemOk);
        if (kitItemOk==false) {
          this.setState({disableAddOrder:true});
          this.setState({orderAddMessage: "Order cannot be submitted ... please correct kit item quantities and try again."});
          return false;
        } 
      }
      
      
      for (i = 0; i < _orderitems.length; i++) {
        var _sku =  _orderitems[i].pr_sku;
        var _qty =  _orderitems[i].qty;
        var _qoh =  _orderitems[i].qoh;
        var _csid =  _orderitems[i].cs_id;
        var _csqty =  _orderitems[i].cs_qty;
        var _qtyavail = _orderitems[i].qty_avail;        

        if (_qtyavail < 1) {
          alert(_sku + ' does not have enough QOH. Only '+_qoh+ ' can be ordered. Please adjust and try again.');
          this.setState({disableAddOrder:false});
          this.setState({orderAddMessage: ""});
          return false;  
        }

        if (_csid > 0) {
          _qty = _qty * _csqty;
        }
        
        if (_qoh==0) {
          alert(_sku + ' has 0 QOH and cannot be ordered. Please remove and try again.');
          this.setState({disableAddOrder:false});
          this.setState({orderAddMessage: ""});
          return false;
          
        }
        if (_qty > _qoh) {
          alert(_sku + ' does not have enough QOH. Only '+_qoh+ ' can be ordered. Please adjust and try again.');
          this.setState({disableAddOrder:false});
          this.setState({orderAddMessage: ""});
          return false;  
        }
      }      
      if (this.state.or_ship_address === '' || this.state.or_ship_city === '' || this.state.or_ship_state === '' || this.state.or_zip === '' || this.state.or_ship_country === '') {
          alert('Please enter a full shipping address');
          this.setState({disableAddOrder:false});
          this.setState({orderAddMessage: ""});
          return false;
      }
      if (this.state.or_type==='') {
          alert('Please select an order type');
          this.setState({disableAddOrder:false});
          this.setState({orderAddMessage: ""});
          return false;
      }
      /* TODO - fix validation
      if (this.state.or_carrier !=='') {
        if (this.state.or_carrier_service=='') {
          alert('Please complete shipment options');
          return false;
        }
      } else {
        alert('Please complete shipment options');
        return false;
      }        
      */
   

      //1. What is the order type? 
      var fullName = this.state.or_ship_fname.trim() + ' ' + this.state.or_ship_lname.trim();
      
      //SHIPSTATION, craft SS JSON and send order
      
      console.log('type : ' + this.state.or_type);      
      console.log('type account: ' + this.state.or_type_account); 
      //return true;      
      
      if (this.state.or_type == 'Small Package') {
        if (this.state.or_type_account == '') {
            alert('Please select a Shipping Account to use');
            this.setState({orderAddMessage: ""});
            return false;
        }
      }

      if (this.state.or_type_account === 'BW SHIPSTATION ACCOUNT' || this.state.or_type_account === 'CLIENT SHIPSTATION ACCOUNT' || this.state.or_small_service_type === 'Account Info') {          

        if (this.state.or_small_service_type === 'Account Info') {
          if (this.state.or_small_acct.trim()==='' || this.state.or_small_zip.trim()==='' || this.state.or_small_country.trim()==='') {
            alert('This order type requires an Account Number, Billing Country and Zip Code');
            this.setState({orderAddMessage: ""});
            return false;
          }
        }
        //add support for selecting different accounts, for now, use the saved SS settings for each client

        if (this.state.cl_shipstation_manual_id ==='') {
          alert('Your account is not configured for Shipstation orders. Please select a different order type contact support');
          this.setState({disableAddOrder:false});
          this.setState({orderAddMessage: ""});
          return false;
        }

        var billTo = {
          name: fullName,
          company: this.state.or_ship_company,
          street1: this.state.or_ship_address,
          street2: this.state.or_ship_address1,
          street3: this.state.or_ship_attention + ' ' + this.state.or_other,
          city: this.state.or_city,
          state: this.state.or_state,
          postalCode: this.state.or_zip,
          country: this.state.or_country,
          phone: this.state.or_ship_phone,
          residential: null
        };

        var shipTo = {
          name: fullName,
          company: this.state.or_ship_company,
          street1: this.state.or_ship_address,
          street2: this.state.or_ship_address1,
          street3: this.state.or_ship_attention + ' ' + this.state.or_other,
          city: this.state.or_city,
          state: this.state.or_state,
          postalCode: this.state.or_zip,
          country: this.state.or_country,
          phone: this.state.or_ship_phone,
          residential: true
        };

        var _items = [];

       // console.log(this.state.orderitems);

        let _orderitems = this.state.orderitems;
        var m;
        let _totalweight=0;

        for (m = 0; m < _orderitems.length; m++) {

          _totalweight +=_orderitems[m].pr_weight;

          var _itemweight = {
            value: _orderitems[m].pr_weight,
            units: 'pounds'
          }

          var _item = {
            lineItemKey: _orderitems[m].id,
            sku: _orderitems[m].pr_sku,
            name: _orderitems[m].pr_desc, 
            imageUrl: null,
            weight: _itemweight,
            quantity: _orderitems[m].qty
          } 

          _items.push(_item);
        }     

        var _objTotalWeight = {
          value: _totalweight,
          units: 'pounds'
        }

        var _advancedOptions;

        if (this.state.or_small_acct !== '') {
          _advancedOptions = {
            storeId: this.state.cl_shipstation_manual_id,         
            billToPostalCode: this.state.or_small_zip,
            billToAccount: this.state.or_small_acct,
            billToCountryCode: this.state.or_small_country,
            billToParty: 'third_party',
            customField1: this.state.or_ship_attention,
            customField2: this.state.or_other
          }
        } else {
          _advancedOptions = {
            storeId: this.state.cl_shipstation_manual_id,
            customField1: this.state.or_ship_attention,
            customField2: this.state.or_other,
            source: this.state.or_type
          }
        }

        console.log('adv: ' + JSON.stringify(_advancedOptions));
        
        let _ss_order_id = this.state.or_ss_order_id;
        let _orderNumber = this.generateRandomString();

        if (_ss_order_id !=='') {
          _orderNumber = _ss_order_id;
        }          
        
        var isoString = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSSSSS');
        var isoShipByDate;

        if (this.state.or_ship_by !=='') {
          isoShipByDate = moment(this.state.or_ship_by).format('YYYY-MM-DD[T]HH:mm:ss.SSSSSSS');
        } else {
          isoShipByDate = isoString;
        }        

        let _carrier_service = this.state.or_carrier_service;
        //let _carrier_service_code = '';

        /*
        if (this.state.or_carrier === 'FedEx') {
          if (_carrier_service === '3 Day') {
            _carrier_service_code = 'fedex_3day';
          }
          if (_carrier_service === '2 Day') {
            _carrier_service_code = 'fedex_2day';
          }
          if (_carrier_service === '1 Day') {
            _carrier_service_code = 'fedex_standard_overnight';
          }
          if (_carrier_service === 'Ground') {
            _carrier_service_code = 'fedex_ground';
          }
          
        }
        if (this.state.or_carrier === 'UPS') {
          if (_carrier_service === '3 Day') {
            _carrier_service_code = '12';
          }
          if (_carrier_service === '2 Day') {
            _carrier_service_code = 'ups_second_day_air';
          }
          if (_carrier_service === '1 Day') {
            _carrier_service_code = 'ups_next_day_air_saver';
          }
          if (_carrier_service === 'Ground') {
            _carrier_service_code = 'ups_ground';
          }
          
        }

        if (this.state.or_carrier === 'USPS') {         
          if (_carrier_service === 'Priority') {
            _carrier_service_code = 'usps_priority';
          }
          if (_carrier_service === 'First Class') {
            _carrier_service_code = 'usps_first';
          }
          if (_carrier_service === 'Ground') {
            _carrier_service_code = 'usps_priority';
          }
          
        }
        */

        if (this.state.or_carrier === 'UPS' || this.state.or_carrier === 'USPS' || this.state.or_carrier === 'FedEx') {
          if (_carrier_service==='') {
            _carrier_service = "Ground"
          }
        }
               
        let sOrder = {
          orderNumber: _orderNumber,
          orderDate: isoString,
          shipByDate: isoShipByDate,
          orderStatus: "awaiting_shipment",
          customerUsername: this.state.or_ship_email,
          customerEmail: this.state.or_ship_email,
          billTo: billTo,
          shipTo: shipTo,
          items: _items,
          internalNotes: this.state.or_note,
          requestedShippingService: this.state.or_carrier + ' ' + _carrier_service,          
          advancedOptions: _advancedOptions,
          weight: _objTotalWeight
        };
        
        var sOrderJSON = JSON.stringify(sOrder);
        console.log(sOrderJSON);

        var sAdvOptionsJSON = JSON.stringify(_advancedOptions);
        console.log(sAdvOptionsJSON);

        fetch(process.env.REACT_APP_NODE_ROOT_URL+'/export/'+this.state.cl_id, {
            method: 'post',        
            body: sOrderJSON,      
            headers: {     
            'Content-Type': 'application/json',
            'bwToken': this.state.userToken          
            }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            if (response.status==200) {
              alert('Order added successfully.'); 
              window.location.replace('/orders');         
            }
        }).then(function(data) { 
            console.log(data);
        }).catch(function(error) {
            alert('Order could not be added'); 
            console.log('submit err: ' + error);       
        });      
      
      } else {

        let _carrierService;
        let _smalllabel="No";          
        let _bol ="No";
        let _or_type = this.state.or_type;
        let _ss_order_id = this.state.or_ss_order_id;
        let _orderNumber = this.generateRandomString();

        if (_ss_order_id !=='') {
          _orderNumber = _ss_order_id;
        }  

        if (this.state.or_type_account === 'Client Provided BOL')
        {
          _carrierService = "Client Provided BOL"
          _bol="Yes";
        } else {
          _carrierService = this.state.or_carrier_service;
        }
        if (this.state.or_small_service_type === 'Upload Label')
        {
          _carrierService = "Client Provided Label"
          _smalllabel="Yes"
        } else {
          _carrierService = this.state.or_carrier_service;
        }       

        let _carrier = this.state.or_carrier;

        if (this.state.or_type == 'Freight') {
          _carrier = 'Freight';
          _or_ship_method = 'Freight';
        }

        let _or_ship_method = _carrier + ' ' + _carrierService;        

        if (_or_type=='Will Call') {
          _carrier = 'Will Call';
          _or_ship_method = 'Will Call';
        }

        var orderJSON = JSON.stringify({
            cl_id: this.state.cl_id,
            or_ship_fname: this.state.or_ship_fname,
            or_ship_lname: this.state.or_ship_lname,
            or_ship_email: this.state.or_ship_email,
            or_ship_address: this.state.or_ship_address,
            or_ship_address1: this.state.or_ship_address1,
            or_status: this.state.or_status,            
            or_ship_by: this.state.or_ship_by,
            or_city: this.state.or_city,
            or_state: this.state.or_state,
            or_zip: this.state.or_zip,
            or_country: this.state.or_country,
            or_ship_phone: this.state.or_ship_phone,
            or_ship_company: this.state.or_ship_company,
            or_ship_attention: this.state.or_ship_attention,
            or_key: this.state.or_key,
            or_internal_notes: this.state.or_note,            
            or_customer_notes: this.state.or_customer_notes,
            or_ship_cost: this.roundThis(this.state.or_ship_cost),
            or_subtotal: this.roundThis(this.state.or_subtotal),
            or_total: this.roundThis(this.state.or_total),
            or_tax: this.roundThis(this.state.or_tax),
            or_oe: this.state.or_oe,
            or_type: this.state.or_type,
            or_ship_method: _or_ship_method,
            or_carrier: _carrier,
            or_carrier_service: _carrierService,
            or_freight_service_type: this.state.or_freight_service_type,
            or_freight_express_date: this.state.or_freight_express_date,  
            or_freight_nmfc: this.state.or_freight_nmfc,
            or_freight_class: this.state.or_freight_class,
            or_freight_bol: _bol,          
            or_small_service_type: this.state.or_small_service_type,
            or_small_zip: this.state.or_small_zip,
            or_small_acct: this.state.or_small_acct,
            or_small_label: _smalllabel,    
            or_small_country: this.state.or_small_country,
            or_wc_name: this.state.or_wc_name,
            or_wc_phone: this.state.or_wc_phone,
            or_wc_time: this.state.or_wc_time,
            or_ss_order_id: _orderNumber,
            or_other: this.state.or_other,            
            orderitems: this.state.orderitems,
            orderblemitems: this.state.orderblemitems, 
            or_us_id: this.state.us_id
          });

            console.log('logging json...');
            console.log(orderJSON);

           // return true;
          
          fetch(process.env.REACT_APP_NODE_ROOT_URL+'/orderentry/'+this.state.cl_id, {
                  method: 'post',        
                  body: orderJSON,      
                  headers: {            
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'bwToken': this.state.userToken          
                  }
                  //TODO:
                  //credentials: 'same-origin', // send cookies
                  //credentials: 'include'   // send cookies, even in CORS
              
              }).then(function(response) {  
                  return response.json();
              }).then(orderid => this.setState({ orderid }))
                .then(this.sendAttach)
                .then(alert('Order added successfully.'))
                .then(window.location.replace('/orders'))             
                .catch(function(error) {
                  console.log(error);   
                        
              });                          
          
        } 
      
    }

    handleQtyInputChange(event,i) {

      const target = event.target;
      const value = target.value;
      //let _cur_weight = Number(this.state.total_weight);
      //let total_weight = 0;
           
      let _cur_qty = value;

      if (_cur_qty==='') {
        _cur_qty=0;
      }
     
      let {orderitems} = this.state;
      orderitems[i].qty = _cur_qty;

      this.setState({disableAddOrder:false}); 

      

      /*figure out total one day 
      let _temp_weight = Number(_cur_weight+(_cur_qty*orderitems[i].pr_weight));
      console.log('temp weight: ' + _temp_weight);
      
      //_temp_weight = _temp_weight.toFixed(4);
      if (_temp_weight > 0) {
        total_weight = parseFloat(_temp_weight.toFixed(4));
        console.log('total weight: ' + total_weight);
        
      } else {
        total_weight = 0.000;
            }
      this.setState({total_weight});
      */

      this.setState({orderitems});

    }

    handleInputChange(event) {
   
      const target = event.target;
      const value = target.value;
      const name = target.name;

      //console.log('order_num: ' + value)

      this.setState({
       [name]: value
      });

      //if (name=='or_ship_cost') {
      //  this.calculateOrderTotal();
      //}
    }

    handleKeyPress = (event) => {
      if(event.key === 'Enter'){
          //set upc from search box
          var find_upc = this.state.upc_search;
          
          this.findUPC(find_upc);       
          
          //used to track total scans if needed
          //this.setState((state) => ({ scans: state.scans + 1}));
          
          //TODO: check case value 
          
      }
    } 

    onRemoveItem = i => {
      
      this.setState(state => {
      const orderitems = state.orderitems.filter((item, j) => i !== j);
        return {
          orderitems,
        };
      });

      //this.calculateOrderTotal();
    }

    onRemoveBlemItem = i => {
      
      this.setState(state => {
      const orderblemitems = state.orderblemitems.filter((item, j) => i !== j);
        return {
          orderblemitems,
        };
      });

      //this.calculateOrderTotal();
    }

    calculateOrderTotal(){
      let { orderitems } = this.state;

      var _subtotal = orderitems.reduce((sum, i) => (
            sum = sum + Number(i.qty || 0) * Number(i.price)
          ), 0)

      _subtotal = this.roundThis(_subtotal); //parseFloat(Math.round(_subtotal * 100) / 100).toFixed(2);

      var _taxDecimal = this.state.cl_tax_rate * .01;  

      var _tax = Number(_taxDecimal) * orderitems.reduce((sum, i) => (
            sum = sum + Number(i.qty || 0) * Number(i.price)
          ), 0)

      var _total = _tax + Number(this.state.or_ship_cost) + orderitems.reduce((sum, i) => (
            sum += Number(i.qty || 0) * Number(i.price)
          ), 0)

      //_total = _total + or_ship_cost;

      console.log('sutotal: ' + _subtotal);
      console.log('tax: ' + _tax);

      //console.log('tax: ' + _tax);
      console.log('total: ' + _total);

      this.setState({or_subtotal: _subtotal});
      this.setState({or_tax: _tax});
      this.setState({or_total: _total});
    }

    roundThis(value) {
      return parseFloat(Math.round(value * 100) / 100).toFixed(2);
    }


    render(){
      
      const orderTypes = [
        'Freight','Small Package','Will Call' 
      ]

      const freightAccounts = [
        'BW Freight Account', 'Client Provided BOL' 
      ]

      const freightServiceType = ['Standard','Express']      
       
      const smallCustomerService = ['Upload Label','Account Info']
      const smallAccountCarrier = ['UPS', 'FedEx', 'USPS']
      const smallAccountCarrierService = ['3 Day','2 Day','1 Day']
      const smallAccountCarrierServiceUSPS = ['Priority','First Class']
        
      
      const orderViewDate = moment(this.state.or_date).format('MM/DD/YYYY hh:mm:ss A');

      //order items
      //const mappingFunction = p => ({ id: p.pr_id, label: p.pr_desc, sku: p.pr_sku, price: p.pr_price, weight: p.pr_weight, itemtotal: '' });

      const mappingFunction = p => ({ label: p.pr_sku + ' - ' + p.pr_desc + ' (' + p.pr_upc + ')', value: p.pr_id, cs_id: 0, qty:1, qoh: p.pr_qty, qty_res: p.pr_qty_reserved, pr_id: p.pr_id, cl_id: p.cl_id, pr_desc: p.pr_desc, pr_sku: p.pr_sku, pr_weight: p.pr_weight, cs_qty:0 });
      const blemFunction = p => ({ label: p.pr_sku + ' - ' + p.pr_desc + ' (' + p.pr_upc + ')', value: p.pr_id, cs_id: 0, qty:1, qoh: p.pr_qty, qty_res: p.pr_qty_reserved, pr_id: p.pr_id, cl_id: p.cl_id, pr_desc: p.pr_desc, pr_sku: p.pr_sku, pr_weight: p.pr_weight, cs_qty:0, ri_id: p.ri_id });

      //or_ship_fname, or_ship_lname, or_ship_email, or_ship_address, or_ship_address1, or_city, or_state, or_zip, or_country, or_ship_company, or_ship_phone, or_customer_notes
      //const customerMappingFunction = c => ({ id: c.or_ship_email, label: c.or_ship_fname==''?c.or_ship_company:c.or_ship_fname + ' ' + c.or_ship_lname, firstname: c.or_ship_fname, lastname: c.or_ship_lname, address: c.or_ship_address, address1: c.or_ship_address1, city: c.or_city, state: c.or_state, zip: c.or_zip, country: c.or_country, company: c.or_ship_company, attention: c.or_ship_attention, phone: c.or_ship_phone, other: typeof(c.other) === 'undefined' ? '' : c.other });
      //const customerMappingFunction = c => ({ id: c.cn_id, label: c.cn_firstname===''?c.cn_company:c.cn_firstname + ' ' + c.cn_lastname, firstname: c.cn_firstname, lastname: c.cn_lastname, address: c.cn_ship_address, address1: c.cn_ship_address1, city: c.cn_city, state: c.cn_state, zip: c.cn_zip, country: c.cn_country, company: c.cn_company, attention: c.cn_attention, phone: c.cn_phone, email: c.cn_email, other: typeof(c.cn_other) === 'undefined' ? '' : c.cn_other });
      const customerMappingFunction = c => ({ id: c.cn_id, label: c.cn_firstname + ' ' + c.cn_lastname + ' ' + c.cn_company, firstname: c.cn_firstname, lastname: c.cn_lastname, address: c.cn_ship_address, address1: c.cn_ship_address1, city: c.cn_city, state: c.cn_state, zip: c.cn_zip, country: c.cn_country, company: c.cn_company, attention: c.cn_attention, phone: c.cn_phone, email: c.cn_email, other: typeof(c.cn_other) === 'undefined' ? '' : c.cn_other });

      //const caseFunction = p => ({ id: p.cs_id, label: p.cs_name, sku: p.cs_sku });
      const caseFunction = p => ({ label: p.cs_sku + ' - ' + p.cs_name + ' (' + p.cs_upc + ')', value: p.cs_id, cs_id: p.cs_id, qty:1, qoh: p.pr_qty, qty_res: p.pr_qty_reserved, pr_id: p.pr_id, cl_id: p.cl_id, pr_desc: p.cs_name, pr_sku: p.cs_sku, pr_weight: p.pr_weight, cs_qty: p.cs_qty });
      const kitFunction = p => ({ label: p.kt_sku + ' - ' + p.kt_name + ' (' + p.kt_upc + ')', value: p.kt_id, kt_id: p.kt_id, qty:1, qoh: p.pr_qty, qty_res: p.pr_qty_reserved, pr_id: p.kt_id, cl_id: p.cl_id, pr_desc: p.kt_name, pr_sku: p.kt_sku, pr_weight: 0, cs_qty: 0 });

      //product drop down
      const productsearch = this.state.products.map(mappingFunction);
      const productblemsearch = this.state.productblems.map(blemFunction);
      const productcasesearch = this.state.productcases.map(caseFunction);
      const productkitsearch = this.state.productkits.map(kitFunction);
      const customersearch = this.state.customers.map(customerMappingFunction);
            
      const { case_search, blem_search, kit_search, upc_search, customer_search, cl_ship_methods } = this.state;

        return (
          <div id="main">
            <div className="page-wrapper">
              <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                  <h3 className="text-primary">Order Entry</h3> </div>
                <div className="col-md-7 align-self-center"> 
                  Order Date: {orderViewDate}                 
                </div>
              </div>
            <div className="container-fluid">               
      
            <h3>Ship To</h3>
            <div className="row">
              <div className="col-sm-12">
                Select a previous customer:<br/>                    
                <Select options={customersearch} className="customer-drop" name="customer_search" onChange={this.handleCustomerChange} value={customer_search} placeholder="Search customers" />                  
                <br/><br/>          
              </div>  
            </div>               
            <div className="row">
              <div className="col-sm-4"><label>
                          <label>
                           ~ Order ID/PO Number:<br/>                            
                            <input
                              name="or_ss_order_id"
                              type="text"
                              value={this.state.or_ss_order_id}
                              onChange={this.handleInputChange}
                              onBlur={this.getOrder} />
                          </label>
                          <br />                          
                            ~ First Name:<br/>
                            <input
                              name="or_ship_fname"
                              type="text"
                              value={this.state.or_ship_fname}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            ~ Last Name:<br/>
                            <input
                              name="or_ship_lname"
                              type="text"
                              value={this.state.or_ship_lname}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <p><strong>AND/OR</strong></p>
                          <label>
                            ~ Company:<br/>
                            <input
                              name="or_ship_company"
                              type="text"
                              value={this.state.or_ship_company}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            ~ Attention To:<br/>
                            <input
                              name="or_ship_attention"
                              type="text"
                              value={this.state.or_ship_attention}
                              onChange={this.handleInputChange} />
                          </label>
                          <br/>                         
                          <label>
                            ~ Other:<br/>
                            <input                              
                              name="or_other"
                              type="text"
                              value={this.state.or_other}
                              onChange={this.handleInputChange} />
                          </label> <br/>         
                          <strong>~</strong> these fields all appear as part of the address <br/>on the packing slip and shipping label. None are required.
                          <br/><br/>
                          <div>
                          <button type="button" className="btn btn-info btn-sm" onClick={() => { if (window.confirm('Save details as a new contact?')) this.createContact() } }>Save as New</button>&nbsp;<button type="button" className="btn btn-info btn-sm" onClick={() => { if (window.confirm('Update contact?')) this.updateContact() } }>Update</button>&nbsp;<button type="button" className="btn btn-info btn-sm" onClick={() => { if (window.confirm('Delete this contact?')) this.deleteContact() } }>Delete</button>&nbsp;
                          </div>                                        
                          </div>
                          <div className="col-sm-4"><label>
                            * Address 1:<br/>
                            <input
                              name="or_ship_address"
                              type="text"
                              value={this.state.or_ship_address}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Address 2:<br/>
                            <input
                              name="or_ship_address1"
                              type="text"
                              value={this.state.or_ship_address1}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            * City:<br/>
                            <input
                              name="or_city"
                              type="text"
                              value={this.state.or_city}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            * State:<br/>
                            <StateSelector state={this.state.or_state} onStateChange={this.handleShipStateChange}/>                            
                          </label>
                          <br />
                          <label>
                            * Zip:<br/>
                            <input
                              name="or_zip"
                              type="text"
                              value={this.state.or_zip}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            * Country:<br/>
                             <CountrySelector country={this.state.or_country} onCountryChange={this.handleShipCountryChange}/>
                          </label>                        
                          <br />
                          <br />
                          <label>
                            Email:<br/>
                            <input
                              name="or_ship_email"
                              type="text"
                              value={this.state.or_ship_email}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />
                          <label>
                            Phone:<br/>
                            <input
                              name="or_ship_phone"
                              type="text"
                              value={this.state.or_ship_phone}
                              onChange={this.handleInputChange} />
                          </label>
                          <br />                          
                          </div>
                          <div className="col-sm-4">
                          <label>
                            * Order Type:<br/>
                            <select value={this.state.or_type} 
                                  onChange={(e) => this.setState({or_type: e.target.value})}>
                                    <option value="">-- select a type --</option>
                            {orderTypes.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                          </select>
                          </label>                                                  
                          <br />
                          <label>                                                     
                            {this.state.or_type === 'Freight' &&                              
                            <div>                              
                              * Shipping Account To Use:<br/> 
                              <select value={this.state.or_type_account} 
                                      onChange={(e) => this.setState({or_type_account: e.target.value})}>
                                        <option value="">-- select an account --</option>
                                {freightAccounts.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                              </select>
                              <br/><br/>
                              {this.state.or_type_account !== 'Client Provided BOL' &&                              
                                <div>                                  
                                  * Shipment Carrier and Service:<br/>
                                  <div>                                                        
                                    <select value={this.state.or_carrier_service} 
                                          onChange={(e) => this.setState({or_carrier_service: e.target.value})}>
                                            <option value="">-- select a service type --</option>
                                      {freightServiceType.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                                    </select>
                                    {this.state.or_carrier_service === 'Express' &&
                                    <div>
                                      Desired Delivery Date:<br/>
                                      <DateTimePicker
                                        disableClock={true}
                                        onChange={or_freight_express_date => this.setState({ or_freight_express_date })}
                                        value={this.state.or_freight_express_date}
                                        format={"MM-dd-y h:mm:ss a"}
                                      />                             
                                    
                                    </div>
                                    }
                                    <br/><br/>                              
                                    <div>
                                    NMFC Number/Description:<br/>                             
                                    <input
                                      name="or_freight_nmfc"
                                      type="text"
                                      placeholder="NMFC # / Desc"
                                      value={this.state.or_freight_nmfc}
                                      onChange={this.handleInputChange} /> 
                                      <br/><br/>
                                      Freight Class:<br/> 
                                      <input
                                      name="or_freight_class"
                                      type="text"
                                      placeholder="Freight Class"
                                      value={this.state.or_freight_class}
                                      onChange={this.handleInputChange} /> 
                                    </div>
                                    <div>
                                      <br/>                                    
                                      Pick-up Info:<br/> 
                                    <input
                                      name="or_wc_name"
                                      type="text"
                                      placeholder="Contact Name"
                                      value={this.state.or_wc_name}
                                      onChange={this.handleInputChange} />                             
                                      <br/><br/>
                                      <input
                                      name="or_wc_phone"
                                      type="text"
                                      placeholder="Contact Number"
                                      value={this.state.or_wc_phone}
                                      onChange={this.handleInputChange} />
                                      <br/><br/>
                                      <input
                                      name="or_wc_time"
                                      type="text"
                                      placeholder="Pick Up Time"
                                      value={this.state.or_wc_time}
                                      onChange={this.handleInputChange} /> 
                                    </div>
                                    
                                    </div>
                                </div>
                              }
                              {this.state.or_type_account === 'Client Provided BOL' &&
                              <div>
                                <button type="button" className="btn btn-info btn-sm" onClick={this.openFilesModal}>Upload BOL</button>
                                <br/><br/>
                               {this.state.fileToUploadMsg}
                              </div>
                              }                              
                            
                            </div>
                            }
                            {this.state.or_type === 'Small Package' &&
                            <div>                            
                              * Shipping Account To Use:<br/> 
                              <select value={this.state.or_type_account} 
                                    onChange={(e) => this.setState({or_type_account: e.target.value})}>
                                      <option value="">-- select an account --</option>
                                      {cl_ship_methods.map((_opt) => <option key={_opt.name} value={_opt.name}>{_opt.name}</option>)}
                              </select><br/><br/>
                              <label>
                          
                          {this.state.or_type_account !== 'CLIENT PROVIDED ACCOUNT' &&
                            <div>
                            <select value={this.state.or_carrier} 
                                  onChange={(e) => this.setState({or_carrier: e.target.value})}>
                                    <option value="">-- select a carrier --</option>
                            {smallAccountCarrier.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                            </select><br/><br/>
                              {this.state.or_carrier === 'USPS' &&
                              <div>                             
                              <select value={this.state.or_carrier_service} 
                                      onChange={(e) => this.setState({or_carrier_service: e.target.value})}>
                                        <option value="Ground">Ground</option>
                                {smallAccountCarrierServiceUSPS.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                              </select>
                              </div>
                              }
                              {this.state.or_carrier !== 'USPS' &&
                              <div>                             
                              <select value={this.state.or_carrier_service} 
                                      onChange={(e) => this.setState({or_carrier_service: e.target.value})}>
                                        <option value="Ground">Ground</option>
                                {smallAccountCarrierService.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                              </select>
                              </div>
                              } 
                            </div>
                            }
                            {this.state.or_type_account === 'CLIENT PROVIDED ACCOUNT' &&
                            <div>
                            <select value={this.state.or_small_service_type} 
                                  onChange={(e) => this.setState({or_small_service_type: e.target.value})}>
                                    <option value="">-- select a shipment option --</option>
                            {smallCustomerService.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                          </select><br/><br/>
                          <select value={this.state.or_carrier} 
                                  onChange={(e) => this.setState({or_carrier: e.target.value})}>
                                    <option value="">-- select a carrier --</option>
                            {smallAccountCarrier.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                            </select><br/><br/>
                            {this.state.or_carrier === 'USPS' &&
                              <div>                             
                              <select value={this.state.or_carrier_service} 
                                      onChange={(e) => this.setState({or_carrier_service: e.target.value})}>
                                        <option value="Ground">Ground</option>
                                {smallAccountCarrierServiceUSPS.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                              </select>
                              </div>
                              }
                              {this.state.or_carrier !== 'USPS' &&
                              <div>                             
                              <select value={this.state.or_carrier_service} 
                                      onChange={(e) => this.setState({or_carrier_service: e.target.value})}>
                                        <option value="Ground">Ground</option>
                                {smallAccountCarrierService.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                              </select>
                              </div>
                              } 
                              <br/>
                          {this.state.or_small_service_type === 'Account Info' &&
                            <div>
                            * Account Number<br/>
                             <input
                              name="or_small_acct"
                              type="text"
                              placeholder="Account Number"
                              value={this.state.or_small_acct}
                              onChange={this.handleInputChange} />
                            <br/><br/>
                           * Billing Zip Code<br/>
                            <input
                              name="or_small_zip"
                              type="text"
                              placeholder="Billing Zip Code"
                              value={this.state.or_small_zip}
                              onChange={this.handleInputChange} /> <br/>
                            <br />
                            <label>
                           * Billing Country:<br/>
                              <BillingCountrySelector country={this.state.or_small_country !='' ? 'US' : this.state.or_small_country} onBillingCountryChange={this.handleBillingCountryChange}/>
                            </label>
                           
                          </div>
                            }
                             {this.state.or_small_service_type === 'Upload Label' &&
                            <div>
                             <button type="button" className="btn btn-info btn-sm" onClick={this.openFilesModal}>Upload Label</button>
                          </div>
                            }
                          </div>
                            }                            

                                          
                          </label>      
                          <br/>


                          </div>
                            }
                            {this.state.or_type === 'Will Call' &&
                            <div>
                            <input
                            name="or_wc_name"
                            type="text"
                            placeholder="Contact Name"
                            value={this.state.or_wc_name}
                            onChange={this.handleInputChange} />                             
                            <br/><br/>
                            <input
                            name="or_wc_phone"
                            type="text"
                            placeholder="Contact Number"
                            value={this.state.or_wc_phone}
                            onChange={this.handleInputChange} />
                            <br/><br/>
                            <input
                            name="or_wc_time"
                            type="text"
                            placeholder="Pick Up Time"
                            value={this.state.or_wc_time}
                            onChange={this.handleInputChange} /> 

                            </div>

                            }
                            
                          </label>
                          <br/>
                          {this.state.or_type === 'Will Call' &&
                          <label>
                            Order Note:<br/>
                            <input
                              style={{width:"200px"}}
                              name="or_note"
                              type="text"
                              value={this.state.or_note}
                              onChange={this.handleInputChange} />
                          </label>
                          }             
                             
                          
                          </div>
            </div>                
                
                    <div className="row">  
                      <div className="col-md-6">
                        <h3>Select a Product to add...</h3>
                        <Select options={productsearch} className="product-drop" name="upc_search" onChange={this.handleProductChange} value={upc_search} placeholder="Search products" />
                      </div>                      
                    </div>
                    <div className="row">  
                      <div className="col-md-6">
                        <h3>Select a Blemished Product to add...</h3>
                        <Select options={productblemsearch} className="product-drop" name="blem_search" onChange={this.handleProductBlemChange} value={blem_search} placeholder="Search blemished products" />
                      </div>                      
                    </div>
                    <div className="row">  
                      <div className="col-md-6">
                        <h3>Select a Product Case to add...</h3>
                        <Select options={productcasesearch} className="product-drop" name="case_search" onChange={this.handleProductCaseChange} value={case_search} placeholder="Search product cases" />
                      </div>
                    </div>
                    <div className="row">  
                      <div className="col-md-6">
                        <h3>Select a Product Kit to add...</h3>
                        <Select options={productkitsearch} className="product-drop" name="kit_search" onChange={this.handleProductKitChange} value={kit_search} placeholder="Search product kits" />
                      </div>                     
                      
                    </div>
                    <div style={{marginLeft:"20px"}}>
                    <h3>{this.state.productkitname}</h3>
                    {this.state.productkititems.map((kititem, idx) => {
                
                      let ktblId = `tb-${idx}`;
                      let ktrId = `tr-${idx}`; 
                      return (
                      <span key={ktblId} style={kitRow}>                        
                        {this.state.productkititems[idx].pr_qty <= 0 &&                  
                          <span style={{color:"red"}}><strong>{this.state.productkititems[idx].pr_sku}</strong>&nbsp;&nbsp;&nbsp;{this.state.productkititems[idx].pr_desc}&nbsp;&nbsp;&nbsp;<strong>KIT ITEM QTY: {this.state.productkititems[idx].ki_qty}</strong>&nbsp;&nbsp;&nbsp;<strong>QOH: {this.state.productkititems[idx].pr_qty}</strong>&nbsp;&nbsp;&nbsp;<strong>KITS AVAIL: {Math.floor(this.state.productkititems[idx].pr_qty/this.state.productkititems[idx].ki_qty)}</strong></span>
                        }
                        {this.state.productkititems[idx].pr_qty > 0 &&                  
                          <span><strong>{this.state.productkititems[idx].pr_sku}</strong>&nbsp;&nbsp;&nbsp;{this.state.productkititems[idx].pr_desc}&nbsp;&nbsp;&nbsp;<strong>KIT ITEM QTY: {this.state.productkititems[idx].ki_qty}</strong>&nbsp;&nbsp;&nbsp;<strong>QOH: {this.state.productkititems[idx].pr_qty}</strong>&nbsp;&nbsp;&nbsp;<strong>KITS AVAIL: {Math.floor(this.state.productkititems[idx].pr_qty/this.state.productkititems[idx].ki_qty)}</strong></span>
                        }                    
                        <br/>
                        
                      </span>
                             
                      )
                    })                 
                    }                   
                    </div>
                    <div className="row">  
                      <div className="col-md-9">  
                      <div id="div-order-items" className="form-group">
                         <hr/>
                         <h3>Order Items</h3>
                          <table className="table-order-items">
                            <thead> 
                              <tr>
                                <td className="td-oi-sku">SKU</td>
                                <td className="td-oi-label">Name</td>  
                                <td className="td-oi-qty">Qty Available (items)</td>
                                <td className="td-oi-qty">Qty</td>
                                <td className="td-oi-qty">Case Qty</td>
                                <td className="td-oi-price">Total Item Weight </td>
                                <td className="td-oi-rem">Remove</td>                              
                              </tr>
                            </thead>
                            {this.state.orderitems.map((orderitem, idx) => {
                              let tblId = `tbl-${idx}`;
                              let nameId = `item-${idx}`;
                              let _cs_qty = this.state.orderitems[idx].cs_qty;
                              
                              //var _success = this.checkKitQoh(this.state.orderitems[idx].qoh);
                              //console.log("_Success: " + _success);

                              if (_cs_qty === 0) {
                                _cs_qty = 1
                              }

                              return (
                                  
                                  <tbody key={tblId}>
                                  <tr>
                                    <td className="td-oi-sku">{this.state.orderitems[idx].pr_sku}</td>
                                    <td className="td-oi-label">{this.state.orderitems[idx].pr_desc}</td>                                                                   
                                    <td className="td-oi-qty">{this.state.orderitems[idx].qoh}</td>                                
                                    <td className="td-oi-qty"><input name={nameId} 
                                      type="text" 
                                      disabled={this.state.qoh<1}
                                      value={this.state.orderitems[idx].qty || 0} 
                                      onBlur={(e) => {this.handleKitQtyKeyPress(e)}}            
                                      onChange={(e) => {this.handleQtyInputChange(e, idx)}}                                      
                                      className="qty_field" />
                                      
                                    </td>
                                    <td className="td-oi-qty">{this.state.orderitems[idx].cs_qty}</td>  
                                    <td className="td-oi-qty">{((this.state.orderitems[idx].pr_weight*_cs_qty)*this.state.orderitems[idx].qty).toFixed(4)} lbs.</td> 
                                    <td className="td-oi-rem"><button
                                            type="button"
                                            onClick={() => this.onRemoveItem(idx)}
                                          >
                                            X
                                          </button>
                                    </td>
                                  </tr>
                                 
                                  </tbody>                                 
                                )
                              })                
                            }
                            {this.state.orderblemitems.map((orderitem, idx) => {
                              let tblId = `tbl-${idx}`;
                              let nameId = `item-${idx}`;
                              let _cs_qty = this.state.orderblemitems[idx].cs_qty;
                              
                              //var _success = this.checkKitQoh(this.state.orderitems[idx].qoh);
                              //console.log("_Success: " + _success);

                              if (_cs_qty === 0) {
                                _cs_qty = 1
                              }

                              return (
                                  
                                  <tbody key={tblId}>
                                  <p>Blemished Items</p>
                                  <tr>
                                    <td className="td-oi-sku">{this.state.orderblemitems[idx].pr_sku}</td>
                                    <td className="td-oi-label">{this.state.orderblemitems[idx].pr_desc}</td>                                                                   
                                    <td className="td-oi-qty">{this.state.orderblemitems[idx].qoh}</td>                                
                                    <td className="td-oi-qty"><input name={nameId} 
                                      type="text" 
                                      disabled={this.state.qoh<1}
                                      value={this.state.orderblemitems[idx].qty || 0}                                                                             
                                      className="qty_field" />
                                      
                                    </td>
                                    <td className="td-oi-qty">{this.state.orderblemitems[idx].cs_qty}</td>  
                                    <td className="td-oi-qty">{((this.state.orderblemitems[idx].pr_weight*_cs_qty)*this.state.orderblemitems[idx].qty).toFixed(4)} lbs.</td> 
                                    <td className="td-oi-rem"><button
                                            type="button"
                                            onClick={() => this.onRemoveBlemItem(idx)}
                                          >
                                            X
                                          </button>
                                    </td>
                                  </tr>
                                 
                                  </tbody>                                 
                                )
                              })                
                            }   
                                          
                        </table>  
                       
                        <br/>
                        &nbsp; {this.state.orderAddMessage}
                        <br/>       
                         <input type="button" value="Submit Order" onClick={this.handleSubmit} className="btn btn-primary btn-sm" disabled={this.state.disableAddOrder}/>
                        
                      </div>
                    </div> 
              </div>
      
            </div>                      

         </div>

         <Modal

            isOpen={this.state.filesModalIsOpen}
            onAfterOpen={this.afterOpenFilesModal}
            onRequestClose={this.closeFilesModal}
            style={customStyles}
            contentLabel="Add Files"
          >                   
         
            <div className="container-fluid">               
            
              <h2>Add Files</h2>
                  <div className="row">
                    <div className="col-sm-12">                                      
                      Select and add a file.<br/>
                       <form onSubmit={this.handleAttach}> 
                         <input type="file" id="myFile" name="myFile" />
                         <input type="submit" value="Upload a file"/>&nbsp;&nbsp;
                         <button onClick={this.closeFilesModal} style={cancelButton}>Cancel</button>
                      </form>                   
                                     
                    </div>
                        
                  </div>

              <div className="modalMessage">{this.state.filesModalMessage}</div>              

             </div>                          
            
          </Modal>

         </div>

         

                )
    }
}
export default OrderEntryContent;